import { forwardRef } from "react";
import styled from "@emotion/styled";
import { Input as BaseInput, InputProps } from "antd";

export const Input = styled(
  forwardRef<HTMLInputElement, InputProps>((props, ref) => (
    <BaseInput {...props} />
  ))
)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.component.border["2"]};
  border-radius: ${({ theme }) => theme.roundness.sm};
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};

  &:focus {
    border-color: ${({ theme }) => theme.colors.action.primary.default};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
`;
