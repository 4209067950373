import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "@dip/theme";
import { SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { Popover } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

const TopBar = styled(Spacing)`
  height: 4rem;
  padding: 0.75rem 1rem;
`;

const IconWrapper = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderBar = ({ children, ...props }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { logout, loginWithRedirect } = useAuth0();

  return (
    <TopBar
      direction="horizontal"
      justify="space-between"
      content="center"
      {...props}
    >
      <Popover
        placement="bottomLeft"
        content={
          <Spacing>
            <SecondaryButton onClick={() => loginWithRedirect()}>
              Switch Organization
            </SecondaryButton>
            <SecondaryButton
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Logout
            </SecondaryButton>
          </Spacing>
        }
      >
        <IconWrapper
          icon={UnlikelyAiLogo}
          fill={theme.colors.action.primary.default}
          size="1.5rem"
          onClick={() => navigate("/")}
        />
      </Popover>
      {children}
    </TopBar>
  );
};
