import { CSSProperties } from "react";
import { Combobox } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const iconStyle: CSSProperties = { maxWidth: 12 };

export const optionStyle: CSSProperties = {
  color: "#111827",
  cursor: "pointer",
  display: "flex",
  fontSize: "0.85rem",
  gap: "0.5rem",
  padding: "0.5rem 1rem",
};

interface ColorOptions {
  background?: CSSProperties["background"];
  textColor?: CSSProperties["color"];
}

export interface Colors {
  selected?: ColorOptions;
  unselected?: ColorOptions;
}

interface AutoCompleteOptionProps {
  isActive: boolean;
  value: string;
  colors?: Colors;
}

const AutoCompleteOption = ({
  isActive,
  value,
  colors,
}: AutoCompleteOptionProps) => (
  <Combobox.Option
    key={value}
    style={{
      ...optionStyle,
      ...(isActive ? colors?.selected : colors?.unselected),
    }}
    value={value}
  >
    <ArrowRightIcon style={iconStyle} />
    {value}
  </Combobox.Option>
);

export default AutoCompleteOption;
