import React from "react";
import styled from "@emotion/styled";
import { Row, Table, createColumnHelper } from "@tanstack/react-table";
import { Input } from "antd";
import { Column } from "./StyledTable.types";

const CenteredDiv = styled.div`
  height: 100%;
  width: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

/**
 * Generates the required column structure for react-tables to be able to render tables.
 * @param {Column[]} columns Columns list passed into the styled table component from calling
 * @param {boolean} selectionEnabled Whether or not the table should have a selection column
 * utility
 */
const columnGenerator = <T,>(
  columns: Column<T>[],
  selectionEnabled?: boolean
) => {
  const columnHelper = createColumnHelper<T>();

  const Checkbox = (checked: boolean, onChange: () => void) => (
    <CenteredDiv>
      <Input
        type={"checkbox"}
        style={{ width: "16px", height: "16px" }}
        checked={checked}
        onChange={() => onChange()}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      />
    </CenteredDiv>
  );

  const selectionColumn = selectionEnabled
    ? [
        {
          id: "selection",
          cell: ({ row }: { row: Row<T> }) =>
            Checkbox(row.getIsSelected(), row.toggleSelected),
          header: ({ table }: { table: Table<T> }) =>
            Checkbox(table.getIsAllRowsSelected(), table.toggleAllRowsSelected),
          minSize: 15,
          maxSize: 15,
          size: 15,
        },
      ]
    : [];
  return [
    ...selectionColumn,
    ...columns.map((column) => {
      return columnHelper.accessor((row: T) => row[column.id] as string, {
        id: column.id as string,
        cell: (info) =>
          column.cellRenderer
            ? column.cellRenderer(info.getValue(), info.row.original)
            : info.getValue(),
        header: () =>
          column.headerRenderer
            ? column.headerRenderer(column.displayLabel)
            : column.displayLabel,
        minSize: column.minWidth ?? undefined,
        maxSize: column.maxWidth ?? undefined,
        size: column.width ?? undefined,
      });
    }),
  ];
};

export { columnGenerator };
