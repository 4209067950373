import { NodeEntry, Text } from "slate";
import { DecorationEditor, RangeWithDecorations } from "../types/customTypes";

type CharInfo = { lineNumber: number; charNumber: number };
type ParseError = {
  description: string | null;
  start: CharInfo | null;
  end: CharInfo | null;
};

export const parseErrorDecoration =
  (error: ParseError) =>
  (_: DecorationEditor) =>
  ([node, path]: NodeEntry) => {
    if (!Text.isText(node)) return [];

    const { start, end } = error;
    const { text } = node;

    const errorLineStart = start?.lineNumber ?? 1;
    const errorLineEnd = end?.lineNumber ?? Infinity;

    /* In its current setup, Slate views the entire input as one block with newline characters instead of having different blocks
    (i.e. paths) for each line. This means that we need to split the input on the newline character in order to process it line-by-line, which
    is the easiest way to match the input with the ParseError expected by the function. To that end, the reduce below iterates over each of the
    lines and creates a Slate range for each line that highlights the incorrect text within that line. */
    const lines = text.split("\n");
    const { ranges } = lines.reduce<{
      ranges: RangeWithDecorations[];
      totalCharCount: number;
    }>(
      ({ ranges, totalCharCount }, currentLine, i) => {
        // lines are 0-indexed, but the error line and char numbers begin at "1"
        const currentLineNumber = i + 1;
        const currentLineCharCount = currentLine.length;
        // Since the text was split on the newline ("\n") character, we need to account for the newline character when calculating the new character count
        const newCharCount = currentLineCharCount + 1;

        // if the current line is not within the error range, add the line's character count to the total count and proceed to the next line
        if (
          !(
            errorLineStart <= currentLineNumber &&
            currentLineNumber <= errorLineEnd
          )
        )
          return {
            ranges,
            totalCharCount: totalCharCount + newCharCount,
          };

        // by keeping track of the leading whitespace count, we can remove the red squiggly from any leading whitespace characters
        const leadingWhitespaceCount =
          currentLineCharCount - currentLine.trimStart().length;

        /* If the line we are processing is the same line where the error starts, we should start the error decoration at start.charNumber.
        Otherwise, we should start the error at the first character after the leading whitespace.
        Finally, since Slate sees the input as one entire line, we need to move the offset by the number of characters processed so far,
        i.e. totalCharsWithNewlines */
        const startOffset =
          (currentLineNumber === errorLineStart && start?.charNumber
            ? start.charNumber
            : leadingWhitespaceCount) + totalCharCount;

        /* If the line we are processing is the same line where the error ends, we should end the error decoration at end.charNumber.
        Otherwise, the error should extend across the entire line. Note that this will include trailing whitespace characters.
        Finally, since Slate sees the input as one entire line, we need to move the offset by the number of characters processed so far,
        i.e. totalCharsWithNewlines */
        const endOffset =
          (currentLineNumber === errorLineEnd && end?.charNumber
            ? end.charNumber
            : currentLineCharCount) + totalCharCount;

        /* If the start and the end offset are the same, it means we only need to highlight one character, so the start character gets moved back
        by a space so that the correct character is highlighted. */
        const finalStartOffset =
          startOffset === endOffset ? startOffset - 1 : startOffset;

        return {
          ranges: [
            ...ranges,
            {
              anchor: { path, offset: finalStartOffset },
              focus: { path, offset: endOffset },
              parseError: true,
            },
          ],
          totalCharCount: totalCharCount + newCharCount,
        };
      },
      { ranges: [], totalCharCount: 0 }
    );

    return ranges;
  };
