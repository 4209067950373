import { Navigate, Route, Routes } from "react-router-dom";
import { config } from "@dip/config";
import {
  AblyProviderWrapper,
  RealtimeProvider,
} from "@dip/data-access/dip-api-service";
import { CreateDomainFlowEntry, Dashboard } from "@dip/ui/features/dashboard";
import { DecisionTreePage } from "@dip/ui/features/decision-tree";
import {
  AnsweringPage,
  DomainLayout,
  DomainProvider,
  UnderstandingDomainPage,
  UnderstandingGeneralExclusionsPage,
  UnderstandingLayout,
  UnderstandingScenarioPage,
} from "@dip/ui/features/domain";
import { AppLayout } from "@dip/ui/layouts";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { RequireAuth } from "./RequireAuth";

export const AppRouter = withLDProvider({
  clientSideID: config.LAUNCH_DARKLY_CLIENT_TOKEN,
})(() => (
  <RequireAuth>
    <AblyProviderWrapper>
      <Routes>
        <Route element={<AppLayout />}>
          <Route element={<RealtimeProvider />}>
            <Route index element={<Dashboard />} />
            <Route path="create" element={<CreateDomainFlowEntry />} />
            <Route path="domains/:domainId" element={<DomainProvider />}>
              <Route element={<DomainLayout />}>
                <Route path="tree" element={<DecisionTreePage />} />
                <Route path="understanding" element={<UnderstandingLayout />}>
                  <Route index element={<UnderstandingDomainPage />} />
                  <Route
                    path="general-exclusions"
                    element={<UnderstandingGeneralExclusionsPage />}
                  />
                  <Route
                    path="scenarios/:scenarioId"
                    element={<UnderstandingScenarioPage />}
                  />
                </Route>
                <Route path="answering" element={<AnsweringPage />} />
              </Route>
              <Route index element={<Navigate replace to="answering" />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AblyProviderWrapper>
  </RequireAuth>
));
