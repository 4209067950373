import { MouseEvent } from "react";
import { NavigateFunction } from "react-router-dom";

/** An onClick handler that respects the user's preference for opening links in a new tab. */
export function onClickRespectsNewTab(
  url: string,
  event: MouseEvent,
  navigate: NavigateFunction
): void {
  if (event.ctrlKey || event.metaKey) {
    window.open(url, "_blank");
  } else {
    navigate(url);
  }
}

/**
 * Returns an object that can be passed to the AntD Table component's onRow parameter. The object
 * specifies to navigate to a URL when a row is clicked, respecting the user's preference for
 * opening links in a new tab.
 * */
export function navigateOnTableRowClick(
  url: string,
  navigate: NavigateFunction
) {
  return {
    onClick: (event: MouseEvent) => onClickRespectsNewTab(url, event, navigate),
    onMouseDown: (event: MouseEvent) => {
      if (event.button === 1) {
        window.open(url, "_blank");
      }
    },
  };
}
