import React, { FunctionComponent, ReactElement } from "react";
import reactElementToJSXString from "react-element-to-jsx-string";
import { List } from "antd";
import { CopyButton } from "../components/CopyButton";
import { UlSerializer } from "../ul/UlSerializer";
import { UlElement } from "../ul/ul-element";
import { GraphNode, StepType } from "./reasoning-data";

/**
 * Returns details of a reasoning step as a string. This function returns a string rather
 * than a JSX object because react-force-graph requires that.
 */
export function getStepDetailString(reasoningGraph: GraphNode): string {
  return getStepDetailContents(reasoningGraph, true, true) as string;
}

export type StepDetailsProps = {
  reasoningGraph: GraphNode;
  /**
   * Show less information to conserve space - specifically only a sample of the first 4 solutions.
   */
  compact?: boolean;
};

/**
 * Returns an Element containing details of a reasoning step.
 */
export const StepDetails: FunctionComponent<StepDetailsProps> = ({
  reasoningGraph,
  compact = false,
}) => {
  return getStepDetailContents(reasoningGraph, false, compact) as ReactElement;
};

/**
 * Returns details of a reasoning step as either a string or an Element.
 */
function getStepDetailContents(
  reasoningGraph: GraphNode,
  stringify: boolean,
  compact: boolean
): ReactElement | string {
  const compactSampleLength = 4;

  if (reasoningGraph.stepType !== StepType.QUERY) {
    const nonQueryHtml = (
      <UlSerializer element={reasoningGraph.query}>
        {(psg) => (
          <ul>
            <li>
              <strong>Data</strong>: {psg}
              {!stringify && <CopyUlButton ulToCopy={reasoningGraph.query} />}
            </li>
            <li>
              <strong>Budget</strong>: {reasoningGraph.budget}
            </li>
            <li>
              <strong>State</strong>: {reasoningGraph.state}
            </li>
          </ul>
        )}
      </UlSerializer>
    );
    return stringify ? reactElementToJSXString(nonQueryHtml) : nonQueryHtml;
  }

  const constraintsBullets =
    Object.keys(reasoningGraph.constraints).length === 0 ? (
      "0"
    ) : (
      <ul>
        {Array.from(reasoningGraph.constraints).map((keyValue) => {
          const unknown = keyValue[0];
          const constraint = keyValue[1];
          const constraintBullets = constraint.constraintsSample.map(
            (constraint: UlElement) => {
              return (
                <UlSerializer
                  key={JSON.stringify(constraint)}
                  element={constraint}
                >
                  {(constraintString) => (
                    <li>
                      {constraintString}
                      {!stringify && <CopyUlButton ulToCopy={constraint} />}
                    </li>
                  )}
                </UlSerializer>
              );
            }
          );

          return (
            <UlSerializer key={JSON.stringify(unknown)} element={unknown}>
              {(unknownString) => (
                <li>
                  {unknownString}: ({constraint.numConstraints})
                  <ul>
                    {constraintBullets}
                    {constraint.numConstraints >
                      constraint.constraintsSample.length && <li>...</li>}
                  </ul>
                </li>
              )}
            </UlSerializer>
          );
        })}
      </ul>
    );

  // Each solution maps unknowns to grounded values, if we have more than one pair in this mapping we want to display it differently.
  const singleSolutions =
    Array.from(reasoningGraph.solutionsSample[0] ?? []).length <= 1;
  const solutions = reasoningGraph.solutionsSample.map((solution) => {
    return (
      <List.Item key={JSON.stringify(solution)}>
        {Array.from(solution).map((keyValue) => (
          <UlSerializer key={JSON.stringify(keyValue[0])} element={keyValue[0]}>
            {(key) => (
              <UlSerializer element={keyValue[1]}>
                {(value) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {`${key} = ${value}`}
                    <CopyUlButton ulToCopy={keyValue[1]} />
                  </div>
                )}
              </UlSerializer>
            )}
          </UlSerializer>
        ))}
      </List.Item>
    );
  });

  const html = (
    <UlSerializer element={reasoningGraph.query}>
      {(psg) => (
        <div>
          <strong>Query</strong>: {psg}
          {!stringify && <CopyUlButton ulToCopy={reasoningGraph.query} />}
          <ul>
            <li>
              <strong>Constraints</strong>:{constraintsBullets}
            </li>
            <li>
              <strong>Budget</strong>: {reasoningGraph.budget}
            </li>
            <li>
              <strong>State</strong>: {reasoningGraph.state}
            </li>
          </ul>
          <strong>Solutions</strong>: {reasoningGraph.numSolutions}
          {solutions.length > 0 && (
            <List
              dataSource={
                solutions.length <= compactSampleLength
                  ? solutions
                  : compact
                    ? solutions
                        .slice(0, compactSampleLength)
                        .concat([
                          <List.Item key="truncated">{"..."}</List.Item>,
                        ])
                    : solutions
              }
              renderItem={(item) => item}
              split={!singleSolutions}
              size={"small"}
            />
          )}
        </div>
      )}
    </UlSerializer>
  );
  return stringify ? reactElementToJSXString(html) : html;
}

export const CopyUlButton: FunctionComponent<{ ulToCopy: UlElement }> = ({
  ulToCopy,
}) => {
  return (
    // Make sure the copied text is usable elsewhere in the UI by never using pseudo-nicknames or
    // truncated UUIDS.
    <UlSerializer element={ulToCopy} truncateUuids={false} onlyRealNicknames>
      {(ulString) => <CopyButton strToCopy={ulString} />}
    </UlSerializer>
  );
};
