import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  SecondaryButtonProps,
  SecondaryButton as UnlikelySecondaryButton,
} from "@unlikelyai-magic/ui/buttons";

export const SecondaryButton = styled(
  ({ size, ...props }: SecondaryButtonProps) => (
    <UnlikelySecondaryButton {...props} />
  )
)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary.default};
    box-shadow: none !important;
    border: 0.0625rem solid ${theme.colors.component.background[2]};
    background: ${theme.colors.background.default};
    &:hover,
    &:focus,
    &:active,
    &.ant-btn-loading {
      background: ${theme.colors.action.secondary.hover};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.primary.default};
      background: ${theme.colors.component.background[1]};
      cursor: not-allowed;
    }
  `}
`;
