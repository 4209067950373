import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ExpertiseType,
  FeedbackRequestedFrom,
} from "@dip/data-access/api-types";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { PageSpinner, UnlikelyAISpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { useReasoningGraph } from "../../hooks";
import { ChatContainer, ReasoningGraphTabs } from "./components";
import { DomainExpertState } from "./types";

const ChatPageContainer = styled.div`
  // The height of the header bar is 3rem
  height: calc(100vh - 3rem);
  display: flex;
`;

const ChatBoxContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const ChatBox = styled(ChatContainer)`
  width: 50vw;
  padding: 1rem 0;
`;

const EmptyStateSpacing = styled(Spacing)`
  height: 20%;
  width: 100%;
  align-self: center;
`;

const OopsText = styled(Heading)`
  font-family: Knewave;
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: 400;
`;

const EmptyStateText = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const EmptyStateButton = styled(SecondaryButton)`
  border-radius: ${({ theme }) => theme.roundness.sm};
  border: 0.0625rem solid ${({ theme }) => theme.colors.text.secondary.default};
  height: 2.25rem;
`;

const ReasoningGraphTabContainer = styled(motion.div)`
  display: flex;
  overflow: hidden;
  width: 50vw;
`;

export const AnsweringPage = () => {
  const navigate = useNavigate();
  const { domainId } = useParams();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  const { activeKey } = useReasoningGraph();

  const versions = data?.versions;
  const latestVersion = versions ? versions[versions.length - 1] : null;
  const [domainExpertState, setDomainExpertState] =
    useState<DomainExpertState>();

  useEffect(() => {
    if (!latestVersion) return;
    setDomainExpertState({
      expertContext: {
        expertiseType: ExpertiseType.UL,
        representation: latestVersion.representation,
        messageHistory: [],
        feedback: [],
      },
      understandingVersion: latestVersion,
    });
  }, [latestVersion]);

  if (!data) return <PageSpinner />;

  const currentVersion = domainExpertState?.understandingVersion;

  const isDomainUnderstood =
    currentVersion?.feedbackRequestedFrom !== FeedbackRequestedFrom.HUMAN;

  const isDomainReadyForQuestions = currentVersion && isDomainUnderstood;

  const handleEmptyStateButtonClick = () =>
    navigate(`/domains/${domainId}/understanding`);

  const slideOutAnimationDuration = 0.4;

  return (
    <ChatPageContainer>
      {isDomainReadyForQuestions ? (
        <AnimatePresence>
          <ChatBoxContainer
            initial={{ flex: 1 }}
            animate={{ flex: activeKey ? 2 : 1 }}
            transition={{ duration: slideOutAnimationDuration }}
          >
            <ChatBox domainName={data.title} />
          </ChatBoxContainer>
          <ReasoningGraphTabContainer
            key={domainId}
            initial={{ flex: 0 }}
            animate={{ flex: activeKey ? 1.75 : 0 }}
            transition={{ duration: slideOutAnimationDuration }}
          >
            <ReasoningGraphTabs />
          </ReasoningGraphTabContainer>
        </AnimatePresence>
      ) : (
        <EmptyStateSpacing gap="lg" justify="center" items="center">
          {isDomainUnderstood ? (
            <UnlikelyAISpinner />
          ) : (
            <OopsText level="h1">Oops!</OopsText>
          )}
          <EmptyStateText level="h4">
            {currentVersion
              ? "Unable to understand the policy"
              : "Understanding policy..."}
          </EmptyStateText>
          <EmptyStateButton onClick={handleEmptyStateButtonClick}>
            {currentVersion ? "Review" : "View progress"}
          </EmptyStateButton>
        </EmptyStateSpacing>
      )}
    </ChatPageContainer>
  );
};
