import React from "react";
import InfoCircleTwoTone from "@ant-design/icons/InfoCircleOutlined";
import { Card, Space, Tooltip } from "antd";
import { ResponseTopLevelAnswer } from "../../reasoning-engine/reasoning-engine";
import SolutionCard from "./SolutionCard";

interface SolutionCardGridProps {
  topLevelAnswers: ResponseTopLevelAnswer[];
  setExplainedAnswer: (resp: ResponseTopLevelAnswer) => void;
  setGraphAnswer?: (resp: ResponseTopLevelAnswer) => void;
}

type SolutionCardGridWithGraveyardProps = SolutionCardGridProps & {
  invalidatedAnswerIds: string[];
};

/**
 * Solution grid for a set of solutions
 */
export const SolutionCardGrid = (props: SolutionCardGridProps) => {
  const { topLevelAnswers, setExplainedAnswer, setGraphAnswer } = props;

  return (
    <Space
      style={{
        display: "grid",
        // This sets each column to have a minimum width of 350px, but increases their widths
        // evenly beyond that to fill the container
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, auto))",
      }}
    >
      {topLevelAnswers.map((s) => (
        <SolutionCard
          key={s.answer.solutionId}
          topLevelAnswer={s}
          onExplanationRequested={() => setExplainedAnswer(s)}
          onGraphRequested={
            (setGraphAnswer && (() => props.setGraphAnswer?.(s))) || undefined
          }
        />
      ))}
    </Space>
  );
};

/**
 * Element containing a grid for valid solutions and grid (graveyard) of invalidated solutions below
 */
export const SolutionCardGridWithGraveyard = (
  props: SolutionCardGridWithGraveyardProps
) => {
  return (
    <>
      <SolutionCardGrid
        topLevelAnswers={props.topLevelAnswers.filter(
          (s) => !props.invalidatedAnswerIds.includes(s.answer.solutionId)
        )}
        setExplainedAnswer={props.setExplainedAnswer}
        setGraphAnswer={props.setGraphAnswer}
      />
      {props.invalidatedAnswerIds.length > 0 && (
        <Card
          title={
            <>
              Solution graveyard
              <sup>
                <Tooltip
                  title="Previously emitted solutions which have since been invalidated, e.g.
              reducer solutions outdated by a newer result."
                >
                  <InfoCircleTwoTone />
                </Tooltip>
              </sup>
            </>
          }
          size={"small"}
          style={{ marginTop: "5em" }}
          headStyle={{ backgroundColor: "grey", color: "black" }}
          bodyStyle={{ backgroundColor: "lightgrey" }}
        >
          <SolutionCardGrid
            topLevelAnswers={props.topLevelAnswers.filter((s) =>
              props.invalidatedAnswerIds.includes(s.answer.solutionId)
            )}
            setExplainedAnswer={props.setExplainedAnswer}
            setGraphAnswer={props.setGraphAnswer}
          />
        </Card>
      )}
    </>
  );
};
