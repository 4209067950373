import { ButtonProps } from "../../types";
import { SecondaryButton } from "../SecondaryButton/SecondaryButton";
import { GoogleIcon } from "./GoogleIcon";

export type GoogleSocialButtonProps = ButtonProps;

export const GoogleSocialButton = ({
  icon = GoogleIcon,
  children = "Continue with Google",
  ...props
}: GoogleSocialButtonProps) => (
  <SecondaryButton {...props} icon={icon}>
    {children}
  </SecondaryButton>
);
