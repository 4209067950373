export const ALL_SUBSEQUENT_NODES_HAVE_CLASS = {
  uuid: "c80b93ee-6ec3-4c03-8140-d341158c24b3",
};
export const AT_LEAST = { uuid: "6c28f8b7-bd3c-4bd0-b93b-483819b394ab" };
export const HAS_SUBSEQUENT_NODES = {
  uuid: "addea47f-f92d-4f8d-84fd-aff23f9aee4b",
};
export const INTEGER = { uuid: "6831b318-0053-4bc4-8648-4083d05ea88c" };
export const LIST = { uuid: "fdf2224a-7ef5-4354-9810-5350e042de54" };
export const SUBSEQUENT_NODES_HAVE_CLASSES = {
  uuid: "a979c3ea-dcea-4697-86ae-e271d0eaa6d6",
};
export const IS_SUBCLASS_OF = { uuid: "8b77adb2-8a8b-4135-90f1-aeecc63a5386" };

export const CLASS = { uuid: "7e27973e-742d-4259-b00c-4369ffd56b14" };
export const ATTRIBUTE = { uuid: "fb527637-f6a2-480a-be04-62af2f67d9fd" };
export const INTRODUCTORY_NODE = {
  uuid: "b0ea2ec9-baeb-4791-8367-a15e9ab2a1f3",
};
export const RELATION = { uuid: "8f012528-ddca-49c2-8fcb-3f5247f5d5c5" };

export const IS_A = { uuid: "03fa7cd0-9ec9-4366-a97e-638c2e010d31" };
export const HAS_ENGLISH_DESCRIPTION = {
  uuid: "354d8daa-cc09-4197-b5a1-63deb23fd648",
};
export const EXPRESSED_IN = { uuid: "26027b28-4742-4c02-944b-e93db7d811b4" };
export const NATURAL_LANGUAGE = {
  uuid: "7b11820f-d3f4-48ca-9ed2-9620f33ceb9f",
};
export const IS_SUB_RELATION_OF = {
  uuid: "cb6a7fba-a26e-45e0-a40c-8c3266bc520b",
};
export const HAS_SCOPE = { uuid: "4544f932-7b49-471f-94fd-5946893f1935" };
export const TRANSITIVE = { uuid: "2b807de3-7404-40a3-9613-727e65f26def" };
export const SYMMETRIC = { uuid: "86da4080-0b60-4ff5-a9ac-35d9e092f643" };
export const ASYMMETRIC = { uuid: "4bb8f512-c852-4506-8b89-095f3b49a299" };
export const REFLEXIVE = { uuid: "43e489fe-e103-4d27-9a77-2e6cb64cb352" };
export const IRREFLEXIVE = { uuid: "006f5374-bfbf-4904-9c60-d0771799a15e" };
export const ANTISYMMETRIC = { uuid: "c3a1931f-0147-4990-96e8-043ed40a5418" };
export const HAS_ATTRIBUTE = { uuid: "b04d228e-66e1-4fbe-88ca-b04470d0dc13" };
export const IS_A_MORE_SPECIFIC_FORM_OF_THE_ATTRIBUTE = {
  uuid: "08205ab6-b615-4d66-8238-cf284d97cbda",
};
export const ARE_MUTUALLY_EXCLUSIVE_CLASSES = {
  uuid: "0d043360-9a66-4511-a07d-bae455c60fb2",
};
export const ARE_MUTUALLY_EXCLUSIVE_ATTRIBUTES = {
  uuid: "dade2e66-e5c2-41ab-9572-3c677dcc2971",
};
