import { theme } from "@dip/theme";
import { Tag } from "@dip/ui/components/tags";

type NodeResolutionTagProps = {
  resolved: boolean;
};

export const NodeResolutionTag = ({ resolved }: NodeResolutionTagProps) => (
  <Tag
    color={
      resolved
        ? theme.colors.semantic.success.default
        : theme.colors.semantic.warning.default
    }
  >
    {resolved ? "Resolved" : "Coined"}
  </Tag>
);
