import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import {
  DocumentContentDto,
  FeedbackRequestedFrom,
  NodeDto,
  isErrorWithDataMessage,
} from "@dip/data-access/api-types";
import {
  RealtimeChannel,
  useAppSelector,
  useCreateDomainUnderstandingVersionMutation,
  useCreateFeedbackMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { PromptInput } from "@dip/ui/components/inputs";
import { Popover } from "@dip/ui/components/popover";
import { PageSpinner, Spinner } from "@dip/ui/components/spinners";
import { Tabs, TabsProps } from "@dip/ui/components/tabs";
import { CodeTextBox, NLTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { ChannelProvider } from "ably/react";
import moment from "moment";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { UnderstandingLoadingCard } from "../UnderstandingLoadingCard";
import { DomainNodes } from "./DomainNodes";
import { ULFieldName, ULTabContent } from "./ULTabContent";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  // 3rem is the size of the header bar
  height: calc(100vh - 3rem);

  @media (min-width: ${screenBreakpoints.lg}) {
    grid-template-columns: 0.5fr 1fr;
  }
`;

const Representation = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
  /* TODO: We probably shouldn't use vh here, but this appears to be the only height setting that fixes the height when changing tabs.
  https://linear.app/unlikelyai/issue/UA-402/ul-tab-creates-a-scroll-on-the-whole-page-when-content-overflows */
  height: calc(100vh - 3rem);
`;

const RepresentationTabs = styled(Tabs)<{ isDisabled: boolean }>`
  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: 0 1rem;
  }

  .ant-tabs-content-holder {
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.component.background[1]};
    opacity: ${({ isDisabled }) => isDisabled && "0.5"};
  }

  height: 100%;
  overflow: hidden;
`;

const UnsavedChangesDot = styled(Paragraph)`
  font-size: 3rem;
`;

const ProvideFeedbackInput = styled(PromptInput)`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
`;

const UpdatedAt = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.lg};
`;

const SuccessOrErrorIconContainer = styled(Spacing)`
  padding-right: 1rem;
`;

interface TopLevelLayoutProps {
  descriptionFieldName: keyof DocumentContentDto;
  naturalLanguageFieldName: "exclusionsCommentary" | "representationCommentary";
  universalLanguageFieldName: ULFieldName;
}

export const TopLevelLayout = ({
  descriptionFieldName,
  naturalLanguageFieldName,
  universalLanguageFieldName,
}: TopLevelLayoutProps) => {
  const { domainId } = useParams();
  const tenantId = useAppSelector(({ auth }) => auth.tenantId);
  const { data, isFetching: isFetchingDomain } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  const [createFeedback] = useCreateFeedbackMutation();
  const [
    createManualDomainUnderstandingVersion,
    {
      isLoading: isNewVersionLoading,
      isError: isCreateNewVersionError,
      error: createNewVersionError,
    },
  ] = useCreateDomainUnderstandingVersionMutation();

  const isLoading = isFetchingDomain || isNewVersionLoading;

  const { numOfRefinements } = useSettingsSidebar();

  const [hasUnsavedULChanges, setHasUnsavedULChanges] = useState(false);

  /* We assume there is only one understanding per domain. Eventually we will support multiple
  understandings and this will need to be updated. */
  const understanding = data?.understandings[0];
  const versions = understanding?.versions;
  const latestVersion = versions?.[versions.length - 1];
  const showFeedbackInput =
    // If feedback is requested from a human, show the input
    latestVersion?.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN ||
    // If we are done understanding, we should still show the input because
    // the user may still have feedback to apply.
    latestVersion?.feedbackRequestedFrom === null;

  const handleFeedback = async (value: string) => {
    if (!data || !understanding) return;

    await createFeedback({
      feedback: value,
      domainId: data.id,
      domainUnderstandingId: understanding.id,
      refinements: numOfRefinements,
    });
  };

  const handleUpdate = ({
    representation,
    nodes,
  }: {
    representation?: string[];
    nodes?: NodeDto[];
  }) => {
    if (!data || !understanding) return;

    createManualDomainUnderstandingVersion({
      domainId: data.id,
      domainUnderstandingId: understanding.id,
      representation: representation ?? latestVersion?.representation ?? [],
      exclusions: latestVersion?.exclusions ?? [],
      nodes: nodes ?? latestVersion?.nodes ?? [],
    });
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "0",
      label: (
        <Spacing direction="horizontal" items="center" gap="sm">
          <Paragraph small>Natural language</Paragraph>
          {isLoading && <Spinner size="1.25rem" />}
        </Spacing>
      ),
      children: (
        <CodeTextBox
          value={(latestVersion?.[naturalLanguageFieldName] ?? []).map(
            (rule, i) => `Rule ${i + 1}: ${rule}`
          )}
        />
      ),
    },
    {
      key: "1",
      label: (
        <Spacing direction="horizontal" items="center" gap="sm">
          <Paragraph small>Universal language</Paragraph>
          {hasUnsavedULChanges && <UnsavedChangesDot>·</UnsavedChangesDot>}
        </Spacing>
      ),
      children: domainId && understanding && (
        <ULTabContent
          rules={latestVersion?.[universalLanguageFieldName] ?? []}
          setHasUnsavedTabContent={setHasUnsavedULChanges}
          onSave={(representation) => handleUpdate({ representation })}
          isDisabled={isLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Nodes",
      children: (
        <DomainNodes
          nodes={latestVersion?.nodes ?? []}
          onUpdate={(nodes) => handleUpdate({ nodes })}
          isLoading={isLoading}
        />
      ),
    },
  ];

  if (!data) return <PageSpinner />;

  const errorMessage = isCreateNewVersionError
    ? isErrorWithDataMessage(createNewVersionError)
      ? createNewVersionError.data.message
      : "An unexpected error occurred"
    : "";

  return (
    <Layout>
      <NLTextBox
        header="Description"
        content={data.content[descriptionFieldName]}
      />
      {latestVersion ? (
        <Representation>
          <RepresentationTabs
            items={tabItems}
            isDisabled={isLoading}
            tabBarStyle={{ margin: 0 }}
            tabBarExtraContent={{
              right: (
                <SuccessOrErrorIconContainer>
                  <Popover
                    content={errorMessage}
                    showArrow={false}
                    placement="bottomRight"
                    overlayStyle={{ padding: "0 1rem" }}
                  >
                    <Icon
                      icon={
                        isCreateNewVersionError
                          ? MinusCircleIcon
                          : CheckCircleIcon
                      }
                      size="1.25rem"
                      color={
                        isCreateNewVersionError
                          ? theme.colors.semantic.error.default
                          : theme.colors.semantic.success.default
                      }
                    />
                  </Popover>
                </SuccessOrErrorIconContainer>
              ),
            }}
          />
          {showFeedbackInput && (
            <ProvideFeedbackInput
              placeholder="Provide feedback..."
              placeholderColor={theme.colors.text.primary.default}
              onSubmit={handleFeedback}
            />
          )}
          <UpdatedAt small>{`Last updated ${moment(
            new Date(latestVersion.dateCreated)
          ).fromNow()}`}</UpdatedAt>
        </Representation>
      ) : (
        understanding && (
          <ChannelProvider
            channelName={`${tenantId}:${RealtimeChannel.PIPELINE}:${understanding.id}`}
            /* Get the previous Ably message so we know which state the domain understanding is in. This ensures
            the backend does not have to write the status of the domain understanding to the database each time the status
            changes. The change in status is already encoded in Ably, and so we can simply pull it from there. */
            options={{ params: { rewind: "1" } }}
          >
            <UnderstandingLoadingCard
              domainUnderstandingId={understanding.id}
            />
          </ChannelProvider>
        )
      )}
    </Layout>
  );
};
