import React, { FunctionComponent, useEffect } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Form, Input, Popconfirm, Tooltip } from "antd";

interface SearchBarProps {
  reloadButtonProps?: {
    reloadButtonEnabled: boolean;
    isLoading: boolean;
    onReloadClick: () => void;
  };
  deleteButtonProps?: {
    deleteButtonEnabled: boolean;
    onDeleteClick: () => void;
    deleteButtonText: string;
    isLoading?: boolean;
  };
  addButtonProps?: {
    addButtonEnabled: boolean;
    onAddClick: () => void;
    addButtonText: string;
  };
  searchInputProps?: {
    searchInputEnabled: boolean;
    onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    initialSearchTerm?: string;
  };
}

const ToolBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  width: 100%;
`;
const LeftHandContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const TableToolbar: FunctionComponent<SearchBarProps> = (props) => {
  const [inputForm] = Form.useForm();

  useEffect(() => {
    inputForm.setFieldsValue({
      searchTerm: props.searchInputProps?.initialSearchTerm ?? "",
    });
  }, [props.searchInputProps?.initialSearchTerm]);

  const AddButton = (
    <Button
      icon={<PlusOutlined />}
      type={"primary"}
      style={{ borderRadius: 2 }}
      onClick={props.addButtonProps?.onAddClick}
    >
      {props.addButtonProps?.addButtonText}
    </Button>
  );

  const DeleteButton = (
    <Popconfirm
      placement="bottomRight"
      title="Are you sure you want to go ahead with the deletion?"
      onConfirm={props.deleteButtonProps?.onDeleteClick}
      okText="Yes"
      cancelText="No"
    >
      <Button
        danger
        style={{ borderRadius: 2 }}
        loading={props.deleteButtonProps?.isLoading}
      >
        {props.deleteButtonProps?.deleteButtonText}
      </Button>
    </Popconfirm>
  );

  const ReloadButton = (
    <Tooltip placement="bottomLeft" title="Reload Data">
      <Button
        type={"text"}
        icon={<ReloadOutlined />}
        loading={props.reloadButtonProps?.isLoading}
        onClick={props.reloadButtonProps?.onReloadClick}
      />
    </Tooltip>
  );

  const SearchField = (
    <Form
      form={inputForm}
      initialValues={{
        searchTerm: props.searchInputProps?.initialSearchTerm ?? "",
      }}
    >
      <Form.Item name={"searchTerm"} style={{ margin: 0 }}>
        <Input
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={props.searchInputProps?.placeholder}
          onChange={props.searchInputProps?.onSearchInputChange}
          onSubmit={props.searchInputProps?.onSearchInputChange}
          style={{ width: 300, borderRadius: 2, border: "1px solid #e1e3e6" }}
        />
      </Form.Item>
    </Form>
  );

  return (
    <ToolBarContainer>
      <LeftHandContainer>
        {props.searchInputProps?.searchInputEnabled && SearchField}
        {props.addButtonProps?.addButtonEnabled && AddButton}
        {props.deleteButtonProps?.deleteButtonEnabled && DeleteButton}
        {props.reloadButtonProps?.reloadButtonEnabled && ReloadButton}
      </LeftHandContainer>
    </ToolBarContainer>
  );
};
