import React, { ReactNode } from "react";
import { Typography } from "antd";
import { UlSerializer } from "../../ul/UlSerializer";
import { UlElement } from "../../ul/ul-element";

export const StatementModalHeader = ({
  height,
  header,
  nl,
  ul,
}: {
  height?: number;
  header?: ReactNode;
  nl?: string;
  ul?: UlElement;
}) => {
  function OutputCard(translation: string) {
    return (
      <Typography.Text className={"rd-modal-header-ul"} code>
        {translation}
      </Typography.Text>
    );
  }

  return (
    <div
      style={{
        height: height,
        flex: "1",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
      className={"rd-modal-header-in"}
    >
      {header}
      <h3>{nl}</h3>
      {ul && (
        <UlSerializer element={ul} truncateUuids>
          {OutputCard}
        </UlSerializer>
      )}
    </div>
  );
};
