import React from "react";
import { SecondaryButton, TransparentButton } from "@dip/ui/components/buttons";
import { Form, FormItem } from "@dip/ui/components/forms";
import { Input } from "@dip/ui/components/inputs";
import styled from "@emotion/styled";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

const AddExpressedInButton = styled(SecondaryButton)`
  width: 100%;
`;

const ExpressedInFormItem = styled(FormItem)`
  width: 100%;
`;

export const ExpressedInInput = () => {
  return (
    <Form.List
      name="expressedIns"
      rules={[
        {
          validator: async (_, expressedIns) => {
            if (!expressedIns.length)
              return Promise.reject(
                new Error("At least 1 expressed in required")
              );
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <Spacing gap="md">
          {fields.map((field, index) => (
            <Spacing direction="horizontal" gap="sm" key={field.key}>
              <ExpressedInFormItem
                label={index === 0 ? "Expressed In(s)" : ""}
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message:
                      fields.length > 1
                        ? "Please input an expressed in or delete this field."
                        : "Please input an expressed in",
                  },
                ]}
              >
                <Input />
              </ExpressedInFormItem>

              {fields.length > 1 && (
                <TransparentButton
                  icon={<TrashIcon />}
                  onClick={() => remove(field.name)}
                />
              )}
            </Spacing>
          ))}
          <FormItem>
            <AddExpressedInButton onClick={() => add()} icon={<PlusIcon />}>
              Add expressed in
            </AddExpressedInButton>
          </FormItem>
        </Spacing>
      )}
    </Form.List>
  );
};
