import { ComponentProps } from "react";

export const UndrawScientist = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="106"
      height="64"
      viewBox="0 0 106 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1442_4031)">
        <path
          d="M83.4602 43.5761H80.7012L80.6912 43.5661C80.2007 43.0464 79.9799 42.3149 80.1018 41.612C80.1405 41.3844 80.2136 41.1639 80.3198 40.9563L81.1586 39.3172L80.826 33.4464C80.8202 33.3376 80.8575 33.2345 80.9321 33.1544C81.0066 33.0756 81.1084 33.0312 81.2174 33.0312H81.7337C81.9459 33.0312 82.118 33.1973 82.1266 33.4078L82.3517 39.2099L83.5133 41.0222C83.6122 41.1768 83.6925 41.3414 83.7499 41.5118C83.9779 42.1717 83.8947 42.889 83.5204 43.4788L83.4588 43.5747H83.4602V43.5761Z"
          fill="#3F3D56"
        />
        <path
          d="M83.222 43.1694C83.1962 43.2109 83.1503 43.2352 83.1016 43.2352H81.0022C80.962 43.2352 80.9219 43.218 80.8961 43.188C80.5419 42.7857 80.3856 42.2575 80.4472 41.7435C80.4544 41.6877 80.496 41.6405 80.5505 41.6261C80.9893 41.5102 81.5184 41.4443 82.0877 41.4443C82.5337 41.4443 82.9538 41.4859 83.3267 41.5589C83.3783 41.5689 83.4199 41.6061 83.4342 41.6562C83.5834 42.1601 83.5131 42.7127 83.2234 43.1694H83.222Z"
          fill="#FF4338"
        />
        <path
          d="M105.37 44.8171H0.1434C0.0645299 44.8171 0 44.7527 0 44.6739C0 44.5952 0.0645299 44.5308 0.1434 44.5308H105.37C105.449 44.5308 105.514 44.5952 105.514 44.6739C105.514 44.7527 105.449 44.8171 105.37 44.8171Z"
          fill="#3F3D56"
        />
        <path
          d="M30.8952 18.2273C29.3537 17.7663 28.3413 16.5982 28.6353 15.619C28.9292 14.6398 30.4177 14.2189 31.9593 14.6799C32.5773 14.856 33.1452 15.1766 33.6141 15.6161L40.123 17.6504L39.102 20.6795L32.7938 18.3518C32.16 18.462 31.509 18.4191 30.8952 18.2258L30.8952 18.2273Z"
          fill="#A0616A"
        />
        <path
          d="M59.7689 19.0235L59.8478 24.3732C59.8478 24.3732 53.1109 24.2844 50.7534 24.4691C48.3959 24.6552 33.7046 19.2869 33.7046 19.2869L36.1453 15.8325L52.3523 19.7378L59.7689 19.022V19.0235Z"
          fill="#E6E6E6"
        />
        <path
          d="M31.3759 11.748H30.3721V25.3478H31.3759V11.748Z"
          fill="#FF4338"
        />
        <path
          d="M34.9997 44.674H29.1934L29.1719 44.6511C28.1394 43.556 27.6748 42.0185 27.9301 40.5383C28.0118 40.0601 28.1653 39.5963 28.389 39.1597L30.1556 35.7111L29.4544 23.3567C29.4415 23.1277 29.5218 22.9101 29.6796 22.744C29.8373 22.5779 30.051 22.4863 30.2804 22.4863H31.3659C31.8119 22.4863 32.1762 22.8342 32.1934 23.2794L32.6651 35.4906L35.1115 39.3043C35.3209 39.6307 35.4887 39.9771 35.6106 40.335C36.0895 41.7236 35.9146 43.2325 35.1288 44.4736L34.9997 44.6755L34.9997 44.674Z"
          fill="#3F3D56"
        />
        <path
          d="M34.5004 43.8194C34.4459 43.9053 34.3498 43.9583 34.2466 43.9583H29.827C29.7424 43.9583 29.6592 43.9225 29.6033 43.8595C28.8576 43.012 28.5292 41.9026 28.6583 40.8203C28.6726 40.7015 28.7601 40.6041 28.8763 40.5741C29.7983 40.3307 30.9125 40.1904 32.1113 40.1904C33.0492 40.1904 33.9354 40.2778 34.7184 40.4309C34.8259 40.4524 34.9148 40.5297 34.9449 40.6342C35.2575 41.6936 35.1098 42.8574 34.5004 43.8194Z"
          fill="#FF4338"
        />
        <path
          d="M55.4671 11.4618L56.3275 17.3312L49.4443 18.047L49.8745 10.4597L55.4671 11.4618Z"
          fill="#A0616A"
        />
        <path
          d="M48.9236 13.2098C51.4872 13.2098 53.5654 11.1351 53.5654 8.57584C53.5654 6.01658 51.4872 3.94189 48.9236 3.94189C46.36 3.94189 44.2817 6.01658 44.2817 8.57584C44.2817 11.1351 46.36 13.2098 48.9236 13.2098Z"
          fill="#A0616A"
        />
        <path
          d="M34.4604 29.6011C33.1469 28.4244 31.4576 28.1596 30.6862 29.0099C29.9147 29.8603 30.3535 31.5037 31.667 32.6804C32.1847 33.1586 32.8128 33.5021 33.4997 33.6839L39.1281 38.5985L41.4497 35.8628L35.655 31.309C35.4026 30.6491 34.9924 30.0635 34.4604 29.6011Z"
          fill="#A0616A"
        />
        <path
          d="M49.8743 16.6154L56.9009 15.6133L61.0595 18.7627L61.4897 36.0845L65.5049 63.7136L38.8325 63.9999C38.8325 63.9999 40.8401 46.1054 41.8439 45.1034C42.8477 44.1013 43.5647 39.5203 43.5647 39.5203L45.5723 19.7648L49.8743 16.6154Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.2214 19.6933C47.2214 19.6933 43.493 19.407 42.6326 21.9838C41.7722 24.5606 40.7684 33.0068 40.7684 33.0068L38.474 33.2931L36.4664 31.1458L34.3154 36.2994C34.3154 36.2994 41.9156 44.6024 44.7836 40.8803C47.6516 37.1583 47.2214 19.6933 47.2214 19.6933Z"
          fill="#E6E6E6"
        />
        <path
          d="M56.8293 10.968C56.8293 10.968 56.8293 6.37983 57.2595 4.94827C57.6897 3.51671 55.2519 1.942 54.5349 0.510443C53.8179 -0.921115 49.3725 1.08307 47.5083 1.36938C45.6441 1.65569 45.7875 2.80093 44.6403 4.66196C43.4931 6.52298 45.7875 9.3861 47.5083 9.67241C49.2291 9.95872 48.6555 11.2471 49.8357 12.6787C51.0159 14.1102 54.7873 12.6787 54.7873 12.6787L55.2519 13.3945C55.2519 13.3945 54.2481 17.4028 56.1123 20.8386L60.9879 22.127C59.5539 17.8323 61.1313 17.8323 62.0304 15.6134C62.9295 13.3945 56.8293 10.968 56.8293 10.968Z"
          fill="#2F2E41"
        />
        <path
          d="M90.9312 43.4313H88.3758V39.1538H90.9312C91.2223 39.1538 91.456 38.9176 91.456 38.627C91.456 38.3364 91.2208 38.1045 90.9312 38.103H73.1152C72.8241 38.103 72.5903 38.3392 72.5903 38.6298C72.5903 38.9204 72.8255 39.1524 73.1152 39.1538H75.2949V43.4313H73.1152C72.8241 43.4313 72.5903 43.6675 72.5903 43.9581C72.5903 44.2487 72.8255 44.4806 73.1152 44.482H90.9312C91.2223 44.482 91.456 44.2458 91.456 43.9552C91.456 43.6646 91.2208 43.4327 90.9312 43.4313ZM76.3474 43.4313L77.5491 40.236L87.3218 39.1538V43.4313H76.3474Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1442_4031">
          <rect width="105.514" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
