import { useState } from "react";

export type Pagination<T> = {
  elements: T[];
  elementCount: number;
  moreAvailable: boolean;
  isLoadingMore: boolean;
  isLoadingCount: boolean;
  updatePageSize: (pageSize: number) => void;
  fetchCount: () => void;
  fetchElements: (offset: number, limit: number, type: "set" | "add") => void;
  fetchNextPage: () => void;
  fetchFirstPage: () => void;
  refetchAll: () => void;
  addElement: (elementToAdd: T) => void;
  updateElement: (updatedElement: T, oldElement: T) => void;
  deleteElement: (elementToDelete: T, comparisonKey?: keyof T) => void;
};

export const DEFAULT_PAGINATION_PAGE_SIZE = 20;

export function usePagination<T>(
  fetchFn: (offset: number, limit: number) => Promise<T[]>,
  errorHandler: (e: any) => void,
  countFn?: () => Promise<number>,
  fetchCountOnFetchElements = true
): Pagination<T> {
  const [moreAvailable, setMoreAvailable] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [elements, setElements] = useState<T[]>([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGINATION_PAGE_SIZE);
  const [elementCount, setElementCount] = useState(0);
  const fetchElements = (
    offset: number,
    limit: number,
    type: "set" | "add" = "set"
  ) => {
    setIsLoadingMore(true);
    if (fetchCountOnFetchElements) {
      fetchCount();
    }
    fetchFn(offset, limit)
      .catch((e) => {
        errorHandler(e);
        return [];
      })
      .then((newElements: T[]) => {
        const updatedElements =
          type === "set" ? newElements : elements.concat(newElements);
        setElements(updatedElements);
        setMoreAvailable(newElements.length >= limit);
      })
      .finally(() => setIsLoadingMore(false));
  };

  const fetchCount = () => {
    if (!countFn) {
      return;
    }
    setIsLoadingCount(true);
    countFn()
      .catch((e) => {
        errorHandler(e);
      })
      .then((count) => {
        setElementCount(count ?? 0);
      })
      .finally(() => setIsLoadingCount(false));
  };

  const fetchNextPage = () => fetchElements(elements.length, pageSize, "add");

  const fetchFirstPage = () => fetchElements(0, pageSize);

  const refetchAll = () => {
    // We round the passage number up to the nearest page size and then refetch that many passages
    // make sure we pick the smallest page size if there are no passages at all in the store
    const limit = Math.max(
      Math.ceil(elements.length / pageSize) * pageSize,
      pageSize
    );
    fetchElements(0, limit);
  };

  const addElement = (elementToAdd: T) => {
    setElements(elements.concat(elementToAdd));
    setElementCount(elementCount + 1);
  };

  const updatePageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  /**
   * Returns value for a key of an object if a key is passed, otherwise returns the
   * object itself.
   * @param element Object for which comparison is being performed
   * @param comparisonKey Key to get the comparison value. If left undefined, it returns the Object
   * passed itself.
   */
  const comparisonObjectGenerator = (
    element: T,
    comparisonKey?: keyof T
  ): T[keyof T] | T => {
    return comparisonKey ? element[comparisonKey] : element;
  };

  const updateElement = (updatedElement: T, oldElement: T) => {
    const elementIndex = elements.findIndex(
      (element) => element === oldElement
    );
    if (elementIndex >= 0) {
      elements[elementIndex] = updatedElement;
    }
    setElements(elements);
  };

  const deleteElement = (elementToDelete: T, comparisonKey?: keyof T) => {
    const elementToDeleteComparator = comparisonObjectGenerator(
      elementToDelete,
      comparisonKey
    );
    const oldElementsCount = elements.length;
    const newElements = elements.filter(
      (element) =>
        comparisonObjectGenerator(element, comparisonKey) !==
        elementToDeleteComparator
    );
    const newElementsCount = newElements.length;
    setElements(newElements);
    setElementCount(elementCount - (oldElementsCount - newElementsCount));
  };

  return {
    elements,
    elementCount,
    moreAvailable,
    isLoadingMore,
    isLoadingCount,
    updatePageSize,
    fetchCount,
    fetchElements,
    fetchNextPage,
    fetchFirstPage,
    refetchAll,
    addElement,
    updateElement,
    deleteElement,
  };
}
