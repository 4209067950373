import React, { useState } from "react";
import { Switch } from "antd";
import { ReasoningGraph } from "../ReasoningGraph";
import { ReasoningData } from "../reasoning-data";
import { StatementCardError } from "./StatementCardError";
import { StatementDetailCard } from "./StatementDetailCard";

export const ExplanationReasoningGraphCard = ({
  reasoningData,
  reasoningDataError,
  width,
  height,
  generation,
}: {
  reasoningData?: ReasoningData;
  reasoningDataError?: string;
  width: number;
  height: number;
  generation?: number;
}) => {
  const [filterToSolutions, setFilterToSolutions] = useState(true);

  const ExplanationReasoningGraph = () => {
    return (
      reasoningData && (
        <ReasoningGraph
          widthPx={width}
          heightPx={height}
          reasoningStep={reasoningData.steps[reasoningData.steps.length - 1]}
          filterToSolutions={filterToSolutions}
          resetOnDataChange={false}
          generation={generation}
        />
      )
    );
  };

  const filterToggle = () => {
    return (
      <>
        <Switch
          defaultChecked={filterToSolutions}
          style={{ marginRight: 8 }}
          onChange={setFilterToSolutions}
        />
        Filter to solutions
      </>
    );
  };

  return (
    <StatementDetailCard
      title={"Reasoning Graph"}
      extra={filterToggle()}
      isLoading={!reasoningData && !reasoningDataError}
      width={width}
      height={height}
    >
      {reasoningDataError ? (
        <StatementCardError error={reasoningDataError} />
      ) : (
        ExplanationReasoningGraph()
      )}
    </StatementDetailCard>
  );
};
