import { Passage, Uuid } from "./ul-element";

type ExplanationType =
  | "KNOWLEDGE"
  | "COMPUTATION"
  | "REASONING"
  | "SEMANTIC_EQUIVALENCE"
  | "RECONCEPTUALISATION"
  | "EXPOUNDING"
  | "AND"
  | "OR"
  | "REDUCER";

type BaseExplanation = {
  explanationType: ExplanationType;
  isPreDerived: boolean;
  passage: Passage;
};

export type KnowledgeExplanation = BaseExplanation & {
  explanationType: "KNOWLEDGE";
};
export type ReasoningExplanation = BaseExplanation & {
  explanationType: "REASONING";
  explanationSteps: Explanation[];
  reasoningPassageUsed: Passage;
};
export type ComputationExplanation = BaseExplanation & {
  explanationType: "COMPUTATION";
  computationUnitUsed: Uuid;
};
export type SemanticEquivalenceExplanation = BaseExplanation & {
  explanationType: "SEMANTIC_EQUIVALENCE";
  explanationStep: Explanation;
  equivalencePassagesUsed: Passage[];
};
export type ReconceptualisationExplanation = BaseExplanation & {
  explanationType: "RECONCEPTUALISATION";
  reconceptualisationRule: Passage;
  explanationSteps: Explanation[];
};
export type ExpoundingExplanation = BaseExplanation & {
  explanationType: "EXPOUNDING";
  explanationStep: Explanation;
  extraContext: Passage[];
};
export type AndExplanation = BaseExplanation & {
  explanationType: "AND";
  explanationSteps: Explanation[];
};
export type OrExplanation = BaseExplanation & {
  explanationType: "OR";
  explanationStep: Explanation;
};
export type ReducerExplanation = BaseExplanation & {
  explanationType: "REDUCER";
  reducerPassageUsed: Passage;
  ruleExplanationSteps: Explanation[];
  combinerExplanationSteps: Explanation[];
};

export type Explanation =
  | KnowledgeExplanation
  | ReasoningExplanation
  | ComputationExplanation
  | SemanticEquivalenceExplanation
  | ReconceptualisationExplanation
  | ExpoundingExplanation
  | AndExplanation
  | OrExplanation
  | ReducerExplanation;

export interface ExplanationVisitor<T> {
  visitKnowledge(explanation: KnowledgeExplanation): T;

  visitReasoning(explanation: ReasoningExplanation): T;

  visitComputation(explanation: ComputationExplanation): T;

  visitSemanticEquivalence(explanation: SemanticEquivalenceExplanation): T;

  visitReconceptualisation(explanation: ReconceptualisationExplanation): T;

  visitExpounding(explanation: ExpoundingExplanation): T;

  visitAnd(explanation: AndExplanation): T;

  visitOr(explanation: OrExplanation): T;

  visitReducer(explanation: ReducerExplanation): T;
}

/**
 * Note that this isn't like a normal visitor, as there's no Explanation::accept method to recurse
 * into the structure (because Explanation is a simple data type sent from the server). Handling
 * this is up to the caller.
 */
export function visitExplanation<T>(
  explanation: Explanation,
  visitor: ExplanationVisitor<T>
): T {
  switch (explanation.explanationType) {
    case "KNOWLEDGE":
      return visitor.visitKnowledge(explanation);
    case "REASONING":
      return visitor.visitReasoning(explanation);
    case "COMPUTATION":
      return visitor.visitComputation(explanation);
    case "SEMANTIC_EQUIVALENCE":
      return visitor.visitSemanticEquivalence(explanation);
    case "RECONCEPTUALISATION":
      return visitor.visitReconceptualisation(explanation);
    case "EXPOUNDING":
      return visitor.visitExpounding(explanation);
    case "AND":
      return visitor.visitAnd(explanation);
    case "OR":
      return visitor.visitOr(explanation);
    case "REDUCER":
      return visitor.visitReducer(explanation);
  }
}

/**
 * Although there are lots of different explanation types, they all have the same basic form: a
 * passage being explained, some details about how that passage was derived (a reasoning passage,
 * semantic equivalence passages, etc.), and then child explanation steps. This function allows the
 * caller to treat them all the same way.
 */
export function extractExplanationDetails<T>(
  explanation: Explanation,
  handleExplanation: (
    /**
     * The passage being explained.
     */
    passage: Passage,
    /**
     * The details of how the passage was derived (e.g. the reasoning passage used, the semantic equivalence passages used, etc).
     */
    details: Passage[],
    /**
     * The child explanation steps.
     */
    children: Explanation[]
  ) => T
): T {
  return visitExplanation(explanation, {
    visitKnowledge: (explanation) => {
      return handleExplanation(explanation.passage, [], []);
    },
    visitComputation: (explanation) => {
      return handleExplanation(explanation.passage, [], []);
    },
    visitReasoning: (explanation) => {
      return handleExplanation(
        explanation.passage,
        [explanation.reasoningPassageUsed],
        explanation.explanationSteps
      );
    },
    visitSemanticEquivalence: (explanation) => {
      return handleExplanation(
        explanation.passage,
        explanation.equivalencePassagesUsed,

        [explanation.explanationStep]
      );
    },
    visitReconceptualisation: (explanation) => {
      return handleExplanation(
        explanation.passage,
        [explanation.reconceptualisationRule],
        explanation.explanationSteps
      );
    },
    visitExpounding: (explanation) => {
      return handleExplanation(explanation.passage, explanation.extraContext, [
        explanation.explanationStep,
      ]);
    },
    visitAnd: (explanation) => {
      return handleExplanation(
        explanation.passage,
        [explanation.passage],
        explanation.explanationSteps
      );
    },
    visitOr: (explanation) => {
      return handleExplanation(
        explanation.passage,
        [explanation.passage],
        [explanation.explanationStep]
      );
    },
    visitReducer: (explanation) => {
      return handleExplanation(
        explanation.passage,
        [explanation.reducerPassageUsed],
        explanation.ruleExplanationSteps
      );
    },
  });
}
