import { useState } from "react";
import { NodeDto } from "@dip/data-access/api-types";
import { Input } from "@dip/ui/components/inputs";
import styled from "@emotion/styled";
import { Col, Row } from "antd";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { DomainNodeCard } from "./DomainNodeCard";
import { DomainNodeDetailsModal } from "./DomainNodeDetailsModal";
import { DomainNodesFilters } from "./DomainNodesFilters";
import { NodeFilters } from "./types";

const Container = styled.div`
  margin: ${({ theme }) => theme.spacings.xl};
`;

const HeaderRow = styled(Row)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.md};
`;

const NodesCardRow = styled(Row)`
  width: 100%;
  padding-left: 1rem; // required to match then trailing antd padding
`;

const SearchInput = styled(Input)`
  margin-left: 1rem; // required to match then trailing antd padding
  margin-right: ${({ theme }) => theme.spacings.md};
  width: 50%;
`;

const NoResultsMessage = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacings.xl};
  color: ${({ theme }) => theme.colors.text.secondary.default};
`;

type NodeSearchField = "nickname" | "nodeId" | "description" | "expressedIn";

const SEARCH_FIELDS: NodeSearchField[] = [
  "nickname",
  "nodeId",
  "description",
  "expressedIn",
];

type DomainNodesProps = {
  nodes: NodeDto[];
  onUpdate: (nodes: NodeDto[]) => void;
  isLoading?: boolean;
};

export const DomainNodes = ({
  nodes,
  onUpdate,
  isLoading,
}: DomainNodesProps) => {
  const { openModal } = useModal();

  const [searchQuery, setSearchQuery] = useState("");

  const [filters, setFilters] = useState<NodeFilters>({ type: "all" });

  // TODO(ALL-121) - Can this logic be cleaned up? updating the node list in place is a bit messy
  const handleUpdateNode = (nodeIdToUpdate: string, updatedNode: NodeDto) => {
    const updatedNodes = nodes.map((node) => {
      if (node.nodeId === nodeIdToUpdate) {
        return updatedNode;
      }
      return node;
    });
    onUpdate(updatedNodes);
  };

  const isSearchMatch = (node: Pick<NodeDto, NodeSearchField>) =>
    searchQuery.trim() === "" ||
    SEARCH_FIELDS.some((field) => {
      const value = node[field];
      if (Array.isArray(value))
        return value.some((v) =>
          v.toLowerCase().includes(searchQuery.trim().toLowerCase())
        );
      return value.toLowerCase().includes(searchQuery.trim().toLowerCase());
    });

  const isFiltersMatch = (node: NodeDto) =>
    filters.type === "all" ||
    (node.resolved && filters.type === "resolved") ||
    (!node.resolved && filters.type === "coined");

  const uniqueNodes = [
    ...new Map(nodes.map((node) => [node.nickname, node])).values(),
  ];

  const filteredNodes = uniqueNodes
    .filter((node: NodeDto) => isSearchMatch(node) && isFiltersMatch(node))
    // sort alphabetically
    .sort((a, b) => a.nickname.localeCompare(b.nickname));

  const hasResults = filteredNodes.length > 0;

  return (
    <Container>
      <HeaderRow>
        <SearchInput
          placeholder="Search nodes"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          disabled={isLoading}
        />
        <DomainNodesFilters
          filters={filters}
          setFilters={setFilters}
          isDisabled={isLoading}
        />
      </HeaderRow>
      {hasResults ? (
        <NodesCardRow gutter={[16, 16]} justify="start">
          {filteredNodes.map((node) => (
            <Col xs={24} xxl={12} key={node.nickname}>
              <DomainNodeCard
                node={node}
                isLoading={isLoading}
                onClick={() => {
                  openModal(
                    <DomainNodeDetailsModal
                      node={node}
                      onUpdate={(nodeUpdated) =>
                        handleUpdateNode(node.nodeId, nodeUpdated)
                      }
                    />
                  );
                }}
              />
            </Col>
          ))}
        </NodesCardRow>
      ) : (
        <NoResultsMessage>No nodes found.</NoResultsMessage>
      )}
    </Container>
  );
};
