import { PropsWithChildren, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  setAccessToken,
  setTenantId,
  useAppDispatch,
  useAppSelector,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { UnlikelyAISpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.default};
`;

const LoadingSpinnerContainer = styled.div`
  flex: 1;
  height: 20%;
`;

const ErrorPageContainer = styled(Spacing)`
  flex: 1;
`;

export const RequireAuth = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { accessToken, tenantId } = useAppSelector(({ auth }) => auth);

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;

    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(setAccessToken(accessToken));
    };

    const getTenantId = async () => {
      const claims = await getIdTokenClaims();
      dispatch(setTenantId(claims?.org_id));
    };

    getAccessToken();
    getTenantId();
  }, [dispatch, getAccessTokenSilently, getIdTokenClaims, isAuthenticated]);

  const LoadingScreen = (
    <PageContainer>
      <LoadingSpinnerContainer>
        <UnlikelyAISpinner />
      </LoadingSpinnerContainer>
    </PageContainer>
  );

  if (isLoading) return LoadingScreen;

  const error = searchParams.get("error");

  if (error)
    return (
      <PageContainer>
        <ErrorPageContainer gap="md">
          <Icon
            icon={UnlikelyAiLogo}
            size="5rem"
            fill={theme.colors.action.primary.default}
          />
          <Heading level="h1" textAlign="center">
            Error logging in
          </Heading>
          <Paragraph textAlign="center">
            {searchParams.get("error_description")}
          </Paragraph>
          <Paragraph textAlign="center">
            Please email your UnlikelyAI contact for help.
          </Paragraph>
        </ErrorPageContainer>
      </PageContainer>
    );

  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        invitation: searchParams.get("invitation") ?? "",
        organization: searchParams.get("organization") ?? "",
      },
    });
    return LoadingScreen;
  }

  if (!(accessToken && tenantId)) return LoadingScreen;

  return children;
};
