import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Select, SelectProps, Tag } from "antd";
import { eq } from "lodash";
import { BaseSelectRef } from "rc-select";
import { DefaultOptionType } from "rc-select/lib/Select";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors, screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { InputActionButtons } from "../InputActionButtons/InputActionButtons";
import { LoadingOverlay } from "../LoadingOverlay";

interface ContainerProps {
  containerWidth?: string;
  style?: SerializedStyles;
}

interface LabelProps {
  labelWidth?: string;
  style?: SerializedStyles;
}

interface ValueContainerProps {
  valueWidth?: string;
  labelVisible: boolean;
  editingEnabled?: boolean;
  editing: boolean;
  style?: SerializedStyles;
}

const EditableTagsContainer = styled.div<ContainerProps>`
  width: ${({ containerWidth }) => `${containerWidth ?? "300px"}`};
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media (min-width: ${screenBreakpoints.md}) {
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  ${({ style }) => style};
`;

const Label = styled(Paragraph)<LabelProps>`
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  flex: 1;
  width: 100%;
  padding: 0.25rem;
  height: 1.5rem;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelWidth }) => `${labelWidth ?? "50%"}`};
  }

  ${({ style }) => style};
`;

const ValueContainer = styled.div<ValueContainerProps>`
  position: relative;
  transition: background-color 0.2s ease;
  width: 100%;
  font-weight: bolder;
  padding: 0.25rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;

  :hover {
    background: ${({ editing, editingEnabled }) =>
      `${editingEnabled && !editing ? `${colors.neutral["500"]}44` : ""}`};
  }

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelVisible, valueWidth }) =>
      labelVisible ? (valueWidth ?? "50%") : "100%"};
  }

  ${({ style }) => style};
`;

const Value = styled(Tag)`
  background: ${colors.transparent};
  border-color: ${colors.primary};
  align-items: center;
  border-radius: 0.2rem;
  line-height: 1.5rem;
  height: 1.5rem;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: visible;
`;

const TagsInput = styled(Select)<SelectProps>`
  border: 0;
  line-height: 1.5rem;
  flex: 1;
  font-weight: bolder;
  transition: background-color 0.2s ease;
  height: 100%;
  width: 100%;

  :focus {
    border: 0;
    outline: 0;
  }
`;

/**
 * @typedef EditableTagsInputProps<T>
 * @property {string} label The label to display for the input element. If an empty string is
 * passed. no label is displayed and the value container takes 100% of the available width
 * @property {T} value The value to display for the input element
 * @property {T[]} dataOptions The list of options to display in the dropdown menu
 * @property {keyof T} dataKey The key to use to extract the value from the data options. If not
 * provided, the data options are assumed to be strings
 * @property {boolean} editingEnabled Whether editing is enabled for the input element
 * @property {string} containerWidth The width of the container element. Defaults to 300px
 * @property {string} labelWidth The width of the label element. Defaults to 50%
 * @property {string} valueWidth The width of the value element. Defaults to 50%
 * @property {SerializedStyles} labelStyle Override styles for label container
 * @property {SerializedStyles} valueStyle Override styles for value container
 * @property {SerializedStyles} containerStyle Override styles for the parent container
 * @property {boolean} loading Whether the input element is in a loading state
 * @property {string} className The class name to apply to the container element
 * @property {(updatedValue: T | undefined) => void} onChange Callback function to
 * execute when the user clicks the OK button or presses enter on the keyboard
 */
type EditableTagsInputProps<T> = {
  label: string;
  value: T[];
  dataOptions: T[];
  dataKey?: keyof T;
  editingEnabled: boolean;
  containerWidth?: string;
  labelWidth?: string;
  valueWidth?: string;
  labelStyle?: SerializedStyles;
  valueStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  loading?: boolean;
  className?: string;
  onChange?: (updatedValue: T[] | undefined) => Promise<void>;
};

/**
 * The component displays a static value when editing mode is off. When editing is enabled,
 * the component displays an input element that allows the user to select a value from a list of
 * tags or type out a new tag.
 * @param props {EditableTagsInputProps}
 */
export const EditableTagsInput = <T,>(props: EditableTagsInputProps<T>) => {
  // Define editing state for the component
  const [editing, setEditing] = useState(props.value.length === 0);
  const [tagsValue, setTagsValue] = useState<T[]>(props.value);
  // Tracking the dropdown menu state to allow keyboard shortcuts to work only when dropdown is
  // not open
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  // Define a ref for the input element to allow the element to be accessed across the component
  const inputRef = useRef<BaseSelectRef>(null);

  const setTags = (values: string[], options: DefaultOptionType[]) => {
    const fixedOptions = options.map((option, index) => {
      return (
        option.value === undefined ? { value: values[index] } : option
      ) as T;
    });
    setTagsValue(props.dataKey ? fixedOptions : (values as T[]));
  };

  // Set focus on the tag element when editing is enabled
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    } else {
      setDropdownOpen(false);
    }
  }, [editing]);

  const handleCompletion = async () => {
    // On render, any editable value that is blank is set to editable by default. All the fields
    // which are editable try to grab focus, which causes onBlur to be triggered with blank values.
    // This condition ensures that only the fields which are actually edited are set to non-editable
    if (
      tagsValue.length !== 0 ||
      (tagsValue.length === 0 && !eq(tagsValue, props.value))
    ) {
      setEditing(false);
    }
    if (!eq(tagsValue, props.value)) {
      await props.onChange?.(tagsValue);
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  /**
   * Handles key press events for the tag element. If the enter key is pressed when the dropdown is
   * not visible, the input value is saved and editing is disabled. If the escape key is pressed
   * when the dropdown is not visible, editing is disabled without saving the input value.
   * @param event Key press event
   */
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!dropdownOpen) {
      if (event.key === "Enter") {
        handleCompletion();
      } else if (event.key === "Escape") {
        handleCancel();
      }
    }
  };

  useEffect(() => {
    setTagsValue(props.value);
    setEditing(props.value.length === 0);
  }, [props.value]);

  // Define the static non-editable tag element. This element can be clicked to enable editing
  // mode when editing is enabled for the component
  const StaticValue = props.value.map((value) => (
    <Value>
      {props.dataKey ? (value[props.dataKey] as string) : (value as string)}
    </Value>
  ));

  // Define the editable tag element. This element is shown when editing is enabled and allows
  // the user to edit the tags
  const EditableValue = (
    <InputContainer onBlur={handleCompletion}>
      <TagsInput
        ref={inputRef}
        mode={"tags"}
        onChange={(values, options) =>
          setTags(values, options as DefaultOptionType[])
        }
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        value={tagsValue}
        onKeyDown={handleKeyPress}
        options={
          props.editingEnabled
            ? props.dataOptions.map((option) => ({
                ...(props.dataKey && { ...option }),
                value: props.dataKey
                  ? (option[props.dataKey] as string)
                  : (option as string),
                label: props.dataKey
                  ? (option[props.dataKey] as string)
                  : (option as string),
              }))
            : undefined
        }
      />
      {!props.loading && (
        <InputActionButtons
          onCancel={handleCancel}
          onSubmit={handleCompletion}
        />
      )}
    </InputContainer>
  );

  return (
    <EditableTagsContainer
      className={props.className}
      containerWidth={props.containerWidth}
      style={props.containerStyle}
    >
      {props.label && (
        <Label
          color={colors.neutral["400"]}
          labelWidth={props.labelWidth}
          style={props.labelStyle}
        >
          {props.label}
        </Label>
      )}
      <ValueContainer
        labelVisible={!!props.label}
        valueWidth={props.valueWidth}
        editingEnabled={props.editingEnabled}
        style={props.valueStyle}
        editing={editing}
        onClick={() => (props.editingEnabled ? setEditing(true) : undefined)}
      >
        {props.loading ? <LoadingOverlay /> : null}
        {editing ? EditableValue : StaticValue}
      </ValueContainer>
    </EditableTagsContainer>
  );
};
