import { ComponentProps } from "react";

export const UndrawDeveloperActivity = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="74"
      height="64"
      viewBox="0 0 74 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1442_3947)">
        <path
          d="M66.415 33.3066C66.4247 41.2259 63.356 48.8406 57.8542 54.5498C57.5225 54.8963 57.1819 55.2343 56.8323 55.5641C56.2996 56.0694 55.7491 56.5552 55.1807 57.0214C49.8634 61.3914 43.2253 63.8471 36.3373 63.9922C36.1078 63.9974 35.8775 64 35.6462 64C30.1529 64 23.2436 61.0731 16.9734 57.0214C16.4547 56.6863 15.9405 56.3433 15.431 55.9925C15.1928 55.8301 14.9558 55.6654 14.72 55.4984C12.3017 53.795 10.0062 51.9244 7.85052 49.9003C6.43912 48.579 5.11559 47.1673 3.88837 45.6742C-0.929438 39.7461 -1.99207 34.4046 4.87652 33.3066C5.68974 33.177 6.47899 33.0385 7.24429 32.8911C8.35283 32.6786 9.41374 32.4491 10.427 32.2026C11.7919 31.8717 13.0742 31.5129 14.274 31.1262C14.6776 30.9966 15.0719 30.8644 15.457 30.7296C21.1504 28.7349 24.961 26.1337 27.5548 23.3113C27.9947 22.8336 28.4003 22.3501 28.7716 21.8609C29.3396 21.1162 29.8555 20.3334 30.3157 19.518C30.9509 18.3851 31.4845 17.1983 31.9101 15.9717L31.9335 15.9043C33.9973 9.96587 33.3694 4.45767 35.6462 2.61411C45.3788 -5.26721 57.4957 5.95653 63.183 19.1923C63.4696 19.8592 63.7393 20.5302 63.9919 21.2052C64.1053 21.5067 64.2147 21.8087 64.3201 22.1114C64.5972 22.9001 64.8487 23.6909 65.0744 24.4837C65.161 24.786 65.2436 25.0881 65.3221 25.3899C65.4061 25.7156 65.4867 26.0404 65.5611 26.3652C66.1145 28.7435 66.415 31.089 66.415 33.3066Z"
          fill="#F2F2F2"
        />
        <path
          d="M36.3375 63.9923C36.108 63.9975 35.8776 64.0001 35.6464 64.0001C30.153 64.0001 23.2437 61.0732 16.9736 57.0215C16.4548 56.6863 15.9407 56.3434 15.4312 55.9926L15.878 53.28L29.4065 51.4883L36.1963 56.6673L36.3375 63.9923Z"
          fill="#2F2E41"
        />
        <path
          d="M36.9884 15.5544H35.0606C35.0009 15.5545 34.9436 15.5781 34.9014 15.6203C34.8592 15.6624 34.8355 15.7195 34.8354 15.779V17.7029C34.8355 17.7324 34.8413 17.7615 34.8527 17.7887C34.864 17.8159 34.8806 17.8406 34.9015 17.8614C34.9224 17.8822 34.9472 17.8986 34.9745 17.9098C35.0018 17.9211 35.0311 17.9268 35.0606 17.9267H36.9884C37.048 17.9267 37.1052 17.9032 37.1474 17.8612C37.1896 17.8193 37.2134 17.7624 37.2136 17.7029V15.779C37.2134 15.7195 37.1896 15.6625 37.1475 15.6204C37.1053 15.5783 37.0481 15.5546 36.9884 15.5544Z"
          fill="white"
        />
        <path
          d="M36.9884 18.833H35.0606C35.0009 18.833 34.9436 18.8567 34.9014 18.8988C34.8592 18.9409 34.8355 18.9981 34.8354 19.0576V20.9815C34.8355 21.011 34.8413 21.0401 34.8527 21.0673C34.864 21.0945 34.8806 21.1192 34.9015 21.14C34.9224 21.1608 34.9472 21.1772 34.9745 21.1884C35.0018 21.1996 35.0311 21.2053 35.0606 21.2053H36.9884C37.048 21.2053 37.1052 21.1818 37.1474 21.1398C37.1896 21.0979 37.2134 21.0409 37.2136 20.9815V19.0576C37.2134 18.9981 37.1896 18.9411 37.1475 18.899C37.1053 18.8569 37.0481 18.8332 36.9884 18.833Z"
          fill="#E6E6E6"
        />
        <path
          d="M36.9884 22.1113H35.0606C35.0009 22.1114 34.9436 22.135 34.9014 22.1772C34.8592 22.2193 34.8355 22.2764 34.8354 22.3359V24.259C34.8354 24.2885 34.8412 24.3177 34.8525 24.345C34.8638 24.3723 34.8803 24.3971 34.9013 24.4179C34.9222 24.4388 34.947 24.4553 34.9744 24.4666C35.0017 24.4779 35.031 24.4836 35.0606 24.4836H36.9884C37.0481 24.4836 37.1054 24.4599 37.1476 24.4178C37.1898 24.3757 37.2136 24.3185 37.2136 24.259V22.3359C37.2134 22.2764 37.1896 22.2194 37.1475 22.1773C37.1053 22.1352 37.0481 22.1115 36.9884 22.1113Z"
          fill="#E6E6E6"
        />
        <path
          d="M37.2101 25.5748C37.2005 25.523 37.1731 25.4763 37.1326 25.4425C37.0922 25.4088 37.0412 25.3902 36.9884 25.3899H35.0606C35.0009 25.3899 34.9436 25.4136 34.9014 25.4557C34.8592 25.4978 34.8355 25.555 34.8354 25.6145V27.5375C34.8354 27.5671 34.8412 27.5963 34.8525 27.6236C34.8638 27.6508 34.8803 27.6756 34.9013 27.6965C34.9222 27.7174 34.947 27.7339 34.9744 27.7452C35.0017 27.7564 35.031 27.7622 35.0606 27.7622H36.9884C37.0481 27.7621 37.1054 27.7385 37.1476 27.6963C37.1898 27.6542 37.2136 27.5971 37.2136 27.5375V25.6145C37.2138 25.6012 37.2126 25.5879 37.2101 25.5748Z"
          fill="#E6E6E6"
        />
        <path
          d="M37.0759 28.6857C37.0482 28.6743 37.0184 28.6684 36.9884 28.6685H35.0606C35.031 28.6684 35.0017 28.6742 34.9744 28.6854C34.947 28.6967 34.9222 28.7132 34.9013 28.7341C34.8803 28.755 34.8638 28.7798 34.8525 28.807C34.8412 28.8343 34.8354 28.8636 34.8355 28.8931V30.8161C34.8353 30.8456 34.841 30.8749 34.8523 30.9022C34.8636 30.9295 34.8802 30.9543 34.9011 30.9752C34.922 30.9961 34.9469 31.0127 34.9743 31.0239C35.0017 31.0351 35.031 31.0409 35.0606 31.0407H36.9884C37.0481 31.0407 37.1054 31.017 37.1476 30.9749C37.1898 30.9328 37.2136 30.8757 37.2136 30.8161V28.8931C37.2135 28.8489 37.2004 28.8057 37.1759 28.7688C37.1514 28.732 37.1167 28.7031 37.0759 28.6857Z"
          fill="#E6E6E6"
        />
        <path
          d="M41.0382 15.5544H39.1104C39.0507 15.5545 38.9935 15.5781 38.9512 15.6203C38.909 15.6624 38.8853 15.7195 38.8853 15.779V17.7029C38.8853 17.7324 38.8911 17.7615 38.9025 17.7887C38.9138 17.8159 38.9304 17.8406 38.9513 17.8614C38.9722 17.8822 38.997 17.8986 39.0243 17.9098C39.0516 17.9211 39.0809 17.9268 39.1104 17.9267H41.0382C41.0978 17.9267 41.155 17.9032 41.1972 17.8612C41.2394 17.8193 41.2632 17.7624 41.2634 17.7029V15.779C41.2632 15.7195 41.2395 15.6625 41.1973 15.6204C41.1551 15.5783 41.0979 15.5546 41.0382 15.5544Z"
          fill="#E6E6E6"
        />
        <path
          d="M41.0382 18.833H39.1104C39.0507 18.833 38.9934 18.8567 38.9512 18.8988C38.909 18.9409 38.8853 18.9981 38.8853 19.0576V20.9815C38.8853 21.011 38.8911 21.0401 38.9025 21.0673C38.9138 21.0945 38.9304 21.1192 38.9513 21.14C38.9722 21.1608 38.997 21.1772 39.0243 21.1884C39.0516 21.1996 39.0809 21.2053 39.1104 21.2053H41.0382C41.0978 21.2053 41.155 21.1818 41.1972 21.1398C41.2394 21.0979 41.2632 21.0409 41.2634 20.9815V19.0576C41.2632 18.9981 41.2395 18.9411 41.1973 18.899C41.1551 18.8569 41.0979 18.8332 41.0382 18.833Z"
          fill="#FF4338"
        />
        <path
          d="M41.0382 22.1113H39.1104C39.0507 22.1114 38.9934 22.135 38.9512 22.1772C38.909 22.2193 38.8853 22.2764 38.8853 22.3359V24.259C38.8852 24.2885 38.891 24.3177 38.9023 24.345C38.9136 24.3723 38.9301 24.3971 38.9511 24.4179C38.972 24.4388 38.9968 24.4553 39.0242 24.4666C39.0515 24.4779 39.0808 24.4836 39.1104 24.4836H41.0382C41.0979 24.4836 41.1552 24.4599 41.1974 24.4178C41.2396 24.3757 41.2634 24.3185 41.2634 24.259V22.3359C41.2632 22.2764 41.2395 22.2194 41.1973 22.1773C41.1551 22.1352 41.0979 22.1115 41.0382 22.1113Z"
          fill="#FF4338"
        />
        <path
          d="M41.0382 25.3899H39.1104C39.0632 25.3899 39.0172 25.4048 38.979 25.4324C38.9407 25.46 38.9122 25.4989 38.8974 25.5437C38.8894 25.5664 38.8853 25.5904 38.8853 25.6145V27.5375C38.8852 27.5671 38.891 27.5963 38.9023 27.6236C38.9136 27.6508 38.9301 27.6756 38.9511 27.6965C38.972 27.7174 38.9968 27.7339 39.0242 27.7452C39.0515 27.7564 39.0808 27.7622 39.1104 27.7622H41.0382C41.0979 27.7621 41.1552 27.7385 41.1974 27.6963C41.2396 27.6542 41.2634 27.5971 41.2634 27.5375V25.6145C41.2632 25.555 41.2394 25.498 41.1973 25.4559C41.1551 25.4138 41.0979 25.3901 41.0382 25.3899Z"
          fill="#FF4338"
        />
        <path
          d="M41.0382 28.6685H39.1104C39.0808 28.6684 39.0515 28.6742 39.0242 28.6854C38.9968 28.6967 38.972 28.7132 38.9511 28.7341C38.9301 28.755 38.9136 28.7798 38.9023 28.807C38.891 28.8343 38.8852 28.8636 38.8853 28.8931V30.8161C38.8852 30.8456 38.891 30.8749 38.9023 30.9021C38.9136 30.9294 38.9301 30.9542 38.9511 30.9751C38.972 30.9959 38.9968 31.0125 39.0242 31.0237C39.0515 31.035 39.0808 31.0408 39.1104 31.0407H41.0382C41.0979 31.0407 41.1552 31.017 41.1974 30.9749C41.2396 30.9328 41.2634 30.8757 41.2634 30.8161V28.8931C41.2634 28.8335 41.2396 28.7764 41.1974 28.7343C41.1552 28.6922 41.0979 28.6685 41.0382 28.6685Z"
          fill="#E6E6E6"
        />
        <path
          d="M45.088 15.5544H43.1602C43.1005 15.5545 43.0433 15.5781 43.001 15.6203C42.9588 15.6624 42.9351 15.7195 42.9351 15.779V17.7029C42.9351 17.7324 42.9409 17.7615 42.9523 17.7887C42.9636 17.8159 42.9802 17.8406 43.0011 17.8614C43.022 17.8822 43.0469 17.8986 43.0742 17.9098C43.1015 17.9211 43.1307 17.9268 43.1602 17.9267H45.088C45.1476 17.9267 45.2048 17.9032 45.247 17.8612C45.2892 17.8193 45.313 17.7624 45.3132 17.7029V15.779C45.313 15.7195 45.2893 15.6625 45.2471 15.6204C45.2049 15.5783 45.1477 15.5546 45.088 15.5544Z"
          fill="#FF4338"
        />
        <path
          d="M45.088 18.833H43.1602C43.1005 18.833 43.0433 18.8567 43.001 18.8988C42.9588 18.9409 42.9351 18.9981 42.9351 19.0576V20.9815C42.9351 21.011 42.9409 21.0401 42.9523 21.0673C42.9636 21.0945 42.9802 21.1192 43.0011 21.14C43.022 21.1608 43.0469 21.1772 43.0742 21.1884C43.1015 21.1996 43.1307 21.2053 43.1602 21.2053H45.088C45.1476 21.2053 45.2048 21.1818 45.247 21.1398C45.2892 21.0979 45.313 21.0409 45.3132 20.9815V19.0576C45.313 18.9981 45.2893 18.9411 45.2471 18.899C45.2049 18.8569 45.1477 18.8332 45.088 18.833Z"
          fill="#FF4338"
        />
        <path
          d="M45.088 22.1113H43.1602C43.1005 22.1114 43.0433 22.135 43.001 22.1772C42.9588 22.2193 42.9351 22.2764 42.9351 22.3359V24.259C42.935 24.2885 42.9408 24.3177 42.9521 24.345C42.9634 24.3723 42.98 24.3971 43.0009 24.4179C43.0218 24.4388 43.0466 24.4553 43.074 24.4666C43.1013 24.4779 43.1306 24.4836 43.1602 24.4836H45.088C45.1477 24.4836 45.205 24.4599 45.2472 24.4178C45.2894 24.3757 45.3132 24.3185 45.3132 24.259V22.3359C45.313 22.2764 45.2893 22.2194 45.2471 22.1773C45.2049 22.1352 45.1477 22.1115 45.088 22.1113Z"
          fill="#E6E6E6"
        />
        <path
          d="M45.088 25.3899H43.1602C43.1005 25.3899 43.0433 25.4136 43.001 25.4557C42.9588 25.4978 42.9351 25.555 42.9351 25.6145V27.5375C42.935 27.5671 42.9408 27.5963 42.9521 27.6236C42.9634 27.6508 42.98 27.6756 43.0009 27.6965C43.0218 27.7174 43.0466 27.7339 43.074 27.7452C43.1013 27.7564 43.1306 27.7622 43.1602 27.7622H45.088C45.1477 27.7621 45.205 27.7385 45.2472 27.6963C45.2895 27.6542 45.3132 27.5971 45.3132 27.5375V25.6145C45.313 25.555 45.2893 25.498 45.2471 25.4559C45.2049 25.4138 45.1477 25.3901 45.088 25.3899Z"
          fill="#FF4338"
        />
        <path
          d="M45.088 28.6685H43.1602C43.1306 28.6684 43.1013 28.6742 43.074 28.6854C43.0466 28.6967 43.0218 28.7132 43.0009 28.7341C42.98 28.755 42.9634 28.7798 42.9521 28.807C42.9408 28.8343 42.935 28.8636 42.9351 28.8931V30.8161C42.935 30.8456 42.9408 30.8749 42.9521 30.9021C42.9634 30.9294 42.98 30.9542 43.0009 30.9751C43.0218 30.9959 43.0466 31.0125 43.074 31.0237C43.1013 31.035 43.1306 31.0408 43.1602 31.0407H45.088C45.1477 31.0407 45.205 31.017 45.2472 30.9749C45.2895 30.9328 45.3132 30.8757 45.3132 30.8161V28.8931C45.3132 28.8335 45.2895 28.7764 45.2472 28.7343C45.205 28.6922 45.1477 28.6685 45.088 28.6685Z"
          fill="#FF4338"
        />
        <path
          d="M49.1374 15.5544H47.2095C47.1498 15.5545 47.0926 15.5781 47.0504 15.6203C47.0081 15.6624 46.9844 15.7195 46.9844 15.779V17.7029C46.9844 17.7324 46.9903 17.7615 47.0016 17.7887C47.0129 17.8159 47.0295 17.8406 47.0504 17.8614C47.0714 17.8822 47.0962 17.8986 47.1235 17.9099C47.1508 17.9211 47.18 17.9268 47.2095 17.9267H49.1374C49.1969 17.9267 49.2541 17.9032 49.2963 17.8612C49.3385 17.8193 49.3623 17.7624 49.3625 17.7029V15.779C49.3624 15.7195 49.3386 15.6625 49.2964 15.6204C49.2542 15.5783 49.197 15.5546 49.1374 15.5544Z"
          fill="#FF4338"
        />
        <path
          d="M49.1374 18.833H47.2095C47.1498 18.833 47.0926 18.8567 47.0504 18.8988C47.0081 18.9409 46.9844 18.9981 46.9844 19.0576V20.9815C46.9844 21.011 46.9903 21.0401 47.0016 21.0673C47.0129 21.0945 47.0295 21.1192 47.0504 21.14C47.0714 21.1608 47.0962 21.1772 47.1235 21.1884C47.1508 21.1996 47.18 21.2054 47.2095 21.2053H49.1374C49.1969 21.2053 49.2541 21.1818 49.2963 21.1398C49.3385 21.0979 49.3623 21.0409 49.3625 20.9815V19.0576C49.3624 18.9981 49.3386 18.9411 49.2964 18.899C49.2542 18.8569 49.197 18.8332 49.1374 18.833Z"
          fill="white"
        />
        <path
          d="M49.1374 22.1113H47.2095C47.1498 22.1114 47.0926 22.135 47.0504 22.1772C47.0081 22.2193 46.9844 22.2764 46.9844 22.3359V24.259C46.9843 24.2885 46.9901 24.3177 47.0014 24.345C47.0127 24.3723 47.0293 24.3971 47.0502 24.4179C47.0711 24.4388 47.096 24.4553 47.1233 24.4666C47.1507 24.4779 47.18 24.4836 47.2095 24.4836H49.1374C49.1971 24.4836 49.2543 24.4599 49.2966 24.4178C49.3388 24.3757 49.3625 24.3185 49.3625 24.259V22.3359C49.3624 22.2764 49.3386 22.2194 49.2964 22.1773C49.2542 22.1352 49.197 22.1115 49.1374 22.1113Z"
          fill="white"
        />
        <path
          d="M49.1374 25.3899H47.2095C47.1498 25.3899 47.0926 25.4136 47.0504 25.4557C47.0081 25.4978 46.9844 25.555 46.9844 25.6145V27.5375C46.9843 27.5671 46.9901 27.5963 47.0014 27.6236C47.0127 27.6508 47.0293 27.6756 47.0502 27.6965C47.0711 27.7174 47.096 27.7339 47.1233 27.7452C47.1507 27.7565 47.18 27.7622 47.2095 27.7622H49.1374C49.1971 27.7621 49.2543 27.7385 49.2966 27.6963C49.3388 27.6542 49.3625 27.5971 49.3625 27.5375V25.6145C49.3624 25.555 49.3386 25.498 49.2964 25.4559C49.2542 25.4138 49.197 25.3901 49.1374 25.3899Z"
          fill="#E6E6E6"
        />
        <path
          d="M49.1374 28.6685H47.2095C47.18 28.6684 47.1507 28.6742 47.1233 28.6854C47.096 28.6967 47.0711 28.7132 47.0502 28.7341C47.0293 28.755 47.0127 28.7798 47.0014 28.807C46.9901 28.8343 46.9843 28.8636 46.9844 28.8931V30.8161C46.9843 30.8456 46.9901 30.8749 47.0014 30.9021C47.0127 30.9294 47.0293 30.9542 47.0502 30.9751C47.0711 30.9959 47.096 31.0125 47.1233 31.0237C47.1507 31.035 47.18 31.0408 47.2095 31.0407H49.1374C49.1971 31.0407 49.2543 31.017 49.2966 30.9749C49.3388 30.9328 49.3625 30.8757 49.3625 30.8161V28.8931C49.3625 28.8335 49.3388 28.7764 49.2966 28.7343C49.2543 28.6922 49.1971 28.6685 49.1374 28.6685Z"
          fill="#FF4338"
        />
        <path
          d="M53.1872 15.5544H51.2585C51.1989 15.5546 51.1418 15.5784 51.0998 15.6205C51.0578 15.6626 51.0342 15.7196 51.0342 15.779V17.7029C51.0342 17.7623 51.0578 17.8192 51.0999 17.8611C51.142 17.9031 51.199 17.9267 51.2585 17.9267H53.1872C53.2467 17.9267 53.3039 17.9032 53.3461 17.8612C53.3883 17.8193 53.4121 17.7624 53.4124 17.7029V15.779C53.4122 15.7195 53.3884 15.6625 53.3462 15.6204C53.304 15.5783 53.2468 15.5546 53.1872 15.5544Z"
          fill="white"
        />
        <path
          d="M53.1872 18.833H51.2585C51.1989 18.8332 51.1418 18.857 51.0998 18.8991C51.0578 18.9412 51.0342 18.9982 51.0342 19.0576V20.9815C51.0342 21.0409 51.0578 21.0978 51.0999 21.1397C51.142 21.1817 51.199 21.2053 51.2585 21.2053H53.1872C53.2467 21.2053 53.3039 21.1818 53.3461 21.1398C53.3883 21.0979 53.4121 21.0409 53.4124 20.9815V19.0576C53.4122 18.9981 53.3884 18.9411 53.3462 18.899C53.304 18.8569 53.2468 18.8332 53.1872 18.833Z"
          fill="white"
        />
        <path
          d="M53.1872 22.1113H51.2585C51.1989 22.1115 51.1419 22.1353 51.0998 22.1774C51.0578 22.2195 51.0342 22.2765 51.0342 22.3359V24.259C51.0341 24.2884 51.0398 24.3176 51.0511 24.3448C51.0623 24.3721 51.0788 24.3968 51.0996 24.4177C51.1205 24.4385 51.1452 24.4551 51.1725 24.4664C51.1997 24.4777 51.229 24.4836 51.2585 24.4836H53.1872C53.2469 24.4836 53.3041 24.4599 53.3464 24.4178C53.3886 24.3757 53.4123 24.3185 53.4124 24.259V22.3359C53.4122 22.2764 53.3884 22.2194 53.3462 22.1773C53.304 22.1352 53.2468 22.1115 53.1872 22.1113Z"
          fill="white"
        />
        <path
          d="M53.1872 25.3899H51.2585C51.1989 25.3901 51.1419 25.4139 51.0998 25.456C51.0578 25.4981 51.0342 25.5551 51.0342 25.6145V27.5375C51.0341 27.567 51.0398 27.5962 51.0511 27.6234C51.0623 27.6506 51.0788 27.6754 51.0996 27.6963C51.1205 27.7171 51.1452 27.7337 51.1725 27.745C51.1997 27.7563 51.229 27.7621 51.2585 27.7622H53.1872C53.2469 27.7621 53.3041 27.7385 53.3464 27.6963C53.3886 27.6542 53.4123 27.5971 53.4124 27.5375V25.6145C53.4122 25.555 53.3884 25.498 53.3462 25.4559C53.304 25.4138 53.2468 25.3901 53.1872 25.3899Z"
          fill="#FF4338"
        />
        <path
          d="M53.1872 28.6685H51.2585C51.229 28.6685 51.1997 28.6743 51.1725 28.6856C51.1452 28.6969 51.1205 28.7135 51.0996 28.7344C51.0788 28.7552 51.0623 28.78 51.0511 28.8072C51.0398 28.8344 51.0341 28.8636 51.0342 28.8931V30.8161C51.0341 30.8455 51.0398 30.8747 51.0511 30.902C51.0623 30.9292 51.0788 30.9539 51.0996 30.9748C51.1205 30.9957 51.1452 31.0122 51.1725 31.0235C51.1997 31.0349 51.229 31.0407 51.2585 31.0407H53.1872C53.2469 31.0407 53.3041 31.017 53.3464 30.9749C53.3886 30.9328 53.4123 30.8757 53.4124 30.8161V28.8931C53.4123 28.8335 53.3886 28.7764 53.3464 28.7343C53.3041 28.6922 53.2469 28.6685 53.1872 28.6685Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.2365 15.5544H55.3078C55.2482 15.5546 55.1912 15.5784 55.1491 15.6205C55.1071 15.6626 55.0835 15.7196 55.0835 15.779V17.7029C55.0835 17.7623 55.1071 17.8192 55.1492 17.8611C55.1913 17.9031 55.2483 17.9267 55.3078 17.9267H57.2365C57.2961 17.9267 57.3532 17.9032 57.3954 17.8612C57.4376 17.8193 57.4615 17.7624 57.4617 17.7029V15.779C57.4615 15.7195 57.4377 15.6625 57.3955 15.6204C57.3533 15.5783 57.2962 15.5546 57.2365 15.5544Z"
          fill="white"
        />
        <path
          d="M57.2365 18.833H55.3078C55.2482 18.8332 55.1912 18.857 55.1491 18.8991C55.1071 18.9412 55.0835 18.9982 55.0835 19.0576V20.9815C55.0835 21.0409 55.1071 21.0978 55.1492 21.1397C55.1913 21.1817 55.2483 21.2053 55.3078 21.2053H57.2365C57.2961 21.2053 57.3532 21.1818 57.3954 21.1398C57.4376 21.0979 57.4615 21.0409 57.4617 20.9815V19.0576C57.4615 18.9981 57.4377 18.9411 57.3955 18.899C57.3533 18.8569 57.2962 18.8332 57.2365 18.833Z"
          fill="white"
        />
        <path
          d="M57.2365 22.1113H55.3078C55.2482 22.1115 55.1912 22.1353 55.1491 22.1774C55.1071 22.2195 55.0835 22.2765 55.0835 22.3359V24.259C55.0834 24.2884 55.0892 24.3176 55.1004 24.3448C55.1116 24.3721 55.1281 24.3968 55.149 24.4177C55.1698 24.4385 55.1945 24.4551 55.2218 24.4664C55.2491 24.4777 55.2783 24.4836 55.3078 24.4836H57.2365C57.2962 24.4836 57.3534 24.4599 57.3957 24.4178C57.4379 24.3757 57.4616 24.3185 57.4617 24.259V22.3359C57.4615 22.2764 57.4377 22.2194 57.3955 22.1773C57.3533 22.1352 57.2962 22.1115 57.2365 22.1113Z"
          fill="#FF4338"
        />
        <path
          d="M57.2365 25.3899H55.3078C55.2482 25.3901 55.1912 25.4139 55.1491 25.456C55.1071 25.4981 55.0835 25.5551 55.0835 25.6145V27.5375C55.0834 27.567 55.0892 27.5962 55.1004 27.6234C55.1116 27.6506 55.1281 27.6754 55.149 27.6963C55.1698 27.7171 55.1945 27.7337 55.2218 27.745C55.2491 27.7563 55.2783 27.7621 55.3078 27.7622H57.2365C57.2962 27.7621 57.3535 27.7385 57.3957 27.6963C57.4379 27.6542 57.4616 27.5971 57.4617 27.5375V25.6145C57.4615 25.555 57.4377 25.498 57.3955 25.4559C57.3533 25.4138 57.2962 25.3901 57.2365 25.3899Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.2365 28.6685H55.3078C55.2783 28.6685 55.2491 28.6743 55.2218 28.6856C55.1945 28.6969 55.1698 28.7135 55.149 28.7344C55.1281 28.7552 55.1116 28.78 55.1004 28.8072C55.0892 28.8344 55.0834 28.8636 55.0835 28.8931V30.8161C55.0834 30.8455 55.0892 30.8747 55.1004 30.902C55.1116 30.9292 55.1281 30.9539 55.149 30.9748C55.1698 30.9957 55.1945 31.0122 55.2218 31.0235C55.2491 31.0349 55.2783 31.0407 55.3078 31.0407H57.2365C57.2962 31.0407 57.3535 31.017 57.3957 30.9749C57.4379 30.9328 57.4616 30.8757 57.4617 30.8161V28.8931C57.4616 28.8335 57.4379 28.7764 57.3957 28.7343C57.3535 28.6922 57.2962 28.6685 57.2365 28.6685Z"
          fill="white"
        />
        <path
          d="M61.2863 15.5544H59.3576C59.298 15.5546 59.241 15.5784 59.1989 15.6205C59.1569 15.6626 59.1333 15.7196 59.1333 15.779V17.7029C59.1333 17.7623 59.1569 17.8192 59.199 17.8611C59.2411 17.9031 59.2981 17.9267 59.3576 17.9267H61.2863C61.3459 17.9267 61.403 17.9032 61.4452 17.8612C61.4874 17.8193 61.5113 17.7624 61.5115 17.7029V15.779C61.5113 15.7195 61.4875 15.6625 61.4453 15.6204C61.4031 15.5783 61.346 15.5546 61.2863 15.5544Z"
          fill="#FF4338"
        />
        <path
          d="M61.2863 18.833H59.3576C59.298 18.8332 59.241 18.857 59.1989 18.8991C59.1569 18.9412 59.1333 18.9982 59.1333 19.0576V20.9815C59.1333 21.0409 59.1569 21.0978 59.199 21.1397C59.2411 21.1817 59.2981 21.2053 59.3576 21.2053H61.2863C61.3459 21.2053 61.403 21.1818 61.4452 21.1398C61.4874 21.0979 61.5113 21.0409 61.5115 20.9815V19.0576C61.5113 18.9981 61.4875 18.9411 61.4453 18.899C61.4031 18.8569 61.346 18.8332 61.2863 18.833Z"
          fill="#FF4338"
        />
        <path
          d="M61.2863 22.1113H59.3576C59.298 22.1115 59.241 22.1353 59.1989 22.1774C59.1569 22.2195 59.1333 22.2765 59.1333 22.3359V24.259C59.1332 24.2884 59.139 24.3176 59.1502 24.3448C59.1614 24.3721 59.1779 24.3968 59.1988 24.4177C59.2196 24.4385 59.2444 24.4551 59.2716 24.4664C59.2989 24.4777 59.3281 24.4836 59.3576 24.4836H61.2863C61.346 24.4836 61.4033 24.4599 61.4455 24.4178C61.4877 24.3757 61.5114 24.3185 61.5115 24.259V22.3359C61.5113 22.2764 61.4875 22.2194 61.4453 22.1773C61.4031 22.1352 61.346 22.1115 61.2863 22.1113Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.2863 25.3899H59.3576C59.298 25.3901 59.241 25.4139 59.1989 25.456C59.1569 25.4981 59.1333 25.5551 59.1333 25.6145V27.5375C59.1332 27.567 59.139 27.5962 59.1502 27.6234C59.1614 27.6506 59.1779 27.6754 59.1988 27.6963C59.2196 27.7171 59.2444 27.7337 59.2716 27.745C59.2989 27.7563 59.3281 27.7621 59.3576 27.7622H61.2863C61.346 27.7621 61.4033 27.7385 61.4455 27.6963C61.4877 27.6542 61.5114 27.5971 61.5115 27.5375V25.6145C61.5113 25.555 61.4875 25.498 61.4453 25.4559C61.4031 25.4138 61.346 25.3901 61.2863 25.3899Z"
          fill="white"
        />
        <path
          d="M61.2863 28.6685H59.3576C59.3281 28.6685 59.2989 28.6743 59.2716 28.6856C59.2444 28.6969 59.2196 28.7135 59.1988 28.7344C59.1779 28.7552 59.1614 28.78 59.1502 28.8072C59.139 28.8344 59.1332 28.8636 59.1333 28.8931V30.8161C59.1332 30.8455 59.139 30.8747 59.1502 30.902C59.1614 30.9292 59.1779 30.9539 59.1988 30.9748C59.2196 30.9957 59.2444 31.0122 59.2716 31.0235C59.2989 31.0349 59.3281 31.0407 59.3576 31.0407H61.2863C61.346 31.0407 61.4033 31.017 61.4455 30.9749C61.4877 30.9328 61.5114 30.8757 61.5115 30.8161V28.8931C61.5114 28.8335 61.4877 28.7764 61.4455 28.7343C61.4033 28.6922 61.346 28.6685 61.2863 28.6685Z"
          fill="#FF4338"
        />
        <path
          d="M65.3361 15.5544H63.4074C63.3478 15.5546 63.2908 15.5784 63.2487 15.6205C63.2067 15.6626 63.1831 15.7196 63.1831 15.779V17.7029C63.1831 17.7623 63.2068 17.8192 63.2488 17.8611C63.2909 17.9031 63.3479 17.9267 63.4074 17.9267H65.3361C65.3957 17.9267 65.4528 17.9032 65.495 17.8612C65.5372 17.8193 65.5611 17.7624 65.5613 17.7029V15.779C65.5611 15.7195 65.5373 15.6625 65.4951 15.6204C65.4529 15.5783 65.3958 15.5546 65.3361 15.5544Z"
          fill="#FF4338"
        />
        <path
          d="M65.3361 18.833H63.4074C63.3478 18.8332 63.2908 18.857 63.2487 18.8991C63.2067 18.9412 63.1831 18.9982 63.1831 19.0576V20.9815C63.1831 21.0409 63.2068 21.0978 63.2488 21.1397C63.2909 21.1817 63.3479 21.2053 63.4074 21.2053H65.3361C65.3957 21.2053 65.4528 21.1818 65.495 21.1398C65.5372 21.0979 65.5611 21.0409 65.5613 20.9815V19.0576C65.5611 18.9981 65.5373 18.9411 65.4951 18.899C65.4529 18.8569 65.3958 18.8332 65.3361 18.833Z"
          fill="#FF4338"
        />
        <path
          d="M65.3361 22.1113H63.4074C63.3478 22.1115 63.2908 22.1353 63.2487 22.1774C63.2067 22.2195 63.1831 22.2765 63.1831 22.3359V24.259C63.183 24.2884 63.1888 24.3176 63.2 24.3448C63.2112 24.3721 63.2277 24.3968 63.2486 24.4177C63.2694 24.4385 63.2942 24.4551 63.3214 24.4664C63.3487 24.4777 63.3779 24.4836 63.4074 24.4836H65.3361C65.3958 24.4836 65.4531 24.4599 65.4953 24.4178C65.5375 24.3757 65.5612 24.3185 65.5613 24.259V22.3359C65.5611 22.2764 65.5373 22.2194 65.4951 22.1773C65.4529 22.1352 65.3958 22.1115 65.3361 22.1113Z"
          fill="#E6E6E6"
        />
        <path
          d="M65.3361 25.3899H63.4074C63.3478 25.3901 63.2908 25.4139 63.2487 25.456C63.2067 25.4981 63.1831 25.5551 63.1831 25.6145V27.5375C63.183 27.567 63.1888 27.5962 63.2 27.6234C63.2112 27.6506 63.2277 27.6754 63.2486 27.6963C63.2694 27.7171 63.2942 27.7337 63.3214 27.745C63.3487 27.7563 63.3779 27.7621 63.4074 27.7622H65.3361C65.3958 27.7621 65.4531 27.7385 65.4953 27.6963C65.5375 27.6542 65.5612 27.5971 65.5613 27.5375V25.6145C65.5611 25.555 65.5373 25.498 65.4951 25.4559C65.4529 25.4138 65.3958 25.3901 65.3361 25.3899Z"
          fill="#FF4338"
        />
        <path
          d="M65.3361 28.6685H63.4074C63.3779 28.6685 63.3487 28.6743 63.3214 28.6856C63.2942 28.6969 63.2694 28.7135 63.2486 28.7344C63.2277 28.7552 63.2112 28.78 63.2 28.8072C63.1888 28.8344 63.183 28.8636 63.1831 28.8931V30.8161C63.183 30.8455 63.1888 30.8747 63.2 30.902C63.2112 30.9292 63.2277 30.9539 63.2486 30.9748C63.2694 30.9957 63.2942 31.0122 63.3214 31.0235C63.3487 31.0349 63.3779 31.0407 63.4074 31.0407H65.3361C65.3958 31.0407 65.4531 31.017 65.4953 30.9749C65.5375 30.9328 65.5612 30.8757 65.5613 30.8161V28.8931C65.5612 28.8335 65.5375 28.7764 65.4953 28.7343C65.4531 28.6922 65.3958 28.6685 65.3361 28.6685Z"
          fill="#FF4338"
        />
        <path
          d="M69.3854 15.5544H67.4567C67.3972 15.5546 67.3401 15.5784 67.298 15.6205C67.256 15.6626 67.2324 15.7196 67.2324 15.779V17.7029C67.2324 17.7623 67.2561 17.8192 67.2981 17.8611C67.3402 17.9031 67.3972 17.9267 67.4567 17.9267H69.3854C69.445 17.9267 69.5021 17.9032 69.5443 17.8612C69.5866 17.8193 69.6104 17.7624 69.6106 17.7029V15.779C69.6104 15.7195 69.5866 15.6625 69.5444 15.6204C69.5022 15.5783 69.4451 15.5546 69.3854 15.5544Z"
          fill="#E6E6E6"
        />
        <path
          d="M69.3854 18.833H67.4567C67.3971 18.8332 67.3401 18.857 67.298 18.8991C67.256 18.9412 67.2324 18.9982 67.2324 19.0576V20.9815C67.2324 21.0409 67.2561 21.0978 67.2981 21.1397C67.3402 21.1817 67.3972 21.2053 67.4567 21.2053H69.3854C69.445 21.2053 69.5021 21.1818 69.5443 21.1398C69.5866 21.0979 69.6104 21.0409 69.6106 20.9815V19.0576C69.6104 18.9981 69.5866 18.9411 69.5444 18.899C69.5022 18.8569 69.4451 18.8332 69.3854 18.833Z"
          fill="#FF4338"
        />
        <path
          d="M69.3854 22.1113H67.4567C67.3971 22.1115 67.3401 22.1353 67.298 22.1774C67.256 22.2195 67.2324 22.2765 67.2324 22.3359V24.259C67.2323 24.2884 67.2381 24.3176 67.2493 24.3448C67.2605 24.3721 67.277 24.3968 67.2979 24.4177C67.3187 24.4385 67.3435 24.4551 67.3707 24.4664C67.398 24.4777 67.4272 24.4836 67.4567 24.4836H69.3854C69.4451 24.4836 69.5024 24.4599 69.5446 24.4178C69.5868 24.3757 69.6105 24.3185 69.6106 24.259V22.3359C69.6104 22.2764 69.5866 22.2194 69.5444 22.1773C69.5022 22.1352 69.4451 22.1115 69.3854 22.1113Z"
          fill="#FF4338"
        />
        <path
          d="M69.3854 25.3899H67.4567C67.3971 25.3901 67.3401 25.4139 67.298 25.456C67.256 25.4981 67.2324 25.5551 67.2324 25.6145V27.5375C67.2323 27.567 67.2381 27.5962 67.2493 27.6234C67.2605 27.6506 67.277 27.6754 67.2979 27.6963C67.3187 27.7171 67.3435 27.7337 67.3707 27.745C67.398 27.7563 67.4272 27.7621 67.4567 27.7622H69.3854C69.4451 27.7621 69.5024 27.7385 69.5446 27.6963C69.5868 27.6542 69.6105 27.5971 69.6106 27.5375V25.6145C69.6104 25.555 69.5866 25.498 69.5444 25.4559C69.5022 25.4138 69.4451 25.3901 69.3854 25.3899Z"
          fill="#FF4338"
        />
        <path
          d="M69.3854 28.6685H67.4567C67.4272 28.6685 67.398 28.6743 67.3707 28.6856C67.3435 28.6969 67.3187 28.7135 67.2979 28.7344C67.277 28.7552 67.2605 28.78 67.2493 28.8072C67.2381 28.8344 67.2323 28.8636 67.2324 28.8931V30.8161C67.2323 30.8455 67.2381 30.8747 67.2493 30.902C67.2605 30.9292 67.277 30.9539 67.2979 30.9748C67.3187 30.9957 67.3435 31.0122 67.3707 31.0235C67.398 31.0349 67.4272 31.0407 67.4567 31.0407H69.3854C69.4451 31.0407 69.5024 31.017 69.5446 30.9749C69.5868 30.9328 69.6105 30.8757 69.6106 30.8161V28.8931C69.6105 28.8335 69.5868 28.7764 69.5446 28.7343C69.5024 28.6922 69.4451 28.6685 69.3854 28.6685Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.4352 15.5544H71.5065C71.447 15.5547 71.3899 15.5784 71.3479 15.6205C71.3058 15.6626 71.2822 15.7196 71.2822 15.779V17.7029C71.2822 17.7623 71.3059 17.8192 71.3479 17.8611C71.39 17.9031 71.4471 17.9267 71.5065 17.9267H73.4352C73.4948 17.9267 73.552 17.9032 73.5942 17.8612C73.6364 17.8193 73.6602 17.7624 73.6604 17.7029V15.779C73.6602 15.7195 73.6364 15.6625 73.5942 15.6204C73.5521 15.5783 73.4949 15.5546 73.4352 15.5544Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.4352 18.833H71.5065C71.447 18.8332 71.3899 18.857 71.3479 18.8991C71.3058 18.9412 71.2822 18.9982 71.2822 19.0576V20.9815C71.2822 21.0409 71.3059 21.0978 71.3479 21.1397C71.39 21.1817 71.4471 21.2053 71.5065 21.2053H73.4352C73.4948 21.2053 73.552 21.1818 73.5942 21.1398C73.6364 21.0979 73.6602 21.0409 73.6604 20.9815V19.0576C73.6602 18.9981 73.6364 18.9411 73.5942 18.899C73.5521 18.8569 73.4949 18.8332 73.4352 18.833Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.4352 22.1113H71.5065C71.447 22.1115 71.3899 22.1353 71.3479 22.1774C71.3058 22.2195 71.2822 22.2765 71.2822 22.3359V24.259C71.2821 24.2884 71.2879 24.3176 71.2991 24.3448C71.3104 24.3721 71.3269 24.3968 71.3477 24.4177C71.3685 24.4385 71.3933 24.4551 71.4205 24.4664C71.4478 24.4777 71.477 24.4836 71.5065 24.4836H73.4352C73.4949 24.4836 73.5522 24.4599 73.5944 24.4178C73.6366 24.3757 73.6604 24.3185 73.6604 24.259V22.3359C73.6602 22.2764 73.6364 22.2194 73.5943 22.1773C73.5521 22.1352 73.4949 22.1115 73.4352 22.1113Z"
          fill="#FF4338"
        />
        <path
          d="M73.4352 25.3899H71.5065C71.447 25.3901 71.3899 25.4139 71.3479 25.456C71.3058 25.4981 71.2822 25.5551 71.2822 25.6145V27.5375C71.2821 27.567 71.2879 27.5962 71.2991 27.6234C71.3104 27.6506 71.3269 27.6754 71.3477 27.6963C71.3685 27.7171 71.3933 27.7337 71.4205 27.745C71.4478 27.7563 71.477 27.7621 71.5065 27.7622H73.4352C73.4949 27.7621 73.5522 27.7385 73.5944 27.6963C73.6366 27.6542 73.6604 27.5971 73.6604 27.5375V25.6145C73.6602 25.555 73.6364 25.498 73.5943 25.4559C73.5521 25.4138 73.4949 25.3901 73.4352 25.3899Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.4352 28.6685H71.5065C71.477 28.6685 71.4478 28.6743 71.4205 28.6856C71.3933 28.6969 71.3685 28.7135 71.3477 28.7344C71.3269 28.7552 71.3104 28.78 71.2991 28.8072C71.2879 28.8344 71.2821 28.8636 71.2822 28.8931V30.8161C71.2821 30.8456 71.2879 30.8747 71.2991 30.902C71.3104 30.9292 71.3269 30.9539 71.3477 30.9748C71.3685 30.9957 71.3933 31.0122 71.4205 31.0235C71.4478 31.0349 71.477 31.0407 71.5065 31.0407H73.4352C73.4949 31.0407 73.5522 31.017 73.5944 30.9749C73.6366 30.9328 73.6604 30.8757 73.6604 30.8161V28.8931C73.6604 28.8335 73.6366 28.7764 73.5944 28.7343C73.5522 28.6922 73.4949 28.6685 73.4352 28.6685Z"
          fill="#E6E6E6"
        />
        <path
          d="M29.108 57.0217H29.0537L29.1001 57.0156L29.108 57.0217Z"
          fill="#2F2E41"
        />
        <path
          d="M24.4931 21.1701C27.761 21.1701 30.4101 18.5275 30.4101 15.2678C30.4101 12.008 27.761 9.36548 24.4931 9.36548C21.2253 9.36548 18.5762 12.008 18.5762 15.2678C18.5762 18.5275 21.2253 21.1701 24.4931 21.1701Z"
          fill="#FFB6B6"
        />
        <path
          d="M29.6747 10.9404C29.6747 10.9404 31.2223 7.98923 27.8176 7.72094C27.8176 7.72094 24.915 5.0945 21.9565 7.24079C21.9565 7.24079 20.3428 7.24079 19.4604 9.06237C19.4604 9.06237 18.1912 8.58222 17.9123 9.86723C17.9123 9.86723 16.9836 12.5501 17.9123 14.9647C18.841 17.3793 19.1494 17.6475 19.1494 17.6475C19.1494 17.6475 17.6234 12.5852 21.3379 12.317C25.0525 12.0487 29.2091 9.73309 29.5187 12.6842C29.8282 15.6354 30.2941 16.3625 30.2941 16.3625C30.2941 16.3625 33.2343 12.1477 29.6747 10.9404Z"
          fill="#2F2E41"
        />
        <path
          d="M39.7991 29.814L39.7801 29.9142L39.5662 31.0407L38.0878 38.8331L38.0004 39.2918L37.8168 40.262L37.5656 41.5854L37.0087 44.5184L36.8494 45.3589L36.7896 45.6743L36.7688 45.7848L36.6034 46.6548L36.5532 46.9174L36.1496 49.9004L35.7971 52.4999L36.9767 53.6765L36.2735 54.378L35.8119 57.0155L35.8127 57.0215H16.9737C16.2116 56.5291 15.4604 56.0214 14.7202 55.4985L14.8752 54.3409C14.8752 54.3409 15.4087 52.2736 15.1844 52.0265C14.961 51.7794 14.4708 51.338 14.4708 51.338L15.0883 49.9004L15.174 49.7009L15.0303 46.7265L15.0268 46.6548L14.9792 45.6743L14.9731 45.5481L14.9636 45.3589L14.7808 41.5854L14.7774 41.5232L14.722 40.3794L14.67 39.2918L14.6475 38.8331L14.4249 34.2432L14.2742 31.1262L14.1426 28.4127L14.6717 28.2339L20.3313 26.3152L22.6757 23.2751L27.555 23.3114L29.9436 23.3296L33.9291 27.0167L34.8358 27.4486L34.8428 27.4521L35.4932 27.7622L37.1421 28.5475L38.8855 29.3786L39.7801 29.8045L39.7991 29.814Z"
          fill="#CACACA"
        />
        <path
          d="M23.3058 48.1666C22.9483 48.3048 22.5647 48.3631 22.1821 48.3374C21.7995 48.3117 21.4272 48.2026 21.0915 48.0179C20.7557 47.8332 20.4646 47.5772 20.2388 47.2681C20.0129 46.959 19.8577 46.6043 19.7841 46.2289L10.5557 44.9229L13.7637 41.303L22.0354 43.0982C22.6758 43.0167 23.3239 43.1724 23.857 43.5357C24.39 43.899 24.7709 44.4448 24.9275 45.0695C25.0841 45.6943 25.0055 46.3546 24.7066 46.9255C24.4077 47.4963 23.9093 47.9379 23.3058 48.1666Z"
          fill="#FFB6B6"
        />
        <path
          d="M19.1916 41.5853C19.0206 41.3691 18.7786 41.2201 18.5083 41.1646L14.7219 40.3793L14.3331 40.2989C14.1071 40.2519 13.8936 40.1578 13.7066 40.0228C13.5196 39.8877 13.3633 39.7148 13.2479 39.5154C13.1282 39.306 13.0558 39.0731 13.0357 38.8329C13.0177 38.6145 13.0439 38.3948 13.1128 38.1867L14.4249 34.243L15.2762 31.6842C15.3793 31.3756 15.4402 31.0545 15.4572 30.7296C15.5046 29.8305 15.2257 28.9445 14.6717 28.2338C14.1845 27.6091 13.5089 27.1573 12.7446 26.9451C11.9803 26.7329 11.1678 26.7716 10.4272 27.0554C10.4055 27.0632 10.383 27.0718 10.3614 27.0805C9.83025 27.292 9.35572 27.6239 8.9753 28.0498C8.59488 28.4758 8.31898 28.9842 8.16941 29.5348L7.24447 32.891L5.33658 39.81C5.14559 40.5032 5.107 41.2294 5.22346 41.9389C5.33993 42.6483 5.60868 43.3243 6.01134 43.9206C6.41399 44.5168 6.94105 45.0193 7.55642 45.3935C8.17179 45.7677 8.86095 46.0049 9.57673 46.0888L15.0302 46.7263L17.5539 47.0218C17.8457 47.0568 18.14 46.9808 18.3782 46.809C18.6164 46.6372 18.7809 46.3822 18.8391 46.0948C18.8432 46.0792 18.8463 46.0633 18.8486 46.0473L18.9092 45.6741L19.4228 42.5313L19.428 42.4993C19.4547 42.3389 19.4475 42.1747 19.4068 42.0173C19.3661 41.8599 19.2928 41.7127 19.1916 41.5853Z"
          fill="#CACACA"
        />
        <path
          d="M29.1844 48.2675C29.5579 48.354 29.9459 48.3577 30.321 48.2785C30.6961 48.1992 31.0493 48.0389 31.3556 47.8087C31.6619 47.5786 31.9138 47.2843 32.0938 46.9465C32.2737 46.6087 32.3772 46.2357 32.3969 45.8537L41.3485 43.2626L37.6608 40.1301L29.7256 43.071C29.08 43.0804 28.4604 43.3256 27.9841 43.7603C27.5077 44.195 27.2078 44.7889 27.1411 45.4294C27.0744 46.07 27.2455 46.7127 27.6221 47.2357C27.9987 47.7588 28.5546 48.1259 29.1844 48.2675Z"
          fill="#FFB6B6"
        />
        <path
          d="M45.7927 37.4663L41.5352 27.6922C41.187 26.8897 40.565 26.2363 39.7797 25.8477C39.537 25.7285 39.282 25.6358 39.0193 25.5713C38.9786 25.5609 38.9379 25.5514 38.8972 25.5436C38.3402 25.4224 37.7624 25.433 37.2102 25.5748C36.7054 25.7041 36.2337 25.9381 35.8257 26.2616C35.4177 26.5851 35.0826 26.9907 34.8424 27.452C34.8398 27.4558 34.8375 27.4599 34.8355 27.4641C34.5671 27.982 34.4244 28.5555 34.4188 29.1384C34.4133 29.7213 34.545 30.2975 34.8034 30.8203L34.8563 30.9257L37.8649 36.9531C37.9676 37.1592 38.0255 37.3847 38.0347 37.6147C38.044 37.8447 38.0044 38.074 37.9185 38.2877C37.8326 38.5013 37.7025 38.6945 37.5365 38.8544C37.3705 39.0143 37.1726 39.1374 36.9555 39.2157L36.7442 39.2917L32.944 40.661C32.6589 40.7625 32.4243 40.9704 32.2898 41.2409C32.1552 41.5115 32.1311 41.8236 32.2226 42.1115L32.2321 42.1417L33.2974 45.542C33.3026 45.5575 33.3078 45.5731 33.3138 45.5886C33.3232 45.618 33.3348 45.6466 33.3485 45.6742C33.4617 45.9274 33.6618 46.1321 33.9128 46.2514C34.1638 46.3706 34.4492 46.3966 34.7177 46.3247L36.7685 45.7847L37.1868 45.6742L42.4826 44.279C43.1795 44.0953 43.8283 43.7636 44.3846 43.3065C44.941 42.8495 45.3918 42.2779 45.7061 41.6309C46.0205 40.9839 46.191 40.2768 46.2059 39.558C46.2208 38.8392 46.0799 38.1257 45.7927 37.4663Z"
          fill="#CACACA"
        />
        <path
          d="M3.88818 45.6741C5.11541 47.1672 6.43893 48.5789 7.85034 49.9003H46.993C47.3701 49.9008 47.7316 50.0506 47.9981 50.3167C48.2647 50.5828 48.4146 50.9435 48.415 51.3196L48.4713 61.2138C50.8793 60.1103 53.1344 58.7011 55.1805 57.0214C55.7487 56.5557 56.2992 56.0699 56.8321 55.564C57.1811 55.234 57.5217 54.8959 57.854 54.5497C60.3451 51.9611 62.3623 48.9571 63.8141 45.6741H3.88818Z"
          fill="#3F3D56"
        />
        <path
          d="M57.8541 54.5498C57.5225 54.8962 57.1818 55.2343 56.8322 55.564H48.5V54.5498H57.8541Z"
          fill="white"
        />
        <path
          d="M39.7212 22.1114C39.68 22.0356 39.6191 21.9723 39.5449 21.9282C39.4706 21.8841 39.3858 21.8608 39.2994 21.8608H10.907C10.7797 21.8612 10.6578 21.9118 10.5678 22.0017C10.4779 22.0916 10.4274 22.2133 10.4272 22.3403V39.2917H39.7801V22.3403C39.7805 22.2602 39.7603 22.1813 39.7212 22.1114Z"
          fill="#3F3D56"
        />
        <path
          d="M10.4014 38.8328V40.9246C10.4014 41.0113 10.4185 41.0972 10.4518 41.1773C10.485 41.2574 10.5338 41.3302 10.5953 41.3915C10.6567 41.4528 10.7297 41.5015 10.81 41.5347C10.8903 41.5679 10.9764 41.5849 11.0634 41.5849H21.8973V45.7132H21.7534C21.7421 45.7132 21.7309 45.7154 21.7205 45.7197C21.71 45.724 21.7005 45.7304 21.6926 45.7383C21.6846 45.7463 21.6782 45.7557 21.6739 45.7662C21.6696 45.7766 21.6674 45.7877 21.6674 45.799V46.0861C21.6674 46.0974 21.6696 46.1085 21.6739 46.119C21.6782 46.1294 21.6846 46.1388 21.6926 46.1468C21.7005 46.1547 21.71 46.1611 21.7205 46.1654C21.7309 46.1697 21.7421 46.1719 21.7534 46.1719H28.4789C28.4902 46.1719 28.5014 46.1697 28.5118 46.1654C28.5223 46.1611 28.5318 46.1547 28.5397 46.1468C28.5477 46.1388 28.5541 46.1294 28.5584 46.119C28.5627 46.1085 28.5649 46.0974 28.5649 46.0861V45.799C28.5649 45.7877 28.5627 45.7766 28.5584 45.7662C28.5541 45.7557 28.5477 45.7463 28.5397 45.7383C28.5318 45.7304 28.5223 45.724 28.5118 45.7197C28.5014 45.7154 28.4902 45.7132 28.4789 45.7132H28.335V41.5849H39.1689C39.2559 41.5849 39.342 41.5679 39.4223 41.5347C39.5026 41.5015 39.5756 41.4528 39.637 41.3915C39.6985 41.3302 39.7473 41.2574 39.7805 41.1773C39.8138 41.0972 39.8309 41.0113 39.8309 40.9246V38.8328H10.4014Z"
          fill="#3F3D56"
        />
        <path
          d="M24.7606 32.829C26.258 32.829 27.4719 31.6182 27.4719 30.1245C27.4719 28.6308 26.258 27.4199 24.7606 27.4199C23.2632 27.4199 22.0493 28.6308 22.0493 30.1245C22.0493 31.6182 23.2632 32.829 24.7606 32.829Z"
          fill="#FF4338"
        />
        <path
          d="M31.6685 14.9453H31.2355C31.2355 11.6798 27.9956 9.53989 24.7272 9.53817C24.2518 9.53678 23.7774 9.58279 23.3112 9.67553C22.7864 9.78006 22.2416 9.90532 21.7064 10.0686C19.5958 10.7139 17.6386 11.9614 17.6386 14.9453C17.6381 15.5248 17.7121 16.102 17.8586 16.6627L17.4394 16.7716C17.2839 16.1752 17.2053 15.5615 17.2056 14.9453C17.2056 12.3381 18.2786 10.5472 20.1319 9.47942C21.1062 8.91789 22.2953 8.55592 23.6576 8.37968C24.2245 8.30445 24.7976 8.28708 25.368 8.32785C29.1267 8.59738 31.6685 11.4724 31.6685 14.9453Z"
          fill="#FF4338"
        />
        <path
          d="M32.3899 15.1847L32.2193 13.9269C32.1772 13.6092 32.0238 13.3167 31.7863 13.101C31.6338 12.9604 31.4512 12.8562 31.2524 12.7963C31.0536 12.7365 30.8438 12.7225 30.6388 12.7554L30.3677 12.7926C30.2935 12.8023 30.222 12.8266 30.1574 12.8642C30.0927 12.9017 30.0361 12.9516 29.991 13.0111C29.9454 13.0704 29.9121 13.1381 29.8929 13.2103C29.8738 13.2826 29.8691 13.3578 29.8793 13.4319L30.2733 16.3294C30.2895 16.4475 30.3426 16.5575 30.425 16.6438C30.5074 16.7302 30.6149 16.7885 30.7323 16.8106C30.7671 16.8175 30.8025 16.821 30.838 16.8209C30.8635 16.8209 30.8889 16.8192 30.9142 16.8157L31.2173 16.7751C31.4021 16.7507 31.5799 16.6888 31.7399 16.5932C31.8998 16.4977 32.0385 16.3706 32.1474 16.2196C32.1708 16.1868 32.1933 16.1531 32.2141 16.1186C32.3764 15.8359 32.4384 15.5069 32.3899 15.1847Z"
          fill="#FF4338"
        />
        <path
          d="M30.1928 12.8452C30.1133 12.8837 30.0441 12.9406 29.991 13.0111C29.9454 13.0704 29.9121 13.1381 29.8929 13.2103C29.8738 13.2825 29.8691 13.3578 29.8793 13.4318L30.2733 16.3293C30.2895 16.4474 30.3426 16.5574 30.425 16.6438C30.5074 16.7301 30.6149 16.7885 30.7323 16.8105L30.1928 12.8452Z"
          fill="white"
        />
        <path
          d="M32.3898 15.1845L32.2192 13.9267C32.1771 13.6091 32.0237 13.3165 31.7861 13.1008L32.1785 15.9837C32.1843 16.0299 32.1963 16.0752 32.214 16.1184C32.3763 15.8357 32.4382 15.5067 32.3898 15.1845Z"
          fill="#3F3D56"
        />
        <path
          d="M31.0416 16.0911C31.1601 16.075 31.2197 15.7938 31.1746 15.4628C31.1296 15.1319 30.9971 14.8766 30.8786 14.8926C30.7601 14.9086 30.7005 15.1899 30.7456 15.5209C30.7906 15.8518 30.9231 16.1071 31.0416 16.0911Z"
          fill="#3F3D56"
        />
        <path
          d="M30.7818 14.5359C30.9003 14.5199 30.9599 14.2386 30.9149 13.9076C30.8699 13.5767 30.7373 13.3214 30.6188 13.3374C30.5004 13.3535 30.4408 13.6347 30.4858 13.9657C30.5308 14.2966 30.6633 14.5519 30.7818 14.5359Z"
          fill="#3F3D56"
        />
        <path
          d="M32.4273 15.1406C32.5458 15.1246 32.6054 14.8433 32.5604 14.5124C32.5154 14.1814 32.3828 13.9261 32.2644 13.9422C32.1459 13.9582 32.0863 14.2395 32.1313 14.5704C32.1763 14.9014 32.3088 15.1567 32.4273 15.1406Z"
          fill="#3F3D56"
        />
        <path
          d="M24.7271 9.53817C24.2517 9.53678 23.7773 9.58279 23.3111 9.67553C22.7863 9.78006 22.2415 9.90532 21.7063 10.0686C20.8706 10.028 20.2557 9.81202 20.1318 9.47942C21.1061 8.91789 22.2952 8.55592 23.6575 8.37968C24.2244 8.30445 24.7975 8.28708 25.3679 8.32785C25.4283 8.36316 25.4805 8.41082 25.5211 8.4677C25.5617 8.52458 25.5898 8.58938 25.6035 8.65786C25.6442 8.9585 25.3004 9.27813 24.7271 9.53817Z"
          fill="#3F3D56"
        />
        <path
          d="M19.1985 17.9431L18.5317 15.1778C18.5079 15.078 18.4551 14.9875 18.3799 14.9176C18.3047 14.8476 18.2105 14.8014 18.109 14.7847C18.0804 14.7803 18.0514 14.778 18.0224 14.7778C17.9816 14.778 17.9409 14.7829 17.9012 14.7925L17.6431 14.8547C17.3108 14.9349 17.024 15.1433 16.8455 15.4342C16.6671 15.725 16.6116 16.0746 16.6913 16.4063L16.9901 17.6468C17.0583 17.9389 17.2208 18.2005 17.4526 18.3915C17.4901 18.4218 17.5295 18.4497 17.5703 18.4753C17.7155 18.5661 17.8774 18.6269 18.0466 18.6542C18.2157 18.6815 18.3886 18.6747 18.555 18.6343L18.8123 18.5721C18.8803 18.5562 18.9443 18.5265 19.0002 18.4848C19.0834 18.4243 19.1466 18.3405 19.1819 18.2441C19.2173 18.1476 19.223 18.0429 19.1985 17.9431Z"
          fill="#FF4338"
        />
        <path
          d="M17.8003 17.4286C17.9166 17.4007 17.9473 17.1148 17.869 16.7901C17.7907 16.4654 17.6329 16.2248 17.5167 16.2527C17.4004 16.2806 17.3697 16.5665 17.448 16.8912C17.5263 17.2159 17.6841 17.4565 17.8003 17.4286Z"
          fill="#3F3D56"
        />
        <path
          d="M16.9341 17.6879C17.0504 17.66 17.0811 17.3741 17.0028 17.0494C16.9244 16.7247 16.7667 16.4841 16.6504 16.512C16.5342 16.5399 16.5034 16.8257 16.5818 17.1504C16.6601 17.4752 16.8179 17.7158 16.9341 17.6879Z"
          fill="#3F3D56"
        />
        <path
          d="M19.2407 17.9328L18.5739 15.1675C18.5479 15.0595 18.4906 14.9616 18.4091 14.886C18.3276 14.8104 18.2255 14.7605 18.1157 14.7424L19.0259 18.5194C19.1158 18.4537 19.1842 18.363 19.2224 18.2586C19.2607 18.1542 19.267 18.0408 19.2407 17.9328Z"
          fill="white"
        />
        <path
          d="M16.8417 15.8586C16.8233 15.7817 16.7888 15.7096 16.7404 15.647C16.6467 15.8891 16.6294 16.1541 16.691 16.4063L16.9898 17.6469C17.058 17.939 17.2205 18.2006 17.4523 18.3916L16.8417 15.8586Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_1442_3947">
          <rect width="73.6604" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
