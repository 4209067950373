import { useEffect, useMemo, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { contexts } from "@dip/contexts";
import { InContextExample } from "@dip/data-access/api-types";
import { PrimaryButton } from "@dip/ui/components/buttons";
import { InContextExampleCheckboxes } from "@dip/ui/components/in-context-example-checkboxes";
import { Slider } from "@dip/ui/components/slider";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Collapse, Form, Radio } from "antd";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { StepLayout } from "../layouts";

const { Panel } = Collapse;
const ContextRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.sm};
`;
const ErrorContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
`;
const ErrorText = styled(Paragraph)`
  color: inherit;
`;
const ExclamationCircleIconContainer = styled(ExclamationCircleIcon)`
  color: inherit;
  height: 1.5rem;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const ContextSlider = styled(Slider)`
  width: 18rem;
  margin-top: ${({ theme }) => theme.spacings.xl};
`;
const ContextRadio = styled(Radio)`
  border-radius: ${({ theme }) => theme.roundness.md};
  border: 1px solid ${({ theme }) => theme.colors.component.border[1]};
  padding: 1rem;
  align-content: center;
  gap: ${({ theme }) => theme.spacings.lg};
`;

const HelperText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
`;

interface DomainPageProps {
  onGoForward: () => void;
}

export const ContextPage = ({ onGoForward }: DomainPageProps) => {
  const domainDefinitionFieldName = "domainDefinition";
  const allInContextExamples = useMemo(
    () => Object.values(InContextExample),
    []
  );
  const form = Form.useFormInstance();
  const selectedDomain = Form.useWatch<InContextExample>(
    domainDefinitionFieldName,
    form
  );
  const [inContextExampleCheckboxValues, setInContextExampleCheckboxValues] =
    useState(
      allInContextExamples.filter((example) => example !== selectedDomain)
    );
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (form.isFieldTouched(domainDefinitionFieldName)) {
      allInContextExamples.forEach((example) => {
        const checkboxName = `in-context-checkbox-${example}`;
        form.setFieldsValue({
          [checkboxName]: example !== selectedDomain,
        });
      });
      setInContextExampleCheckboxValues(
        allInContextExamples.filter((example) => example !== selectedDomain)
      );
    }
  }, [selectedDomain, form, domainDefinitionFieldName, allInContextExamples]);

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );

  const isDomainInputError = async () => {
    try {
      await form.validateFields([domainDefinitionFieldName]);
      setHasError(false);
      return false;
    } catch (errorInfo) {
      setHasError(true);
      return true;
    }
  };

  const handleContinue = async () =>
    !(await isDomainInputError()) && onGoForward();

  return (
    <StepLayout
      title={<Heading level="h4">Select context</Heading>}
      headerDescription={
        <Spacing>
          <HelperText small>
            Select the context appropriate to this domain. This ensures the
            context will not be applied when testing
          </HelperText>
          {hasError && (
            <ErrorContainer gap="xs" direction="horizontal" items="center">
              <ExclamationCircleIconContainer fontSize={4} />
              <ErrorText>Please select a context.</ErrorText>
            </ErrorContainer>
          )}
          <FormItem
            name={domainDefinitionFieldName}
            rules={[{ required: true }]}
            help=""
          >
            <ContextRadioGroup>
              {allInContextExamples.map(
                (example) =>
                  example !== InContextExample.NONE && (
                    <ContextRadio value={example} key={example}>
                      {contexts[example].title}
                    </ContextRadio>
                  )
              )}
            </ContextRadioGroup>
          </FormItem>
          <Collapse
            collapsible={selectedDomain ? "header" : "disabled"}
            expandIconPosition="end"
            ghost
          >
            <Panel header="Advanced Settings" key="1" extra={genExtra()}>
              <Spacing gap="lg">
                <Spacing gap={"none"}>
                  <Heading level="h5">Max. refinements</Heading>
                  <HelperText small>
                    The highest number of refinement iterations allowed before
                    requesting user feedback.
                  </HelperText>
                  <FormItem name="refinements">
                    <ContextSlider
                      max={10}
                      marks={{
                        0: "0",
                        10: "10",
                      }}
                    />
                  </FormItem>
                </Spacing>
                <Spacing gap={"none"}>
                  <Heading level={"h5"}>Customise in-context examples</Heading>
                  <Spacing>
                    <HelperText small>
                      These are examples which will be given to the LLM. You
                      must ensure that the in-context examples do not contain
                      the domain currently being understood.
                    </HelperText>
                    <InContextExampleCheckboxes
                      value={inContextExampleCheckboxValues}
                      disabled={[selectedDomain]}
                      onChange={(checkedValues) => {
                        setInContextExampleCheckboxValues(
                          allInContextExamples.filter((example) =>
                            checkedValues.includes(example)
                          )
                        );
                        allInContextExamples.forEach((example) =>
                          form.setFieldValue(
                            `in-context-checkbox-${example}`,
                            checkedValues.includes(example)
                          )
                        );
                      }}
                    />
                  </Spacing>
                </Spacing>
              </Spacing>
            </Panel>
          </Collapse>
          <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
        </Spacing>
      }
    />
  );
};
