import React, { FunctionComponent } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

type SeeMoreButtonProps = {
  onLoadMore: () => void;
  moreAvailable?: boolean;
  // If not specified, the default is false
  isLoadingMore?: boolean;
};

export const SeeMoreButton: FunctionComponent<SeeMoreButtonProps> = (props) => {
  if (!props.moreAvailable) {
    return <React.Fragment />;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Button type="primary" shape="round" onClick={props.onLoadMore}>
        {props.isLoadingMore ? <LoadingOutlined /> : "See More"}
      </Button>
    </div>
  );
};
