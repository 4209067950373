// TODO (CTRL-14): Replace this file with an API call to Acorn!!

type LogicalConnector = "AND" | "OR";
type LogicalOperator = "NOT";

type UnderstandingRepresentation = {
  name: string;
  question: string;
  line?: string;
  logical_operator?: LogicalOperator;
  logical_connective?: LogicalConnector;
  sub_conditions?: UnderstandingRepresentation[];
};

export const adultDepJSON: UnderstandingRepresentation = {
  line: "",
  question: "Can i qualify for the Adults dependant loan?",
  name: "CanGetAdultDependantsGrant",
  sub_conditions: [
    {
      line: "<p>If you’re a full-time student in higher education and an adult depends on you financially, you can apply for an Adult Dependants’ Grant of up to:</p>",
      question: "Are you a full-time student in higher education?",
      name: "YouAreFullTimeStudentInHigherEducation",
    },
    {
      line: "<p>You cannot get an Adult Dependants’ Grant if you’re getting a Postgraduate Loan.</p>",
      question: "Are you getting a Postgraduate Loan?",
      name: "GettingPostgraduateLoan",
      logical_operator: "NOT",
    },
    {
      line: "<p>Usually the adult dependant will be:</p>",
      question: "Is your adult dependant eligible for the grant?",
      name: "AdultDependantIsEligibleForGrant",
      sub_conditions: [
        {
          line: "<li>your husband, wife, partner or civil partner</li>",
          question:
            "Is your adult dependant your husband, wife, partner or civil partner?",
          name: "AdultDependantIsYourHusbandWifePartnerOrCivilPartner",
        },
        {
          line: "<li>a relative, such as a parent or grandparent</li>",
          question:
            "Is your adult dependant a relative, such as a parent or grandparent?",
          name: "AdultDependantIsYourRelative",
        },
      ],
      logical_connective: "OR",
    },
    {
      line: "<p>You’re not eligible if the adult dependant is:</p>",
      question: "Is your adult dependant ineligible for the grant?",
      name: "AdultDependantIsNotEligibleForGrant",
      logical_operator: "NOT",
      sub_conditions: [
        {
          line: "<li>your child</li>",
          question: "Is your adult dependant your child?",
          name: "AdultDependantIsYourChild",
        },
        {
          line: "<li>a relative who earns more than £3,796 a year</li>",
          question:
            "Is your adult dependant a relative who earns more than £3,796 a year?",
          name: "AdultDependantIsRelativeWithHigherIncome",
        },
        {
          line: "<li>getting student finance</li>",
          question: "Is your adult dependant getting student finance?",
          name: "AdultDependantIsGettingStudentFinance",
        },
        {
          line: "<p>If you’re under 25, the adult dependant cannot be your partner unless you’re married or in a civil partnership.</p>",
          question:
            "Are you under 25 and the adult dependant is your partner that you are not married or in a civil relationship with?",
          name: "Under25AndAdultDependantIsPartnerNotMarriedOrInCivilRelationship",
        },
      ],
      logical_connective: "OR",
    },
  ],
  logical_connective: "AND",
};

export const avivaJSON: UnderstandingRepresentation = {
  name: "ClaimCoveredByAvivaSingleAndMultiTripCoveragePolicy",
  line: "",
  question:
    "Does your claim meet the requirements to be covered by the Aviva Single Trip and Annual Multi-trip Travel Insurance Policy?",
  sub_conditions: [
    {
      name: "ClaimMeetsGeneralInformationConditions",
      line: "## Information about your Travel Insurance Policy",
      question:
        "Does your claim meet the core eligibility requirements described in the Information about your Travel Insurance Policy section?",
      sub_conditions: [
        {
          name: "IsClaimantUKResident",
          line: "1. you are a UK resident;",
          question: "Is the claimant a UK resident?",
        },
        {
          name: "JourneyBeginsAndEndsInUK",
          line: "2. the journey is a round trip beginning and ending in the UK;",
          question: "Does the journey begin and end in the UK as a round trip?",
        },
        {
          name: "HasReturnJourneyBooked",
          line: "3. you have booked your return journey when travelling outside the UK;",
          question:
            "Is the claimant's return journey booked if travelling outside the UK?",
        },
        {
          name: "IsEligibleJourneyType",
          line: "4. the journey is either:",
          question:
            "Does the trip meet the definition of an eligible holiday or business trip under the policy?",
          sub_conditions: [
            {
              name: "IsHolidayOutsideUK",
              line: "a) a holiday outside the UK",
              question:
                "Is the journey a holiday that takes place outside the UK?",
            },
            {
              name: "IsBusinessTripOutsideUKClericalOnly",
              line: "b) a business trip outside the UK involving clerical and administrative duties only (Business travel is optional and is only covered if shown on your policy schedule)",
              question:
                "Is the journey a strictly clerical or administrative business trip outside the UK that is covered on your policy schedule?",
            },
            {
              name: "IsHolidayWithinUKTwoPlusNights",
              line: "c) a holiday within the UK, which includes two or more consecutive nights stay in pre-booked holiday accommodation",
              question:
                "Is the journey a holiday within the UK with at least two consecutive nights of pre-booked holiday accommodation?",
            },
          ],
          logical_connective: "OR",
        },
      ],
      logical_connective: "AND",
    },
    {
      name: "AnyGeneralExclusionsApply",
      line: "### General Exclusions",
      question: "Do any of the General Exclusions apply to this claim?",
      logical_operator: "NOT",
      sub_conditions: [
        {
          name: "GeneralExclusionWar",
          line: "1. Any consequence whatsoever which is the direct or indirect result of any of the following, or anything connected with any of the following, whether or not contributed to by any other cause or event:",
          question:
            "Is the claim a direct or indirect result of (a) war, invasion, hostilities, or conflict, or (b) any action taken to prevent or control such events?",
          sub_conditions: [
            {
              name: "GeneralExclusionWarHostilities",
              line: "a) war; invasion; act of a foreign enemy; hostilities or warlike operation or operations (whether war has been declared or not); civil war; revolution, rebellion or insurrection; civil commotion; military power; usurped power",
              question:
                "Is the claim a direct or indirect result (or anything connected with) war, invasion, hostilities, rebellion, civil commotion, or similar conflict?",
            },
            {
              name: "GeneralExclusionActionPreventWar",
              line: "b) any action taken to prevent, control or suppress, or which in any way relates to (a) above.",
              question:
                "Is the claim a direct or indirect result of any action taken to prevent, control, or suppress war, hostilities, or related events?",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "GeneralExclusionRadioactiveContamination",
          line: "2. Claims directly or indirectly caused by:",
          question:
            "Is the claim caused (directly or indirectly) by any nuclear/radioactive contamination or related hazard?",
          sub_conditions: [
            {
              name: "IonisingRadiationContamination",
              line: "a) Ionising radiation or contamination by radioactivity from any nuclear fuel or from any nuclear waste from burning nuclear fuel;",
              question:
                "Is the claim caused or contributed to by ionising radiation or radioactive contamination from nuclear fuel or nuclear waste?",
            },
            {
              name: "RadioactiveToxicExplosiveProperties",
              line: "b) The radioactive, toxic, explosive or other hazardous properties of any explosive nuclear assembly or nuclear part of an assembly;",
              question:
                "Is the claim caused or contributed to by the radioactive, toxic, or explosive properties of nuclear assemblies (or related parts)?",
            },
            {
              name: "PressureWavesFromSupersonicFlight",
              line: "c) Pressure waves caused by aircraft and other aerial devices travelling at sonic or supersonic speeds.",
              question:
                "Is the claim caused or contributed to by pressure waves from aircraft or devices traveling at sonic or supersonic speeds?",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "GeneralExclusionSuicideSubstance",
          line: "3. Any claim for your death, injury or disability resulting from; a) your suicide or attempted suicide; or b) your misuse of alcohol or drugs or your consumption of alcohol or drugs (other than drugs taken under medical supervision and not for treating alcohol or drug addiction) to an extent which causes immediate or long-term physical or mental harm, or where your judgement is affected causing you to take actions you would not usually take; or c) any exacerbation of an accepted medical condition caused by your misuse of alcohol or drugs.",
          question:
            "Is this claim resulting from the claimant's suicide or attempted suicide, or from the misuse of alcohol or drugs (including consumption to a harmful extent), or from an exacerbation of a condition caused by that misuse?",
          sub_conditions: [
            {
              name: "ClaimResultOfSuicideOrAttemptedSuicide",
              line: "a) your suicide or attempted suicide;",
              question:
                "Is the claim the result of the claimant's suicide or attempted suicide?",
            },
            {
              name: "ClaimResultOfAlcoholDrugMisuse",
              line: "b) your misuse of alcohol or drugs or your consumption of alcohol or drugs (other than drugs taken under medical supervision and not for treating alcohol or drug addiction) to an extent which causes immediate or long-term physical or mental harm, or where your judgement is affected causing you to take actions you would not usually take;",
              question:
                "Is the claim the result of misuse or harmful consumption of alcohol or drugs?",
            },
            {
              name: "ClaimResultOfConditionExacerbationByMisuse",
              line: "c) any exacerbation of an accepted medical condition caused by your misuse of alcohol or drugs.",
              question:
                "Is the claim the result of a worsened (exacerbated) medical condition due to the claimant's misuse of alcohol or drugs?",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "GeneralExclusionDeliberateExposure",
          line: "4. Any claim where the claimant deliberately put themselves at risk of death, injury, illness or disability (unless saving a human life).",
          question:
            "Did the claimant deliberately expose themselves to danger (other than to save a human life)?",
        },
        {
          name: "GeneralExclusionNotCoveredLoss",
          line: "5. Any loss that is not specifically described in the cover sections of this policy, e.g. we will not pay for loss of earnings if you are unable to return to work due to injury or illness during your trip, or any payment which you would normally have made during your travels.",
          question:
            "Is the claim for loss outside the scope of what is specifically described in the cover sections of this policy?",
        },
        {
          name: "GeneralExclusionProviderInsolvency",
          line: "6. Claims resulting from any tour operator, travel agent, airline or other service provider becoming insolvent and not being able or willing to carry out any part of their duty to you.",
          question:
            "Is the claim resulting from any tour operator, travel agent, airline or other service provider becoming insolvent and not being able or willing to carry out any part of their duty to you?",
        },
        {
          name: "GeneralExclusionPreexistingMental",
          line: "7. Any claim that results from any anxiety state, depression, mental, nervous or emotional disorder which had been diagnosed before you took out or renewed your policy or when you booked your trip (whichever is later).",
          question:
            "Is the claim resulting from any anxiety state, depression, mental, nervous or emotional disorder which had been diagnosed before you took out or renewed your policy or when you booked your trip (whichever is later)?",
        },
        {
          name: "GeneralExclusionBeyondTripDuration",
          line: "8. Any incident which happens after the trip duration limit, shown on your policy schedule, has been exceeded.",
          question:
            "Did the incident occur after the trip duration limit, shown on your policy schedule, had been exceeded?",
        },
        {
          name: "GeneralExclusionHazardousOrExcludedActivities",
          line: "9. Any claim for an incident which happens during the trip that results from:",
          question:
            "Is the claim for an incident during the trip that arises from any excluded activities?",
          sub_conditions: [
            {
              name: "FlyingOrAerialActivitiesExclusion",
              line: "a. you flying an aircraft or taking part in other aerial activities not listed in the Leisure Activities and Activity Based Holidays sections;",
              question:
                "Did the incident arise from flying an aircraft or another aerial activity not listed as covered?",
            },
            {
              name: "RidingScooterMopedOrMotorcycle",
              line: "b. you riding or being a passenger on a scooter, moped or motorcycle:",
              question:
                "Did the incident arise from riding or being a passenger on a scooter, moped, or motorcycle in an excluded manner?",
              sub_conditions: [
                {
                  name: "ScooterOrMoped125ccOrUnder",
                  line: "125cc or under; unless you wear a crash helmet and, as a rider, you are fully licensed to use such a vehicle in the UK;",
                  question:
                    "Did the claim arise from riding or being a passenger on a scooter or moped (125cc or under) without wearing a crash helmet, or without holding a full UK licence?",
                },
                {
                  name: "MotorcycleOver125cc",
                  line: "over 125cc; unless this is your mode of transport from the UK and you wear a crash helmet and appropriate protective clothing; and, as a rider, you are fully licensed and insured to use this vehicle in the UK. There is no cover for trips taken outside of Europe.",
                  question:
                    "Did the claim arise from riding or being a passenger on a motorcycle over 125cc where you did not meet the licensing, insurance, helmet, or protective clothing requirements, or if the trip was outside Europe?",
                },
              ],
              logical_connective: "OR",
            },
            {
              name: "RidingQuadBikeOrATV",
              line: "c. you riding or being a passenger on a quad bike, all terrain vehicle or similar on or off road;",
              question:
                "Did the incident arise from riding or being a passenger on a quad bike, all terrain vehicle, or similar on or off road?",
            },
            {
              name: "DrivingMotorisedVehicleWithoutLicense",
              line: "d. you driving any motorised vehicle, unless you are fully licensed to drive such a vehicle in the UK;",
              question:
                "Did the incident arise from you driving a motorised vehicle without being fully licensed to drive it in the UK?",
            },
            {
              name: "DrivingOrPassengerMotorisedVehicleNonCompliance",
              line: "e. you driving or being a passenger in any motorised vehicle unless you have complied with all laws applying to use of that vehicle in the country you are visiting, for example, you must wear a seat belt where this is required by law;",
              question:
                "Did the incident arise from driving or riding as a passenger in a motorised vehicle without complying with all relevant laws (e.g., seat belt use) in the country visited?",
            },
            {
              name: "InvolvementInManualWork",
              line: "f. your involvement in paid or unpaid manual work or physical labour of any kind, other than Charity and Conservation work as shown in the Activity Based Holidays section;",
              question:
                "Did the incident arise from your involvement in any form of manual work or physical labour (other than the permitted Charity or Conservation work)?",
            },
            {
              name: "BusinessTravelNotOnSchedule",
              line: "g. business travel unless this is shown on your policy schedule;",
              question:
                "Did the incident arise from business travel not specifically shown as covered on the policy schedule?",
            },
            {
              name: "BusinessTravelNonOfficePublicFacing",
              line: "h. business travel if your job is not wholly an office based role and your journey involves dealing with members of the public or any tasks other than clerical or administrative duties;",
              question:
                "Did the incident arise from business travel involving non-office duties or public interaction if only office-based business travel is covered?",
            },
            {
              name: "IncidentAroseFromTakingPartInUnlistedOrNonConformingLeisureActivity",
              line: "i. you taking part in a leisure activity, activity based holiday or winter sports activity not listed in the Leisure Activities or Activity Based Holidays section;",
              question:
                "Did the incident arise from taking part in a leisure activity or activity based holiday that is not covered in the policy?",
              sub_conditions: [
                {
                  name: "IncidentAroseFromLeisureActivityOrActivityBasedHoliday",
                  line: "i. you taking part in a leisure activity, activity based holiday or winter sports activity not listed in the Leisure Activities or Activity Based Holidays section;",
                  question:
                    "Did the incident arise from taking part in a leisure activity or activity based holiday?",
                },
                {
                  name: "TakingPartInUnlistedOrNonConformingLeisureActivity",
                  line: "i. you taking part in a leisure activity, activity based holiday or winter sports activity not listed in the Leisure Activities or Activity Based Holidays section; There is no cover at all for any injury or death if, during your trip, you take part in any leisure activity and/or activity based holiday that meets any of the following conditions:",
                  question:
                    "Did the incident arise from taking part in a leisure activity or activity based holiday that is not listed in the Leisure Activities or Activity Based Holidays section or because it meets any of the disqualifying criteria",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "TakingPartInUnlistedLeisureActivityOrActivityBasedHoliday",
                      line: "i. you taking part in a leisure activity, activity based holiday or winter sports activity not listed in the Leisure Activities or Activity Based Holidays section;",
                      question:
                        "Did the incident arise from taking part in a leisure activity or activity based holiday that is not listed in the Leisure Activities or Activity Based Holidays section;",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "MainPurposeAndParticipatedInNotListedActivityBasedHoliday",
                          line: "There is no cover where the main purpose of your trip is to take part in a leisure activity (unless shown as an Activity Based Holiday below).",
                          question:
                            "Was the main purpose of your trip to take part in the leisure activity or activity based holiday that is not listed as covered?",
                          logical_connective: "AND",
                          sub_conditions: [
                            {
                              name: "MainPurposeOfTripWasToParticipateInLeisureActivity",
                              line: "There is no cover where the main purpose of your trip is to take part in a leisure activity (unless shown as an Activity Based Holiday below).",
                              question:
                                "Was the main purpose of your trip to take part in the leisure activity as an activity based holiday?",
                            },
                            {
                              name: "ParticipatedInListedActivityBasedHoliday",
                              line: "You are covered for the following activity based holidays, subject to any limitation shown below",
                              question:
                                "Did you take part in one of the listed activity based holidays?",
                              logical_connective: "OR",
                              logical_operator: "NOT",
                              sub_conditions: [
                                {
                                  name: "TookPartInCharityOrConservationWorkMeetingConditions",
                                  line: "Charity or Conservation Work – Voluntary work only; organised through a registered charity or conservation organisation; trip no longer than 31 days; manual work involving hand tools only; no work at heights above 3 metres.",
                                  question:
                                    "Did you take part in Charity or Conservation Work that meets all of the following conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInCharityOrConservationWork",
                                      line: "Charity or Conservation Work",
                                      question:
                                        "Did you take part in Charity or Conservation Work as an activity based holiday?",
                                    },
                                    {
                                      name: "IsVoluntaryWorkOnly",
                                      line: "Voluntary work only",
                                      question:
                                        "Was the Charity or Conservation Work undertaken strictly on a voluntary basis?",
                                    },
                                    {
                                      name: "IsOrganisedThroughCharityOrConservation",
                                      line: "Organised through a registered charity or conservation organisation",
                                      question:
                                        "Was the Charity or Conservation Work organised by a registered charity or conservation organisation?",
                                    },
                                    {
                                      name: "TripDurationWithin31Days",
                                      line: "Trip no longer than 31 days",
                                      question:
                                        "Was the duration of the trip 31 days or less?",
                                    },
                                    {
                                      name: "ManualWorkUsingHandToolsOnly",
                                      line: "Manual work involving hand tools only",
                                      question:
                                        "Was the manual work limited to the use of hand tools only?",
                                    },
                                    {
                                      name: "NoWorkAtHeightsAbove3m",
                                      line: "No work at heights above 3 metres",
                                      question:
                                        "Did you avoid any work at heights above 3 metres?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInCycleTouring",
                                  line: "Cycle Touring",
                                  question:
                                    "Did you take part in Cycle Touring?",
                                },
                                {
                                  name: "TookPartInFishing",
                                  line: "Fishing",
                                  question: "Did you take part in Fishing?",
                                },
                                {
                                  name: "TookPartInFlotillaSailingMeetingConditions",
                                  line: "Flotilla Sailing – Under supervision of a qualified lead skipper and with no racing involved; Organised through a licensed operator/tour operator only.",
                                  question:
                                    "Did you take part in Flotilla Sailing under supervision of a qualified lead skipper and with no racing involved?",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInFlotillaSailing",
                                      line: "Flotilla Sailing",
                                      question:
                                        "Did you take part in Flotilla Sailing?",
                                    },
                                    {
                                      name: "FlotillaSailingOrganisedThroughLicensedOperator",
                                      line: "Organised through a licensed operator/tour operator only.",
                                      question:
                                        "Did you take part in Flotilla Sailing organised through a licensed operator/tour operator?",
                                    },
                                    {
                                      name: "FlotillaSailingUnderSupervision",
                                      line: "Under supervision of a qualified lead skipper and with no racing involved",
                                      question:
                                        "Did you take part in Flotilla Sailing under supervision of a qualified lead skipper?",
                                    },
                                    {
                                      name: "FlotillaSailingNoRacing",
                                      line: "Under supervision of a qualified lead skipper and with no racing involved",
                                      question:
                                        "Was no racing involved in your Flotilla Sailing activity?",
                                    },
                                  ],
                                  logical_connective: "AND",
                                },
                                {
                                  name: "TookPartInGolf",
                                  line: "Golf",
                                  question: "Did you take part in Golf?",
                                },
                                {
                                  name: "TookPartInHikingAndTrekkingMeetingConditions",
                                  line: "Hiking and Trekking up to 5,000 metres altitude – Must be accompanied by qualified guides; not applicable if the trek includes an ascent of over 5,000 metres; Organised through a licensed operator/tour operator only",
                                  question:
                                    "Did you take part in Hiking and Trekking up to 5,000 metres altitude under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInHikingAndTrekking",
                                      line: "Hiking and Trekking up to 5,000 metres altitude",
                                      question:
                                        "Did you take part in Hiking and Trekking?",
                                    },
                                    {
                                      name: "HikingAndTrekkingOrganisedThroughLicensedOperator",
                                      line: "Organised through a licensed operator/tour operator only.",
                                      question:
                                        "Did you take part in Hiking and Trekking organised through a licensed operator/tour operator?",
                                    },
                                    {
                                      name: "AccompaniedByQualifiedGuides",
                                      line: "Accompanied by qualified guides",
                                      question:
                                        "Were you accompanied by qualified guides during your trek?",
                                    },
                                    {
                                      name: "AscentWithin5000mLimit",
                                      line: "No trek with an ascent over 5,000 metres",
                                      question:
                                        "Was the trek conducted without an ascent exceeding 5,000 metres?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInNarrowBoatCanalCruisingMeetsConditions",
                                  line: "Narrow boat / Canal cruising – Inland waters only",
                                  question:
                                    "Did you take part in Narrow boat / Canal cruising under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInNarrowBoatCanalCruising",
                                      line: "Narrow boat / Canal cruising",
                                      question:
                                        "Did you take part in narrow boat or canal cruising?",
                                    },
                                    {
                                      name: "NarrowBoatCanalCruisingInlandWatersOnly",
                                      line: "Inland waters only",
                                      question:
                                        "Were you narrow boat or canal cruising only in inland waters?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInSafari",
                                  line: "Safari – Must be conducted with supervised walking and by vehicle",
                                  question:
                                    "Did you take part in a Safari conducted under supervised conditions?",
                                },
                                {
                                  name: "TookPartInTennis",
                                  line: "Tennis",
                                  question: "Did you take part in Tennis?",
                                },
                                {
                                  name: "TookPartInYoga",
                                  line: "Yoga",
                                  question: "Did you take part in Yoga?",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          name: "NotMainPurposeAndParticipatedInNotListedLeisureActivity",
                          line: "There is no cover where the main purpose of your trip is to take part in a leisure activity (unless shown as an Activity Based Holiday below).",
                          question:
                            "Was the main purpose of your trip to take part in the leisure activity or activity based holiday that is not listed as covered?",
                          logical_connective: "AND",
                          sub_conditions: [
                            {
                              name: "MainPurposeOfTripWasToParticipateInLeisureActivity",
                              line: "There is no cover where the main purpose of your trip is to take part in a leisure activity (unless shown as an Activity Based Holiday below).",
                              question:
                                "Was the main purpose of your trip to take part in the leisure activity as an activity based holiday?",
                              logical_operator: "NOT",
                            },
                            {
                              name: "ParticipatedInListedLeisureActivity",
                              line: "You will be covered whilst taking part in the following leisure activities, subject to any limitation shown.",
                              question:
                                "Did you take part in a listed activity based holiday?",
                              logical_operator: "NOT",
                              logical_connective: "OR",
                              sub_conditions: [
                                {
                                  name: "TookPartInAbseiling",
                                  line: "Abseiling – Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Abseiling under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInAerobics",
                                  line: "Aerobics",
                                  question: "Did you take part in Aerobics?",
                                },
                                {
                                  name: "TookPartInArchery",
                                  line: "Archery – Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Archery under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInArtificialWallClimbing",
                                  line: "Artificial Wall Climbing",
                                  question:
                                    "Did you take part in Artificial Wall Climbing?",
                                },
                                {
                                  name: "TookPartInBadminton",
                                  line: "Badminton",
                                  question: "Did you take part in Badminton?",
                                },
                                {
                                  name: "TookPartInBananaBoatingRingo",
                                  line: "Banana boating / Ringo",
                                  question:
                                    "Did you take part in Banana boating or Ringo?",
                                },
                                {
                                  name: "TookPartInBaseball",
                                  line: "Baseball",
                                  question: "Did you take part in Baseball?",
                                },
                                {
                                  name: "TookPartInBasketball",
                                  line: "Basketball",
                                  question: "Did you take part in Basketball?",
                                },
                                {
                                  name: "TookPartInBodyBoarding",
                                  line: "Body boarding",
                                  question:
                                    "Did you take part in Body boarding?",
                                },
                                {
                                  name: "TookPartInBowlsPetanque",
                                  line: "Bowls / Petanque",
                                  question:
                                    "Did you take part in Bowls or Petanque?",
                                },
                                {
                                  name: "TookPartInBridgeWalking",
                                  line: "Bridge walking – Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Bridge walking under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInBungeeJumping",
                                  line: "Bungee jumping – Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Bungee jumping under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInCamelElephantRides",
                                  line: "Camel or Elephant rides",
                                  question:
                                    "Did you take part in Camel or Elephant rides?",
                                },
                                {
                                  name: "TookPartInCanoeing",
                                  line: "Canoeing - Maximum Grade 3 (or the equivalent international grade used by the country you are in)",
                                  question:
                                    "Did you take part in Canoeing upto a maximum of Grade 3 (or the equivalent international grade used by the country you are in)?",
                                },
                                {
                                  name: "TookPartInCanopyTreetopWalking",
                                  line: "Canopy / Treetop walking",
                                  question:
                                    "Did you take part in Canopy or Treetop walking?",
                                },
                                {
                                  name: "TookPartInCaveRiverTubeing",
                                  line: "Cave / River Tubing - Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Cave / River Tubing under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInClayPigeonOrSmallBoreShooting",
                                  line: "Clay-pigeon or small bore shooting - Must be under supervision of a qualified instructor/guide only; Organised through a licensed operator only.",
                                  question:
                                    "Did you take part in Clay-pigeon or small bore shooting organised through a licensed operator and under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInCoasteering",
                                  line: "Coasteering – Must be under supervision of a qualified instructor/guide only.",
                                  question:
                                    "Did you take part in Coasteering under supervision of a qualified instructor/guide?",
                                },
                                {
                                  name: "TookPartInCricket",
                                  line: "Cricket",
                                  question: "Did you take part in Cricket?",
                                },
                                {
                                  name: "TookPartInCroquet",
                                  line: "Croquet",
                                  question: "Did you take part in Croquet?",
                                },
                                {
                                  name: "TookPartInCurling",
                                  line: "Curling",
                                  question: "Did you take part in Curling?",
                                },
                                {
                                  name: "TookPartInCycling",
                                  line: "Cycling – No BMX or racing allowed.",
                                  question:
                                    "Did you take part in Cycling apart from BMX or competitive racing?",
                                },
                                {
                                  name: "TookPartInDodgeball",
                                  line: "Dodgeball",
                                  question: "Did you take part in Dodgeball?",
                                },
                                {
                                  name: "TookPartInDinghySailingMeetingConditions",
                                  line: "Dinghy sailing",
                                  question:
                                    "Did you take part in Dinghy sailing meeting the conditions for cover?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInDinghySailing",
                                      line: "Dinghy sailing",
                                      question:
                                        "Did you take part in Dinghy sailing?",
                                    },
                                    {
                                      name: "DinghySailingConditions",
                                      line: "No racing and within a 5 mile limit of the coastline or on inland waters",
                                      question:
                                        "Were you not racing and within a 5 mile limit of the coastline or on inland waters?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInFellWalkingRunning",
                                  line: "Fell walking / running",
                                  question:
                                    "Did you take part in Fell walking or running?",
                                },
                                {
                                  name: "TookPartInFencing",
                                  line: "Fencing",
                                  question: "Did you take part in Fencing?",
                                },
                                {
                                  name: "TookPartInFishing",
                                  line: "Fishing",
                                  question: "Did you take part in Fishing?",
                                },
                                {
                                  name: "TookPartInFootball",
                                  line: "Football",
                                  question: "Did you take part in Football?",
                                },
                                {
                                  name: "TookPartInGeocaching",
                                  line: "Geocaching – Maximum 3,000 metres altitude",
                                  question:
                                    "Did you take part in Geocaching within a maximum altitude of 3,000 metres?",
                                },
                                {
                                  name: "TookPartInGlidingMeetsConditions",
                                  line: "Gliding - Passenger only; Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Gliding under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInGliding",
                                      line: "Gliding",
                                      question: "Did you take part in Gliding?",
                                    },
                                    {
                                      name: "GlidingOrganisedThroughLicensedOperator",
                                      line: "Organised through a licensed operator/tour operator only.",
                                      question:
                                        "Did you take part in Gliding organised through a licensed operator/tour operator?",
                                    },
                                    {
                                      name: "GlidingPassengerOnly",
                                      line: "Passenger only",
                                      question:
                                        "Were you Gliding as a passenger only?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInGoKarting",
                                  line: "Go karting",
                                  question: "Did you take part in Go karting?",
                                },
                                {
                                  name: "TookPartInGolf",
                                  line: "Golf",
                                  question: "Did you take part in Golf?",
                                },
                                {
                                  name: "TookPartInHandball",
                                  line: "Handball",
                                  question: "Did you take part in Handball?",
                                },
                                {
                                  name: "TookPartInHikingUpTo3000m",
                                  line: "Hiking, hill walking, rambling and trekking up to 3,000 metres",
                                  question:
                                    "Did you take part in hiking, hill walking, rambling, or trekking up to 3,000 metres?",
                                },
                                {
                                  name: "TookPartInHorseRidingHacking",
                                  line: "Horse riding / hacking – No hunting, jumping or polo",
                                  question:
                                    "Did you take part in Horse riding or hacking, with no hunting, jumping, or polo?",
                                },
                                {
                                  name: "TookPartInHotAirBallooning",
                                  line: "Hot air ballooning – passenger only; must be operated through a licensed operator",
                                  question:
                                    "Did you take part in hot air ballooning as a passenger only, operated through a licensed operator?",
                                },
                                {
                                  name: "TookPartInIceSkating",
                                  line: "Ice skating – no hockey or speed skating",
                                  question:
                                    "Did you take part in ice skating, excluding hockey or speed skating?",
                                },
                                {
                                  name: "TookPartInJetSkiingMeetsConditions",
                                  line: "Jet skiing – passenger only, operated with a licensed operator",
                                  question:
                                    "Did you take part in Jet skiing under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInJetSkiing",
                                      line: "Jet skiing",
                                      question:
                                        "Did you take part in Jet skiing?",
                                    },
                                    {
                                      name: "JetSkiingLicensedOperator",
                                      line: "Must be operated with a licensed operator",
                                      question:
                                        "Was your Jet skiing activity carried out through a licensed operator?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInJetBoating",
                                  line: "Jet boating – passenger only",
                                  question:
                                    "Did you take part in jet boating strictly as a passenger only?",
                                },
                                {
                                  name: "TookPartInJoggingRunningNoMarathons",
                                  line: "Jogging / running – no marathons or competitive running",
                                  question:
                                    "Did you take part in jogging or running (excluding marathons or competitive running)?",
                                },
                                {
                                  name: "TookPartInKayaking",
                                  line: "Kayaking – Maximum Grade 3 (or the equivalent international grade used by the country you are in)",
                                  question:
                                    "Did you take part in kayaking up to a maximum of Grade 3 (or the equivalent international grade used by the country you are in)?",
                                },
                                {
                                  name: "TookPartInMicrolightingMeetsConditions",
                                  line: "Microlighting – passenger only; Organised through a licensed operator only.",
                                  question:
                                    "Did you take part in microlighting under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInMicrolighting",
                                      line: "Microlighting",
                                      question:
                                        "Did you take part in Microlighting?",
                                    },
                                    {
                                      name: "MicrolightingPassengerOnly",
                                      line: "Passenger only",
                                      question:
                                        "Were you a passenger only during the microlighting activity?",
                                    },
                                    {
                                      name: "MicrolightingLicensedOperator",
                                      line: "Under licensed operator/tour operator supervision.",
                                      question:
                                        "Was your microlighting organised through a licensed operator?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInMotorcyclingUpTo125cc",
                                  line: "Motorcycling up to 125cc",
                                  question:
                                    "Did you take part in riding or being a passenger on a motorcycle up to 125cc?",
                                },
                                {
                                  name: "TookPartInMountainBikingDownhill12Only",
                                  line: "Mountain biking – Downhill grades 1 and 2 only (or the equivalent international grade used by the country you are in). No freeriding, four-cross, dirt jumping or trials",
                                  question:
                                    "Did you take part in mountain biking limited to downhill grades 1 and 2, with no freeriding, four-cross, or dirt jumping?",
                                },
                                {
                                  name: "TookPartInNetball",
                                  line: "Netball",
                                  question: "Did you take part in Netball?",
                                },
                                {
                                  name: "TookPartInPaintball",
                                  line: "Paintball",
                                  question: "Did you take part in Paintball?",
                                },
                                {
                                  name: "TookPartInParascending",
                                  line: "Parascending - Over water only; Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Parascending organised through a licensed operator and over water only?",
                                },
                                {
                                  name: "TookPartInPonyTrekking",
                                  line: "Pony trekking",
                                  question:
                                    "Did you take part in Pony trekking?",
                                },
                                {
                                  name: "TookPartInRacquetball",
                                  line: "Racquetball",
                                  question: "Did you take part in Racquetball?",
                                },
                                {
                                  name: "TookPartInRaftingWhiteAndBlackWater",
                                  line: "Rafting – White & Black water",
                                  question:
                                    "Did you take part in Rafting – White & Black water organised through a licensed operator and upto a maximum Grade 3 (or the equivalent international grade used by the country you are in)?",
                                },
                                {
                                  name: "TookPartInRollerBladingOrSkating",
                                  line: "Roller blading or skating",
                                  question:
                                    "Did you take part in roller blading or roller skating?",
                                },
                                {
                                  name: "TookPartInRounders",
                                  line: "Rounders",
                                  question: "Did you take part in Rounders?",
                                },
                                {
                                  name: "TookPartInRowing",
                                  line: "Rowing – inland waters only",
                                  question:
                                    "Did you take part in Rowing on inland waters only?",
                                },
                                {
                                  name: "TookPartInSafari",
                                  line: "Safari – Must be supervised walking and vehicle only",
                                  question:
                                    "Did you take part in a Safari conducted only with supervised walking and vehicle?",
                                },
                                {
                                  name: "TookPartInSailBoarding",
                                  line: "Sail boarding",
                                  question:
                                    "Did you take part in Sail boarding?",
                                },
                                {
                                  name: "TookPartInSandBoardingOrSkiing",
                                  line: "Sand boarding / skiing",
                                  question:
                                    "Did you take part in Sand boarding or Sand skiing?",
                                },
                                {
                                  name: "TookPartInScubaDivingMeetingConditions",
                                  line: "Scuba diving to a depth of 15 metres",
                                  question:
                                    "Did you take part in recreational Scuba diving to a depth of 15 metres under all listed conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInScubaDiving15m",
                                      line: "Scuba diving to a depth of 15 metres",
                                      question:
                                        "Did you take part in Scuba diving to a depth of 15 metres?",
                                    },
                                    {
                                      name: "ScubaDivingLicensedOperator",
                                      line: "Organised through a licensed operator only",
                                      question:
                                        "Was your scuba diving organised through a licensed operator?",
                                    },
                                    {
                                      name: "ScubaDivingMaxFiveDives",
                                      line: "Maximum 5 dives per trip",
                                      question:
                                        "Did you dive no more than 5 times in total on this trip?",
                                    },
                                    {
                                      name: "ScubaDivingInstructorOrDivemasterRequired",
                                      line: "Must be accompanied by a qualified scuba diving instructor or dive master at all times",
                                      question:
                                        "Were you always accompanied by a qualified scuba diving instructor or dive master?",
                                    },
                                    {
                                      name: "ScubaDivingNoProOrTechnical",
                                      line: "No professional, commercial or technical diving, including, but not limited to enriched air diving, tutor diving, ice diving, free diving, wreck, cave or cavern diving or solo diving.",
                                      question:
                                        "Did you avoid all professional, commercial, or technical types of diving, including, but not limited to enriched air diving, tutor diving, ice diving, free diving, wreck, cave or cavern diving or solo diving?",
                                    },
                                    {
                                      name: "ScubaDivingNoAirTravelWithin24Hours",
                                      line: "No air travel within 24 hours of completing a dive",
                                      question:
                                        "Did you refrain from flying within 24 hours after your final dive?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInSegway",
                                  line: "Segway",
                                  question:
                                    "Did you take part in using a Segway?",
                                },
                                {
                                  name: "TookPartInSeaKayaking",
                                  line: "Sea kayaking – must be accompanied by a qualified guide",
                                  question:
                                    "Did you take part in Sea kayaking while accompanied by a qualified guide?",
                                },
                                {
                                  name: "TookPartInSharkCageDiving",
                                  line: "Shark cage diving - Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Shark cage diving organised through a licensed operator?",
                                },
                                {
                                  name: "TookPartInSightseeingFlightsHeliRides",
                                  line: "Sightseeing flights/helicopter rides - Passenger only; Organised through a licensed operator only",
                                  question:
                                    "Did you take part in a sightseeing flight or helicopter ride as a passenger only and organised through a licensed operator?",
                                },
                                {
                                  name: "TookPartInSkateboarding",
                                  line: "Skateboarding",
                                  question:
                                    "Did you take part in Skateboarding?",
                                },
                                {
                                  name: "TookPartInSnorkelling",
                                  line: "Snorkelling",
                                  question: "Did you take part in Snorkelling?",
                                },
                                {
                                  name: "TookPartInSoftball",
                                  line: "Softball",
                                  question: "Did you take part in Softball?",
                                },
                                {
                                  name: "TookPartInSquash",
                                  line: "Squash",
                                  question: "Did you take part in Squash?",
                                },
                                {
                                  name: "TookPartInSurfingFlowriding",
                                  line: "Surfing and flowriding",
                                  question:
                                    "Did you take part in Surfing or flowriding?",
                                },
                                {
                                  name: "TookPartInSwimming",
                                  line: "Swimming",
                                  question: "Did you take part in Swimming?",
                                },
                                {
                                  name: "TookPartInSwimmingWithDolphins",
                                  line: "Swimming with Dolphins - Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Swimming with Dolphins organised through a licensed operator?",
                                },
                                {
                                  name: "TookPartInTableTennis",
                                  line: "Table Tennis",
                                  question:
                                    "Did you take part in Table Tennis?",
                                },
                                {
                                  name: "TookPartInTandemSkyDivingMeetsConditions",
                                  line: "Tandem sky diving - Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Tandem sky diving under the required conditions?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInTandemSkyDiving",
                                      line: "Tandem sky diving",
                                      question:
                                        "Did you take part in Tandem sky diving?",
                                    },
                                    {
                                      name: "TandemSkydiveLicensedOperator",
                                      line: "Organised through a licensed operator only",
                                      question:
                                        "Was your Tandem sky diving arranged through a licensed operator?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInTenPinBowling",
                                  line: "Ten pin bowling",
                                  question:
                                    "Did you take part in Ten pin bowling?",
                                },
                                {
                                  name: "TookPartInTennis",
                                  line: "Tennis",
                                  question: "Did you take part in Tennis?",
                                },
                                {
                                  name: "TookPartInTrampolining",
                                  line: "Trampolining",
                                  question:
                                    "Did you take part in Trampolining?",
                                },
                                {
                                  name: "TookPartInTugOfWar",
                                  line: "Tug of war",
                                  question: "Did you take part in Tug of war?",
                                },
                                {
                                  name: "TookPartInVolleyball",
                                  line: "Volleyball",
                                  question: "Did you take part in Volleyball?",
                                },
                                {
                                  name: "TookPartInWakeBoarding",
                                  line: "Wake boarding",
                                  question:
                                    "Did you take part in Wake boarding?",
                                },
                                {
                                  name: "TookPartInWaterPolo",
                                  line: "Water polo",
                                  question: "Did you take part in Water polo?",
                                },
                                {
                                  name: "TookPartInWaterSkiing",
                                  line: "Water skiing",
                                  question:
                                    "Did you take part in Water skiing?",
                                },
                                {
                                  name: "TookPartInWindSurfing",
                                  line: "Wind surfing",
                                  question:
                                    "Did you take part in Wind surfing?",
                                },
                                {
                                  name: "TookPartInYachtingMeetsConditions",
                                  line: "Yachting – must be within a 12 mile limit and no racing",
                                  question:
                                    "Did you take part in Yachting within a 12 mile limit and with no racing?",
                                  logical_connective: "AND",
                                  sub_conditions: [
                                    {
                                      name: "TookPartInYachting",
                                      line: "Yachting",
                                      question:
                                        "Did you take part in Yachting?",
                                    },
                                    {
                                      name: "YachtingWithin12MileLimitNoRacing",
                                      line: "Yachting",
                                      question:
                                        "Were you yachting within a 12 mile limit and with no racing?",
                                    },
                                  ],
                                },
                                {
                                  name: "TookPartInYoga",
                                  line: "Yoga",
                                  question: "Did you take part in Yoga?",
                                },
                                {
                                  name: "TookPartInZipLining",
                                  line: "Zip lining – Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Zip lining organised through a licensed operator?",
                                },
                                {
                                  name: "TookPartInZorbingSphering",
                                  line: "Zorbing / Sphering - Organised through a licensed operator only",
                                  question:
                                    "Did you take part in Zorbing or Sphering organised through a licensed operator?",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      name: "NonConformingLeisureActivity",
                      line: "There is no cover at all for any injury or death if, during your trip, you take part in any leisure activity and/or activity based holiday that meets any of the following conditions:",
                      question:
                        "Did the incident arise from taking part in a leisure activity or activity based holiday that is not covered because it meets any of the disqualifying criteria?",
                      sub_conditions: [
                        {
                          name: "ActivityInProfessionalOrFinancialReward",
                          line: "b. either as a professional or where you receive any financial reward or gain;",
                          question:
                            "Did you take part in a leisure activity or activity based holiday on a professional basis or where you received any financial reward or gain?",
                        },
                        {
                          name: "ActivityForPractisingOrCompetition",
                          line: "c. for the purpose of practising for or taking part in:",
                          question:
                            "Is the activity undertaken for practising or participating in competitive events?",
                          sub_conditions: [
                            {
                              name: "ActivityForSpeedOrRace",
                              line: "i. any speed or time trial or race of any kind;",
                              question:
                                "Did you take part in a leisure activity or activity based holiday for any speed or time trial or race of any kind?",
                            },
                            {
                              name: "ActivityForOrganisedCompetition",
                              line: "ii. any organised team competition or tournament;",
                              question:
                                "Did you take part in a leisure activity or activity based holiday for any organised team competition or tournament?",
                            },
                          ],
                          logical_connective: "OR",
                        },
                        {
                          name: "ActivityProhibitedByMedicalCondition",
                          line: "d. if you suffer from a medical condition which would normally prohibit you from participating in that activity;",
                          question:
                            "Do you have a medical condition that would normally prohibit participation in the leisure activity or activity based holiday?",
                        },
                        {
                          name: "FailedToFollowSafetyGuidelines",
                          line: "e. Where you have failed to follow all safety guidelines and use the necessary safety equipment.",
                          question:
                            "Did you fail to follow the required safety guidelines or use the necessary safety equipment for the leisure activity or activity based holiday?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "TakingPartInWinterSportsWithoutCover",
              line: "j. you taking part in any winter sports activity unless Winter Sports cover is shown on your policy schedule;",
              question:
                "Did the incident arise from taking part in a winter sports activity when Winter Sports cover was not purchased or shown on the policy schedule?",
            },
            {
              name: "ExplorationOrScientificExpedition",
              line: "k. you taking part in exploration or scientific expeditions or being a crew member on a vessel travelling from one country to another;",
              question:
                "Did the incident arise from participation in any exploration/scientific expedition or from you acting as crew on a vessel traveling between countries?",
            },
            {
              name: "BalconyRailingOrWallRisk",
              line: "l. you sitting on any balcony railing; jumping from or climbing on or over any balcony railing, ledge or wall, regardless of its height, other than Artificial Wall Climbing listed in the Leisure Activities section.",
              question:
                "Did the incident arise from sitting on, jumping from, or climbing on or over any balcony railing, ledge, or wall (except for listed artificial wall climbing)?",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "GeneralExclusionTropicalDisease",
          line: "10. Any claim resulting from a tropical disease where you have not had the recommended inoculations and/or taken the recommended medication.",
          question:
            "Is the claim resulting from a tropical disease where you have not had the recommended inoculations and/or taken the recommended medication?",
        },
        {
          name: "GeneralExclusionExcludedCosts",
          line: "11. Any claim for unused travel/accommodation that is recoverable from provider, or for promotional tokens, or timeshare fees...",
          question:
            "Is the claim for any excluded costs covered by loyalty/points/timeshare or recoverable from other sources?",
          sub_conditions: [
            {
              name: "UnusedTravelAccommodationPointsTimesharePromos",
              line: "a) unused travel or accommodation arranged by using air miles, loyalty or points based ownership schemes, timeshares or similar promotions;",
              question:
                "Is the claim for unused travel or accommodation booked via air miles, loyalty/points-based ownership, timeshares, or similar promotions?",
            },
            {
              name: "ManagementMaintenanceOrExchangeFees",
              line: "b) management fees, maintenance costs or exchange fees associated with loyalty or points based ownership schemes, timeshares or similar promotions;",
              question:
                "Is the claim for management, maintenance, or exchange fees from a loyalty/points-based or timeshare arrangement?",
            },
            {
              name: "RecoverableFromProvider",
              line: "c) costs where these are recoverable from your travel and/or accommodation provider;",
              question:
                "Are the costs sought by this claim actually recoverable from the claimant’s travel or accommodation provider?",
            },
            {
              name: "RefundOfCostsPaidOnBehalfOfOthersNotNamed",
              line: "d) the refund of any costs you have paid for on behalf of persons not named on your policy schedule;",
              question:
                "Is the claim for refund of any costs paid on behalf of people not named on the policy schedule?",
            },
            {
              name: "ProviderAdminFeesForRefund",
              line: "e) administration costs charged by your travel and/or accommodation provider in respect of obtaining a refund for unused travel and accommodation.",
              question:
                "Is the claim for administration fees charged by the provider for obtaining a refund of unused travel or accommodation?",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "GeneralExclusionCourseFees",
          line: "12. Any claim for refund of any course or tuition fees, project costs, sponsorship fees or similar (other than your pre- paid ski pack if Winter Sports cover is shown on your policy schedule).",
          question:
            "Is the claim for refund of any course or tuition fees, project costs, sponsorship fees or similar?",
        },
        {
          name: "GeneralExclusionDisinclination",
          line: "13. Any claim because the claimant does not feel like travelling or isn't enjoying the trip.",
          question:
            "Is the claim simply due to disinclination to travel or lack of enjoyment?",
        },
        {
          name: "GeneralConditionFraudCheck",
          line: "15. If your claim is in any way dishonest or exaggerated we will not pay any benefit under this policy or return any premium to you and we may cancel your policy immediately and backdate the cancellation to the date of the fraudulent claim. We may also take legal action against you.",
          question: "Is your claim in any way dishonest or exaggerated?",
        },
      ],
      logical_connective: "OR",
    },
    {
      name: "AllGeneralConditionsMet",
      line: "### General Conditions",
      question: "Are all of the General Conditions satisfied for this claim?",
      sub_conditions: [
        {
          name: "GeneralConditionAccurateInformation",
          line: "1. You must take reasonable care to provide complete and accurate answers to the questions we ask when you take out, make changes to, and renew your policy.",
          question:
            "Has the claimant taken reasonable care to provide complete and accurate information?",
        },
        {
          name: "GeneralConditionReasonablePrecautions",
          line: "2. You must take reasonable precautions to protect yourself and your property against any accident, injury, theft, loss or damage.",
          question:
            "Has the claimant taken all reasonable precautions to prevent loss, damage, or injury?",
        },
        {
          name: "GeneralConditionRepayUnsupportedAmounts",
          line: "3. If we make a payment before cover is confirmed and our claims investigation reveals that no cover exists under the terms of the policy, you must pay us back any amount we have paid, which you are not covered for.",
          question:
            "Did the claimant accept or repay amounts advanced if later found not covered?",
        },
        {
          name: "GeneralConditionNotifyIncidents",
          line: "4. You must tell us as soon as possible after any injury, illness, incident or redundancy, or if you discover any loss or damage which may lead to a claim under this policy. You must also tell us if you are aware of any writ, summons or prosecution. You must send us every communication relating to a claim immediately.",
          question:
            "Has the claimant promptly notified Aviva and provided all relevant communications?",
        },
        {
          name: "GeneralConditionNoUnauthorizedNegotiation",
          line: "5. You, or any person acting for you, must not negotiate, admit or reject any claim without our permission in writing.",
          question:
            "Has the claimant or any person acting on their behalf refrained negotiation, admission, or rejection of claims without the insurer's written consent?",
        },
        {
          name: "GeneralConditionReceiptsNeeded",
          line: "6. We may refuse to pay expenses for which no receipts or bills can be produced.",
          question:
            "Is the claimant able to provide necessary proof of expenses (receipts/bills)?",
        },
        {
          name: "GeneralConditionEvidenceProvided",
          line: "7. You or your legal representative must pay for any relevant certificates, information and evidence, which we may need to deal with your claim; for example death or medical certificates, police reports or purchase receipts. When there is a claim for injury or illness, we may ask for, and will pay for, any insured person to be medically examined on our behalf. We may also ask for, and will pay for, a post-mortem examination if any insured person dies.",
          question:
            "Have you or your legal representative paid for all necessary certificates, information, and evidence to support the claim?",
        },
        {
          name: "GeneralConditionNoTransferOfRights",
          line: "10. You cannot transfer your rights under this policy.",
          question:
            "Is the claimant the policyholder or an insured person with the right to claim (no unauthorized transfer)?",
        },
        {
          name: "GeneralConditionAccessMedicalRecords",
          line: "11. If you make a medical claim you will be asked to supply your doctor’s name to enable us to access your medical records. This will help the treating doctors, and us, to provide you with the most appropriate treatment and assess whether cover applies. If you do not agree to provide this we may not deal with your claim.",
          question:
            "Has the claimant consented to share relevant medical records if claiming medically?",
        },
      ],
      logical_connective: "AND",
    },
    {
      name: "ClaimMeetsOneSubsectionTerms",
      line: "",
      question: "Is your claim covered under any section of the policy?",
      sub_conditions: [
        {
          name: "ClaimCoveredUnderBeforeYouLeaveHome",
          line: "## Before You Leave Home",
          question:
            "Is your claim covered under any subsection of the Before You Leave Home section (e.g. Cancellation)?",
          sub_conditions: [
            {
              name: "IsClaimValidCancellation",
              line: "### Cancellation",
              question:
                "Does the claim meet the conditions for a valid Cancellation claim?",
              sub_conditions: [
                {
                  name: "HadToCancelTripAfterPolicyOrBooking",
                  line: "If after you took out or renewed your policy or booked your trip (whichever is later), you must cancel for any of the following reasons.",
                  question:
                    "Did you have to cancel your trip after you took out or renewed your policy or booked your trip (whichever is later) for a covered reason?",
                },
                {
                  name: "ClaimingNonRecoverableTravelCosts",
                  line: "We will pay for costs that each insured person has paid, and cannot get back, or which legally have to be paid for their own travel and accommodation (including excursions and unused kennel, cattery or professional pet sitter fees).",
                  question:
                    "Are you claiming for non-recoverable travel or accommodation costs (including excursions and pet care fees)?",
                },
                {
                  name: "CancellationForValidReasons",
                  line: "If you need to cancel your trip, we will pay for costs that each insured person has paid and cannot get back, or which legally have to be paid for their own travel and accommodation, if (after you took out or renewed your policy or booked your trip, whichever is later) you have to cancel for any of the reasons below.",
                  question:
                    "Which of the following covered reasons for cancellation (also applicable to abandonment for certain reasons) applies to you or your travelling companion?",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "YouOrCompanionInjuredIllQuarantinedDieSupportedByEvidence",
                      line: "",
                      question:
                        "Have you or your travelling companion suffered an injury, illness, quarantine or death, and provided supporting medical/death certificate evidence?",
                      logical_connective: "AND",
                      sub_conditions: [
                        {
                          name: "YouOrCompanionInjuredIllQuarantinedDie",
                          line: "1. You are injured, fall ill, are quarantined or die. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                          question:
                            "Are you or your travelling companion injured, ill, quarantined, or deceased, forcing you to cancel or abandon your trip?",
                        },
                        {
                          name: "MedicalReportsOrDeathCertificate",
                          line: "All claims resulting from injury, illness, quarantine or death must be supported by medical reports or a death certificate (or both) indicating the necessity to cancel or abandon your trip.",
                          question:
                            "Have you obtained medical reports or a death certificate indicating that cancellation or abandonment was necessary?",
                        },
                      ],
                    },
                    {
                      name: "CloseRelativeColleagueOrHostSeriousIllnessOrDeath",
                      line: "2. One of the following people is seriously injured, falls seriously ill or dies: (a) a close relative; or (b) a close business colleague; or (c) the person you were going to stay with. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Did a close relative, close business colleague, or the person you or your travelling companion were going to stay with become seriously ill, injured, or die, forcing you to cancel or abandon your trip?",
                    },
                    {
                      name: "CalledForJuryServiceOrWitness",
                      line: "3. You are called for jury service or as a witness in a court of law during the period of insurance. You will also be covered if a cancellation is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Are you or your travelling companion required for jury service or as a witness in court during the period of insurance, forcing you to cancel your trip?",
                    },
                    {
                      name: "MadeRedundant",
                      line: "4. You are made redundant. You will also be covered if a cancellation is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Have you or your travelling companion been made redundant, forcing you to cancel your trip?",
                    },
                    {
                      name: "YourOrCompanionHomeUninhabitable",
                      line: "5. Your home is made uninhabitable by fire, storm or severe/adverse weather. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Has your or your travelling companion’s home been made uninhabitable by fire, storm, or severe/adverse weather, forcing you to cancel or abandon your trip?",
                    },
                    {
                      name: "PoliceNeedToSpeakYouOrCompanionDueToBurglary",
                      line: "6. The police need to speak to you because your home or place of work has been burgled. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Do the police need to speak to you or your travelling companion because your home or place of work was burgled, forcing you to cancel or abandon your trip?",
                    },
                  ],
                },
                {
                  name: "DocumentaryEvidenceNoRefund",
                  line: "All claims must be supported by documentary evidence that you have been unable to obtain a refund from the travel and/or accommodation provider.",
                  question:
                    "Do you have documentation showing that no refund was available from the travel or accommodation provider?",
                },
                {
                  name: "CancellationExclusions",
                  line: "What is not covered for Cancellation",
                  question:
                    "Do any of the following exclusions apply to your cancellation claim?",
                  logical_connective: "OR",
                  logical_operator: "NOT",
                  sub_conditions: [
                    {
                      name: "UndisclosedPreexistingCondition",
                      line: "3. Any claim for a medical condition if any of the following applied when you took out or renewed your policy or when you booked your trip (whichever is later). You:",
                      question:
                        "Is the cancellation or abandonment due to a pre-existing medical condition (any of the following) that was not declared and accepted by the insurer, in circumstances applying when you took out or renewed your policy or when you booked your trip (whichever is later)?",
                      sub_conditions: [
                        {
                          name: "HadSeriousChronicOrRecurringIllnessLast12MoNotDisclosed",
                          line: "a) had received advice, medication or treatment for any serious, chronic or recurring illness, injury or disease in the last 12 months unless the condition was disclosed to and accepted by us;",
                          question:
                            "Did you receive advice, medication or treatment for a serious, chronic or recurring illness, injury or disease in the last 12 months, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "WereUnderInvestigationOrAwaitingResultsNotDisclosed",
                          line: "b) were under investigation or awaiting results for any diagnosed or undiagnosed condition unless disclosed to and accepted by us;",
                          question:
                            "Were you under investigation or awaiting results for any diagnosed or undiagnosed condition, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "WereAwaitingInpatientTreatmentOrAwareOfNeedNotDisclosed",
                          line: "c) were on a waiting list for in-patient treatment or were aware of the need for in-patient treatment for any diagnosed or undiagnosed condition unless disclosed to and accepted by us;",
                          question:
                            "Were you on a waiting list or aware of the need for in-patient treatment for any diagnosed or undiagnosed condition, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "HadTerminalIllnessNotDisclosed",
                          line: "d) had been told you have a terminal illness.",
                          question:
                            "Had you been told you have a terminal illness, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                    {
                      name: "MedicalConditionTripDependsOnThirdPartySeriousIllness",
                      line: "4. Any claim for a medical condition if any person upon whose good health your trip depends had a serious, chronic, or recurring illness, injury, or disease which you were aware of at the date you took out or renewed your policy or when you booked your trip (whichever is later) unless the condition was disclosed to and accepted by us.",
                      question:
                        "Is the claim due to a serious, chronic, or recurring illness, injury, or disease of someone upon whose good health your trip depends—one that you were already aware of at the time you took out or renewed your policy or booked your trip—without having disclosed it to and had it accepted by us?",
                    },
                    {
                      name: "ReferredConsultantBetweenBookingAndDeparture",
                      line: "5. Any claim for a medical condition where you or anyone upon whose good health your trip depends have been referred to a Consultant or Specialist, attended A&E, or been admitted to a hospital between booking your trip and the departure date, unless disclosed to and accepted by us.",
                      question:
                        "Was the claimant or a person upon whose good health the trip depends referred to a Consultant or Specialist, attended A&E, or admitted to hospital between booking the trip and departure, and was this not disclosed to and accepted by us?",
                    },
                    {
                      name: "PlannedMedicalTreatmentDuringTrip",
                      line: "6. Any claim for a medical condition for which you were planning to get medical treatment during your trip.",
                      question:
                        "Is the claim for a medical condition that you were already planning to receive treatment for during your trip?",
                    },
                    {
                      name: "ClaimForDismissalMisconductResignationVoluntaryRedundancy",
                      line: "6. Any claim for dismissal, misconduct, resignation or voluntary redundancy.",
                      question:
                        "Is the claim arising from your or your travelling companion’s dismissal, misconduct, resignation, or voluntary redundancy?",
                    },
                    {
                      name: "ClaimForRedundancyKnownOrNoEvidence",
                      line: "7. Any claim for redundancy if you or your travelling companion knew of the redundancy when you took out or renewed your policy or when you booked your trip (whichever is later) or where you cannot provide written evidence that the reason you or your travelling companion left the job was due to redundancy.",
                      question:
                        "Is this claim for redundancy where you or your travelling companion already knew of the redundancy at policy purchase or trip booking, or you cannot provide written proof that the job ended specifically due to redundancy?",
                    },
                    {
                      name: "KnewUnableToTravel",
                      line: "8. Any claim where you knew, at the time you took out your policy or when you booked your trip (whichever is later), that you or your travelling companion would be unable to travel.",
                      question:
                        "At the time of taking out your policy or booking your trip, did you or your travelling companion already know that you would be unable to travel?",
                    },
                    {
                      name: "NoCoverForPrepaidLeisureActivityCancelledByOrganizer",
                      line: "There is no cover under the Cancellation Charges or Abandonment sections for: cancellation of a pre-paid leisure activity or any activity based holiday by the organiser of that activity.",
                      question:
                        "Is the claim because a pre-paid leisure activity or activity based holiday was cancelled by its organiser?",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "ClaimCoveredUnderWhileTravelling",
          line: "## While You Are Travelling",
          question:
            "Is your claim covered under any subsection of the While You Are Travelling section (Missed Departure, Delayed Departure, Missed Connection)?",
          sub_conditions: [
            {
              name: "IsClaimValidMissedInternationalDeparture",
              line: "### Missed International Departure",
              question:
                "Does the claim meet the conditions for a valid Missed International Departure claim?",
              sub_conditions: [
                {
                  name: "ArrivedTooLateForDeparture",
                  line: "If you arrive too late at your point of international departure to check in and/or board your transport as a direct result of:",
                  question:
                    "Did you arrive too late at your point of international departure (the airport, port or station from which you will undertook international travel from or into the UK) to check in and/or board?",
                },
                {
                  name: "ArriveTooLateForDepartureCoveredCause",
                  line: "If you arrive too late at your point of international departure to check in and/or board your transport as a direct result of:",
                  question:
                    "Did you arrive too late at your point of international departure to check in and/or board for a covered reason?",
                  sub_conditions: [
                    {
                      name: "DueToTransportDelayOrCancellationAndRequestedEvidence",
                      line: "1. Delay or cancellation to scheduled public transport services or a connecting scheduled flight;",
                      question:
                        "Did you miss your international departure because of delay or cancellation of scheduled public transport or a connecting flight, and do you have proof?",
                      sub_conditions: [
                        {
                          name: "MissedDepartureDueToTransportDelayOrCancellation",
                          line: "1. Delay or cancellation to scheduled public transport services or a connecting scheduled flight;",
                          question:
                            "Did you miss your international departure because of a delay or cancellation of scheduled public transport or a connecting scheduled flight?",
                        },
                        {
                          name: "RequestEvidenceForTransportDelayOrCancellation",
                          line: "You must request evidence from the transport provider if your scheduled public transport is delayed or cancelled.",
                          question:
                            "Did you request written evidence from the transport provider confirming the delay or cancellation?",
                        },
                      ],
                      logical_connective: "AND",
                    },
                    {
                      name: "DueToVehicleAccidentOrBreakdownAndRequestedReport",
                      line: "2. Accidental damage to, or breakdown of the vehicle in which you are travelling.",
                      question:
                        "Did you miss your departure because of accidental damage or breakdown of the vehicle, and do you have the required report?",
                      sub_conditions: [
                        {
                          name: "MissedDepartureDueToVehicleAccidentOrBreakdown",
                          line: "2. Accidental damage to, or breakdown of the vehicle in which you are travelling.",
                          question:
                            "Did you miss your international departure because of accidental damage to, or breakdown of, the vehicle you were travelling in?",
                        },
                        {
                          name: "RequestReportIfVehicleAccidentOrBreakdown",
                          line: "You must request a report from the repairer or breakdown assistance provider if you are claiming because the vehicle you were travelling in had an accident or broke down.",
                          question:
                            "Have you obtained a repairer or breakdown report if you are claiming due to an accident or breakdown of your vehicle?",
                        },
                      ],
                      logical_connective: "AND",
                    },
                  ],
                  logical_connective: "OR",
                },
                {
                  name: "CoverageMissedInternationalDeparture",
                  line: "We will pay up to the limits shown on your policy schedule for extra travel and accommodation costs you have to pay to reach your: a) destination on your outward journey from the UK; or b) home on your return journey.",
                  question:
                    "Are you claiming the extra travel or accommodation costs (up to the policy limit) to reach your onward destination or return home?",
                },
                {
                  name: "ContactCarrierLateArrivalOrAlternative",
                  line: "1. If you miss your transport due to one of the reasons listed above you must contact the carrier or their handling agent to see if a late arrival is possible or if alternative travel arrangements can be made.",
                  question:
                    "Did you contact the carrier or their handling agent to check for a late arrival possibility or alternative arrangement?",
                },
                {
                  name: "DocumentaryEvidenceOfCosts",
                  line: "4. All claims must be supported by documentary evidence of the costs you have incurred.",
                  question:
                    "Do you have documentary proof (receipts, bills) of the costs you are claiming?",
                },
                {
                  name: "MissedInternationalDepartureExclusions",
                  line: "What is not covered",
                  question:
                    "Do any of the following exclusions apply to this missed international departure claim?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "TripSolelyWithinUKExclusion",
                      line: "2. Any claim if your trip is solely within the UK.",
                      question: "Is your trip solely within the UK?",
                    },
                    {
                      name: "StrikeIndustrialActionPubliclyAnnouncedExclusion",
                      line: "3. Any claim caused by a strike or industrial action for which the dates had been publicly announced or reported by the media at the time you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the missed departure caused by a publicly announced strike or industrial action before policy or trip booking?",
                    },
                    {
                      name: "DelayReasonPublicKnowledgeExclusion",
                      line: "4. Any claim where a possible reason for any delay or cancellation was public knowledge or had been reported by the media when you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the reason for delay/cancellation already public knowledge or reported in the media by policy or trip booking?",
                    },
                    {
                      name: "InsufficientTimeAllowedExclusion",
                      line: "5. Any claim where you have not allowed sufficient time, or done everything you reasonably can, to get to the point of international departure for the time specified on your ticket/itinerary.",
                      question:
                        "Did you fail to allow enough time or do everything possible to reach the departure point on schedule?",
                    },
                    {
                      name: "CarrierOfferedSuitableAlternativeTransportExclusion",
                      line: "6. Any claim where the carrier has offered you suitable alternative transport.",
                      question:
                        "Did the carrier offer suitable alternative transport, making this claim ineligible?",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidDelayedDeparture",
              line: "### Delayed Departure after Check-in",
              question:
                "Does the claim meet the conditions for a valid Delayed Departure claim?",
              sub_conditions: [
                {
                  name: "WasScheduledTransportDelayedAtPointOfInternationalDeparture",
                  line: "If the scheduled departure of the ship, aircraft or train on which you are booked to travel is delayed at the point of international departure",
                  question:
                    "Was your scheduled transport (ship, aircraft or train) delayed at the point of international departure?",
                },
                {
                  name: "DelayedDepartureClaimType",
                  question:
                    "Which type of Delayed Departure claim are you making?",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "ClaimingDelayBenefitFor12HourIncrements",
                      line: "We will pay the benefit shown on your policy schedule for each full 12-hour period that the ship, aircraft or train is delayed.",
                      question:
                        "Are you claiming the per-12-hour delay benefit for the delay period?",
                    },
                    {
                      name: "AbandonTripOver24HourDelay",
                      line: "Up to the limits shown on your policy schedule for unused travel/accommodation costs if the delay lasts more than 24 hours on your outward journey from the UK and you choose to abandon the trip.",
                      question:
                        "Did the delay exceed 24 hours on your outward journey from the UK, and have you chosen to abandon the trip and claim unused travel/accommodation costs?",
                    },
                  ],
                },
                {
                  name: "CheckedInAsSpecified",
                  line: "You must have checked in at the time specified on your ticket/itinerary.",
                  question:
                    "Did you check in at or before the stated check-in time on your ticket/itinerary?",
                },
                {
                  name: "HasWrittenConfirmationReasonAndTiming",
                  line: "You must provide written confirmation from the carrier or their handling agents of the actual date and time of departure and the reason for delay.",
                  question:
                    "Do you have written confirmation from the carrier (or their handling agents) showing the actual departure date/time and the reason for the delay?",
                },
                {
                  name: "DelayedDepartureExclusions",
                  line: "Exclusions for Delayed Departure after Check-in:",
                  question:
                    "Do any of the following exclusions apply (which would invalidate the Delayed Departure claim)?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "InternalOrOnwardConnectionExclusion",
                      line: "Cover does not apply for any internal and/or onward connecting travel.",
                      question:
                        "Is this delay claim only for an internal or onward connecting journey (which is not covered)?",
                    },
                    {
                      name: "TripSolelyWithinUKExclusion",
                      line: "Any claim if your trip is solely within the UK.",
                      question: "Is your trip solely within the UK?",
                    },
                    {
                      name: "DelayNotAtInternationalDepartureExclusion",
                      line: "Any claim if a delay of the ship, aircraft or train does not happen at your point of international departure.",
                      question:
                        "Did the delay occur somewhere other than your point of international departure from or into the UK?",
                    },
                    {
                      name: "StrikeIndustrialActionPubliclyAnnouncedExclusion",
                      line: "Any claim caused by a strike or industrial action for which the dates had been publicly announced or reported by the media at the time you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the delay caused by a strike or industrial action already publicly announced or reported before you took out the policy or booked the trip?",
                    },
                    {
                      name: "PossibleReasonPublicKnowledgeExclusion",
                      line: "Any claim where a possible reason for any delay or cancellation was public knowledge or had been reported by the media when you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the reason for the delay or cancellation public knowledge or already reported by media prior to the policy purchase or trip booking?",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidMissedConnection",
              line: "### Missed Connection outside of the UK",
              question:
                "Does the claim meet the conditions for a valid Missed Connection outside the UK claim?",
              logical_connective: "AND",
              sub_conditions: [
                {
                  name: "HaveMissedConnection",
                  line: "If as a direct result of a scheduled public transport service on which you are booked to travel not running to its published timetable, you miss a pre-booked onward connection outside of the UK",
                  question:
                    "Have you missed a pre-booked onward connection outside of the UK as a direct result of a scheduled public transport service on which you are booked to travel not running to its published timetable?",
                },
                {
                  name: "CostsForMissedConnection",
                  line: "we will pay for extra accommodation and travel costs you have to pay to reach the next destination shown on your ticket/itinerary",
                  question:
                    "Are you seeking extra accommodation or travel costs so you can still reach your next destination as shown on your ticket or itinerary?",
                },
                {
                  name: "ContactTravelAssistantHelpline",
                  line: "1. If you have missed or will miss a travel connection, you must contact the Travel Assistant helpline and we will contact the carrier for you (if a late arrival is possible) or will make alternative travel arrangements for you, up to the limit shown on your policy schedule. Any cost incurred may have to be paid by you and be submitted as a claim.",
                  question:
                    "Did you contact the Travel Assistant helpline to arrange help or alternative travel?",
                },
                {
                  name: "CarrierWrittenConfirmation",
                  line: "2. You must provide written confirmation from the carrier or their handling agents of the reason for the service not running to its published timetable.",
                  question:
                    "Have you obtained written confirmation from the carrier or their handling agents stating why the service did not run according to schedule?",
                },
                {
                  name: "MissedConnectionExclusions",
                  line: "What is not covered",
                  question:
                    "Do any of the following exclusions apply (which would invalidate a Missed Connection outside of the UK claim)?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "AnythingInGeneralExclusions",
                      line: "1. Anything mentioned in the General Exclusions section.",
                      question:
                        "Is the claim excluded by anything mentioned in the General Exclusions section?",
                    },
                    {
                      name: "InsufficientTimeAllowed",
                      line: "2. Any claim where you have not allowed sufficient time to make the travel connections shown on your ticket/ itinerary e.g. transfers between terminals, airports, ports or stations.",
                      question:
                        "Did you fail to allow enough time (including transfers) to make your scheduled travel connections?",
                    },
                    {
                      name: "JourneyNotPrebookedBeforeUKDeparture",
                      line: "3. Any claim for missed connection in relation to a journey that was not pre-booked before you left the UK.",
                      question:
                        "Was the journey on which you missed the connection not actually pre-booked before leaving the UK?",
                    },
                    {
                      name: "NoCoverForAbandonment",
                      line: "4. Any claim for abandonment of your trip following a missed connection.",
                      question:
                        "Are you trying to abandon your entire trip due to this missed connection?",
                    },
                    {
                      name: "DislikeOrNotEnjoyingTrip",
                      line: "5. Any claim made because you did not enjoy your trip.",
                      question:
                        "Is the claim simply because you did not enjoy your trip?",
                    },
                    {
                      name: "StrikeOrIndustrialActionAnnounced",
                      line: "6. Any claim caused by a strike or industrial action for which the dates had been publicly announced or reported by the media at the time you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the missed connection caused by a strike or industrial action that was already public knowledge when you took out the policy or booked the trip?",
                    },
                    {
                      name: "DelayReasonPublicKnowledge",
                      line: "7. Any claim where a possible reason for any delay was public knowledge or had been reported by the media when you took out your policy or when you booked your trip (whichever is later).",
                      question:
                        "Was the reason for the missed connection already public knowledge or reported by the media prior to the policy or trip booking?",
                    },
                    {
                      name: "NotDoneEverythingPossible",
                      line: "8. Any claim where you have not done everything you can reasonably do to get to the departure point for the time specified on your ticket/ itinerary.",
                      question:
                        "Could you have done more to arrive on time at the departure point, yet failed to do so?",
                    },
                  ],
                },
              ],
            },
          ],
          logical_connective: "OR",
        },
        {
          name: "ClaimCoveredUnderAtYourDestination",
          line: "## At Your Destination",
          question:
            "Is your claim covered under any subsection of the At Your Destination section (Abandoning your holiday, Medical Expenses, Personal Accident, etc.)?",
          sub_conditions: [
            {
              name: "IsClaimValidAbandoningHoliday",
              line: "### Abandoning your holiday",
              question:
                "Does the claim meet the conditions for a valid Abandoning your holiday claim?",
              logical_connective: "AND",
              sub_conditions: [
                {
                  name: "AbandonmentHappenedForCoveredReason",
                  line: "If you have to unavoidably abandon your trip and return home early after you have left home, we will pay if any of the following happens. You will also be covered if abandonment is unavoidable because your travelling companion is unable to continue the trip due to one of the reasons listed above.",
                  question:
                    "Did you or your travelling companion unavoidably abandon the trip and return home early for one of the reasons detailed below (including if your travelling companion is unable to continue for those same reasons)?",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "YouOrCompanionInjuredIllQuarantinedDieSupportedByEvidence",
                      line: "",
                      question:
                        "Have you or your travelling companion suffered an injury, illness, quarantine or death, and provided supporting medical/death certificate evidence?",
                      logical_connective: "AND",
                      sub_conditions: [
                        {
                          name: "YouOrCompanionInjuredIllQuarantinedDie",
                          line: "1. You are injured, fall ill, are quarantined or die. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                          question:
                            "Are you or your travelling companion injured, ill, quarantined, or deceased, forcing you to cancel or abandon your trip?",
                        },
                        {
                          name: "MedicalReportsOrDeathCertificate",
                          line: "All claims resulting from injury, illness, quarantine or death must be supported by medical reports or a death certificate (or both) indicating the necessity to cancel or abandon your trip.",
                          question:
                            "Have you obtained medical reports or a death certificate indicating that cancellation or abandonment was necessary?",
                        },
                      ],
                    },
                    {
                      name: "CloseRelativeColleagueOrHostSeriousIllnessOrDeath",
                      line: "2. One of the following people is seriously injured, falls seriously ill or dies: (a) a close relative; or (b) a close business colleague; or (c) the person you were going to stay with. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Did a close relative, close business colleague, or the person you or your travelling companion were going to stay with become seriously ill, injured, or die, forcing you to cancel or abandon your trip?",
                    },
                    {
                      name: "YourOrCompanionHomeUninhabitable",
                      line: "5. Your home is made uninhabitable by fire, storm or severe/adverse weather. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Has your or your travelling companion’s home been made uninhabitable by fire, storm, or severe/adverse weather, forcing you to cancel or abandon your trip?",
                    },
                    {
                      name: "PoliceNeedToSpeakYouOrCompanionDueToBurglary",
                      line: "6. The police need to speak to you because your home or place of work has been burgled. You will also be covered if a cancellation or abandonment is unavoidable because your travelling companion is unable to travel due to any of the reasons listed above.",
                      question:
                        "Do the police need to speak to you or your travelling companion because your home or place of work was burgled, forcing you to cancel or abandon your trip?",
                    },
                  ],
                },
                {
                  name: "NotifyMedicalEmergencyAssistance",
                  line: "1. If you need to return home and intend to make a claim, phone the Medical Emergency Assistance provider as soon as possible.",
                  question:
                    "Did you contact the Medical Emergency Assistance provider as soon as reasonably possible once you knew you needed to return home early?",
                },
                {
                  name: "AbandoningHolidaySpecificExclusions",
                  line: "What is not covered (Abandoning your holiday):",
                  question:
                    "Do any of the Abandoning your holiday policy exclusions apply (which would invalidate the claim)?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "TravelledAgainstDoctorAdvice",
                      line: "2. Any claim for a medical condition if any insured person has travelled against the advice of a doctor or purposely travelled without medical advice when it was reasonable for them to have consulted a doctor.",
                      question:
                        "Did you (the insured person) travel against the advice of a doctor or purposely travel without medical advice when it was reasonable for you to have consulted a doctor?",
                    },
                    {
                      name: "UndisclosedPreexistingCondition",
                      line: "3. Any claim for a medical condition if any of the following applied when you took out or renewed your policy or when you booked your trip (whichever is later). You:",
                      question:
                        "Is the cancellation or abandonment due to a pre-existing medical condition (any of the following) that was not declared and accepted by the insurer, in circumstances applying when you took out or renewed your policy or when you booked your trip (whichever is later)?",
                      sub_conditions: [
                        {
                          name: "HadSeriousChronicOrRecurringIllnessLast12MoNotDisclosed",
                          line: "a) had received advice, medication or treatment for any serious, chronic or recurring illness, injury or disease in the last 12 months unless the condition was disclosed to and accepted by us;",
                          question:
                            "Did you receive advice, medication or treatment for a serious, chronic or recurring illness, injury or disease in the last 12 months, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "WereUnderInvestigationOrAwaitingResultsNotDisclosed",
                          line: "b) were under investigation or awaiting results for any diagnosed or undiagnosed condition unless disclosed to and accepted by us;",
                          question:
                            "Were you under investigation or awaiting results for any diagnosed or undiagnosed condition, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "WereAwaitingInpatientTreatmentOrAwareOfNeedNotDisclosed",
                          line: "c) were on a waiting list for in-patient treatment or were aware of the need for in-patient treatment for any diagnosed or undiagnosed condition unless disclosed to and accepted by us;",
                          question:
                            "Were you on a waiting list or aware of the need for in-patient treatment for any diagnosed or undiagnosed condition, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                        {
                          name: "HadTerminalIllnessNotDisclosed",
                          line: "d) had been told you have a terminal illness.",
                          question:
                            "Had you been told you have a terminal illness, which was not disclosed to and accepted by the insurer at the time you took out or renewed your policy or when you booked your trip (whichever is later)?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                    {
                      name: "MedicalConditionTripDependsOnThirdPartySeriousIllness",
                      line: "4. Any claim for a medical condition if any person upon whose good health your trip depends had a serious, chronic, or recurring illness, injury, or disease which you were aware of at the date you took out or renewed your policy or when you booked your trip (whichever is later) unless the condition was disclosed to and accepted by us.",
                      question:
                        "Is the claim due to a serious, chronic, or recurring illness, injury, or disease of someone upon whose good health your trip depends—one that you were already aware of at the time you took out or renewed your policy or booked your trip—without having disclosed it to and had it accepted by us?",
                    },
                    {
                      name: "ReferredConsultantBetweenBookingAndDeparture",
                      line: "5. Any claim for a medical condition where you or anyone upon whose good health your trip depends have been referred to a Consultant or Specialist, attended A&E, or been admitted to a hospital between booking your trip and the departure date, unless disclosed to and accepted by us.",
                      question:
                        "Was the claimant or a person upon whose good health the trip depends referred to a Consultant or Specialist, attended A&E, or admitted to hospital between booking the trip and departure, and was this not disclosed to and accepted by us?",
                    },
                    {
                      name: "PlannedMedicalTreatmentDuringTrip",
                      line: "6. Any claim for a medical condition for which you were planning to get medical treatment during your trip.",
                      question:
                        "Is the claim for a medical condition that you were already planning to receive treatment for during your trip?",
                    },
                    {
                      name: "NotAuthorizedByMedicalAssistanceBeforeReturn",
                      line: "7. Any claim which was not authorised by our Medical Emergency Assistance provider before you returned home.",
                      question:
                        "Did you fail to obtain authorisation from our Medical Emergency Assistance provider before returning home in relation to this abandonment claim?",
                    },
                    {
                      name: "NoReturnTicketPurchased",
                      line: "8. Any claim for additional travelling costs if, before departing the UK, you have not purchased a return ticket back to the UK.",
                      question:
                        "Is the claim for additional travelling costs and you failed to purchase a confirmed return ticket to the UK prior to traveling?",
                    },
                    {
                      name: "KnewYouWouldNeedToReturnEarly",
                      line: "9. Any claim where you knew, prior to departure, that you might need to return home early.",
                      question:
                        "Were you already aware, before starting your trip, of any reason that would likely require you to come home early?",
                    },
                    {
                      name: "NoCoverForPrepaidLeisureActivityCancelledByOrganizer",
                      line: "There is no cover under the Cancellation Charges or Abandonment sections for: cancellation of a pre-paid leisure activity or any activity based holiday by the organiser of that activity.",
                      question:
                        "Is the claim because a pre-paid leisure activity or activity based holiday was cancelled by its organiser?",
                    },
                  ],
                },
              ],
            },
            {
              name: "IsClaimValidEmergencyMedical",
              line: "### Emergency Medical and Associated Expenses",
              question:
                "Does the claim meet the conditions for a valid Emergency Medical and Associated Expenses claim?",
              sub_conditions: [
                {
                  name: "IncidentForEmergencyMedicalCover",
                  line: "If you are injured, fall ill, are quarantined or die during your trip, we will cover you up to the limits shown on your policy schedule for Emergency Treatment, Associated Expenses, and Holiday Disruption.",
                  question:
                    "Has the claimant suffered an injury, illness, or been quarantined (or died) during the trip?",
                },
                {
                  name: "IncidentWithinEmergencyMedicalCoverDefinition",
                  line: "If you are injured, fall ill, are quarantined or die during your trip, we will cover you up to the limits shown on your policy schedule for:",
                  question:
                    "Has the claimant suffered an injury, illness, or been quarantined (or died) during the trip, qualifying for Emergency Medical coverage?",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "EmergencyTreatmentNeededOutsideHomeTerritory",
                      line: "1. Emergency Treatment: (a) emergency medical treatment (including rescue services) outside of your home territory; (b) dental treatment for emergency pain relief outside of your home territory.",
                      question:
                        "Did the claimant require emergency medical or emergency dental treatment outside their home territory?",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "EmergencyMedicalTreatmentOutsideHome",
                          line: "emergency medical treatment (including rescue services to take you to hospital) outside of your home territory",
                          question:
                            "Did the claimant receive emergency medical treatment outside their home territory?",
                        },
                        {
                          name: "EmergencyDentalPainReliefOutsideHome",
                          line: "dental treatment for emergency pain relief outside of your home territory",
                          question:
                            "Did the claimant receive emergency dental treatment for pain relief outside their home territory?",
                        },
                      ],
                    },
                    {
                      name: "AssociatedExpensesCovered",
                      line: "2. Associated Expenses: (a) extra charges for half board accommodation if medically necessary; (b) cost of burial/cremation locally; (c) cost of returning your body/ashes home; (d) cost of getting you home if medically necessary. If approved, also covers travel and accommodation costs for one relative or friend to stay with or travel to you if necessary.",
                      question:
                        "Does the claim include associated expenses that are covered (e.g. extra accommodation, burial/cremation, returning body, necessary repatriation)?",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "ExtraChargesForMedicalStay",
                          line: "extra charges for half board accommodation (of a similar standard to the accommodation you had booked for your trip) if it is medically necessary for you to stay after the date you were going to return home.",
                          question:
                            "Did the claimant incur extra accommodation charges (half board of similar standard) because it was medically necessary to stay beyond the planned return date?",
                        },
                        {
                          name: "CostOfBuryingOrCrematingAbroad",
                          line: "the cost of burying or cremating you in the country where you die",
                          question:
                            "Is the claim for the cost of burying or cremating the claimant abroad?",
                        },
                        {
                          name: "CostOfReturningBodyOrAshes",
                          line: "the cost of returning your body or ashes to your home",
                          question:
                            "Is the claim for the cost of transporting the claimant’s body or ashes back home?",
                        },
                        {
                          name: "CostOfGettingYouHomeMedicallyNecessary",
                          line: "the cost of getting you home, if it is medically necessary because you are seriously injured or fall seriously ill during your trip and you cannot use your return ticket",
                          question:
                            "Is the claim for additional travel costs to get the claimant home because it is medically necessary and they could not use the original return ticket?",
                        },
                        {
                          name: "RelativeOrFriendStayTravelToPatient",
                          line: "If agreed by our Medical Emergency Assistance provider, we will also pay travel and accommodation costs for one relative or friend who has to stay with you or travel to be with you if medically necessary.",
                          question:
                            "Is the claim for a relative or friend’s travel/accommodation costs to stay with or travel to the claimant under medical necessity?",
                        },
                      ],
                    },
                    {
                      name: "HolidayDisruptionCovered",
                      line: "3. Holiday Disruption: If you are in hospital over 24 hours or confined to your accommodation on medical advice for over 24 hours, we pay a benefit per full 24 hours under the policy limits.",
                      question:
                        "Does the claim qualify for Holiday Disruption coverage by satisfying the requirement for more than 24 consecutive hours of hospitalisation or doctor-advised confinement, and that the insured person is the one receiving the in-patient treatment or confinement?",
                      logical_connective: "AND",
                      sub_conditions: [
                        {
                          name: "HolidayDisruptionHospitalInpatientOrConfinedToAccommodationOver24Hrs",
                          line: "If you are in hospital over 24 hours or confined to your accommodation on medical advice for over 24 hours, we pay a benefit per full 24 hours under the policy limits.",
                          question:
                            "Was the claimant hospitalized or confined under medical advice for more than 24 consecutive hours?",
                          logical_connective: "OR",
                          sub_conditions: [
                            {
                              name: "HospitalInpatientOver24Hrs",
                              line: "you are in hospital receiving in-patient treatment for more than 24 consecutive hours",
                              question:
                                "Was the claimant hospitalized (in-patient) for more than 24 consecutive hours?",
                            },
                            {
                              name: "ConfinedToAccommodationOver24Hrs",
                              line: "you are confined to your accommodation on the advice of the treating doctor for more than 24 consecutive hours",
                              question:
                                "Was the claimant confined to their accommodation on a doctor’s advice for more than 24 consecutive hours?",
                            },
                          ],
                        },
                        {
                          name: "HolidayDisruptionExclusions",
                          line: "Any claim for Holiday Disruption if any of the following apply:",
                          question:
                            "Do any of these Holiday Disruption exclusions apply (thus invalidating the claim)?",
                          logical_operator: "NOT",
                          logical_connective: "OR",
                          sub_conditions: [
                            {
                              name: "PeriodLessThan24HoursExclusion",
                              line: "a) where the period in hospital or confined to accommodation is less than 24 consecutive hours;",
                              question:
                                "Was the total period of in-patient hospitalisation or doctor-advised confinement under 24 consecutive hours?",
                            },
                            {
                              name: "InsuredPersonNotBeingTreatedExclusion",
                              line: "b) for any insured person not being treated as an in-patient or confined to their accommodation on medical advice;",
                              question:
                                "Is the insured person claiming Holiday Disruption not the person actually treated as an in-patient or confined on medical advice?",
                            },
                            {
                              name: "NoValidEmergencyTreatmentClaimExclusion",
                              line: "c) where there is no valid claim for Emergency Treatment",
                              question:
                                "Is there no valid Emergency Medical (Emergency Treatment) claim in place?",
                              logical_operator: "NOT",
                              logical_connective: "AND",
                              sub_conditions: [
                                {
                                  name: "EmergencyTreatmentNeededOutsideHomeTerritory",
                                  line: "1. Emergency Treatment: (a) emergency medical treatment (including rescue services) outside of your home territory; (b) dental treatment for emergency pain relief outside of your home territory.",
                                  question:
                                    "Did the claimant require emergency medical or emergency dental treatment outside their home territory?",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "MustContactMedicalEmergencyHelpline",
                  line: "You must phone the Medical Emergency Assistance helpline if your illness or injury means that you need emergency medical advice; or you are told by your treating doctor that you need repeat treatments, tests as an out-patient, or in-patient admission. If you cannot call before in-patient admission because the condition is serious, you must call as soon as possible after going into hospital.",
                  question:
                    "Has the claimant complied with the requirement to contact the Medical Emergency Assistance helpline under these medical circumstances and timing?",
                  sub_conditions: [
                    {
                      name: "IllnessTriggersHelplineRequirement",
                      line: "If your illness or injury means you: (a) need emergency medical advice; or (b) are told by the treating doctor that you need repeat treatments; or (c) will require tests or investigations as an out-patient; or (d) need to go into hospital as an in-patient.",
                      question:
                        "Does the claimant’s illness or injury meet any of these triggers for calling the Medical Emergency Assistance helpline?",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "NeedsEmergencyMedicalAdvice",
                          line: "need emergency medical advice",
                          question:
                            "Did the claimant need emergency medical advice?",
                        },
                        {
                          name: "ToldNeedRepeatTreatments",
                          line: "are told by the treating doctor that you need repeat treatments",
                          question:
                            "Was the claimant told by the treating doctor that they need repeat treatments?",
                        },
                        {
                          name: "ToldNeedTestsInvestigationsOutpatient",
                          line: "that you are going to require tests or investigations as an out-patient",
                          question:
                            "Was the claimant told they will require tests or investigations as an out-patient?",
                        },
                        {
                          name: "ToldInpatientAdmission",
                          line: "are told that you need to go into hospital as an in-patient",
                          question:
                            "Was the claimant told they need to go into hospital as an in-patient?",
                        },
                      ],
                    },
                    {
                      name: "CalledHelplineInRequiredTimeframe",
                      line: "You must phone the Medical Emergency Assistance helpline before making arrangements if any trigger applies, or as soon as possible after admission if calling beforehand was not possible due to seriousness.",
                      question:
                        "Did the claimant call the Medical Emergency helpline at the correct time (before arrangements, or if not possible, as soon as possible after admission)?",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "CalledHelplineBeforeArrangements",
                          line: "phone the Medical Emergency Assistance helpline before you make any arrangements if any of the triggers apply",
                          question:
                            "Did the claimant call the helpline before making any arrangements for treatment?",
                        },
                        {
                          name: "CalledHelplineAsSoonAsPossibleAfterAdmission",
                          line: "If you cannot call before being admitted for a serious condition, you must contact the helpline as soon as possible after going into hospital",
                          question:
                            "If not possible to call before admission, did the claimant call the helpline as soon as possible afterward due to serious condition?",
                        },
                      ],
                    },
                  ],
                  logical_connective: "AND",
                },
                {
                  name: "EmergencyMedicalExclusionsApply",
                  line: "What is not covered under Emergency Medical and Associated Expenses",
                  question:
                    "Do any of the Emergency Medical-specific exclusions apply to this claim?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "TravelAgainstMedicalAdvice",
                      line: "2. Any claim for a medical condition if any insured person has travelled against the advice of a doctor or purposely travels without medical advice when it was reasonable to have consulted a doctor.",
                      question:
                        "Did the claimant travel against doctor’s advice or without seeking doctor’s advice when it would have been reasonable to do so?",
                    },
                    {
                      name: "UndeclaredPreexistingCondition",
                      line: "3. Any claim for a medical condition if any of the following applied when you took out or renewed your policy or when you booked your trip (whichever is later): (a) had received advice, medication or treatment for any serious, chronic or recurring illness in the last 12 months but did not disclose it; (b) you were under investigation or awaiting results; (c) you were on a waiting list for, or aware of the need for, in-patient treatment; (d) you had been told you have a terminal illness.",
                      question:
                        "Is the claim arising from an undeclared or otherwise excluded pre-existing medical condition that was not disclosed to (or accepted by) the insurer?",
                      sub_conditions: [
                        {
                          name: "HadSeriousChronicIllnessLast12MoNotDisclosed",
                          line: "a) had received advice, medication or treatment for any serious, chronic or recurring illness in the last 12 months but did not disclose it;",
                          question:
                            "Did the claimant receive advice, medication or treatment for a serious, chronic or recurring illness in the last 12 months which was not disclosed to the insurer at the time they took out or renewed the policy, or when they booked the trip (whichever is later)?",
                        },
                        {
                          name: "UnderInvestigationOrAwaitingResults",
                          line: "b) you were under investigation or awaiting results for any diagnosed or undiagnosed condition;",
                          question:
                            "Was the claimant under medical investigation or awaiting test results for a diagnosed or undiagnosed condition, and failed to disclose this to the insurer at the time they took out or renewed the policy, or when they booked the trip (whichever is later)?",
                        },
                        {
                          name: "NeededOrAwaitingInpatientTreatment",
                          line: "c) you were on a waiting list for, or aware of the need for, in-patient treatment for any diagnosed or undiagnosed condition;",
                          question:
                            "Was the claimant on a waiting list for, or aware of the need for, in-patient treatment for any diagnosed or undiagnosed condition and failed to disclose this to the insurer at the time they took out or renewed the policy, or when they booked the trip (whichever is later)?",
                        },
                        {
                          name: "ToldTerminalIllness",
                          line: "d) had been told you have a terminal illness.",
                          question:
                            "Had the claimant been told they have a terminal illness, and failed to disclose this to the insurer at the time they took out or renewed the policy, or when they booked the trip (whichever is later)?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                    {
                      name: "ReferredToConsultantBeforeDeparture",
                      line: "4. Any claim for a medical condition where you or anyone upon whose good health your trip depends has been referred to a Consultant/Specialist, attended A&E or admitted to a hospital between booking your trip and departure, unless disclosed and accepted.",
                      question:
                        "Was the claimant (or someone else whose health the trip depends on) referred to a consultant, or admitted to hospital between booking and departure, but not disclosed/accepted by Aviva?",
                    },
                    {
                      name: "PlannedMedicalTreatmentDuringTrip",
                      line: "5. Any claim for a medical condition you were planning to get treatment for during your trip.",
                      question:
                        "Was the claimant planning to receive (non-emergency) medical treatment during the trip?",
                    },
                    {
                      name: "NonEmergencyTreatmentExclusion",
                      line: "6. Any claim for: (a) treatment in your home territory; (b) unapproved inpatient/outpatient or going home early that was not agreed by the Medical Emergency Assistance provider; (c) non-emergency treatment or surgery (including exploratory tests not directly related to the original injury/illness); (d) treatment that could reasonably wait until you return home; (e) cosmetic surgery (unless medically necessary emergency agreed by provider); (f) medication known you would need; (g) extra costs for single/private room; (h) treatment by a health spa, convalescent or nursing home, or rehab centre; (i) any treatment after you have returned home.",
                      question:
                        "Is the claim seeking payment for non-emergency or otherwise excluded treatments/services (including care in home territory, unapproved treatments, cosmetic, or known medication needs)?",
                      logical_connective: "OR",
                      sub_conditions: [
                        {
                          name: "HomeTerritoryTreatmentExclusion",
                          line: "a) treatment in your home territory",
                          question:
                            "Is the claim for treatment received in your home territory?",
                        },
                        {
                          name: "UnapprovedTreatmentOrEarlyReturnExclusion",
                          line: "b) unapproved inpatient/outpatient or going home early that was not agreed by the Medical Emergency Assistance provider",
                          question:
                            "Is the claim for inpatient or outpatient treatment or for returning home early without prior agreement from the Medical Emergency Assistance provider?",
                        },
                        {
                          name: "NonEmergencyOrUnrelatedTreatmentExclusion",
                          line: "c) non-emergency treatment or surgery (including exploratory tests not directly related to the illness or injury you originally went to hospital for)",
                          question:
                            "Is the claim for non-emergency treatment or surgery, including exploratory tests not directly related to the original illness or injury?",
                        },
                        {
                          name: "CouldWaitUntilReturnHomeExclusion",
                          line: "d) treatment that could reasonably wait until you return home",
                          question:
                            "Is the claim for treatment that could reasonably have waited until you returned home?",
                        },
                        {
                          name: "CosmeticSurgeryExclusion",
                          line: "e) cosmetic surgery (unless considered necessary as a medical emergency and agreed with our Medical Emergency Assistance provider)",
                          question:
                            "Is the claim for cosmetic surgery not deemed medically necessary in an emergency or not agreed by the Medical Emergency Assistance provider?",
                        },
                        {
                          name: "KnownMedicationNeedsExclusion",
                          line: "f) medication which, at the time your trip started, you knew that you would need while you were away",
                          question:
                            "Is the claim for medication you already knew you would need during your trip before departing?",
                        },
                        {
                          name: "SingleOrPrivateRoomExclusion",
                          line: "g) any extra costs because you have requested a single or private room",
                          question:
                            "Is the claim for extra costs arising solely from requesting a single or private room?",
                        },
                        {
                          name: "SpaConvalescentRehabCentreExclusion",
                          line: "h) treatment or services provided by a health spa, convalescent or nursing home or any rehabilitation centre",
                          question:
                            "Is the claim for treatment or services at a health spa, convalescent or nursing home, or any rehabilitation centre?",
                        },
                        {
                          name: "AfterReturnHomeExclusion",
                          line: "i) any treatment after you have returned home",
                          question:
                            "Is the claim for treatment provided after you have returned home?",
                        },
                      ],
                    },
                    {
                      name: "RefusalToMoveHospitalOrReturnHome",
                      line: "7. Costs incurred following your decision not to move hospital or return to the UK after the date our provider and treating doctor deemed it safe.",
                      question:
                        "Did the claimant continue incurring medical costs after refusing to return home or change hospitals on the advice of Aviva’s provider and the treating doctor?",
                    },
                    {
                      name: "HolidayDisruptionIfLessThan24Hours",
                      line: "8. Any claim for Holiday Disruption (under this section) if the period in hospital or confined to accommodation is less than 24 consecutive hours; or if the insured person is not the one receiving in-patient treatment or being confined on medical advice.",
                      question:
                        "Is the claim for ‘Holiday Disruption’ but the claimant was not hospitalized or confined over 24 hours, or not the person receiving such treatment?",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidPersonalAccident",
              line: "### Personal Accident",
              question:
                "Does the claim meet the conditions for a valid Personal Accident claim?",
              sub_conditions: [
                {
                  name: "AccidentalBodilyInjuryDuringTrip",
                  line: "We will cover you, up to the limits shown on your policy schedule, if you suffer an accidental bodily injury during your trip which requires urgent and immediate medical attention.",
                  question:
                    "Did the claimant suffer an accidental bodily injury during the trip requiring urgent and immediate medical attention?",
                },
                {
                  name: "InjuryLeadsToQualifyingOutcome",
                  line: "that leads solely, directly and independently to your: (1) death; or (2) loss of one or more limbs and/or the total loss of sight in one or both eyes; or (3) permanent total disablement after 104 weeks from the date of injury (except where compensation is paid under item 2).",
                  question:
                    "Did the accidental injury lead to one of the qualifying outcomes below?",
                  sub_conditions: [
                    {
                      name: "AccidentalInjuryLedToDeath",
                      line: "your (1) death",
                      question:
                        "Did the accidental injury result in your (the claimant's) death?",
                    },
                    {
                      name: "AccidentalInjuryLedToLossOfLimbsOrSight",
                      line: "your (2) loss of one or more limbs and/or the total loss of sight in one or both eyes",
                      question:
                        "Did the accidental injury result in the loss of one or more of your limbs and/or total loss of sight in one or both of your eyes?",
                    },
                    {
                      name: "AccidentalInjuryLedToPermanentDisablement",
                      line: "your (3) permanent total disablement after 104 weeks from the date of injury (except where compensation is paid under item 2).",
                      question:
                        "Did the accidental injury result in your permanent total disablement after 104 weeks from the date of injury?",
                    },
                  ],
                  logical_connective: "OR",
                },
                {
                  name: "DeathOrDisabilityTimingCondition",
                  line: "Special condition: The death or disability must happen within one year of the accident.",
                  question:
                    "Was death or the relevant disability confirmed within one year of the accident (or after 104 weeks for permanent total disablement)?",
                },
                {
                  name: "OnlyOneItemClaimedUnderThisSection",
                  line: "You can only claim for one item under this section, regardless of the number of injuries sustained.",
                  question:
                    "Are you only claiming for one of these outcomes under Personal Accident (no multiple claims)?",
                },
                {
                  name: "PersonalAccidentExclusionsApply",
                  line: "What is not covered under Personal Accident:",
                  question:
                    "Do none of the Personal Accident exclusions apply?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "ClaimForSicknessDiseaseNervousShock",
                      line: "2. Any claim for sickness, disease, nervous shock or a naturally occurring condition or degenerative process.",
                      question:
                        "Is the claim solely for sickness, disease, nervous shock, or a degenerative/naturally occurring (non-accidental) condition?",
                    },
                    {
                      name: "ClaimFromExcludedActivityInLeisureOrWinterSports",
                      line: "3. Any claim as a result of you taking part in any activity where the Personal Accident section is specifically excluded in the Leisure Activities or Winter Sports sections.",
                      question:
                        "Did the claim arise from an activity for which Personal Accident coverage is specifically excluded in the Leisure Activities or Winter Sports sections?",
                      sub_conditions: [
                        {
                          name: "TookPartInCanopyTreetopWalking",
                          line: "Canopy / Treetop walking",
                          question:
                            "Did you take part in Canopy or Treetop walking?",
                        },
                        {
                          name: "TookPartInGliding",
                          line: "Gliding",
                          question: "Did you take part in Gliding?",
                        },
                        {
                          name: "TookPartInMicrolighting",
                          line: "Microlighting",
                          question: "Did you take part in Microlighting?",
                        },
                        {
                          name: "TookPartInScubaDiving15m",
                          line: "Scuba diving to a depth of 15 metres",
                          question:
                            "Did you take part in Scuba diving to a depth of 15 metres?",
                        },
                        {
                          name: "TookPartInSegway",
                          line: "Segway",
                          question: "Did you take part in using a Segway?",
                        },
                        {
                          name: "TookPartInTandemSkyDiving",
                          line: "Tandem sky diving",
                          question: "Did you take part in Tandem sky diving?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidPersonalLiability",
              line: "### Personal Liability",
              question:
                "Does the claim meet the conditions for a valid Personal Liability claim?",
              sub_conditions: [
                {
                  name: "AccidentOccurrenceDuringTrip",
                  line: "We will cover you if this relates to an accident that happens during your trip and causes you to become legally liable.",
                  question:
                    "Did an accident occur during the trip that could give rise to your legal liability?",
                },
                {
                  name: "LiabilityForBodilyInjuryOrPropertyDamage",
                  line: "Any money you legally have to pay relating to an accident which causes: (a) death or physical injury to any person, (b) loss or damage to property, or (c) loss or damage to temporary holiday accommodation not owned by you.",
                  question:
                    "Is your liability due to either death/physical injury to someone else, damage to property, or damage to temporary holiday accommodation that you do not own?",
                  sub_conditions: [
                    {
                      name: "LiabilityForDeathOrInjury",
                      line: "a) death or physical injury to any person",
                      question:
                        "Are you liable for another person’s death or bodily/physical injury?",
                    },
                    {
                      name: "LiabilityForPropertyDamage",
                      line: "b) loss or damage to property",
                      question:
                        "Are you liable for loss of or damage to someone else’s property?",
                    },
                    {
                      name: "LiabilityForDamageToTemporaryAccommodation",
                      line: "c) loss or damage to temporary holiday accommodation which is not owned by you",
                      question:
                        "Are you liable for loss or damage to temporary holiday accommodation that you do not own?",
                    },
                  ],
                  logical_connective: "OR",
                },
                {
                  name: "LegalCostsConsent",
                  line: "We will also pay legal costs and expenses incurred by you in relation to the accident if you get our consent in writing before incurring any cost or expense.",
                  question:
                    "If you are claiming legal costs or expenses, have you obtained Aviva’s written consent beforehand?",
                },
                {
                  name: "PersonalLiabilityExclusionsApply",
                  line: "What is not covered",
                  question:
                    "Do none of the Personal Liability exclusions apply?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "FinesOrExemplaryDamages",
                      line: "2. Any fines or exemplary damages (punishing, or aimed at punishing, the person responsible rather than awarding compensation) you have to pay.",
                      question:
                        "Is the claim seeking payment of fines or exemplary/punitive damages?",
                    },
                    {
                      name: "LiabilityArisingFromInjuryToHouseholdOrEmployees",
                      line: "3.a) death or injury of members of your household or people who work for you",
                      question:
                        "Does the liability arise from death or injury to your household members or employees?",
                    },
                    {
                      name: "LiabilityArisingFromPropertyUnderYourControl",
                      line: "3.b) loss of or damage to property which belongs to you or is under your control, the control of a member of your household, or people who work for you",
                      question:
                        "Is the liability for property that belongs to you, your household, or under your or their control?",
                    },
                    {
                      name: "LiabilityArisingFromYourJob",
                      line: "3.c) your job",
                      question:
                        "Does the liability arise out of or in the course of your job?",
                    },
                    {
                      name: "LiabilityArisingFromManualWork",
                      line: "3.d) your involvement in paid or unpaid manual work or physical labour of any kind",
                      question:
                        "Did the liability result from your paid or unpaid manual work or physical labour?",
                    },
                    {
                      name: "LiabilityOwningOrOccupyingLand",
                      line: "3.e) you owning or occupying any land or building, unless you are occupying any temporary holiday accommodation which is not owned by you (in which case an excess will apply).",
                      question:
                        "Does the liability arise from your ownership or occupation of land/buildings (not just temporary holiday accommodation)?",
                    },
                    {
                      name: "LiabilityOwnershipOrUseOfAnimalsVehiclesEtc",
                      line: "3.f) you owning or using animals (except domestic), firearms (except sporting guns used for clay-pigeon shooting), motorised vehicles, vessels (except manually-propelled watercraft), or aircraft of any kind",
                      question:
                        "Does the liability arise from you owning or using an excluded animal, firearm, motor vehicle, vessel, or aircraft?",
                    },
                    {
                      name: "LiabilityFromExcludedLeisureOrActivityHoliday",
                      line: "3.g) you taking part in any leisure activity or activity based holiday where Personal Liability is specifically excluded in the Leisure Activities, Activity Based Holidays, or Winter Sports sections.",
                      question:
                        "Does the liability arise from a leisure activity or activity based holiday explicitly excluded from Personal Liability coverage?",
                      sub_conditions: [
                        {
                          name: "LiabilityFromLeisureOrActivityHoliday",
                          line: "3.g) you taking part in any leisure activity or activity based holiday where Personal Liability is specifically excluded in the Leisure Activities, Activity Based Holidays, or Winter Sports sections.",
                          question:
                            "Does the liability arise from a leisure activity or activity based holiday explicitly?",
                        },
                        {
                          name: "LiabilityExcludedLeisureOrActivityHoliday",
                          line: "3.g) you taking part in any leisure activity or activity based holiday where Personal Liability is specifically excluded in the Leisure Activities, Activity Based Holidays, or Winter Sports sections.",
                          question:
                            "Did you take part in a leisure activity or activity based holiday explicitly excluded from Personal Liability coverage?",
                          sub_conditions: [
                            {
                              name: "TookPartInBananaBoatingRingo",
                              line: "Banana boating / Ringo",
                              question:
                                "Did you take part in Banana boating or Ringo?",
                            },
                            {
                              name: "TookPartInBodyBoarding",
                              line: "Body boarding",
                              question: "Did you take part in Body boarding?",
                            },
                            {
                              name: "TookPartInCanopyTreetopWalking",
                              line: "Canopy / Treetop walking",
                              question:
                                "Did you take part in Canopy or Treetop walking?",
                            },
                            {
                              name: "TookPartInDinghySailing",
                              line: "Dinghy sailing",
                              question: "Did you take part in Dinghy sailing?",
                            },
                            {
                              name: "TookPartInGliding",
                              line: "Gliding",
                              question: "Did you take part in Gliding?",
                            },
                            {
                              name: "TookPartInGoKarting",
                              line: "Go karting",
                              question: "Did you take part in Go karting?",
                            },
                            {
                              name: "TookPartInJetSkiing",
                              line: "Jet skiing",
                              question: "Did you take part in Jet skiing?",
                            },
                            {
                              name: "TookPartInMicrolighting",
                              line: "Microlighting",
                              question: "Did you take part in Microlighting?",
                            },
                            {
                              name: "TookPartInMotorcyclingUpTo125cc",
                              line: "Motorcycling up to 125cc",
                              question:
                                "Did you take part in riding or being a passenger on a motorcycle up to 125cc?",
                            },
                            {
                              name: "TookPartInSailBoarding",
                              line: "Sail boarding",
                              question: "Did you take part in Sail boarding?",
                            },
                            {
                              name: "TookPartInSandBoardingOrSkiing",
                              line: "Sand boarding / skiing",
                              question:
                                "Did you take part in Sand boarding or Sand skiing?",
                            },
                            {
                              name: "TookPartInScubaDiving15m",
                              line: "Scuba diving to a depth of 15 metres",
                              question:
                                "Did you take part in Scuba diving to a depth of 15 metres?",
                            },
                            {
                              name: "TookPartInSegway",
                              line: "Segway",
                              question: "Did you take part in using a Segway?",
                            },
                            {
                              name: "TookPartInSurfingFlowriding",
                              line: "Surfing and flowriding",
                              question:
                                "Did you take part in Surfing or flowriding?",
                            },
                            {
                              name: "TookPartInTandemSkyDiving",
                              line: "Tandem sky diving",
                              question:
                                "Did you take part in Tandem sky diving?",
                            },
                            {
                              name: "TookPartInWakeBoarding",
                              line: "Wake boarding",
                              question: "Did you take part in Wake boarding?",
                            },
                            {
                              name: "TookPartInWaterSkiing",
                              line: "Water skiing",
                              question: "Did you take part in Water skiing?",
                            },
                            {
                              name: "TookPartInWindSurfing",
                              line: "Wind surfing",
                              question: "Did you take part in Wind surfing?",
                            },
                            {
                              name: "TookPartInYachting",
                              line: "Yachting",
                              question: "Did you take part in Yachting?",
                            },
                            {
                              name: "TookPartInCharityOrConservationWork",
                              line: "Charity or Conservation Work",
                              question:
                                "Did you take part in Charity or Conservation Work as an activity based holiday?",
                            },
                            {
                              name: "TookPartInFlotillaSailing",
                              line: "Flotilla Sailing",
                              question:
                                "Did you take part in Flotilla Sailing?",
                            },
                            {
                              name: "TookPartInNarrowBoatCanalCruising",
                              line: "Narrow boat / Canal cruising – Only on inland waters",
                              question:
                                "Did you take part in narrow boat or canal cruising on inland waters?",
                            },
                          ],
                          logical_connective: "OR",
                        },
                      ],
                      logical_connective: "AND",
                    },
                  ],
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidLegalExpenses",
              line: "### Legal Expenses and Advice",
              question:
                "Do you meet the conditions for a valid Legal Expenses claim?",
              logical_connective: "AND",
              sub_conditions: [
                {
                  name: "AreThereLegalProceedingsToPursueCivilClaimAgainstNegigentParty",
                  line: "We will negotiate on your behalf for your legal rights to bring legal proceedings to pursue a civil claim against a negligent party resulting from an incident (including medical treatment) for which you are not at fault, causing your death or personal injury during your trip, provided that:",
                  question:
                    "Do you need to bring legal proceedings to pursue a civil claim against a negligent party resulting from an incident (including medical treatment) for which you are not at fault, causing your death or personal injury during your trip?",
                },
                {
                  name: "IncidentWithinTerritorialLimitsAndPolicyPeriod",
                  line: "1. the insured incident occurs within the territorial limits and during the period of insurance.",
                  question:
                    "Did the insured incident occur within the policy’s territorial limits and during the valid period of insurance?",
                },
                {
                  name: "ProspectsOfSuccessExist",
                  line: "2. prospects of success exist for the duration of the claim.",
                  question:
                    "Is there a reasonable likelihood (more likely than not) that you will have a successful legal outcome?",
                  sub_conditions: [
                    {
                      name: "LikelyToRecoverDamagesOrRemedy",
                      line: "1. it is always more likely than not that you will recover damages or obtain any other legal remedy which we have agreed to;",
                      question:
                        "Is it more likely than not that you will recover damages or obtain a legal remedy agreed by the insurer?",
                    },
                    {
                      name: "LikelyToMakeSuccessfulDefence",
                      line: "2. make a successful defence;",
                      question:
                        "Is it more likely than not that you will make a successful defence?",
                    },
                    {
                      name: "LikelyToMakeSuccessfulAppealOrDefenceOfAppeal",
                      line: "3. make a successful appeal or defence of an appeal;",
                      question:
                        "Is it more likely than not that you will make a successful appeal or a successful defence of an appeal?",
                    },
                    {
                      name: "DamagesLikelyToExceedCosts",
                      line: "4. recover damages which are higher than any costs and expenses which may be incurred.",
                      question:
                        "Is it more likely than not that the damages recovered will exceed the costs and expenses you may incur?",
                    },
                  ],
                  logical_connective: "OR",
                },
                {
                  name: "ReportedAppealsWithinTime",
                  line: "3. in respect of any appeal or defence of an appeal, it has been reported to us at least 10 working days prior to the deadline for any appeal.",
                  question:
                    "If this is an appeal or defence of an appeal, did you report it to the insurer at least 10 working days before the appeal deadline?",
                },
                {
                  name: "ReportedSoonWithin180Days",
                  line: "4. an insured person reports an insured incident to us as soon as possible and in all cases within 180 days of any circumstances which may give rise to a claim under this section.",
                  question:
                    "Did an insured person report the incident to the insurer as soon as possible and within 180 days of any circumstances which may give rise to a claim?",
                },
                {
                  name: "LegalExpensesExclusionsApply",
                  line: "What is not covered",
                  question: "Do none of the Legal Expenses exclusions apply?",
                  logical_operator: "NOT",
                  logical_connective: "OR",
                  sub_conditions: [
                    {
                      name: "isputeBetweenYouAndInsurer",
                      line: "3. A dispute between you and us.",
                      question:
                        "Is the legal dispute specifically between you (the insured) and the insurer?",
                    },
                    {
                      name: "DisputeTravelCompanionRelativeInsured",
                      line: "4. A dispute between you and someone you were travelling with, a person related to you, or another insured person.",
                      question:
                        "Is the legal dispute between you and someone you were travelling with, a person related to you, or another insured person?",
                    },
                    {
                      name: "CoveredElsewhereOrRefusalFromSpecificInsurance",
                      line: "5. Any claim where you are more specifically insured, or any amount you cannot recover from a more specific insurance because that insurer refuses the claim.",
                      question:
                        "Is the claim already covered elsewhere more specifically, or is it an amount another insurer has refused but that is more specifically covered under their policy?",
                    },
                    {
                      name: "DrivingWithoutLicenceInsurance",
                      line: "6. Any claim relating to you driving a motor vehicle without a valid licence and/or insurance.",
                      question:
                        "Does the claim arise from you driving without a valid licence or without insurance?",
                    },
                    {
                      name: "GradualIllnessNotSudden",
                      line: "7. Any illness, death, or bodily injury which develops gradually or is not caused by a specific sudden event.",
                      question:
                        "Is the claim for an illness, death, or injury that developed gradually or was not caused by a sudden event?",
                    },
                    {
                      name: "DVTOrSymptomsFromAirTravel",
                      line: "8. Any claim from Deep Vein Thrombosis (DVT) or its symptoms that result from travelling by air.",
                      question:
                        "Is the claim related to Deep Vein Thrombosis (DVT) or its symptoms resulting from air travel?",
                    },
                    {
                      name: "LibelSlander",
                      line: "9. Any claim in respect of libel or slander.",
                      question: "Is this claim in respect of libel or slander?",
                    },
                    {
                      name: "CostsBeforeWrittenAcceptance",
                      line: "10. Costs and expenses incurred prior to our written acceptance of a claim.",
                      question:
                        "Is the claim for costs or expenses incurred before the insurer gave written acceptance of your claim?",
                    },
                    {
                      name: "ContingencyFeeArrangement",
                      line: "11. Costs and expenses incurred by the appointed representative on a contingency fee basis.",
                      question:
                        "Is the claim for costs and expenses incurred by the appointed representative on a contingency fee basis?",
                    },
                    {
                      name: "LegalActionWithoutConsent",
                      line: "12. Any legal action you take which we have not agreed to, or where you do anything to hinder us or the appointed representative.",
                      question:
                        "Is the claim related to any legal action you took without the insurer’s agreement, or where you hindered the insurer or appointed representative in any way?",
                    },
                    {
                      name: "DeliberateOrIntentionalAct",
                      line: "13. Any claim deliberately or intentionally caused by you.",
                      question:
                        "Was the incident leading to the claim intentionally or deliberately caused by you?",
                    },
                    {
                      name: "FinesPenalties",
                      line: "14. Any fines, penalties, compensation, or damages which you are ordered to pay by a court or other authority.",
                      question:
                        "Is the claim seeking reimbursement for fines, penalties, compensation, or damages that a court or authority has ordered you to pay?",
                    },
                    {
                      name: "JudicialReviewApplications",
                      line: "15. An application for judicial review.",
                      question:
                        "Does this claim relate to an application for judicial review?",
                    },
                    {
                      name: "NonContractingPartyEnforcement",
                      line: "16. Any claims relating to any non-contracting party’s rights to enforce all or any part of this section.",
                      question:
                        "Is the claim attempting to enforce this Legal Expenses section for or by someone who is not a contracting party to this policy?",
                    },
                  ],
                },
              ],
            },
            {
              name: "IsClaimValidCatastropheCover",
              line: "### Catastrophe cover",
              question:
                "Does the claim meet the conditions for Catastrophe cover?",
              logical_connective: "AND",
              sub_conditions: [
                {
                  name: "ForcedToMoveFromAccommodationDueToCatastrophe",
                  line: "We will cover you up to the limits shown in your policy schedule for additional accommodation and/or transport costs if you are forced to move from your independently booked and prepaid accommodation, due to avalanche, earthquake, explosion, fire, flood, landslide, storm or tsunami occurring during your trip.",
                  question:
                    "Were you forced to move from your independently booked/prepaid accommodation due to avalanche, earthquake, explosion, fire, flood, landslide, storm or tsunami occurring during the trip?",
                },
                {
                  name: "AccommodationIndependentlyBooked",
                  line: "This section only operates if you have booked your accommodation independently and directly with the accommodation provider.",
                  question:
                    "Was your accommodation booked independently and directly with the provider?",
                },
                {
                  name: "ExtraAccommodationSimilarStandard",
                  line: "Extra accommodation costs must be for a similar standard of accommodation to that in which you were originally staying.",
                  question:
                    "Are any extra accommodation costs limited to a similar standard as originally booked?",
                },
                {
                  name: "WrittenConfirmationOfForcedDeparture",
                  line: "You must provide written confirmation from the provider of the accommodation and/or the local or national authorities that you were forced to leave your independently booked and prepaid accommodation and the reason for this.",
                  question:
                    "Do you have written confirmation from the accommodation provider or local/national authorities stating that you were forced to leave and why?",
                },
                {
                  name: "ReceiptsForExtraAccommodationOrTravel",
                  line: "You must provide receipts for any extra accommodation or travel costs incurred if you are forced to move from your independently booked and prepaid accommodation.",
                  question:
                    "Can you provide receipts for all extra costs incurred due to moving from the affected accommodation?",
                },
                {
                  name: "CatastropheExclusionsApply",
                  line: "What is not covered under Catastrophe Cover.",
                  question:
                    "Do none of the specific catastrophe cover exclusions apply?",
                  logical_operator: "NOT",
                  sub_conditions: [
                    {
                      name: "ClaimForTransportCostsHome",
                      line: "2. Any transport costs incurred in returning to your home.",
                      question:
                        "Is the claim (or part of it) for the cost of returning home (which is not covered)?",
                    },
                    {
                      name: "ClaimForPrepaidAccommodationRecoverable",
                      line: "3. Any prepaid accommodation costs that can be claimed back from your accommodation provider.",
                      question:
                        "Is the claim for prepaid accommodation costs that could be recovered from the accommodation provider?",
                    },
                    {
                      name: "CatastropheKnownBeforeBooking",
                      line: "4. Any claim where the catastrophe, as described, had already occurred at the time you took out your policy or booked your trip (whichever is later).",
                      question:
                        "Did the catastrophe event happen before the policy or trip booking date?",
                    },
                    {
                      name: "NoForcedDepartureConfirmation",
                      line: "5. Any claim where the provider of the accommodation and the local or national authorities did not deem it necessary for you to leave your prepaid accommodation.",
                      question:
                        "Did the local authorities or accommodation provider fail to require the claimant to leave (meaning there was actually no forced departure)?",
                    },
                  ],
                  logical_connective: "OR",
                },
              ],
            },
            {
              name: "IsClaimValidEmergencyTravelDocuments",
              line: "### Emergency Travel Document Expenses",
              question:
                "Does the claim meet the conditions for Emergency Travel Document Expenses?",
              sub_conditions: [
                {
                  name: "PassportOrVisaLostStolenDamagedOutsideUK",
                  line: "We will pay up to the limits shown on your policy schedule for the cost of an Emergency Travel Document and extra travel, accommodation and communication expenses in relation to obtaining the new document; if your passport or visa is lost, stolen or damaged while you are outside of the UK and this would prevent you from leaving the country or continuing your trip. If you are unable to use your return ticket to the UK, we will also pay towards the cost of additional travel expenses (of a similar standard you had booked for your trip) to allow you to return home.",
                  question:
                    "Was your passport or visa lost, stolen, or damaged while you were outside the UK, preventing you from leaving or continuing your trip, and requiring an Emergency Travel Document or additional travel costs?",
                },
                {
                  name: "PassportVisaCareAndRecovery",
                  line: "1. You must always take reasonable care to keep your passport and visa safe. If your passport or visa is lost or stolen you must take all reasonable steps to get it back.",
                  question:
                    "Did you take reasonable care to keep your passport/visa safe, and did you take all reasonable steps to recover it if lost or stolen?",
                },
                {
                  name: "DocumentaryEvidenceOfCosts",
                  line: "2. All claims must be supported by documentary evidence of the costs you have incurred.",
                  question:
                    "Can you provide documentary evidence (receipts, bills) for the costs incurred?",
                },
                {
                  name: "EmergencyTravelDocsExclusionsApply",
                  line: "What is not covered",
                  question:
                    "Do any of the exclusions under ‘Emergency Travel Document Expenses: What is not covered’ apply?",
                  logical_operator: "NOT",
                  sub_conditions: [
                    {
                      name: "NoPoliceReportForLossOrTheft",
                      line: "2. Any loss or theft of your passport or visa if you do not report it to the police within 24 hours of discovery or as soon as reasonably possible and request a written police report.",
                      question:
                        "Was your passport or visa lost or stolen and you did not report it to the police within 24 hours of discovery or as soon as reasonably possible and request a written police report?",
                    },
                    {
                      name: "CostOfNewPassportOrVisa",
                      line: "3. The cost of purchasing a new replacement passport or visa.",
                      question:
                        "Is the claim seeking reimbursement for the actual new passport or visa cost (which is not covered)?",
                    },
                    {
                      name: "NoReturnTicketPurchasedOrOpenEnded",
                      line: "4. Any claim for travel and accommodation expenses if: a) you have not purchased your return ticket to the UK before you depart on your trip; or b) you are travelling on an open-ended ticket and have not confirmed a return date to the UK with the airline.",
                      question:
                        "Is the claim for travel/accommodation expenses when no return ticket was purchased before departure, or on an open-ended ticket with no confirmed return date?",
                      sub_conditions: [
                        {
                          name: "NoReturnTicketPurchasedBeforeDeparture",
                          line: "a) you have not purchased your return ticket to the UK before you depart on your trip;",
                          question:
                            "Was no return ticket purchased to the UK before departure?",
                        },
                        {
                          name: "OnOpenEndedTicketWithoutConfirmedReturn",
                          line: "b) you are travelling on an open-ended ticket and have not confirmed a return date to the UK with the airline.",
                          question:
                            "Are you travelling on an open-ended ticket with no confirmed return date to the UK with the airline?",
                        },
                      ],
                      logical_connective: "OR",
                    },
                    {
                      name: "OtherPersonsContinueTravel",
                      line: "5. Any claim for travel and accommodation expenses of any other insured persons who could continue to travel without you but decide to stay with you.",
                      question:
                        "Is the claim seeking costs for other insured persons who chose not to continue their own trip?",
                    },
                  ],
                  logical_connective: "OR",
                },
              ],
              logical_connective: "AND",
            },
            {
              name: "IsClaimValidPersonalMoney",
              line: "### Personal Money",
              question:
                "Does the claim meet the conditions for a valid Personal Money claim?",
              logical_connective: "AND",
              sub_conditions: [
                {
                  name: "PersonalMoneyLostOrStolenDuringTrip",
                  line: "We will cover you up to the limits shown in your policy schedule for loss or theft of your personal money during your trip.",
                  question:
                    "Was your personal money lost or stolen during the trip?",
                },
                {
                  name: "TookReasonableCare",
                  line: "1. You must always take reasonable care to keep your personal money safe. If your personal money is lost or stolen you must take all reasonable steps to get it back.",
                  question:
                    "Did you take reasonable care to keep personal money safe, and attempt recovery if stolen?",
                },
                {
                  name: "EvidenceOfMoneyValue",
                  line: "2. You must be able to provide evidence that you owned the lost or stolen personal money and how much it is worth (for example, proof of withdrawal or a currency exchange receipt).",
                  question:
                    "Can you provide proof of ownership and value (e.g., withdrawal receipt)?",
                },
                {
                  name: "PromptReportOfLossOrTheft",
                  line: "3. You must report any loss or theft to the police within 24 hours of discovery or as soon as reasonably possible and request a written police report or other independent proof of loss.",
                  question:
                    "Did you promptly report the loss/theft to the police or relevant authority and obtain documentation?",
                },
                {
                  name: "PersonalMoneyExclusionsApply",
                  line: "What is not covered",
                  question:
                    "Do any of these exclusions apply to the Personal Money claim?",
                  logical_operator: "NOT",
                  sub_conditions: [
                    {
                      name: "LossOrTheftUnreported",
                      line: "2. Any loss or theft which you do not report to the police within 24 hours of discovery or as soon as reasonably possible and get a written report (where it is not possible to obtain a police report you must provide other independent proof of loss such as a letter from your transport company, accommodation provider, vehicle hire company or repairer).",
                      question:
                        "Was the loss or theft of personal money not reported to the police within 24 hours of discovery or as soon as reasonably possible, or was no written report or other independent proof of loss obtained?",
                    },
                    {
                      name: "DelayedDetainedOrConfiscatedMoney",
                      line: "3. Any personal money which is delayed, detained or confiscated by customs or other officials.",
                      question:
                        "Was the personal money delayed, detained, or confiscated by authorities?",
                    },
                    {
                      name: "BondsOrDocumentsExclusion",
                      line: "4. Loss or theft of bonds, securities or documents of any kind.",
                      question:
                        "Is this claim actually for bonds, securities, or other documents?",
                    },
                    {
                      name: "NotCarriedInHandBaggage",
                      line: "5. Loss or theft of personal money not carried in your hand baggage and fully accessible to you while you are travelling.",
                      question:
                        "Was the personal money not carried in your hand baggage and fully accessible while travelling?",
                    },
                    {
                      name: "LeftUnattendedExclusion",
                      line: "6. Theft of personal money which you have deliberately left unattended.",
                      question:
                        "Was the personal money stolen and deliberately left unattended?",
                    },
                    {
                      name: "MoneyStolenFromLockedRoomSafeOrVehicleUnlessForcibleEntry",
                      line: "7. Theft of personal money from a locked room, safe, motor vehicle or caravan unless there is visible evidence of forcible and violent entry.",
                      question:
                        "Was the personal money stolen from a locked room, safe, motor vehicle or caravan unless there is visible evidence of forcible and violent entry?",
                    },
                    {
                      name: "ShortagesExchangeRateExclusion",
                      line: "8. Shortages due to a mistake or loss due to a change in exchange rates.",
                      question:
                        "Is the claim for shortages due to a mistake or loss due to a change in exchange rates?",
                    },
                  ],
                  logical_connective: "OR",
                },
              ],
            },
            {
              name: "IsClaimValidDelayedBaggage",
              line: "### Delayed Baggage",
              question:
                "Does the claim meet the conditions for a valid Delayed Baggage claim?",
              sub_conditions: [
                {
                  name: "BaggageDelayedMoreThan12HoursOutwardJourney",
                  line: "If your baggage is delayed for more than 12 hours on the outward journey, we will pay up to the limit shown on your policy schedule if you need to replace essential items.",
                  question:
                    "Was the baggage delayed for more than 12 hours on the claimant's outward journey?",
                },
                {
                  name: "HasWrittenConfirmationOfDelay",
                  line: "You must provide written confirmation from the carrier of the date and time of departure and the reason for the delay.",
                  question:
                    "Can the claimant provide written confirmation from the carrier describing the baggage delay duration and reason?",
                },
                {
                  name: "HasReceiptsForEssentialPurchases",
                  line: "To claim under this section, you must keep the receipts of anything you buy while your baggage is delayed.",
                  question:
                    "Has the claimant kept receipts for essential purchases made during the baggage delay?",
                },
                {
                  name: "BaggageDelayDueToCustomsOrOfficials",
                  line: "Any claim for baggage delayed or detained by customs or other officials is excluded.",
                  question:
                    "Was the baggage delay or detention caused by customs or other officials?",
                  logical_operator: "NOT",
                },
              ],
              logical_connective: "AND",
            },
          ],
          logical_connective: "OR",
        },
      ],
      logical_connective: "OR",
    },
  ],
  logical_connective: "AND",
};
