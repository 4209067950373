import React, { FunctionComponent, ReactNode, useState } from "react";
import { AutoComplete, AutoCompleteProps } from "antd";
import { dropRightWhile, takeRightWhile } from "lodash";
import { useCognitoToken } from "../providers";
import { useNicknameDomain } from "./NicknameDomainContext";

export const NicknameAutoComplete: FunctionComponent<AutoCompleteProps> = ({
  onDropdownVisibleChange,
  ...rest
}) => {
  const nicknameDomain = useNicknameDomain();
  const { token } = useCognitoToken();

  const [options, setOptions] = useState<
    { key: string; value: string; label: ReactNode }[]
  >([]);

  const splitSearchString = (inputString: string) => {
    const isAlphaNumeric = (chr: string) => /^[a-z0-9]$/i.test(chr);

    return [
      dropRightWhile(inputString, isAlphaNumeric).join(""),
      takeRightWhile(inputString, isAlphaNumeric).join(""),
    ];
  };

  const updateOptions = (inputString: string) => {
    const [newPrefix, newSearchString] = splitSearchString(inputString);

    if (newSearchString) {
      nicknameDomain
        .prefixSearchNickname(newSearchString, token)
        .then((pairs) => {
          setOptions(
            pairs.map((pair, index) => {
              return {
                key: `${pair.uuid}#${index}`,
                value: newPrefix + pair.nickname,
                label: <>{pair.nickname}</>,
              };
            })
          );
          onDropdownVisibleChange?.(pairs.length > 0);
        });
    } else {
      setOptions([]);
      onDropdownVisibleChange?.(false);
    }
  };

  return <AutoComplete {...rest} options={options} onSearch={updateOptions} />;
};
