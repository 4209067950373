import { ComponentProps, useEffect, useState } from "react";
import { ParseResult } from "@dip/data-access/api-types";
import {
  bracketMatchingDecoration,
  parseErrorDecoration,
  prettifyUL,
  renderLeaf,
} from "@unlikelyai-magic/utils";
import { CodeTextBox } from "./CodeTextBox";

type ULTextBoxProps = ComponentProps<typeof CodeTextBox> & {
  parseError?: ParseResult;
};

export const ULTextBox = ({
  content,
  parseError,
  onValueChange,
  ...props
}: ULTextBoxProps) => {
  const [parseErrorInfo, setParseErrorInfo] = useState<ParseResult>();

  useEffect(() => setParseErrorInfo(parseError), [parseError]);

  const decorations = [
    ...(parseErrorInfo ? [parseErrorDecoration(parseErrorInfo)] : []),
    bracketMatchingDecoration,
  ];

  return (
    <CodeTextBox
      content={content}
      format={prettifyUL}
      onValueChange={(value) => {
        setParseErrorInfo(undefined);
        onValueChange?.(value);
      }}
      decorations={decorations}
      renderLeaf={renderLeaf}
      {...props}
    />
  );
};
