import { ComponentProps } from "react";
import styled from "@emotion/styled";

export const UnlikelyAiLogo = styled((props: ComponentProps<"svg">) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540" {...props}>
      <g clipPath="url(#clip0_4196_10281)">
        <path
          d="M53.9612 71.9732H18V0H53.9612C112.667 0 165.032 28.4794 197.929 72.2938C230.844 28.4794 283.191 0 341.896 0C440.855 0 521.825 80.9854 521.86 179.924L521.86 360.175C521.878 379.981 505.688 396.171 485.882 396.171C466.076 396.171 449.886 379.981 449.886 360.175L449.885 179.942C449.885 120.561 401.332 71.9732 341.932 71.9732C282.532 71.9732 233.943 120.561 233.943 179.942L233.943 360.175C233.925 379.981 217.753 396.171 197.947 396.171C178.141 396.171 161.95 379.981 161.95 360.175L161.95 179.942C161.95 120.561 113.344 71.9732 53.9612 71.9732Z"
          fill={props.fill ?? "#170f11"}
        />
        <path
          d="M485.899 468.027H521.862V540H485.899C427.193 540 374.829 511.521 341.931 467.706C309.016 511.521 256.67 540 197.964 540C99.0053 540 18.0357 459.014 18.0001 360.076L18 179.825C17.9822 160.019 34.1724 143.829 53.9784 143.829C73.7844 143.829 89.9747 160.019 89.9747 179.825L89.9747 360.058C89.9747 419.439 138.528 468.027 197.928 468.027C257.329 468.027 305.918 419.439 305.918 360.058L305.917 179.825C305.935 160.019 322.108 143.829 341.914 143.829C361.72 143.829 377.91 160.019 377.91 179.825L377.91 360.058C377.91 419.439 426.517 468.027 485.899 468.027Z"
          fill={props.fill ?? "#170f11"}
        />
      </g>
    </svg>
  );
})``;
