import Dagre from "@dagrejs/dagre";
import { DecisionResult } from "@dip/data-access/api-types";
import { Edge, Node } from "@xyflow/react";
import { v4 as uuid } from "uuid";
import { AppNode, BaseNodeData, UnderstandingRepresentation } from "./types";

export const REACT_FLOW_NODE_WIDTH = 250;
export const REACT_FLOW_NODE_HEIGHT = 150;

export const convertToReactFlowNodesAndEdges = (
  {
    name,
    question,
    line,
    logical_connective,
    logical_operator,
    sub_conditions,
  }: UnderstandingRepresentation,
  parentNodeId?: string
): { nodes: AppNode[]; edges: Edge[] } => {
  const nodeId = uuid();

  const baseNode: Node<BaseNodeData> = {
    id: nodeId,
    data: {
      label: name,
      question,
      line,
      isNot: logical_operator === "NOT",
      value: DecisionResult.DontKnow, // TODO (CTRL-14): Use the type that comes back from the Acorn API instead
    },
    position: { x: 0, y: 0 },
    style: { width: REACT_FLOW_NODE_WIDTH, height: REACT_FLOW_NODE_HEIGHT },
  };

  const reactFlowNode: AppNode = logical_connective
    ? {
        ...baseNode,
        data: { ...baseNode.data, logicalConnector: logical_connective },
        type: "logicalConnector",
      }
    : { ...baseNode, type: "baseCondition" };

  return (sub_conditions ?? []).reduce<{
    nodes: AppNode[];
    edges: Edge[];
  }>(
    ({ nodes, edges }, subRepresentation) => {
      const subgraphNodesAndEdges = convertToReactFlowNodesAndEdges(
        subRepresentation,
        nodeId
      );

      return {
        nodes: [...nodes, ...subgraphNodesAndEdges.nodes],
        edges: [...edges, ...subgraphNodesAndEdges.edges],
      };
    },
    {
      nodes: [reactFlowNode],
      edges: parentNodeId
        ? [
            {
              id: `${parentNodeId}-${nodeId}`,
              source: parentNodeId,
              target: nodeId,
            },
          ]
        : [],
    }
  );
};

const buildUnderstandingRepresentationForNodeId = (
  nodeId: string,
  nodes: AppNode[],
  edges: Edge[]
): UnderstandingRepresentation | undefined => {
  const node = nodes.find(({ id }) => id === nodeId);
  if (!node) return;

  const { id, data, type } = node;

  const childNodes = edges.reduce<UnderstandingRepresentation[]>(
    (representations, { source, target }) => {
      if (source !== id) return representations;
      const childNodeRepresentation = buildUnderstandingRepresentationForNodeId(
        target,
        nodes,
        edges
      );
      return childNodeRepresentation
        ? [...representations, childNodeRepresentation]
        : representations;
    },
    []
  );

  const { label, question, line, isNot } = data;

  return {
    name: label,
    question,
    line,
    ...(isNot ? { logical_operator: "NOT" } : {}),
    ...(type === "logicalConnector"
      ? { logical_connective: data.logicalConnector }
      : {}),
    ...(childNodes.length ? { sub_conditions: childNodes } : {}),
  };
};

export const convertToUnderstandingRepresentation = (
  nodes: AppNode[],
  edges: Edge[]
) =>
  nodes.reduce<UnderstandingRepresentation[]>((representations, { id }) => {
    // filter out non-root nodes
    if (edges.some(({ target }) => target === id)) return representations;

    const rootRepresentation = buildUnderstandingRepresentationForNodeId(
      id,
      nodes,
      edges
    );

    return rootRepresentation
      ? [...representations, rootRepresentation]
      : representations;
  }, []);

export type LayoutDirection = "TB" | "LR";

export const layoutTree = (
  nodes: AppNode[],
  edges: Edge[],
  direction: LayoutDirection
): { nodes: AppNode[]; edges: Edge[] } => {
  const dagreGraph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    dagreGraph.setNode(node.id, {
      ...node,
      width: REACT_FLOW_NODE_WIDTH,
      height: REACT_FLOW_NODE_HEIGHT,
    })
  );

  Dagre.layout(dagreGraph);

  return {
    nodes: nodes.map((node) => {
      const position = dagreGraph.node(node.id);
      /* The dagre node anchor point (center center) needs to be shifted to the top left
      so it matches the React Flow node anchor point (left top). */
      const x = position.x - REACT_FLOW_NODE_WIDTH / 2;
      const y = position.y - REACT_FLOW_NODE_HEIGHT / 2;

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};
