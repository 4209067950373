import { ComponentProps } from "react";
import { SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { Heading } from "@unlikelyai-magic/ui/typography";

const CloseButton = styled(SecondaryButton)`
  background: none !important;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary.default};

  &:hover,
  &:active,
  &:disabled,
  &:disabled:hover,
  &.ant-btn-loading {
    color: ${({ theme }) => theme.colors.text.secondary.default};
  }
`;

export type ModalHeaderProps = ComponentProps<"div"> & {
  title: string;
  onClose?: () => void;
};

export const ModalHeader = styled(
  ({ title, onClose, ...props }: ModalHeaderProps) => {
    const { closeModal } = useModal();
    return (
      <div {...props}>
        <Dialog.Title as={Heading} level="h5">
          {title}
        </Dialog.Title>
        <div>
          <CloseButton icon={<XMarkIcon />} onClick={onClose || closeModal} />
        </div>
      </div>
    );
  }
)`
  display: flex;
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.xl};

  justify-content: space-between;
  align-items: center;
`;
