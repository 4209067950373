import React, { FunctionComponent } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

type CopyButtonProps = {
  strToCopy: string;
};

export const CopyButton: FunctionComponent<CopyButtonProps> = ({
  strToCopy,
}) => {
  return (
    <CopyToClipboard
      text={strToCopy}
      onCopy={() => message.success("Copied to clipboard")}
    >
      <Button
        type="text"
        icon={<CopyOutlined />}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </CopyToClipboard>
  );
};
