import React, { ReactElement, useEffect, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Spin } from "antd";
import { columnGenerator } from "./StyledTable.helpers";
import {
  Column,
  ColumnDataStyle,
  ColumnsHeaderStyle,
  TableContainerStyle,
} from "./StyledTable.types";

// Styled Elements
const TableContainer = styled("div")<TableContainerStyle>`
  width: 100%;
  max-height: 100%;
  overflow: ${(props) => (props.isLoading ? "hidden" : "auto")};
  position: relative;
`;
const LoadingBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
`;
const LoadingElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const TableElement = styled.table`
  min-width: 100%;
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;
  width: 100%;
`;
const THeadElement = styled.thead();
const TBodyElement = styled("tbody")<ColumnDataStyle>`
  tr {
    transition: background-color 0.2s;
    cursor: ${(props) => (props.hoverEnabled ? "pointer" : undefined)};
    &:hover {
      background: ${(props) => (props.hoverEnabled ? "#e5e9ef" : undefined)};
    }
  }
`;
const TrElement = styled.tr();
const ThElement = styled("th")<ColumnsHeaderStyle>`
  padding: 16px;
  height: 55px;
  background: white;
  text-align: left;
  border-bottom: 1px solid #e0e3e6;
  position: sticky;
  top: 0;
  min-width: ${(props) =>
    props.minWidth ? String(props.minWidth) + "px" : undefined};
  max-width: ${(props) =>
    props.maxWidth ? String(props.maxWidth) + "px" : undefined};
  width: ${(props) => (props.width ? String(props.width) + "px" : undefined)};
  z-index: 3;
  cursor: ${(props) => (props.selectionColumn ? "default" : undefined)};
`;
const TdElement = styled.td`
  padding: 16px;
  height: 55px;
  text-align: left;
  border-bottom: 1px solid #e0e3e6;
  z-index: 1;
  transition: background-color 0.2s;
  cursor: ${(props: { selectionColumn: boolean }) =>
    props.selectionColumn ? "default" : undefined};
`;

/**
 * @typedef StyledTableProps
 * @property data Data to be displayed in the table
 * @property columns Suggestions to be displayed as options in the select element
 * @property selectionEnabled Boolean to represent whether rows can be selected or not
 * @property onRowClick Function to be called when a row is clicked
 * @property onSelectedRowsChanged Function to be called when selected rows are changed
 * @property highlightOnHover Boolean to represent whether a row should be highlighted on hover
 * @property isLoading Boolean to represent whether data has been loaded or not
 */
export interface StyledTableProps<T> {
  data: T[];
  columns: Column<T>[];
  selectionEnabled?: boolean;
  onRowClick?: (event: React.MouseEvent, record: T) => void;
  onSelectedRowsChanged?: (rows: T[]) => void;
  highlightOnHover?: boolean;
  isLoading?: boolean;
}

/**
 * Renders a Table using TanStack react-tables headless library. Makes use of styled
 * emotion css components
 * @param props {StyledTableProps}
 */
export function StyledTable<T>({
  data,
  columns,
  onSelectedRowsChanged,
  onRowClick,
  isLoading,
  selectionEnabled,
  highlightOnHover,
}: StyledTableProps<T>): ReactElement {
  // React Refs
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // State Management
  const [selectedRows, setSelectedRows] = useState({});

  // Data Generation
  const tableColumns = columnGenerator(columns, selectionEnabled);
  const tableData = React.useMemo(() => data, [data]);

  const tableOptions: TableOptions<T> = {
    data: tableData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: selectionEnabled,
    state: {
      rowSelection: selectedRows,
    },
    onRowSelectionChange: setSelectedRows,
  };
  const table = useReactTable(tableOptions);

  // Use Effects
  useEffect(() => {
    setSelectedRows([]);
  }, [tableData]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
      tableContainerRef.current.scrollLeft = 0;
    }
  }, [isLoading]);

  useEffect(() => {
    onSelectedRowsChanged &&
      onSelectedRowsChanged(
        Object.keys(selectedRows).map(
          (rowIndex: string) => tableData[parseInt(rowIndex)]
        )
      );
  }, [selectedRows, onSelectedRowsChanged, tableData]);

  const renderedTable = (
    <TableElement>
      <THeadElement>
        {table.getHeaderGroups().map((headerGroup) => (
          <TrElement key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <ThElement
                key={header.id}
                minWidth={header.column.columnDef.minSize}
                maxWidth={header.column.columnDef.maxSize}
                width={header.column.columnDef.size}
                onClick={
                  header.column.id === "selection"
                    ? (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        table.toggleAllRowsSelected();
                      }
                    : undefined
                }
                selectionColumn={header.column.id === "selection"}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </ThElement>
            ))}
          </TrElement>
        ))}
      </THeadElement>
      <TBodyElement hoverEnabled={highlightOnHover}>
        {table.getRowModel().rows.map((row) => (
          <TrElement
            key={row.id}
            onClick={(event) =>
              onRowClick ? onRowClick(event, data[parseInt(row.id)]) : {}
            }
          >
            {row.getVisibleCells().map((cell) => (
              <TdElement
                key={cell.id}
                onClick={
                  cell.column.id === "selection"
                    ? (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        row.toggleSelected();
                      }
                    : undefined
                }
                selectionColumn={cell.column.id === "selection"}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TdElement>
            ))}
          </TrElement>
        ))}
      </TBodyElement>
    </TableElement>
  );

  const loadingOverlay = (
    <LoadingBody>
      <LoadingElement>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </LoadingElement>
    </LoadingBody>
  );

  // Rendered Element
  return (
    <TableContainer isLoading={isLoading} ref={tableContainerRef}>
      {isLoading ? loadingOverlay : null}
      {renderedTable}
    </TableContainer>
  );
}
