import {
  CreateDomainUnderstandingRequest,
  CreateDomainUnderstandingResponse,
  CreateDomainUnderstandingVersionRunTrainingScenariosRequest,
  CreateFeedbackRequest,
  CreateFeedbackResponse,
} from "@dip/data-access/api-types";
import { dipApi } from "./dipApi";

export const {
  useCreateFeedbackMutation,
  useCreateDomainUnderstandingVersionMutation,
  useCreateRunTrainingScenariosMutation,
} = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeedback: builder.mutation<
      CreateFeedbackResponse,
      CreateFeedbackRequest
    >({
      query: ({ domainId, domainUnderstandingId, ...body }) => ({
        url: `/domains/${domainId}/understandings/${domainUnderstandingId}/feedback`,
        method: "POST",
        body,
      }),
    }),
    createDomainUnderstandingVersion: builder.mutation<
      CreateDomainUnderstandingResponse,
      CreateDomainUnderstandingRequest
    >({
      query: ({ domainId, domainUnderstandingId, ...body }) => ({
        url: `/domains/${domainId}/understandings/${domainUnderstandingId}/versions`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { domainId }) => [
        { type: "Domains", id: domainId },
      ],
    }),
    createRunTrainingScenarios: builder.mutation<
      null,
      CreateDomainUnderstandingVersionRunTrainingScenariosRequest
    >({
      query: ({ domainId, domainUnderstandingId, versionId, ...body }) => ({
        url: `/domains/${domainId}/understandings/${domainUnderstandingId}/versions/${versionId}/run-training-scenarios`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { domainId }) => [
        { type: "Domains", id: domainId },
      ],
    }),
  }),
});
