import { useState } from "react";
import { NodeDto } from "@dip/data-access/api-types";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { Input } from "@dip/ui/components/inputs";
import { Modal } from "@dip/ui/components/modal";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { validate as isUUID } from "uuid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const ErrorContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
`;
const ErrorText = styled(Paragraph)`
  color: inherit;
`;
const ExclamationCircleIconContainer = styled(ExclamationCircleIcon)`
  color: inherit;
  height: 1.5rem;
`;

type DomainNodeDetailsModalProps = {
  node: NodeDto;
  onSubmit: (node: NodeDto) => void;
};

export const ResolvedNodeIdModal = ({
  onSubmit,
  node,
}: DomainNodeDetailsModalProps) => {
  const { closeModal } = useModal();

  const [hasError, setHasError] = useState(false);

  const [nodeId, setNodeId] = useState("");

  const { nickname } = node;

  const handleSubmit = () => {
    if (!nodeId || !isUUID(nodeId)) {
      setHasError(true);
      return;
    }

    onSubmit({
      nodeId: nodeId,
      nickname: nickname,
      description: "",
      resolved: true,
      expressedIn: [],
      principalClass: "",
      specification: "",
    });
  };

  return (
    <Modal>
      <Modal.Header title={nickname} onClose={closeModal} />
      <Modal.Content>
        {hasError && (
          <ErrorContainer gap="xs" direction="horizontal" items="center">
            <ExclamationCircleIconContainer fontSize={4} />
            <ErrorText>Value should be a valid UUID.</ErrorText>
          </ErrorContainer>
        )}
        <Input
          value={nodeId}
          onChange={(e) => {
            setHasError(false);
            setNodeId(e.target.value);
          }}
          placeholder="Enter node id to resolve to"
        />
      </Modal.Content>
      <Modal.Footer>
        <Spacing justify="right" direction="horizontal">
          <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </Spacing>
      </Modal.Footer>
    </Modal>
  );
};
