import { UlElement } from "../ul/ul-element";

interface HashedEntity<T> {
  value: T;
  hash: bigint;
}
enum TranslationTypes {
  VALID = "valid",
  DRAFT = "draft",
}
type NaturalLanguage = HashedEntity<string>;

interface ValidTranslation extends HashedEntity<UlElement> {
  type: TranslationTypes.VALID;
}

interface DraftTranslation {
  type: TranslationTypes.DRAFT;
  value: string;
}

type UniversalLanguageTranslation = ValidTranslation | DraftTranslation;

export type { NaturalLanguage, UniversalLanguageTranslation };
export { TranslationTypes };
