import React from "react";
import { blue } from "@ant-design/colors";
import { Button, Card, List, Space, Typography } from "antd";
import {
  AnswerExplanationDontKnow,
  AnswerVM,
  ResponseTopLevelAnswer,
  SolutionMappingItem,
  isDontKnowAnswer,
  isIsItTrueThatAnswer,
  isWhatAnswer,
} from "../../reasoning-engine/reasoning-engine";
import { millisToString } from "../../reasoning-engine/states";
import { UlSerializer } from "../../ul/UlSerializer";

const { Text } = Typography;
const SolutionCardWhat = ({
  mapping,
  localNodeMappings,
}: {
  mapping: SolutionMappingItem[];
  localNodeMappings: SolutionMappingItem[];
}) => {
  return (
    <List>
      {mapping.map((item, i) => (
        <MappingItem key={i} mapping={item} />
      ))}
      {localNodeMappings.length > 0 && (
        <>
          <List.Item style={{ border: "none" }}>Local Node Mappings:</List.Item>
          {localNodeMappings.map((item, i) => (
            <MappingItem key={i} mapping={item} />
          ))}
        </>
      )}
    </List>
  );
};

const MappingItem = ({
  mapping,
  key,
}: {
  mapping: SolutionMappingItem;
  key: number;
}) => {
  return (
    <List.Item
      key={key}
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <Text>
        <UlSerializer element={mapping.first} truncateUuids={true}>
          {(s) => <Text code>{s}</Text>}
        </UlSerializer>
        {" = "}
        <UlSerializer element={mapping.second} truncateUuids={true}>
          {(s) => <Text code>{s}</Text>}
        </UlSerializer>
      </Text>
    </List.Item>
  );
};

const SolutionCardIsItTrueThat = ({
  outcome,
  localNodeMappings,
}: {
  outcome: string;
  localNodeMappings: SolutionMappingItem[];
}) => {
  return (
    <>
      <p>
        Is it true? <b>{outcome}</b>
      </p>
      <SolutionCardWhat mapping={[]} localNodeMappings={localNodeMappings} />
    </>
  );
};

const SolutionCardDontKnow = ({
  exhaustive,
  localNodeMappings,
}: {
  exhaustive: boolean;
  localNodeMappings: SolutionMappingItem[];
}) => {
  return (
    <p>
      Don't Know. Search was
      <b>{exhaustive ? " exhaustive" : " not exhaustive"}</b>.
      <SolutionCardWhat mapping={[]} localNodeMappings={localNodeMappings} />
    </p>
  );
};

export const makeCardInner = (answer: AnswerVM) => {
  if (isIsItTrueThatAnswer(answer)) {
    return (
      <SolutionCardIsItTrueThat
        outcome={answer.outcome}
        localNodeMappings={answer.localNodeMappings}
      />
    );
  } else if (isWhatAnswer(answer)) {
    return (
      <SolutionCardWhat
        mapping={answer.mapping}
        localNodeMappings={answer.localNodeMappings}
      />
    );
  } else if (isDontKnowAnswer(answer)) {
    return (
      <SolutionCardDontKnow
        exhaustive={
          (answer.explanation as AnswerExplanationDontKnow).exhaustive
        }
        localNodeMappings={answer.localNodeMappings}
      />
    );
  }
};

const SolutionCard = ({
  topLevelAnswer,
  onExplanationRequested,
  onGraphRequested,
}: {
  topLevelAnswer: ResponseTopLevelAnswer;
  onExplanationRequested: () => void;
  onGraphRequested?: () => void;
}) => {
  const selected = false;

  return (
    <Card
      style={{
        ...(selected
          ? { outline: "1px dashed " + blue[8], margin: "2px" }
          : { margin: "2px" }),
      }}
    >
      {makeCardInner(topLevelAnswer.answer)}
      <Space>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onExplanationRequested();
          }}
        >
          Show explanation
        </Button>
        {onGraphRequested && (
          <Button
            onClick={(event) => {
              event.stopPropagation();
              onGraphRequested();
            }}
          >
            Show Graph
          </Button>
        )}
      </Space>

      {topLevelAnswer.timeTakenMs && (
        <Text
          type="secondary"
          style={{
            position: "absolute",
            right: "5px",
            bottom: "5px",
            fontSize: "12px",
          }}
        >
          {millisToString(topLevelAnswer.timeTakenMs)}
        </Text>
      )}
    </Card>
  );
};

export default SolutionCard;
