import {
  CreateDomainDecisionsRequest,
  CreateDomainDecisionsResponse,
} from "@dip/data-access/api-types";
import { dipApi } from "./dipApi";

export const { useCreateDomainDecisionMutation } = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    createDomainDecision: builder.mutation<
      CreateDomainDecisionsResponse,
      CreateDomainDecisionsRequest
    >({
      query: ({ domainId, versionId, ...body }) => ({
        url: `/domains/${domainId}/versions/${versionId}/decisions`,
        method: "POST",
        body,
      }),
    }),
  }),
});
