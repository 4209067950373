import styled from "@emotion/styled";
import { Form, FormItemProps } from "antd";

export const FormItem = styled((props: FormItemProps) => (
  <Form.Item {...props} />
))`
  margin-bottom: 0;

  .ant-form-item-label {
    align-content: center;

    > label {
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
  }
`;
