import React from "react";
import { SourcedExplanation } from "../SourcedExplanation.types";
import { SourcedExplanationCard } from "../SourcedExplanationCard";
import { StatementCardError } from "./StatementCardError";
import { StatementDetailCard } from "./StatementDetailCard";

export const SourcedExplanationPanel = ({
  sourcedExplanation,
  sourcedExplanationError,
  height,
  width,
}: {
  sourcedExplanation?: SourcedExplanation;
  sourcedExplanationError?: string;
  height?: number;
  width?: number;
}) => {
  const SourcedExplanationContent = () => {
    return (
      sourcedExplanation && (
        <SourcedExplanationCard
          nlExplanation={sourcedExplanation.explanationString}
          sources={sourcedExplanation.sourceDictionary}
          maxHeight={height && height - 5}
        />
      )
    );
  };

  return (
    <StatementDetailCard
      height={height}
      width={width}
      title="Explanation"
      isLoading={!sourcedExplanationError && !sourcedExplanation}
    >
      <div style={{ padding: "12px" }}>
        {sourcedExplanationError ? (
          <StatementCardError error={sourcedExplanationError} />
        ) : (
          SourcedExplanationContent()
        )}
      </div>
    </StatementDetailCard>
  );
};
