/**
 * Retries a callback until it succeeds or the maximum number of retries is reached.
 */
export class Retry<T> {
  private readonly callback: () => Promise<T>;
  private readonly onFailure: () => void;
  private readonly delay: number;
  private readonly maxRetries: number;
  private currentTimeout: NodeJS.Timeout | undefined;

  constructor(
    callback: () => Promise<T>,
    onFailure: () => void,
    delay = 1000,
    maxRetries = 3
  ) {
    this.callback = callback;
    this.onFailure = onFailure;
    this.delay = delay;
    this.maxRetries = maxRetries;
  }

  public try(): void {
    if (this.currentTimeout !== undefined) {
      clearTimeout(this.currentTimeout);
      this.currentTimeout = undefined;
    }
    this.runAttempt(1);
  }

  public cancel(): void {
    if (this.currentTimeout !== undefined) {
      clearTimeout(this.currentTimeout);
    }
  }

  private runAttempt(attempt = 1) {
    this.callback().catch(() => {
      if (attempt < this.maxRetries) {
        this.currentTimeout = setTimeout(
          () => this.runAttempt(attempt + 1),
          this.delay
        );
      } else {
        this.onFailure();
      }
    });
  }
}
