import { forwardRef } from "react";
import styled from "@emotion/styled";
import BaseTextArea, { TextAreaProps } from "antd/lib/input/TextArea";

export const TextArea = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => (
    <BaseTextArea {...props} />
  ))
)`
  border: 1px solid ${({ theme }) => theme.colors.component.border["2"]};
  border-radius: ${({ theme }) => theme.roundness.sm};
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};
  &:focus {
    border-color: ${({ theme }) => theme.colors.action.primary.clicked};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.action.primary.default};
  }
`;
