import { ComponentProps, ComponentType, ReactNode } from "react";
import styled from "@emotion/styled";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { Status, defaultStatusConfig } from "./statusConfig";

const StatusBar = styled(
  ({ bannerColor, ...props }: { bannerColor: string } & SpacingProps) => (
    <Spacing {...props} />
  )
)`
  padding: 0.625rem ${({ theme }) => theme.spacings.xl};
  background-color: ${({ bannerColor }) => bannerColor};
  gap: ${({ theme }) => theme.spacings.sm};
`;

const StatusText = styled(Paragraph)`
  line-height: ${({ theme }) => theme.lineHeights.xs};
  overflow-wrap: break-word;
  width: 90%; // TODO(UA-546): Remove the width here
`;

export interface StatusBannerProps {
  status: Status;
  text?: string;
  icon?: ComponentType<ComponentProps<"svg">>;
  color?: string;
  rightItem?: ReactNode;
}

export const StatusBanner = ({
  status,
  text,
  icon,
  color,
  rightItem,
}: StatusBannerProps) => {
  const {
    icon: defaultIcon,
    color: defaultColor,
    text: defaultText,
    backgroundColor,
  } = defaultStatusConfig[status];
  const bannerIcon = icon ?? defaultIcon;
  const iconColor = color ?? defaultColor;
  const bannerText = text ?? defaultText;
  const bannerColor = backgroundColor;

  return (
    <StatusBar
      bannerColor={bannerColor}
      direction="horizontal"
      gap="sm"
      justify="space-between"
      items="center"
    >
      <Icon icon={bannerIcon} color={iconColor} size="1.25rem" />
      <StatusText small>{bannerText}</StatusText>
      {rightItem}
    </StatusBar>
  );
};
