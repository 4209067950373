import { useCallback } from "react";
import {
  Background,
  Controls,
  Edge,
  MiniMap,
  OnConnect,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { AppNode } from "../types";

type LaidOutTreeProps = {
  initialNodes: AppNode[];
  initialEdges: Edge[];
};

const LaidOutTree = ({ initialNodes, initialEdges }: LaidOutTreeProps) => {
  const [nodes, _, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((prevEdges) => addEdge(connection, prevEdges)),
    [setEdges]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      proOptions={{ hideAttribution: true }}
    >
      <Controls />
      <MiniMap />
      <Background gap={12} size={1} />
    </ReactFlow>
  );
};

type DecisionTreeProps = LaidOutTreeProps;

export const DecisionTree = (props: DecisionTreeProps) => (
  <ReactFlowProvider>
    <LaidOutTree {...props} />
  </ReactFlowProvider>
);
