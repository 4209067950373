import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  DomainStatus,
  isErrorWithDataMessage,
} from "@dip/data-access/api-types";
import {
  useCreateDomainUnderstandingVersionMutation,
  useGetDomainQuery,
  useUpdateDomainContentMutation,
} from "@dip/data-access/dip-api-service";
import { Form } from "@dip/ui/components/forms";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { DomainStatusMenu } from "../components";
import { ScenarioStatus } from "../constants";

const UnderstandingContainer = styled(Spacing)`
  height: 100%;
`;

const Sidebar = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.background.default};
  width: 20rem;
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.component.border[1]};

  @media (min-width: ${screenBreakpoints.sm}) {
    display: flex;
  }
`;

/* It seems like styled components messes with generic typing of the Form component.
Using styled(Form) below leads to type errors on the e.g. onFinish and onValuesChange props.
These type errors are coming from the fact that, when used this way, Form does not recognize
the type as the type of form data (which should be inferred from the "form" prop also passed
into the component). The type is unknown instead.

The suspicion is that, if the type of one prop depends on the type of another prop passed into
the same component, we will always have to use this hack, although this assumption has not been verified.
*/
const OutletContainer = styled(({ ...props }) => <Form {...props} />)`
  flex: 1;
`;

export type RulesForm = {
  // TODO (ALL-287): We really shouldn't allow the user to edit the content of the domain directly. A better UX is needed!
  content?: { rules?: string; exclusions?: string };
  representation: string[];
  exclusions: string[];
};

export type OutletContext = {
  createManualDomainUnderstandingVersion: ReturnType<
    typeof useCreateDomainUnderstandingVersionMutation
  >[0];
  updateDomainContent: ReturnType<typeof useUpdateDomainContentMutation>[0];
  currentVersionUserIsEditing?: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
};

export const UnderstandingLayout = () => {
  const { domainId } = useParams();
  const {
    data,
    isLoading: isDomainLoading,
    isFetching: isFetchingDomain,
  } = useGetDomainQuery({ id: domainId || "" }, { skip: !domainId });

  // allows you to edit UL in the Rules and General exclusions pages and preserve edits when navigating between pages
  const [form] = Form.useForm<RulesForm>();
  // TODO (ALL-242): Remove the currentVersionEditing state and the useEffect call below once we decide on a better concurrent editing experience
  const [currentVersionUserIsEditing, setCurrentVersionUserIsEditing] =
    useState(data?.domainVersion);

  const [
    createManualDomainUnderstandingVersion,
    {
      data: newVersionData,
      isLoading: isNewVersionLoading,
      isSuccess: isNewVersionSuccess,
      isError: isCreateNewVersionError,
      error: createNewVersionError,
      reset: resetCreateDomainUnderstandingVersionMutation,
    },
  ] = useCreateDomainUnderstandingVersionMutation();

  /* TODO (ALL-287): We really shouldn't allow the user to edit the content of the domain directly. A better UX is needed!
    When addressed, we need to remove this API call!
    */
  const [
    updateDomainContent,
    {
      data: updateDomainContentData,
      isLoading: isUpdateContentLoading,
      isSuccess: isUpdateContentSuccess,
      isError: isUpdateContentError,
      error: updateContentError,
      reset: resetUpdateDomainContentMutation,
    },
  ] = useUpdateDomainContentMutation();

  // TODO (ALL-242): Remove this useEffect and handle updating the current version in a better way
  useEffect(() => {
    if (!isDomainLoading && data?.domainVersion) {
      setCurrentVersionUserIsEditing(data.domainVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainLoading]);

  // TODO (ALL-242): Remove this useEffect and handle updating the current version and form state in a better way
  useEffect(() => {
    if (!data) {
      return;
    }

    const latestVersion = data.versions.at(-1);

    // After the domain has been fetched, set the new current version only if there is a single version
    // This handles the case of automated domain understanding

    const hasSingleVersion = data.versions.length === 1;
    const hasFetchedSingleVersion = !isFetchingDomain && hasSingleVersion;

    if (hasFetchedSingleVersion && data?.domainVersion) {
      setCurrentVersionUserIsEditing(data.domainVersion);
      // Set the representation and exclusions fields to the latest version's values when the domain is first fetched after understanding
      form.setFieldsValue({
        representation: latestVersion?.representation,
        exclusions: latestVersion?.exclusions,
      });
    }
  }, [isFetchingDomain, data, form]);

  // TODO (ALL-242): Remove this useEffect hook - ensures you can save a domain multiple times locally so long as no one else has edited the same domain in the meantime
  useEffect(() => {
    if (isNewVersionSuccess) {
      setCurrentVersionUserIsEditing(newVersionData.data.domainVersion);
      resetCreateDomainUnderstandingVersionMutation();
    }
    if (isUpdateContentSuccess) {
      setCurrentVersionUserIsEditing(
        updateDomainContentData.data.domainVersion
      );
      resetUpdateDomainContentMutation();
    }
  }, [
    isNewVersionSuccess,
    isUpdateContentSuccess,
    newVersionData?.data.domainVersion,
    resetCreateDomainUnderstandingVersionMutation,
    resetUpdateDomainContentMutation,
    updateDomainContentData?.data.domainVersion,
  ]);

  const isLoading =
    isFetchingDomain || isNewVersionLoading || isUpdateContentLoading;

  const createNewVersionErrorMessage = isCreateNewVersionError
    ? isErrorWithDataMessage(createNewVersionError)
      ? createNewVersionError.data.message
      : "An unexpected error occurred"
    : "";

  const updateContentErrorMessage = isUpdateContentError
    ? isErrorWithDataMessage(updateContentError)
      ? updateContentError.data.message
      : "An unexpected error occurred"
    : "";

  const isError = isCreateNewVersionError || isUpdateContentError;
  const errorMessage = createNewVersionErrorMessage + updateContentErrorMessage;

  if (!data) return <PageSpinner />;

  const latestVersion = data.versions.at(-1);
  const domainStatus = data.status;

  const scenarios = data.trainingScenarios.map(({ id, solution }, index) => {
    const getStatus = () => {
      const latestScenarioAnswer =
        latestVersion?.trainingScenarioDecisions.find(
          (decision) => decision.trainingScenarioId === id
        )?.decision.result;
      switch (true) {
        case domainStatus === DomainStatus.GENERATING_UNDERSTANDING ||
          domainStatus === DomainStatus.FAILED_UNDERSTANDING ||
          domainStatus === DomainStatus.TRAINING_SCENARIOS_NOT_RUN:
          return ScenarioStatus.DISABLED;
        case domainStatus === DomainStatus.RUNNING_TRAINING_SCENARIOS:
          return ScenarioStatus.RUNNING;
        case latestScenarioAnswer === solution.answer:
          return ScenarioStatus.SUCCESS;
        default:
          return ScenarioStatus.WRONG_ANSWER;
      }
    };
    return {
      id: id ?? "",
      name: "Scenario " + (index + 1),
      status: getStatus(),
    };
  });

  return (
    <UnderstandingContainer direction="horizontal" gap="none">
      <Sidebar>
        <DomainStatusMenu
          domain={data}
          scenarios={scenarios}
          hasExclusions={Boolean(data.content.exclusions)}
          errorMessage={data.error} // TODO(UA-546): Remove when we "launch" DIP
        />
      </Sidebar>
      <OutletContainer
        form={form}
        initialValues={{
          // TODO (ALL-287): We really shouldn't allow the user to edit the content of the domain directly. A better UX is needed!
          content: {
            rules: data.content.rules ?? "",
            exclusions: data.content.exclusions ?? "",
          },
          representation: data.versions.at(-1)?.representation,
          exclusions: data.versions.at(-1)?.exclusions,
        }}
      >
        <Outlet
          context={{
            createManualDomainUnderstandingVersion,
            updateDomainContent,
            currentVersionUserIsEditing,
            isLoading,
            isError,
            errorMessage,
          }}
        />
      </OutletContainer>
    </UnderstandingContainer>
  );
};
