import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";

export const CardBody = styled((props: ComponentProps<"div">) => (
  <div {...props} />
))`
  padding: 1rem;

  @media (min-width: ${screenBreakpoints.sm}) {
    padding: 1.5rem;
  }

  &:not(:nth-of-type(1)) {
    padding-top: 0;
  }

  &:not(:nth-last-of-type(1)) {
    padding-bottom: 0;
  }
`;
