import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import {
  useCreateDomainDecisionMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { PromptInput } from "@dip/ui/components/inputs";
import styled from "@emotion/styled";
import { MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { ExplanationWidget } from "./ExplanationWidget";

type InputProps = {
  placeholder?: string;
  className?: string;
};

const Input = ({ placeholder, className }: InputProps = {}) => {
  const { domainId } = useParams();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );
  const domainUnderstanding = data?.understandings[0];
  const versions = domainUnderstanding?.versions;
  const latestVersion = versions ? versions[versions.length - 1] : null;

  const messagesState = useChat();
  const { pushMessage, updateMessage } = messagesState;

  const [createDecisions] = useCreateDomainDecisionMutation();
  const { numOfRefinements } = useSettingsSidebar();

  const handleSendUserMessage = async (text: string) => {
    if (!data) return;
    if (!domainUnderstanding) return;
    if (!latestVersion) return;

    const normalisedText = text.trim();
    if (!normalisedText) return;
    pushMessage({
      author: MessageAuthor.User,
      text: normalisedText,
    });

    const botMessageId = pushMessage({
      author: MessageAuthor.Bot,
      text: "",
    });

    try {
      const result = await createDecisions({
        domainId: data.id,
        domainUnderstandingId: domainUnderstanding.id,
        versionId: latestVersion.id,
        decisions: [
          {
            question: normalisedText,
            refinements: numOfRefinements,
          },
        ],
      }).unwrap();

      const { id, decision } = result.data[0];

      updateMessage({
        id: botMessageId,
        text: decision.result ?? "Something went wrong, please try again",
        widget: decision.explanation ? (
          <ExplanationWidget
            decisionId={id}
            messageId={botMessageId}
            explanation={decision.explanation}
            debugLogId={decision.debugInfo}
          />
        ) : null,
      });
    } catch (err) {
      updateMessage({
        id: botMessageId,
        text: "Something went wrong, please try again",
      });
    }
  };

  return (
    <PromptInput
      className={className}
      placeholder={placeholder ?? "Ask a question..."}
      onSubmit={handleSendUserMessage}
      maxRows={7}
    />
  );
};

export const MessageInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.component.background[1]};
  border-radius: ${({ theme }) => theme.roundness.md};
  border: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
`;
