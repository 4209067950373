import styled, { CSSObject } from "@emotion/styled";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";

type ModalContentProps = SpacingProps & {
  scroll?: boolean;
};

export const ModalContent = styled((props: ModalContentProps) => (
  <Spacing direction="vertical" {...props} />
))`
  display: flex;
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.xl};
  position: relative;
  flex: 1;

  ${({ scroll }): CSSObject => (scroll ? { overflowY: "auto" } : {})};
`;
