import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";

const CardTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const Description = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export type CardHeaderProps = ComponentProps<"div"> & {
  title?: string;
  description?: string;
};

export const CardHeader = styled(
  ({ title, description, children, ...props }: CardHeaderProps) => (
    <div {...props}>
      {title && <CardTitle level="h3">{title}</CardTitle>}
      {description && <Description>{description}</Description>}
      {children}
    </div>
  )
)`
  padding: 1rem;

  @media (min-width: ${screenBreakpoints.sm}) {
    padding: 1.5rem;
  }
`;
