import { ComponentProps } from "react";
import { NodeEntry } from "slate";
import { Editable } from "slate-react";
import { DecorationEditor } from "../types";

export type DecorateFn = Exclude<
  ComponentProps<typeof Editable>["decorate"],
  undefined
>;
export type DecorateFnWithEditor = (editor: DecorationEditor) => DecorateFn;

export const decorate = (decorations: DecorateFn[]) => (nodeEntry: NodeEntry) =>
  decorations.flatMap((decoration) => decoration(nodeEntry));
