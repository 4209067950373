import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { CloseCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { TitleModal } from "@dip/ui/components/modal";
import { ColumnProps, Table } from "@dip/ui/components/table";
import styled from "@emotion/styled";
import { MinusIcon } from "@heroicons/react/24/outline";
import { Button, Form, Select } from "antd";
import { v4 as uuid } from "uuid";
import { DangerButton } from "@unlikelyai-magic/ui/buttons";
import { Input } from "@unlikelyai-magic/ui/inputs";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Modal, useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const AnswerSelect = styled(Select<string>)`
  .ant-select-selector {
    padding-left: 0 !important;
  }
`;

export type ExampleRow = {
  key: string;
  problem: string;
  answer: string;
  reasoning: string;
};

export interface ExamplesTableProps {
  rows: ExampleRow[];
  setRows: Dispatch<SetStateAction<ExampleRow[]>>;
  isDisabled?: boolean;
  onGoBack: () => void;
}

export const ExamplesTable = ({
  rows,
  setRows,
  isDisabled = false,
  onGoBack,
}: ExamplesTableProps) => {
  const form = Form.useFormInstance();
  const { openModal, closeAllModals } = useModal();

  const { Option } = Select;

  const handleInputChange = useCallback(
    (rowIndex: number, field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setRows((prevData) => [
        ...prevData.slice(0, rowIndex),
        { ...prevData[rowIndex], [field]: e.target.value },
        ...prevData.slice(rowIndex + 1),
      ]);
    },
    [setRows]
  );

  const columns: ColumnProps<ExampleRow>[] = useMemo(
    () => [
      {
        header: "Problem",
        accessorKey: "problem",
        flex: 1,
        cell: ({ getValue, row }) => (
          <Input
            value={getValue<string>()}
            onChange={handleInputChange(row.index, "problem")}
          />
        ),
      },
      {
        header: "Answer",
        accessorKey: "answer",
        size: 180,
        fixedWidth: true,
        cell: ({ getValue, row }) => {
          const currentValue = getValue<string>();
          return (
            <AnswerSelect
              dropdownStyle={{ borderRadius: 10 }}
              placeholder="Yes / No / Don't Know"
              bordered={false}
              value={currentValue === "" ? undefined : currentValue}
              onSelect={(value) =>
                setRows((prevData) => [
                  ...prevData.slice(0, row.index),
                  { ...prevData[row.index], answer: value },
                  ...prevData.slice(row.index + 1),
                ])
              }
            >
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="DontKnow">Don't Know</Option>
            </AnswerSelect>
          );
        },
      },
      {
        header: "Reasoning",
        accessorKey: "reasoning",
        flex: 1,
        cell: ({ getValue, row }) => (
          <Input
            value={getValue<string>()}
            onChange={handleInputChange(row.index, "reasoning")}
          />
        ),
      },
      {
        id: "actions",
        header: "",
        size: 75,
        fixedWidth: true,
        justifyCellContent: "center",
        cell: ({ row }) => (
          <DangerButton
            icon={<MinusIcon />}
            onClick={() => {
              setRows((prevData) => {
                const newData = [...prevData];
                newData.splice(row.index, 1);
                return newData;
              });
            }}
            style={{
              backgroundColor: "white",
              color: "red",
              borderColor: "red",
              borderRadius: 0,
              width: "20px",
              height: "20px",
              padding: 0,
            }}
          />
        ),
      },
    ],
    [Option, handleInputChange, setRows]
  );

  const newRow: ExampleRow = {
    key: uuid(),
    problem: "",
    answer: "",
    reasoning: "",
  };

  return (
    <Spacing gap="2xl">
      <Table
        verticalCellBorders={true}
        verticalHeaderBorders={true}
        isTableDisabled={isDisabled}
        title={
          <Spacing
            direction="horizontal"
            justify="space-between"
            items="center"
          >
            <Paragraph>{`${rows.length} example${
              rows.length === 1 ? "" : "s"
            }`}</Paragraph>
            <Spacing direction="horizontal">
              <Button
                icon={<PlusSquareOutlined />}
                type="text"
                onClick={() => {
                  setRows((prevData) => {
                    const newData = [...prevData, { ...newRow, key: uuid() }];
                    form.setFieldValue("examples", newData);
                    return newData;
                  });
                }}
              >
                Add row
              </Button>
              <Button
                danger
                icon={<CloseCircleOutlined />}
                type="text"
                onClick={() => {
                  setRows([]);
                  form.setFieldValue("examples", []);
                }}
              >
                Delete all
              </Button>
            </Spacing>
          </Spacing>
        }
        columns={columns}
        data={rows}
        maxContentHeight="calc(100vh - 31rem)"
      />
      {/* TODO: Move to ExamplesStep component */}
      <Modal.Footer style={{ margin: 0, padding: 0 }}>
        <Spacing
          direction="horizontal"
          justify="space-between"
          style={{ width: "100%" }}
        >
          <SecondaryButton onClick={onGoBack}>Back</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              openModal(
                <TitleModal
                  form={form}
                  onConfirm={() => {
                    form.setFieldValue("examples", rows);
                    form.submit();
                    closeAllModals();
                  }}
                />
              );
            }}
          >
            Continue
          </PrimaryButton>
        </Spacing>
      </Modal.Footer>
    </Spacing>
  );
};
