import { ComponentType, ReactNode, SVGProps } from "react";
import { Dropdown, MenuItemProps } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

type ItemLabelProps =
  | {
      text: string;
      icon?: ComponentType<SVGProps<SVGSVGElement>>;
    }
  | {
      text?: string;
      icon: ComponentType<SVGProps<SVGSVGElement>>;
    };

type Action = ItemLabelProps & {
  onClick: MenuItemProps["onClick"];
  danger?: boolean;
  disabled?: boolean;
};

type ActionsMenuProps = {
  actions: Action[];
  children: ReactNode;
};

const ItemLabel = ({ text, icon }: ItemLabelProps) => (
  <Spacing direction="horizontal" gap="xs">
    {icon && <Icon icon={icon} />}
    {text}
  </Spacing>
);

export const ActionsMenu = ({ actions, children }: ActionsMenuProps) => {
  const items = actions.map(
    ({ onClick, danger, disabled, ...action }, index) => ({
      key: index,
      label: <ItemLabel {...action} />,
      onClick,
      danger,
      disabled,
    })
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      overlayStyle={{ maxHeight: "50vh", overflowY: "auto" }}
    >
      {children}
    </Dropdown>
  );
};
