import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";

export type CardFooterProps = ComponentProps<"div">;

export const CardFooter = styled((props: CardFooterProps) => (
  <div {...props} />
))`
  padding: 1rem;

  @media (min-width: ${screenBreakpoints.sm}) {
    padding: 1.5rem;
  }
`;
