import {
  ClipboardEvent,
  ComponentProps,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useState,
} from "react";
import styled from "@emotion/styled";
import { PaintBrushIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import { Editor, Node, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { SecondaryButton } from "@unlikelyai-magic/ui/buttons";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { DecorateFnWithEditor, decorate } from "@unlikelyai-magic/utils";

const StyledEditor = styled(Editable)<{
  disabled?: boolean;
  readOnly?: boolean;
}>`
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.roundness.sm};
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};
  outline: none;
  pointer-events: ${({ disabled }) => disabled && "none"};

  &:focus {
    border-color: ${({ disabled, readOnly, theme }) =>
      !disabled && !readOnly && theme.colors.component.border.dark};
    box-shadow: ${({ disabled, readOnly, theme }) =>
      !disabled && !readOnly && theme.shadows.md};
  }

  &:hover {
    border-color: ${({ readOnly, theme }) =>
      !readOnly && theme.colors.component.border.dark};
  }
`;

// TODO (ALL-247): Make this button more flexible
const FormatButton = styled(
  forwardRef<HTMLButtonElement, ComponentProps<typeof SecondaryButton>>(
    (props, ref) => <SecondaryButton {...props} />
  )
)`
  position: absolute;
  right: ${({ theme }) => theme.spacings.sm};
  top: ${({ theme }) => theme.spacings.sm};
`;

const TAB_SPACES = 2;

type TextBoxContentProps = {
  // needed to style the editor in calling components that override styles via styled components
  className?: ComponentProps<typeof Editable>["className"];
  decorations?: DecorateFnWithEditor[];
  disabled?: ComponentProps<typeof Editable>["disabled"];
  onBlur?: ComponentProps<typeof Editable>["onBlur"];
  onValueChange?: ComponentProps<typeof Slate>["onValueChange"];
  readOnly?: ComponentProps<typeof Editable>["readOnly"];
  renderLeaf?: ComponentProps<typeof Editable>["renderLeaf"];
  format?: (text: string, tabSpaces?: number) => string;
  value: string;
};

export const TextBoxContent = ({
  value,
  onValueChange,
  format,
  decorations,
  readOnly = true,
  ...editableProps
}: TextBoxContentProps) => {
  const [editor] = useState(() => withReact(withHistory(createEditor())));

  /* Slate does not automatically update the editor value when the value prop changes, so this needs to be done manually.
  However, this should only be done when the text box is read-only because Slate does not really support controlled components,
  which means if a calling component and a user both try to update the same text box at the same time, it could cause chaos. */
  useEffect(() => {
    if (!readOnly) return;

    const newValueNode: Node[] = [
      { type: "paragraph", children: [{ text: value }] },
    ];
    Transforms.delete(editor, { at: [0] });
    Transforms.insertNodes(editor, newValueNode);
  }, [value, editor, readOnly]);

  const handleFormat = () => {
    const { children } = editor;
    if (!children || children.length === 0 || !format) return;

    // Extract all the text content from the editor
    const fullText = children.map((node) => Node.string(node)).join("\n");
    const formattedText = format(fullText, TAB_SPACES);

    Transforms.insertText(editor, formattedText, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
  };

  // paste events do not automatically trigger onValueChange, so this case needs to be handled manually
  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");

    if (text) {
      const formattedText = format?.(text, TAB_SPACES) ?? text;
      Transforms.insertText(editor, formattedText);
      onValueChange?.(editor.children);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Prevent adding a new block of text on "Enter". Instead, append text to the existing block
    if (event.key === "Enter") {
      event.preventDefault();
      Transforms.insertText(editor, "\n");
    }

    // Allow tabbing inside of a block
    if (event.key === "Tab") {
      event.preventDefault();
      Transforms.insertText(editor, " ".repeat(TAB_SPACES));
    }

    // Format on Cmd + Shift + F (Mac) or Ctrl + Shift + F (Windows/Linux)
    if (
      (event.metaKey || event.ctrlKey) &&
      event.shiftKey &&
      event.key === "f"
    ) {
      event.preventDefault();
      handleFormat();
    }
  };

  const decorationsAfterEditorApplied = decorations?.map((decorationFn) =>
    decorationFn(editor)
  );

  return (
    <Slate
      editor={editor}
      initialValue={[{ type: "paragraph", children: [{ text: value }] }]}
      onValueChange={onValueChange}
    >
      <StyledEditor
        readOnly={readOnly}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        decorate={
          decorationsAfterEditorApplied &&
          decorate(decorationsAfterEditorApplied)
        }
        {...editableProps}
      />
      {format && (
        // TODO (ALL-247): The caller should be able to pass whatever component they want to be used for formatting. The only requirement should be that said component should have an onClick handler.
        <Tooltip title="Format">
          <FormatButton
            icon={<Icon icon={PaintBrushIcon} />}
            onClick={handleFormat}
          />
        </Tooltip>
      )}
    </Slate>
  );
};
