import { useOutletContext, useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import {
  DocumentContentDto,
  FeedbackRequestedFrom,
  NodeDto,
} from "@dip/data-access/api-types";
import {
  RealtimeChannel,
  useAppSelector,
  useCreateFeedbackMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { Form } from "@dip/ui/components/forms";
import { PromptInput } from "@dip/ui/components/inputs";
import { Popover } from "@dip/ui/components/popover";
import { PageSpinner, Spinner } from "@dip/ui/components/spinners";
import { Tabs, TabsProps } from "@dip/ui/components/tabs";
import { CodeTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { ChannelProvider } from "ably/react";
import _ from "lodash";
import moment from "moment";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { UnderstandingLoadingCard } from "../UnderstandingLoadingCard";
import { OutletContext, RulesForm } from "../layouts";
import { Description } from "./Description";
import { DomainNodes } from "./DomainNodes";
import { ULFieldName, ULTabContent } from "./ULTabContent";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  // 3rem is the size of the header bar
  height: calc(100vh - 3rem);

  @media (min-width: ${screenBreakpoints.lg}) {
    grid-template-columns: 0.5fr 1fr;
  }
`;

const Representation = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
  /* TODO: We probably shouldn't use vh here, but this appears to be the only height setting that fixes the height when changing tabs.
  https://linear.app/unlikelyai/issue/UA-402/ul-tab-creates-a-scroll-on-the-whole-page-when-content-overflows */
  height: calc(100vh - 3rem);
`;

const RepresentationTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled: boolean }>`
  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: 0 1rem;
  }

  .ant-tabs-content-holder {
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.component.background[1]};
    opacity: ${({ isDisabled }) => isDisabled && "0.5"};
  }

  height: 100%;
  overflow: hidden;
`;

const NLContainer = styled.div`
  padding-top: 0.75rem;
  padding-left: 0.25rem;
`;

const UnsavedChangesDot = styled(Paragraph)`
  font-size: 3rem;
`;

const ProvideFeedbackInput = styled(PromptInput)`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
`;

const UpdatedAt = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.lg};
`;

const SuccessOrErrorIconContainer = styled(Spacing)`
  padding-right: 1rem;
`;

interface TopLevelLayoutProps {
  descriptionFieldName: keyof DocumentContentDto;
  naturalLanguageFieldName: "exclusionsCommentary" | "representationCommentary";
  universalLanguageFieldName: ULFieldName;
}

export const TopLevelLayout = ({
  descriptionFieldName,
  naturalLanguageFieldName,
  universalLanguageFieldName,
}: TopLevelLayoutProps) => {
  const { domainId } = useParams();
  const tenantId = useAppSelector(({ auth }) => auth.tenantId);
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  const [createFeedback] = useCreateFeedbackMutation();

  const form = Form.useFormInstance<RulesForm>();

  // TODO (ALL-242): Remove the currentVersionEditing state and the useEffect call below once we decide on a better concurrent editing experience
  const {
    isLoading,
    isError,
    errorMessage,
    currentVersionUserIsEditing,
    createManualDomainUnderstandingVersion,
    updateDomainContent,
  } = useOutletContext<OutletContext>();

  const { numOfRefinements, resolveNewNodesInManualUpdate } =
    useSettingsSidebar();

  const versions = data?.versions;
  const latestVersion = versions?.[versions.length - 1];
  const showFeedbackInput =
    // If feedback is requested from a human, show the input
    latestVersion?.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN ||
    // If we are done understanding, we should still show the input because
    // the user may still have feedback to apply.
    latestVersion?.feedbackRequestedFrom === null;

  const handleFeedback = async (value: string) => {
    if (!data) return;

    await createFeedback({
      feedback: value,
      domainId: data.id,
      refinements: numOfRefinements,
    });
  };

  const handleUpdate = ({ nodes }: { nodes?: NodeDto[] } = {}) => {
    if (!data) return;

    /* We need to get both the mounted and unmounted fields in the form (b/c both the understanding and general exclusions
    page will not be mounted at the same time). By default, AntD only returns the mounted fields, e.g. onFinish, onWatch, etc.
    This was the only way I could find to get all the mounted and unmounted fields when executing this function. */
    const { representation, exclusions } = form.getFieldsValue(true);

    createManualDomainUnderstandingVersion({
      domainId: data.id,
      // TODO (ALL-242): This should probably be data.version once we remove the currentVersionEditing state?
      domainVersion: currentVersionUserIsEditing ?? 0,
      representation: representation ?? latestVersion?.representation ?? [],
      exclusions: exclusions ?? latestVersion?.exclusions ?? [],
      nodes: nodes ?? latestVersion?.nodes ?? [],
      resolveNewNodes: resolveNewNodesInManualUpdate,
    });
  };

  const hasUnsavedULChanges = !_.isEqual(
    latestVersion?.[universalLanguageFieldName] ?? [],
    Form.useWatch([], { form, preserve: true })?.[universalLanguageFieldName]
  );

  const tabItems: TabsProps["items"] = [
    {
      key: "0",
      label: (
        <Spacing direction="horizontal" items="center" gap="sm">
          <Paragraph small>Natural language</Paragraph>
          {isLoading && <Spinner size="1.25rem" />}
        </Spacing>
      ),
      children: (
        <NLContainer>
          {(latestVersion?.[naturalLanguageFieldName] ?? []).map((rule, i) => (
            <CodeTextBox key={i} content={`Rule ${i + 1}: ${rule}`} />
          ))}
          {isLoading && <Spinner size="1.25rem" />}
        </NLContainer>
      ),
    },
    {
      key: "1",
      label: (
        <Spacing direction="horizontal" items="center" gap="sm">
          <Paragraph small>Universal language</Paragraph>
          {isLoading ? (
            <Spinner size="1.25rem" />
          ) : (
            hasUnsavedULChanges && <UnsavedChangesDot>·</UnsavedChangesDot>
          )}
        </Spacing>
      ),
      children: domainId && (
        <ULTabContent
          fieldName={universalLanguageFieldName}
          onSave={handleUpdate}
          isDisabled={isLoading}
        />
      ),
    },
    {
      key: "2",
      label: (
        <Spacing direction="horizontal" items="center" gap="sm">
          <Paragraph small>Nodes</Paragraph>
          {isLoading && <Spinner size="1.25rem" />}
        </Spacing>
      ),
      children: (
        <DomainNodes
          nodes={latestVersion?.nodes ?? []}
          onUpdate={(nodes) => handleUpdate({ nodes })}
          isLoading={isLoading}
        />
      ),
    },
  ];

  if (!data) return <PageSpinner />;

  return (
    <Layout>
      <Description
        domainId={domainId}
        versionId={currentVersionUserIsEditing}
        currentDescription={data.content[descriptionFieldName] ?? ""}
        descriptionFieldName={descriptionFieldName}
        updateDomainContent={updateDomainContent}
        isDisabled={isLoading}
      />
      {latestVersion ? (
        <Representation>
          <RepresentationTabs
            items={tabItems}
            isDisabled={isLoading}
            tabBarStyle={{ margin: 0 }}
            tabBarExtraContent={{
              right: (
                <SuccessOrErrorIconContainer>
                  <Popover
                    content={errorMessage}
                    showArrow={false}
                    placement="bottomRight"
                    overlayStyle={{ padding: "0 1rem" }}
                  >
                    <div>
                      <Icon
                        icon={isError ? MinusCircleIcon : CheckCircleIcon}
                        size="1.25rem"
                        color={
                          isError
                            ? theme.colors.semantic.error.default
                            : theme.colors.semantic.success.default
                        }
                      />
                    </div>
                  </Popover>
                </SuccessOrErrorIconContainer>
              ),
            }}
          />
          {showFeedbackInput && (
            <ProvideFeedbackInput
              placeholder="Provide feedback..."
              placeholderColor={theme.colors.text.primary.default}
              onSubmit={handleFeedback}
            />
          )}
          <UpdatedAt small>{`Last updated ${moment(
            new Date(latestVersion.dateCreated)
          ).fromNow()}`}</UpdatedAt>
        </Representation>
      ) : (
        domainId && (
          <ChannelProvider
            channelName={`${tenantId}:${RealtimeChannel.PIPELINE}:${domainId}`}
            /* Get the previous Ably message so we know which state the domain understanding is in. This ensures
              the backend does not have to write the status of the domain to the database each time the status
              changes. The change in status is already encoded in Ably, and so we can simply pull it from there. */
            options={{ params: { rewind: "1" } }}
          >
            <UnderstandingLoadingCard domainId={domainId} />
          </ChannelProvider>
        )
      )}
    </Layout>
  );
};
