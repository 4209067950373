import React from "react";
import { Slider } from "antd";

export const REASONING_EFFORT_VALUES = [
  "NONE",
  "LOW",
  "MEDIUM",
  "HIGH",
  "MEGA_HIGH",
] as const;

export type ReasoningEffort = (typeof REASONING_EFFORT_VALUES)[number];

type ReasoningEffortPickerProps = {
  value: ReasoningEffort;
  onChange: (value: ReasoningEffort) => void;
};
export const ReasoningEffortPicker = ({
  value,
  onChange,
}: ReasoningEffortPickerProps) => {
  return (
    <div style={{ padding: "0 20px" }}>
      <Slider
        min={0}
        max={REASONING_EFFORT_VALUES.length - 1}
        value={REASONING_EFFORT_VALUES.indexOf(value)}
        onChange={(v: number) => onChange(REASONING_EFFORT_VALUES[v])}
        marks={Object.fromEntries(
          REASONING_EFFORT_VALUES.map((v, i) => [i, <p key={i}>{v}</p>])
        )}
        tipFormatter={null}
        style={{
          minWidth: "30em", // The number of characters in all the REASONING_EFFORT_VALUES plus A Bit MoreTM
        }}
      />
    </div>
  );
};
