import { PropsWithChildren, createContext, useState } from "react";

export type SettingsSidebarState = {
  numOfRefinements: number;
  setNumOfRefinements: (value: number) => void;
  showAnsweringUL: boolean;
  setShowAnsweringUL: (value: boolean) => void;
  resolveNewNodesInManualUpdate: boolean;
  setResolveNewNodesInManualUpdate: (value: boolean) => void;
};

const NUM_OF_REFINEMENTS_KEY = "num-of-refinements";
const SHOW_ANSWERING_UL_KEY = "show-answering-ul";
const RESOLVE_NEW_NODES_IN_MANUAL_UPDATE_KEY =
  "resolve-new-nodes-in-manual-update";

export const useLocalStorage = <T,>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as const;
};

export const SettingsSidebarContext = createContext<SettingsSidebarState>(
  {} as SettingsSidebarState
);

export const SettingsSidebarProvider = ({ children }: PropsWithChildren) => {
  const [numOfRefinements, setNumOfRefinements] = useLocalStorage(
    NUM_OF_REFINEMENTS_KEY,
    5
  );
  const [showAnsweringUL, setShowAnsweringUL] = useLocalStorage(
    SHOW_ANSWERING_UL_KEY,
    false
  );
  const [resolveNewNodesInManualUpdate, setResolveNewNodesInManualUpdate] =
    useLocalStorage(RESOLVE_NEW_NODES_IN_MANUAL_UPDATE_KEY, true);

  return (
    <SettingsSidebarContext.Provider
      value={{
        numOfRefinements,
        setNumOfRefinements,
        showAnsweringUL,
        setShowAnsweringUL,
        resolveNewNodesInManualUpdate,
        setResolveNewNodesInManualUpdate,
      }}
    >
      {children}
    </SettingsSidebarContext.Provider>
  );
};
