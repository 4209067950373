import { ComponentProps } from "react";

export const UndrawBooked = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="84"
      height="64"
      viewBox="0 0 84 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1760_40)">
        <path
          d="M48.3732 63.814C48.3611 63.794 48.0716 63.3108 47.9712 62.3074C47.8792 61.3869 47.9383 59.8353 48.7431 57.6709C50.2675 53.5706 48.3917 50.2624 48.3726 50.2294L48.4651 50.1757C48.8262 50.8669 49.0871 51.606 49.24 52.3707C49.6178 54.1514 49.4802 56.0029 48.8434 57.7083C47.3216 61.8015 48.453 63.7392 48.4645 63.7584L48.3732 63.814Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.777 49.9887C48.161 49.9887 48.4723 49.6775 48.4723 49.2935C48.4723 48.9095 48.161 48.5982 47.777 48.5982C47.3931 48.5982 47.0818 48.9095 47.0818 49.2935C47.0818 49.6775 47.3931 49.9887 47.777 49.9887Z"
          fill="#F2F2F2"
        />
        <path
          d="M49.9697 52.5557C50.3536 52.5557 50.6649 52.2444 50.6649 51.8604C50.6649 51.4764 50.3536 51.1652 49.9697 51.1652C49.5857 51.1652 49.2744 51.4764 49.2744 51.8604C49.2744 52.2444 49.5857 52.5557 49.9697 52.5557Z"
          fill="#F2F2F2"
        />
        <path
          d="M48.4721 54.267C48.8561 54.267 49.1673 53.9558 49.1673 53.5718C49.1673 53.1878 48.8561 52.8765 48.4721 52.8765C48.0881 52.8765 47.7769 53.1878 47.7769 53.5718C47.7769 53.9558 48.0881 54.267 48.4721 54.267Z"
          fill="#F2F2F2"
        />
        <path
          d="M50.2905 55.711C50.6744 55.711 50.9857 55.3997 50.9857 55.0157C50.9857 54.6317 50.6744 54.3205 50.2905 54.3205C49.9065 54.3205 49.5952 54.6317 49.5952 55.0157C49.5952 55.3997 49.9065 55.711 50.2905 55.711Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.9374 57.957C48.3214 57.957 48.6327 57.6458 48.6327 57.2618C48.6327 56.8778 48.3214 56.5665 47.9374 56.5665C47.5535 56.5665 47.2422 56.8778 47.2422 57.2618C47.2422 57.6458 47.5535 57.957 47.9374 57.957Z"
          fill="#F2F2F2"
        />
        <path
          d="M48.7931 63.8394C48.7931 63.8394 48.0979 62.1281 50.1836 60.8445L48.7931 63.8394Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.9381 63.8086C47.9381 63.8086 47.6217 61.9888 45.1729 62.0044L47.9381 63.8086Z"
          fill="#F2F2F2"
        />
        <path
          d="M11.9071 13.2365C11.3512 13.2365 10.8078 13.0717 10.3456 12.7628C9.88337 12.454 9.52312 12.015 9.31039 11.5015C9.09765 10.9879 9.04199 10.4227 9.15044 9.87753C9.25889 9.33231 9.52658 8.8315 9.91966 8.43842C10.3127 8.04534 10.8136 7.77765 11.3588 7.6692C11.904 7.56075 12.4691 7.61641 12.9827 7.82915C13.4963 8.04188 13.9352 8.40213 14.2441 8.86434C14.5529 9.32655 14.7178 9.86996 14.7178 10.4259C14.7178 11.1713 14.4216 11.8862 13.8945 12.4133C13.3674 12.9404 12.6525 13.2365 11.9071 13.2365ZM11.9071 7.7122C11.3704 7.7122 10.8457 7.87136 10.3994 8.16955C9.95314 8.46774 9.6053 8.89158 9.3999 9.38746C9.19451 9.88333 9.14076 10.429 9.24547 10.9554C9.35018 11.4818 9.60865 11.9654 9.98817 12.3449C10.3677 12.7244 10.8513 12.9829 11.3777 13.0876C11.9041 13.1923 12.4497 13.1386 12.9456 12.9332C13.4415 12.7278 13.8653 12.3799 14.1635 11.9337C14.4617 11.4874 14.6209 10.9627 14.6209 10.426C14.6209 10.0696 14.5507 9.7167 14.4143 9.38745C14.2779 9.0582 14.078 8.75904 13.826 8.50704C13.574 8.25504 13.2749 8.05515 12.9456 7.91877C12.6164 7.78239 12.2635 7.7122 11.9071 7.7122Z"
          fill="#3F3D56"
        />
        <path
          d="M40.3988 13.2365C39.8429 13.2365 39.2995 13.0717 38.8373 12.7628C38.3751 12.454 38.0148 12.015 37.8021 11.5015C37.5894 10.9879 37.5337 10.4227 37.6421 9.87753C37.7506 9.33231 38.0183 8.8315 38.4114 8.43842C38.8044 8.04534 39.3053 7.77765 39.8505 7.6692C40.3957 7.56075 40.9608 7.61641 41.4744 7.82915C41.988 8.04188 42.4269 8.40213 42.7358 8.86434C43.0446 9.32655 43.2095 9.86996 43.2095 10.4259C43.2095 11.1713 42.9133 11.8862 42.3862 12.4133C41.8591 12.9404 41.1442 13.2365 40.3988 13.2365ZM40.3988 7.7122C39.8621 7.7122 39.3374 7.87136 38.8911 8.16955C38.4448 8.46774 38.097 8.89158 37.8916 9.38746C37.6862 9.88333 37.6325 10.429 37.7372 10.9554C37.8419 11.4818 38.1003 11.9654 38.4799 12.3449C38.8594 12.7244 39.343 12.9829 39.8694 13.0876C40.3958 13.1923 40.9414 13.1386 41.4373 12.9332C41.9332 12.7278 42.357 12.3799 42.6552 11.9337C42.9534 11.4874 43.1126 10.9627 43.1126 10.426C43.1126 10.0696 43.0424 9.7167 42.906 9.38745C42.7696 9.0582 42.5697 8.75904 42.3177 8.50704C42.0657 8.25504 41.7666 8.05515 41.4373 7.91877C41.1081 7.78239 40.7552 7.7122 40.3988 7.7122Z"
          fill="#3F3D56"
        />
        <path
          d="M50.6895 6.71315H1.7269C0.773731 6.71478 0.00130496 7.66903 0 8.84675V32.19C0.00130496 33.3676 0.773731 34.3214 1.7269 34.3236H50.6895C51.6427 34.322 52.4151 33.3677 52.4164 32.19V8.84665C52.4151 7.66903 51.6427 6.71521 50.6895 6.71304V6.71315ZM52.2135 32.19C52.2124 33.2292 51.5309 34.0713 50.6897 34.0725H1.7269C0.88574 34.0712 0.204443 33.2292 0.203138 32.19V8.84665C0.204117 7.80746 0.88574 6.96533 1.7269 6.96413H50.6895C51.5307 6.96544 52.212 7.80746 52.2133 8.84665L52.2135 32.19Z"
          fill="#3F3D56"
        />
        <path
          d="M12.2884 16.1019H3.58862V22.1917H12.2884V16.1019Z"
          fill="#F2F2F2"
        />
        <path
          d="M24.468 16.1019H15.7683V22.1917H24.468V16.1019Z"
          fill="#F2F2F2"
        />
        <path
          d="M36.6477 16.1019H27.948V22.1917H36.6477V16.1019Z"
          fill="#F2F2F2"
        />
        <path
          d="M48.8272 16.1019H40.1274V22.1917H48.8272V16.1019Z"
          fill="#F2F2F2"
        />
        <path
          d="M12.2884 25.0191H3.58862V31.1089H12.2884V25.0191Z"
          fill="#F2F2F2"
        />
        <path
          d="M24.468 25.0191H15.7683V31.1089H24.468V25.0191Z"
          fill="#F2F2F2"
        />
        <path
          d="M36.6477 25.0191H27.948V31.1089H36.6477V25.0191Z"
          fill="#F2F2F2"
        />
        <path
          d="M48.8272 25.0191H40.1274V31.1089H48.8272V25.0191Z"
          fill="#F2F2F2"
        />
        <path
          d="M18.6662 31.0735L16.6125 28.2373L17.1719 27.8323L18.744 30.0032L24.7595 23.8229L25.2543 24.3047L18.6662 31.0735Z"
          fill="#FF4338"
        />
        <path
          d="M30.9546 22.1563L28.9009 19.32L29.4603 18.9151L31.0323 21.086L37.0478 14.9057L37.5426 15.3874L30.9546 22.1563Z"
          fill="#FF4338"
        />
        <path d="M11.9443 0H11.7957V10.4386H11.9443V0Z" fill="#2F2E41" />
        <path d="M40.5447 0H40.396V10.4386H40.5447V0Z" fill="#2F2E41" />
        <path
          d="M83.2327 63.9997H41.9905C41.97 64.001 41.9495 63.9982 41.9303 63.9913C41.911 63.9844 41.8933 63.9736 41.8784 63.9596C41.8635 63.9457 41.8515 63.9287 41.8434 63.91C41.8353 63.8912 41.8311 63.8709 41.8311 63.8505C41.8311 63.83 41.8353 63.8098 41.8434 63.791C41.8515 63.7722 41.8635 63.7553 41.8784 63.7413C41.8933 63.7273 41.911 63.7165 41.9303 63.7097C41.9495 63.7028 41.97 63.6999 41.9905 63.7013H83.2327C83.2531 63.6999 83.2736 63.7028 83.2929 63.7097C83.3122 63.7165 83.3298 63.7273 83.3448 63.7413C83.3597 63.7553 83.3716 63.7722 83.3798 63.791C83.3879 63.8098 83.3921 63.83 83.3921 63.8505C83.3921 63.8709 83.3879 63.8912 83.3798 63.91C83.3716 63.9287 83.3597 63.9457 83.3448 63.9596C83.3298 63.9736 83.3122 63.9844 83.2929 63.9913C83.2736 63.9982 83.2531 64.001 83.2327 63.9997Z"
          fill="#3F3D56"
        />
        <path
          d="M6.59543 22.1563L4.54175 19.32L5.10114 18.9151L6.67318 21.086L12.6887 14.9057L13.1835 15.3874L6.59543 22.1563Z"
          fill="#FF4338"
        />
        <path
          d="M71.3649 40.5392C71.4226 40.522 71.4815 40.5094 71.5412 40.5015L73.9227 35.6623L73.1957 34.5723L74.6971 33.26L76.5846 35.5698L72.7403 41.1646C72.8351 41.3763 72.8632 41.6118 72.8209 41.8398C72.7785 42.0678 72.6678 42.2775 72.5033 42.441C72.3389 42.6045 72.1285 42.7141 71.9003 42.7551C71.672 42.7962 71.4367 42.7667 71.2256 42.6707C71.0145 42.5748 70.8376 42.4168 70.7185 42.2178C70.5994 42.0188 70.5437 41.7883 70.5588 41.5569C70.574 41.3254 70.6592 41.1041 70.8032 40.9223C70.9472 40.7405 71.1431 40.6069 71.3649 40.5392Z"
          fill="#FFB8B8"
        />
        <path
          d="M75.4302 33.9294C75.3978 33.9635 75.3605 33.9928 75.3197 34.0164L72.8832 35.4289C72.7709 35.494 72.6373 35.5118 72.5119 35.4784C72.3865 35.445 72.2795 35.3632 72.2144 35.2509C72.2076 35.2392 72.2013 35.2272 72.1954 35.2149L71.0101 32.7112C70.9597 32.6059 70.9488 32.4859 70.9795 32.3732C71.0102 32.2605 71.0804 32.1626 71.1772 32.0973L72.6876 31.0699C72.7819 31.0058 72.8957 30.9766 73.0092 30.9874C73.1227 30.9982 73.2289 31.0483 73.3094 31.1291L75.4205 33.2475C75.5105 33.3376 75.5617 33.4592 75.5635 33.5865C75.5652 33.7138 75.5173 33.8367 75.4299 33.9293L75.4302 33.9294Z"
          fill="#CCCCCC"
        />
        <path
          d="M78.8513 61.6566L77.5649 62.0067L75.6025 57.2109L77.5013 56.6943L78.8513 61.6566Z"
          fill="#FFB8B8"
        />
        <path
          d="M76.5463 61.8885L79.0272 61.2135L79.4521 62.7752L75.4091 63.8752C75.2964 63.4609 75.3528 63.0188 75.5661 62.6462C75.7793 62.2735 76.1319 62.0008 76.5462 61.8881L76.5463 61.8885Z"
          fill="#2F2E41"
        />
        <path
          d="M67.8536 62.6383H66.5203L65.886 57.4959H67.8536V62.6383Z"
          fill="#FFB8B8"
        />
        <path
          d="M65.5681 62.2572H68.1393V63.8762H63.9492C63.9492 63.6637 63.9911 63.4531 64.0724 63.2567C64.1538 63.0603 64.273 62.8818 64.4233 62.7315C64.5736 62.5811 64.7521 62.4619 64.9485 62.3805C65.1449 62.2991 65.3555 62.2572 65.5681 62.2572Z"
          fill="#2F2E41"
        />
        <path
          d="M69.2583 29.3629C70.7334 29.3629 71.9293 28.1671 71.9293 26.692C71.9293 25.2169 70.7334 24.021 69.2583 24.021C67.7832 24.021 66.5874 25.2169 66.5874 26.692C66.5874 28.1671 67.7832 29.3629 69.2583 29.3629Z"
          fill="#FFB8B8"
        />
        <path
          d="M67.7719 58.9718H65.9501C65.8255 58.9717 65.7056 58.9242 65.6147 58.8389C65.5238 58.7536 65.4688 58.6369 65.4608 58.5125C64.9179 49.8823 65.2354 43.6916 66.4601 39.0303C66.4655 39.0099 66.4721 38.9898 66.4799 38.9702L66.5778 38.7263C66.6139 38.6353 66.6765 38.5573 66.7576 38.5024C66.8386 38.4475 66.9344 38.4183 67.0323 38.4187H70.7788C70.8494 38.4186 70.9192 38.4338 70.9833 38.4633C71.0475 38.4928 71.1045 38.5359 71.1504 38.5895L71.6592 39.1833C71.6691 39.1948 71.6785 39.2066 71.6874 39.219C73.9819 42.419 76.0384 48.675 78.4201 57.0837C78.4553 57.2059 78.4413 57.337 78.3812 57.4491C78.3212 57.5612 78.2197 57.6453 78.0984 57.6837L76.141 58.3103C76.0286 58.3457 75.9072 58.3395 75.7989 58.293C75.6907 58.2465 75.6027 58.1626 75.551 58.0567L70.4701 47.5184C70.4345 47.4447 70.3762 47.3845 70.3038 47.3465C70.2314 47.3086 70.1486 47.295 70.0679 47.3078C69.9871 47.3205 69.9126 47.359 69.8554 47.4174C69.7981 47.4758 69.7613 47.5511 69.7502 47.6321L68.2569 58.5486C68.2405 58.6657 68.1824 58.7729 68.0934 58.8507C68.0043 58.9284 67.8902 58.9714 67.7719 58.9718Z"
          fill="#2F2E41"
        />
        <path
          d="M71.4539 39.2268H71.4386L66.8874 39.0828C66.7704 39.0795 66.6585 39.0343 66.5721 38.9554C66.4856 38.8764 66.4305 38.769 66.4167 38.6528C65.8403 33.9831 67.4219 32.7321 68.129 32.4107C68.1881 32.3841 68.2392 32.3426 68.2776 32.2903C68.3159 32.2381 68.3401 32.1768 68.3478 32.1124L68.409 31.6231C68.4194 31.5366 68.4528 31.4546 68.5057 31.3854C69.6142 29.9494 71.1279 29.4895 71.9312 29.3433C72.0587 29.3204 72.1902 29.3491 72.2966 29.4231C72.4029 29.4971 72.4756 29.6103 72.4985 29.7378C72.4999 29.7458 72.5012 29.7539 72.5022 29.762L72.5939 30.4648C72.6032 30.5318 72.63 30.5953 72.6714 30.6487C74.9833 33.671 72.4078 38.1334 71.868 38.9973C71.824 39.0674 71.7629 39.1252 71.6905 39.1653C71.6181 39.2055 71.5367 39.2266 71.4539 39.2268Z"
          fill="#CCCCCC"
        />
        <path
          d="M70.022 45.0634L66.5836 45.726L66.453 45.0479L69.0848 44.5406L66.0259 36.4768L66.6716 36.2319L70.022 45.0634Z"
          fill="#FF4338"
        />
        <path
          d="M74.647 26.9927C74.0512 26.1537 73.3367 25.4057 72.5259 24.772C71.8516 24.2456 71.1013 23.7957 70.2738 23.5806C69.4464 23.3655 68.5318 23.4021 67.7767 23.8034C67.0216 24.2048 66.2406 25.0024 66.2359 25.8576C66.2343 26.1324 66.3043 26.5041 66.5748 26.4565L66.6531 26.4674L68.6113 26.0719L69.4453 28.9663C69.5039 29.1697 69.5825 29.397 69.7756 29.4837C69.9574 29.5654 70.1671 29.4884 70.3519 29.4116C70.3822 29.399 70.6421 28.8155 70.6421 28.8155C70.6486 28.7972 70.6598 28.7811 70.6747 28.7686C70.6895 28.7562 70.7074 28.748 70.7265 28.7448C70.7456 28.7416 70.7653 28.7435 70.7833 28.7505C70.8014 28.7574 70.8173 28.769 70.8294 28.7841L70.9838 28.9772C71.0133 29.0141 71.054 29.0405 71.0997 29.0522C71.1455 29.064 71.1938 29.0606 71.2374 29.0424L74.4309 27.7093C74.578 27.6479 74.7425 27.5679 74.7851 27.414C74.8253 27.266 74.7353 27.1173 74.647 26.9927Z"
          fill="#2F2E41"
        />
        <path
          d="M71.0294 45.8641C71.1693 45.766 71.2859 45.6385 71.3711 45.4905C71.4562 45.3425 71.5079 45.1776 71.5225 45.0074C71.5371 44.8373 71.5142 44.666 71.4554 44.5056C71.3966 44.3453 71.3034 44.1998 71.1823 44.0794L72.0276 33.8712L69.5108 34.2175L69.463 44.1351C69.2738 44.3519 69.1707 44.6305 69.1734 44.9182C69.176 45.2059 69.2842 45.4826 69.4773 45.6958C69.6704 45.909 69.9351 46.044 70.2211 46.075C70.5071 46.1061 70.7945 46.0311 71.0289 45.8642L71.0294 45.8641Z"
          fill="#FFB8B8"
        />
        <path
          d="M72.1921 35.3411C72.145 35.3411 72.0981 35.3344 72.053 35.321L69.351 34.5263C69.2894 34.5082 69.2319 34.4781 69.1819 34.4377C69.1319 34.3974 69.0903 34.3476 69.0595 34.2911C69.0287 34.2347 69.0094 34.1728 69.0025 34.1089C68.9957 34.045 69.0015 33.9804 69.0196 33.9187C69.0235 33.9057 69.0278 33.8929 69.0327 33.8802L70.0327 31.2971C70.0743 31.1879 70.1539 31.0974 70.2568 31.0421C70.3596 30.9868 70.479 30.9703 70.593 30.9956L72.3781 31.3836C72.4895 31.4079 72.589 31.4702 72.6593 31.56C72.7296 31.6498 72.7664 31.7613 72.7633 31.8753L72.6802 34.8649C72.6768 34.9922 72.6239 35.1131 72.5327 35.202C72.4416 35.2908 72.3194 35.3407 72.1921 35.341V35.3411Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1760_40">
          <rect width="83.392" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
