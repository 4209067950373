import { ComponentProps } from "react";
import styled from "@emotion/styled";

export type ModalFooterProps = ComponentProps<"div">;

export const ModalFooter = styled((props: ModalFooterProps) => {
  return <div {...props} />;
})`
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.xl};
`;
