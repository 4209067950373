import { forwardRef } from "react";
import styled from "@emotion/styled";
import {
  SecondaryButtonProps,
  SecondaryButton as UnlikelySecondaryButton,
} from "@unlikelyai-magic/ui/buttons";

export const SecondaryButton = styled(
  forwardRef<HTMLButtonElement, SecondaryButtonProps>(
    ({ size, ...props }, ref) => <UnlikelySecondaryButton {...props} />
  )
)`
  color: ${({ theme }) => theme.colors.text.primary.default};
  box-shadow: none !important;
  border: 0.0625rem solid ${({ theme }) => theme.colors.component.background[2]};
  background: ${({ theme }) => theme.colors.background.default};
  &:hover,
  &:focus,
  &:active,
  &.ant-btn-loading {
    background: ${({ theme }) => theme.colors.action.secondary.hover};
  }

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.colors.text.primary.default};
    background: ${({ theme }) => theme.colors.component.background[1]};
    cursor: not-allowed;
  }
`;
