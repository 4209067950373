import {
  CreatePassagesParseRequest,
  CreatePassagesParseResponse,
} from "@dip/data-access/api-types";
import { dipApi } from "./dipApi";

export const { useParsePassageMutation } = dipApi.injectEndpoints({
  endpoints: (builder) => ({
    parsePassage: builder.mutation<
      CreatePassagesParseResponse,
      CreatePassagesParseRequest
    >({
      query: (body) => ({
        url: "/passages/parse",
        method: "POST",
        body,
      }),
    }),
  }),
});
