import { NodeDto } from "@dip/data-access/api-types";
import { ActionsMenu } from "@dip/ui/components/actions-menu";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { Modal } from "@dip/ui/components/modal";
import { CopyButton } from "@unlikelyai-magic/core";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { NodeResolutionTag } from "./NodeResolutionTag";
import { ResolvedNodeIdModal } from "./ResolvedNodeIdModal";

type DomainNodeDetailsModalProps = {
  node: NodeDto;
  onUpdate: (node: NodeDto) => void;
};

export const DomainNodeDetailsModal = ({
  node,
  onUpdate,
}: DomainNodeDetailsModalProps) => {
  const { closeModal, openModal } = useModal();
  const {
    nodeId,
    nickname,
    description,
    resolved,
    expressedIn,
    principalClass,
  } = node;

  const expressedInText = expressedIn.map((e) => `"${e}"`).join(" or ");

  const onSubmit = (node: NodeDto) => {
    closeModal();
    onUpdate(node);
  };

  return (
    <Modal>
      <Modal.Header title={nickname} onClose={closeModal} />
      <Modal.Content>
        <Paragraph>
          Node Id: {nodeId} <CopyButton strToCopy={nodeId} />
        </Paragraph>
        <Paragraph>
          Description: {description || "No description provided"}
        </Paragraph>
        <Paragraph>Expressed as: {expressedInText}</Paragraph>
        <Paragraph>
          Principal class: {principalClass || "No principal class provided"}
        </Paragraph>
        <Paragraph>
          Type: <NodeResolutionTag resolved={resolved} />
        </Paragraph>
      </Modal.Content>
      <Modal.Footer>
        <Spacing justify="right" direction="horizontal">
          {node.resolved && (
            <ActionsMenu
              actions={[
                {
                  text: "Update resolved node",
                  onClick: (e) => {
                    closeModal();
                    openModal(
                      <ResolvedNodeIdModal node={node} onSubmit={onSubmit} />
                    );
                  },
                },
              ]}
            >
              <div>
                <PrimaryButton>Edit</PrimaryButton>
              </div>
            </ActionsMenu>
          )}

          <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
        </Spacing>
      </Modal.Footer>
    </Modal>
  );
};
