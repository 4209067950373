export type AnyStatus =
  | StatusScheduled
  | StatusRunning
  | StatusCompleted
  | StatusCancelled
  | StatusAborted
  | StatusInternalError
  | StatusNetworkError;

export type StatusScheduled = {
  "@type": "SCHEDULED";
};

export type StatusRunning = {
  "@type": "RUNNING";
  startedAt: number;
};

export type StatusCompleted = {
  "@type": "COMPLETED";
  startedAt: number;
  endedAt: number;
};

export type StatusCancelled = {
  "@type": "CANCELLED";
  startedAt: number;
  endedAt: number;
  reason: string;
};

export type StatusAborted = {
  "@type": "ABORTED";
  startedAt: number;
  endedAt: number;
  reason: string;
};

export type StatusInternalError = {
  "@type": "INTERNAL_ERROR";
  startedAt: number;
  endedAt: number;
  error: InnerInternalError;
};

export type StatusNetworkError = {
  "@type": "NETWORK_ERROR";
  startedAt: number;
  endedAt: number;
  error: InnerNetworkError;
};

export type InnerInternalError = {
  errorClass: string;
  message: string | null;
  stackTrace: string | null;
};

export type InnerNetworkError = {
  message: string;
};

export const getStartedAt = (status: AnyStatus): number | null => {
  switch (status["@type"]) {
    case "SCHEDULED":
      return null;
    case "RUNNING":
    case "COMPLETED":
    case "CANCELLED":
    case "ABORTED":
    case "INTERNAL_ERROR":
    case "NETWORK_ERROR":
      return status.startedAt;
  }
};

export const getEndedAt = (status: AnyStatus): number | null => {
  switch (status["@type"]) {
    case "SCHEDULED":
    case "RUNNING":
      return null;
    case "COMPLETED":
    case "CANCELLED":
    case "ABORTED":
    case "INTERNAL_ERROR":
    case "NETWORK_ERROR":
      return status.endedAt;
  }
};
