import React, { useCallback } from "react";
import { Button } from "antd";
import { SourceInformation } from "./SourcedExplanation.types";

interface IOwnProps {
  nlExplanation: string;
  sources: { [k: string]: SourceInformation };
  maxHeight?: number;
}

export const SourcedExplanationCard = ({
  nlExplanation,
  sources,
  maxHeight,
}: IOwnProps) => {
  const parseExplanation = useCallback(
    (nlExplanation: string, sources: { [k: string]: SourceInformation }) => {
      const parsedExplanation = nlExplanation;

      // extract the regex
      const regex = /\^\[[0-9,\s]*]/g;

      let output = parsedExplanation;
      let match = regex.exec(output);
      while (match) {
        const index = match.index;
        const numbers = match[0]
          .substring(2, match[0].length - 1)
          .replace(" ", "")
          .split(",")
          .filter((num) => num !== "");

        const supTags = numbers
          .map((num) => {
            const link = sources[num].url.replaceAll('"', "");
            return `<a class="link" href=${link}  target="_blank"><sup>[${num}]</sup></a>`;
          })
          .join("");
        output =
          output.slice(0, index) +
          supTags +
          output.slice(index + match[0].length);
        match = regex.exec(output);
      }

      return output;
    },
    []
  );

  const SourcedExplanationCardContent = () => {
    return (
      <div
        style={{
          gap: "8px",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          maxHeight: maxHeight,
        }}
        className={"sourced-explanation-card"}
      >
        {Object.keys(sources).length === 0 ? (
          <div>{nlExplanation}</div>
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: parseExplanation(nlExplanation, sources),
              }}
            />
            <div>
              <h3>Sources:</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "3px",
                }}
              >
                {Object.entries(sources).map(([key, info]) => {
                  const href = info.url.replaceAll('"', "");
                  return (
                    <Button
                      key={`${key}-${href}`}
                      shape={"round"}
                      type="primary"
                      href={href}
                      target="_blank"
                      size={"middle"}
                      icon={
                        href && (
                          <img
                            style={{
                              height: "16px",
                              background: "white",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                            src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${href}`}
                            alt={"favicon"}
                          />
                        )
                      }
                      style={{
                        fontSize: "12px",
                      }}
                    >{`${key}. ${info.name.replaceAll('"', "")}`}</Button>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return <SourcedExplanationCardContent />;
};
