import { NodeEntry, Path, Range, Text } from "slate";
import {
  DecorationEditor,
  Decorations,
  RangeWithDecorations,
} from "../types/customTypes";

const createCharHighlight = (
  path: Path,
  offset: number,
  decoration: Decorations
) => ({
  anchor: { path, offset },
  focus: { path, offset: offset + 1 },
  ...decoration,
});

type BracketMatching = {
  /* matchingBrackets is an object that looks like:
  {0: 89, 11: 55, 55: 11, 89: 0}
  where each key-value pair represents the indices of matching brackets in the text */
  matchingBrackets: Record<number, number>;
  unmatchedClosingBracketIndices: number[];
  stack: number[];
  isCharWithinQuotes: boolean;
  prevChar: string;
};

export const bracketMatchingDecoration =
  (editor: DecorationEditor) =>
  ([node, path]: NodeEntry): RangeWithDecorations[] => {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection) || !Text.isText(node))
      return [];

    const cursorPosition = selection.anchor.offset - 1;
    const { text } = node;

    /*
    The following reduce implements a bracket (i.e. parentheses) matching algorithm in a functional manner using the classic solution with a stack.
    The output of this reduce is 3 objects:
    - matchingBrackets: A map from the index of an opening bracket to the index of the closing bracket that it matches with and vice versa (see the BracketMatching type above)
    - unmatchedClosingBracketIndices: An array of indices of closing brackets that do not have a matching opening bracket
    - stack: An array of indices of opening brackets that do not have a matching closing bracket
    */
    const {
      matchingBrackets,
      unmatchedClosingBracketIndices,
      stack: unmatchedOpeningBracketIndices,
    } = [...text].reduce<BracketMatching>(
      (indicesMap, currentChar, i) => {
        /* brackets inside of "" (i.e. quote characters) should not be considered for bracket matching.
        Note that escaped quotes (\") should be considered as a normal character. */
        if (currentChar === '"' && indicesMap.prevChar !== "\\")
          return {
            ...indicesMap,
            isCharWithinQuotes: !indicesMap.isCharWithinQuotes,
            prevChar: currentChar,
          };

        if (indicesMap.isCharWithinQuotes)
          return { ...indicesMap, prevChar: currentChar };

        // place an opening bracket on the stack and move on to the next character
        if (currentChar === "(")
          return {
            ...indicesMap,
            stack: [...indicesMap.stack, i],
            prevChar: currentChar,
          };

        // characters that are not brackets have no effect on the final result, so skip them and move on to the next character
        if (currentChar !== ")")
          return { ...indicesMap, prevChar: currentChar };

        /* All of the complexity in the algorithm is in determining what to do when you reach a closing bracket character. There are two cases:

        1) An opening bracket is on the stack (i.e. hasOpeningBracketInStack === true): If there is an opening bracket on the stack, it means this
        closing bracket forms a pair with the opening bracket on the stack, so we need to create a new entry in our matchingBrackets map with the
        index of the opening bracket on the stack and the index of the closing bracket we are currently processing. We then need to pop the opening
        bracket off of the stack.

        2) An opening bracket is NOT on the stack (i.e. hasOpeningBracketInStack === false): This means the closing bracket has not been matched to any
        opening bracket, so we need to add its index to the unmatchedBracketIndices array. */
        const lastItemInStack = indicesMap.stack.at(-1);
        // need to explicitly check for undefined because 0 is a valid index and falsy
        const hasOpeningBracketInStack = lastItemInStack !== undefined;

        return {
          ...indicesMap,
          matchingBrackets: {
            ...indicesMap.matchingBrackets,
            ...(hasOpeningBracketInStack
              ? { [lastItemInStack]: i, [i]: lastItemInStack }
              : {}),
          },
          unmatchedClosingBracketIndices: [
            ...indicesMap.unmatchedClosingBracketIndices,
            ...(!hasOpeningBracketInStack ? [i] : []),
          ],
          stack: hasOpeningBracketInStack
            ? indicesMap.stack.slice(0, -1)
            : indicesMap.stack,
          prevChar: currentChar,
        };
      },
      {
        matchingBrackets: {},
        unmatchedClosingBracketIndices: [],
        stack: [],
        isCharWithinQuotes: false,
        prevChar: "",
      }
    );

    // create the Slate ranges needed to highlight matching brackets when the cursor is on one
    // need to explicitly check for undefined because 0 is a valid index and falsy
    const hasMatchingBracket = matchingBrackets[cursorPosition] !== undefined;
    const selectedMatchingBracketRanges = hasMatchingBracket
      ? [
          createCharHighlight(path, cursorPosition, {
            selectedBracketMatch: true,
          }),
          createCharHighlight(path, matchingBrackets[cursorPosition], {
            selectedBracketMatch: true,
          }),
        ]
      : [];

    // create the Slate ranges needed to highlight unmatched brackets
    const allUnmatchedBracketRanges = [
      ...unmatchedOpeningBracketIndices,
      ...unmatchedClosingBracketIndices,
    ].map((index) =>
      createCharHighlight(path, index, { unmatchedBracket: true })
    );

    return [...selectedMatchingBracketRanges, ...allUnmatchedBracketRanges];
  };
