import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { TextBoxContent } from "./TextBoxContent";

const Header = styled(Paragraph)`
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};
  font-weight: 500;
`;

type TextBoxProps = {
  header?: string;
  content: string;
} & Omit<ComponentProps<typeof TextBoxContent>, "value">;

export const TextBox = ({ header, content, ...contentProps }: TextBoxProps) => (
  <Spacing gap="none">
    {header && <Header small>{header}</Header>}
    <TextBoxContent value={content} {...contentProps} />
  </Spacing>
);
