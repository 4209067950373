import { v4 as uuidv4 } from "uuid";
import { EMPTY_OPTIONAL, Optional } from "../Optional";
import { handleErrors } from "../backend/HttpErrors";
import { Passage } from "../ul/ul-element";
import { buildStoreUris } from "../utils";
import { DefinedNicknamePair, NicknamePair } from "./nickname-pair";

export type ParseResult = {
  valid: boolean;
  passage: Passage;
  parseError: Optional<ParseError>;
  handledMissingNicknames?: DefinedNicknamePair[];
};

export type ParseRequest = {
  passage: string;
  temporaryNicknames: DefinedNicknamePair[];
  allowNonPassages: boolean;
};

export const INVALID_PARSE_RESULT: ParseResult = {
  valid: false,
  passage: { elements: [] },
  parseError: EMPTY_OPTIONAL,
};

export type ParseError = {
  description: string;
  start?: Location;
  end?: Location;
};

export type Location = {
  lineNumber: number;
  charNumber: number;
};

export enum NicknameType {
  PROPER = "PROPER",
  PSEUDO = "PSEUDO",
  BEST = "BEST",
}

export class NicknameDomain {
  private _changed: number;
  private url: string;

  constructor(url: string) {
    this.url = url;
    this._changed = 0;
  }

  public get changed() {
    return this._changed;
  }

  public getName() {
    const parts = this.url.split("/");
    return parts[parts.length - 1];
  }

  public getNickname(
    uuid: string,
    storeUri?: string,
    token?: string
  ): Promise<NicknamePair> {
    const headers = new Headers();
    if (token) {
      headers.set("Authorization", token);
    }

    const storeUris = buildStoreUris(storeUri);

    let url = `${this.url}/nicknames/${uuid}`;

    if (storeUris.length > 0) {
      url = `${url}?stores=${storeUris.map(encodeURIComponent).join(",")}`;
    }

    return fetch(url, {
      headers,
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getAllNicknames(limit: number): Promise<NicknamePair[]> {
    return fetch(`${this.url}/nicknames?limit=${limit}`)
      .then(handleErrors)
      .then((res) => res.json());
  }

  public addNicknamePair(
    nicknamePair: DefinedNicknamePair,
    token?: string
  ): Promise<void> {
    const headers = new Headers({ "Content-Type": "application/json" });
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/nicknames/${nicknamePair.uuid}`, {
      method: "POST",
      body: nicknamePair.nickname,
      headers,
    })
      .then(handleErrors)
      .then((res) => {
        this._changed++;
      });
  }

  public updateNicknamePair(
    nicknamePair: DefinedNicknamePair,
    user: string,
    token?: string
  ): Promise<void> {
    const headers = new Headers({ "X-UnlikelyAI-User": user });
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/nicknames/${nicknamePair.uuid}`, {
      method: "PUT",
      body: nicknamePair.nickname,
      headers,
    })
      .then(handleErrors)
      .then((res) => {
        this._changed++;
      });
  }

  public deleteNickname(
    uuid: string,
    user: string,
    token?: string
  ): Promise<void> {
    const headers = new Headers({ "X-UnlikelyAI-User": user });
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/nicknames/${uuid}`, {
      method: "DELETE",
      headers,
    })
      .then(handleErrors)
      .then((res) => {
        this._changed++;
      });
  }

  public getNicknames(
    uuids: string[],
    storeUri?: string,
    type?: NicknameType,
    token?: string,
    ac?: AbortController
  ): Promise<NicknamePair[]> {
    const headers = new Headers({ "Content-Type": "application/json" });
    if (token) {
      headers.set("Authorization", token);
    }

    const storeUris = buildStoreUris(storeUri);

    let url = `${this.url}/search`;

    if (storeUris.length > 0) {
      url = `${url}?stores=${storeUris.map(encodeURIComponent).join(",")}`;
    }

    if (type) {
      url = `${url}&type=${type}`;
    }

    return fetch(url, {
      method: "POST",
      body: JSON.stringify({ uuids: uuids }),
      headers,
      signal: ac?.signal,
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public prefixSearchNickname(
    nicknamePart: string,
    token?: string
  ): Promise<DefinedNicknamePair[]> {
    const headers = new Headers();
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/query/${nicknamePart}`, {
      headers,
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public wildcardSearchNickname(
    nicknamePart: string,
    token?: string
  ): Promise<DefinedNicknamePair[]> {
    const headers = new Headers();
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(
      `${this.url}/wildcard-query/${encodeURIComponent(nicknamePart)}`,
      {
        headers,
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getUuid(
    nickname: string,
    token?: string
  ): Promise<DefinedNicknamePair> {
    const headers = new Headers();
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/uuids/${nickname}`, {
      headers,
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public parseUlElement(
    parseString: string,
    temporaryNicknames: NicknamePair[] = [],
    passageParseOnly = true,
    token?: string,
    ignoreMissingNicknames = false
  ): Promise<ParseResult> {
    const headers = new Headers({ "Content-Type": "application/json" });
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(
      `${this.url}/parse?ignoreMissingNicknames=${ignoreMissingNicknames}`,
      {
        method: "POST",
        body: JSON.stringify({
          passage: parseString,
          temporaryNicknames,
          allowNonPassages: !passageParseOnly,
        }),
        headers,
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getMissingNicknamesFrom(
    parseString: string,
    temporaryNicknames: NicknamePair[] = [],
    deterministicUuids = false
  ): Promise<DefinedNicknamePair[]> {
    return this.parseUlElement(
      parseString,
      temporaryNicknames,
      true,
      undefined,
      true
    ).then((result) => {
      // The UL Editor provides deterministic UUIDs for a given nickname - if we don't want that,
      // we create a new one here. By default, we turn this off because it can cause issues with
      // creating new nicknames. For example, if we create 'Test1' as a nickname, then rename it
      // to 'Test2', then decide to create 'Test1' again, we don't want to get the same UUID as
      // we just assigned to 'Test1' as it would cause a clash.
      if (deterministicUuids) {
        return result.handledMissingNicknames || [];
      }
      return (
        result.handledMissingNicknames?.map((node) => ({
          nickname: node.nickname,
          uuid: uuidv4(),
        })) || []
      );
    });
  }

  public getUnknowns(token?: string): Promise<DefinedNicknamePair[]> {
    const headers = new Headers();
    if (token) {
      headers.set("Authorization", token);
    }
    return fetch(`${this.url}/unknowns`, {
      headers,
    })
      .then(handleErrors)
      .then((res) => res.json());
  }
}
