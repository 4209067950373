import {
  fontSizes,
  lineHeights,
  screenBreakpoints,
  shadows,
  spacings,
} from "@unlikelyai-magic/ui/variables";

export const theme = {
  colors: {
    background: {
      default: "#ffffff",
    },
    component: {
      background: {
        1: "#fbfbfb",
        2: "#f0f0f1",
        3: "#dadadb",
        dark: {
          1: "#1b1b1b",
          2: "#302c31",
        },
      },
      border: {
        1: "#f0f0f1",
        2: "#dadadb",
        dark: "#434548",
      },
    },
    text: {
      primary: {
        default: "#1b1b1b",
      },
      secondary: {
        default: "#6e6b6f",
      },
      dark: {
        default: "#fbfbfb",
      },
    },
    action: {
      primary: {
        2: {
          default: "#0e2b07",
          hover: "#3e5538",
          clicked: "#26401f",
          inactive: "#b6bfb4",
        },
        default: "#2c0033",
        hover: "#4f3855",
        clicked: "#391f40",
        inactive: "#bdb4bf",
      },
      secondary: {
        default: "#ffffff00",
        hover: "#dadadb",
        clicked: "#f0f0f1",
      },
      warning: {
        default: "#da0101",
        hover: "#af0000",
        clicked: "#c50000",
        inactive: "#f4b2b2",
      },
      link: {
        default: "#b000cc",
      },
      dark: {
        default: "#6c48f5",
        hover: "#5639c4",
        clicked: "#6140dc",
        inactive: "#201549",
      },
    },
    semantic: {
      success: {
        default: "#02542d",
        background: "#e5edea",
      },
      warning: {
        default: "#e6902d",
        background: "#fcf3ea",
      },
      error: {
        default: "#da0101",
        background: "#fbe5e5",
      },
    },
  },
  roundness: {
    xs: "0.25rem", // 4px
    sm: "0.5rem", // 8px
    md: "0.75rem", // 12px
    lg: "1rem", // 16px
    xl: "1.5rem", // 24px
    "2xl": "2rem", // 32px
    "3xl": "2.5rem", // 40px
    "4xl": "3rem", // 48px
    "5xl": "3.5rem", // 56px
  },
  spacings,
  lineHeights,
  fontSizes,
  screenBreakpoints,
  shadows,
};
