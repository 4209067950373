import { CSSProperties, Dispatch, SetStateAction } from "react";
import { Combobox } from "@headlessui/react";
import AutoCompleteOption, { Colors, optionStyle } from "./AutoCompleteOption";

// TODO: Use a theme for items like the border radius, colors, spacing, etc.
const backgroundColor: CSSProperties["backgroundColor"] = "#ffffff";
const border: CSSProperties["border"] = "0.1rem solid #e5e7eb";
const borderRadius: CSSProperties["borderRadius"] = "0.5rem";

const baseContainerStyle: CSSProperties = { position: "relative" };

const inputStyle: CSSProperties = {
  backgroundColor,
  border,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  height: "3rem",
  paddingLeft: "1rem",
  outline: "none",
  width: "100%",
};

const optionsStyle: CSSProperties = {
  backgroundColor,
  border,
  borderTop: "none",
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  listStyle: "none",
  maxHeight: "15rem",
  overflowY: "auto",
  paddingLeft: 0,
  position: "absolute",
  width: "100%",
};

interface AutoCompleteProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  options: string[];
  freeSolo?: boolean;
  containerStyle?: CSSProperties;
  placeholder?: string;
  colors?: Colors;
  onSelect?: (value: string) => void;
  onValueChange?: (value: string) => void;
}

export const AutoComplete = ({
  value,
  setValue,
  options,
  freeSolo = true,
  containerStyle,
  placeholder,
  colors = { selected: { background: "#f3f4f6" } },
  onSelect,
  onValueChange,
}: AutoCompleteProps) => {
  const filteredOptions =
    value === ""
      ? options
      : options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        );

  return (
    <div style={{ ...baseContainerStyle, ...containerStyle }}>
      <Combobox
        value={value}
        onChange={(value) => {
          setValue(value);
          onSelect?.(value);
        }}
      >
        {({ activeIndex, open }) => (
          <>
            <Combobox.Button as="div">
              <Combobox.Input
                placeholder={placeholder}
                onChange={({ target: { value } }) => {
                  setValue(value);
                  onValueChange?.(value);
                }}
                style={{
                  ...inputStyle,
                  ...(open
                    ? {
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }
                    : {}),
                }}
              />
            </Combobox.Button>
            <Combobox.Options style={optionsStyle}>
              {!filteredOptions.length ? (
                freeSolo ? (
                  AutoCompleteOption({ isActive: true, colors, value })
                ) : (
                  <div style={optionStyle}>No option found</div>
                )
              ) : (
                filteredOptions.map((option, i) =>
                  AutoCompleteOption({
                    isActive: i === activeIndex,
                    colors,
                    value: option,
                  })
                )
              )}
            </Combobox.Options>
          </>
        )}
      </Combobox>
    </div>
  );
};
