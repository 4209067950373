import { useNavigate } from "react-router-dom";
import {
  DomainEvent,
  EventAction,
  Message,
  useGetDomainsQuery,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { UnlikelyAISpinner } from "@dip/ui/components/spinners";
import { useDomainChannel } from "@dip/ui/features/domain";
import { HeaderBar } from "@dip/ui/layouts";
import styled from "@emotion/styled";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Card } from "antd";
import { UnlikelyAiLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { PolicyRow } from "../components";

const ContentWrapper = styled(Spacing)`
  flex: 1;
`;

const PoliciesCard = styled(Card)`
  width: 50%;
  box-shadow: ${({ theme }) => theme.shadows.md};
  border-radius: ${({ theme }) => theme.roundness.md};
  overflow: hidden;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 90%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-5%);
`;

const LoadingSpinnerContainer = styled.div`
  height: 5rem;
  padding: 1rem;
`;

const AddDomainButton = styled(SecondaryButton)`
  padding: 0.625rem;
  height: 2.5rem;
  font-size: 1rem;
`;

export const Dashboard = () => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetDomainsQuery({
    size: 999,
    sortDescending: true,
  });

  useDomainChannel((message: Message<DomainEvent>) => {
    switch (message.name) {
      case EventAction.CREATED:
        refetch();
        break;
      case EventAction.UPDATED:
      case EventAction.DELETED:
      case EventAction.ERROR: {
        const domainIds = data?.content.map((domain) => domain.id) || [];
        if (domainIds.includes(message.extras?.headers.domainId ?? "")) {
          refetch();
        }
        break;
      }
    }
  });

  const policies = data?.content || [];

  return (
    <>
      <HeaderBar />
      <ContentWrapper justify="center" items="center">
        <LogoContainer>
          <UnlikelyAiLogo
            fill={theme.colors.component.border[1]}
            fillOpacity="40%"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
          />
        </LogoContainer>
        <PoliciesCard
          title={<Heading level="h4">Policies</Heading>}
          extra={
            <AddDomainButton
              icon={<PlusIcon strokeWidth={2} />}
              onClick={() => navigate("create")}
            />
          }
          type="inner"
          bodyStyle={{ padding: 0, maxHeight: "20rem", overflowY: "auto" }}
        >
          {isLoading ? (
            <LoadingSpinnerContainer>
              <UnlikelyAISpinner />
            </LoadingSpinnerContainer>
          ) : (
            policies.map((policy) => (
              <PolicyRow key={policy.id} policy={policy} />
            ))
          )}
        </PoliciesCard>
      </ContentWrapper>
    </>
  );
};
