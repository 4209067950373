import { useFlags } from "launchdarkly-react-client-sdk";
import { CreateDomainFlow } from "./CreateDomainFlow";
import { CreateDomainFlowV1 } from "./CreateDomainFlowV1";

export const CreateDomainFlowEntry = () => {
  const { dipCreateDomainFlowEnabled } = useFlags();

  return dipCreateDomainFlowEnabled ? (
    <CreateDomainFlowV1 />
  ) : (
    <CreateDomainFlow />
  );
};
