import { theme } from "@dip/theme";
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from "antd";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

export type PopoverProps = Omit<AntdPopoverProps, "title"> & {
  title?: string;
};

export const Popover = ({
  children,
  title,
  overlayInnerStyle = {},
  ...props
}: PopoverProps) => {
  return (
    <AntdPopover
      overlayInnerStyle={{
        borderRadius: theme.roundness.md,
        boxShadow: theme.shadows.md,
        ...overlayInnerStyle,
      }}
      title={title && <Paragraph>{title}</Paragraph>}
      {...props}
    >
      {children}
    </AntdPopover>
  );
};
