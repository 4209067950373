import { DecisionTree } from "../components/DecisionTree";
import { adultDepJSON } from "../data";
import { convertToReactFlowNodesAndEdges, layoutTree } from "../utils";

export const DecisionTreePage = () => {
  const { nodes: initialNodesWithoutLayout, edges: initialEdgesWithoutLayout } =
    // TODO (CTRL-14): Replace the JSON below with JSON from an API call!
    convertToReactFlowNodesAndEdges(adultDepJSON);

  const { nodes: initialNodesWithLayout, edges: initialEdgesWithLayout } =
    layoutTree(initialNodesWithoutLayout, initialEdgesWithoutLayout, "TB");

  return (
    <DecisionTree
      initialNodes={initialNodesWithLayout}
      initialEdges={initialEdgesWithLayout}
    />
  );
};
