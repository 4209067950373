import { useState } from "react";
import { PrimaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Form } from "antd";
import { Input } from "@unlikelyai-magic/ui/inputs";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";

const ContainerSpacing = styled(Spacing)`
  width: calc(100% - 2rem);
  margin: auto;
`;

const HeaderText = styled(Heading)`
  font-size: 1.25rem;
  line-height: 1.875rem;
`;

const ErrorContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
  margin-top: 0.5rem;
`;

const ErrorText = styled(Paragraph)`
  color: inherit;
  margin: 0;
  font-size: 0.875rem;
`;

const ExclamationCircleIconContainer = styled(ExclamationCircleIcon)`
  color: inherit;
  height: 1rem;
  width: 1rem;
`;

const FormItemContainer = styled.div`
  width: 100%;
  max-width: 32rem;
  margin: auto;
`;

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  max-width: 32rem;
  margin: auto;
  margin-bottom: 0 !important;

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.colors.text.secondary.default} !important;
  }

  .ant-form-item-explain {
    display: none;
  }
`;

const ContinueButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
`;

export const DomainNameInput = () => {
  const form = Form.useFormInstance();
  const [hasError, setHasError] = useState(false);

  const isDomainInputError = async () => {
    try {
      await form.validateFields(["name"]);
      setHasError(false);
      return false;
    } catch (errorInfo) {
      setHasError(true);
      return true;
    }
  };

  const handleInput = async () => await isDomainInputError();

  const handleContinue = async () =>
    !(await isDomainInputError()) && form.submit();

  return (
    <ContainerSpacing direction="vertical" gap="xl" items="center">
      <Spacing direction="vertical" gap="sm">
        <HeaderText level="h1" textAlign="center">
          Create a domain
        </HeaderText>
        <Paragraph small textAlign="center">
          Give it a name and provide the description
        </Paragraph>
      </Spacing>

      <FormItemContainer>
        <StyledFormItem
          name="name"
          rules={[{ required: true }]}
          label="Name"
          requiredMark="optional"
          help=""
        >
          <Input onChange={handleInput} />
        </StyledFormItem>
        {hasError && (
          <ErrorContainer gap="xs" direction="horizontal" items="center">
            <ExclamationCircleIconContainer fontSize={4} />
            <ErrorText>Please give your domain a name</ErrorText>
          </ErrorContainer>
        )}
      </FormItemContainer>
      <Form.Item noStyle>
        <ContinueButton onClick={handleContinue}>Continue</ContinueButton>
      </Form.Item>
    </ContainerSpacing>
  );
};
