import { NodeDto } from "@dip/data-access/api-types";
import { Card } from "@dip/ui/components/cards";
import { Skeleton } from "@dip/ui/components/skeleton";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { NodeResolutionTag } from "./NodeResolutionTag";

const WrappedDescription = styled(Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
  white-space: normal !important;
`;

const CardTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const CardSubtitle = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const TitleSkeleton = styled(Skeleton)`
  width: 50%;
  height: 1.75rem;
`;

const TagSkeleton = styled(Skeleton)`
  width: 20%;
`;

const DescriptionSkeleton = styled(Skeleton)`
  height: 1.25rem;
`;

// Not used yet, but will be when the backend behaviour is hooked up
const CardLoading = () => (
  <Card>
    <Card.Header>
      <Spacing gap="md">
        <Spacing direction="horizontal" items="center" justify="space-between">
          <TitleSkeleton />
          <TagSkeleton />
        </Spacing>
        <Skeleton />
      </Spacing>
    </Card.Header>
    <Card.Body>
      <DescriptionSkeleton />
      <DescriptionSkeleton />
    </Card.Body>
  </Card>
);

type DomainNodeCardProps = {
  node: NodeDto;
  onClick: () => void;
  isLoading?: boolean;
};

export const DomainNodeCard = ({
  node,
  onClick,
  isLoading,
}: DomainNodeCardProps) => {
  const { nickname, description, resolved, expressedIn } = node;

  if (isLoading) return <CardLoading />;

  return (
    <Card onClick={onClick}>
      <Card.Header>
        <Spacing gap="none">
          <Spacing
            direction="horizontal"
            items="center"
            justify="space-between"
          >
            <CardTitle level="h3">{nickname}</CardTitle>
            <NodeResolutionTag resolved={resolved} />
          </Spacing>
          <CardSubtitle>{`Expressed as: ${expressedIn
            .map((e) => `"${e}"`)
            .join(" or ")}`}</CardSubtitle>
        </Spacing>
      </Card.Header>
      <Card.Body>
        <WrappedDescription>
          Description: {description || "No description provided"}
        </WrappedDescription>
      </Card.Body>
    </Card>
  );
};
