import { Uuid } from "../ul/ul-element";

export interface SourcedExplanation {
  explanationString: string;
  sourceDictionary: { [k: string]: SourceInformation };
}

/**
 * Source information as returned from the SourceRetriever, normalised such that multiple
 * SourceInformation objects can be linked
 * @param id - A unique ID for this source
 * @param name - the name of the source
 * @param node - the UUID of the source (the passage store the passage is found in)
 * @param url - the url of the source
 * @param parentId - optional ID of the parent source connected to this source
 */
export type NormalisedSourceInformation = {
  id: string;
  name: string;
  node: Uuid;
  url: string;
  parentId?: string;
};

/**
 * Source information for a passage
 * @param name - the name of the source
 * @param node - the UUID of the source (the passage store the passage is found in)
 * @param url - the url of the source
 * @param children - optional - child source information for this source (i.e. sources that this source references)
 */
export type SourceInformation = {
  name: string;
  node: Uuid;
  url: string;
  children?: SourceInformation[];
};

/**
 * De-normalise a list of normalised source information into a tree structure, removing the IDs and parent IDs
 * which were used for normalisation
 * @param normalised The normalised source information
 * @returns The de-normalised source information
 */
export function denormaliseSourceInformation(
  normalised: NormalisedSourceInformation[]
): SourceInformation[] {
  const parentNodes = normalised.filter((n) => !n.parentId);
  function assembleNode(node: NormalisedSourceInformation): SourceInformation {
    return {
      name: node.name,
      node: node.node,
      url: node.url,
      children: normalised
        .filter((n) => n.parentId === node.id)
        .map(assembleNode),
    };
  }
  return parentNodes.map(assembleNode);
}
