export const prettifyUL = (ul: string, tabSpaces = 2) => {
  const tab = " ".repeat(tabSpaces);
  const unformattedUL = ul
    .split("\n")
    .map((line) => line.trim())
    .join("");

  /* The algorithm below scans the unformatted UL and adds newlines and tabs before and after
  opening and closing brackets, but leaves all other characters untouched. It does this by keeping track of
  the current indentation level of the line and the previous character processed. */
  const { formattedUL } = [...unformattedUL].reduce(
    (formattingMap, char, i) => {
      const { formattedUL, indentLevel, prevChar, isCharWithinQuotes } =
        formattingMap;

      // ensure that brackets inside of "" (i.e. quote characters) do not affect formatting while ignoring escaped (\") quotes
      if (char === '"' && prevChar !== "\\")
        return {
          ...formattingMap,
          formattedUL: `${formattedUL}${char}`,
          isCharWithinQuotes: !isCharWithinQuotes,
          prevChar: char,
        };

      if (isCharWithinQuotes)
        return {
          ...formattingMap,
          formattedUL: `${formattedUL}${char}`,
          prevChar: char,
        };

      /* If the current character is an opening bracket, add a newline before it (except if it is the first character).
      Then proceed to indent the "(" char by the current indent level. Then increase the indent level. */
      if (char === "(")
        return {
          ...formattingMap,
          formattedUL: `${formattedUL}${i !== 0 ? "\n" : ""}${tab.repeat(indentLevel)}${char}`,
          indentLevel: indentLevel + 1,
          prevChar: char,
        };

      /* If the current character is a closing bracket, decrease the indent level.
      Then, if the character processed before the current character was also a closing bracket,
      add a newline before the current closing bracket and indent it by the new indent level. */
      if (char === ")") {
        const decreasedIndentLevel = Math.max(indentLevel - 1, 0);
        return {
          ...formattingMap,
          formattedUL: `${formattedUL}${prevChar === ")" ? `\n${tab.repeat(decreasedIndentLevel)}` : ""}${char}`,
          indentLevel: decreasedIndentLevel,
          prevChar: char,
        };
      }

      // Otherwise do not add any formatting to the character being processed
      return {
        ...formattingMap,
        formattedUL: `${formattedUL}${char}`,
        prevChar: char,
      };
    },
    { formattedUL: "", indentLevel: 0, prevChar: "", isCharWithinQuotes: false }
  );

  return formattedUL;
};
