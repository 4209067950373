import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { InContextExampleCheckboxes } from "@dip/ui/components/in-context-example-checkboxes";
import { Slider } from "@dip/ui/components/slider";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Switch, SwitchProps, Thumb } from "@unlikelyai-magic/ui/inputs";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Sidebar, SidebarProps } from "@unlikelyai-magic/ui/sidebars";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const AnswerSwitch = styled(
  ({ checked, ...props }: { checked?: boolean } & SwitchProps) => (
    <Switch {...props} />
  )
)`
  width: 3rem;
  min-width: 3rem; /* Prevent shrinking */

  height: 0.5rem;
  padding: 0.7rem 0.1rem;

  ${Thumb} {
    width: 1rem;
    height: 1rem;
    transform: translateX(${({ checked }) => (checked ? 1.5 : 0.25)}rem);
  }
`;

const Container = styled(
  ({ bottomBorder, ...props }: { bottomBorder?: boolean } & SpacingProps) => (
    <Spacing {...props} />
  )
)`
  padding: ${({ theme }) => `${theme.spacings.lg} 2rem`};
  border-bottom: ${({ theme, bottomBorder }) =>
    bottomBorder && `.1rem solid ${theme.colors.background}`};
`;

const GrayText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.primary.default};
`;

type SettingsSidebarProps = Pick<SidebarProps, "open" | "onClose">;

export const SettingsSidebar = (props: SettingsSidebarProps) => {
  const {
    numOfRefinements,
    setNumOfRefinements,
    showAnsweringUL,
    setShowAnsweringUL,
    resolveNewNodesInManualUpdate,
    setResolveNewNodesInManualUpdate,
  } = useSettingsSidebar();
  const { domainId } = useParams();
  const { data: domainData } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  if (!domainData) return <PageSpinner />;

  const selectedInContextExamples = domainData.inContextExamples;

  return (
    <Sidebar {...props} bodyStyle={{ padding: 0 }}>
      <Container gap="xl" bottomBorder>
        <Paragraph small>Max. refinements</Paragraph>
        <Slider
          value={numOfRefinements}
          onChange={(value) => setNumOfRefinements(value)}
          max={10}
          marks={{ 0: "0", 10: "10" }}
        />
      </Container>
      <Container bottomBorder>
        <Paragraph small>In-context examples</Paragraph>
        <InContextExampleCheckboxes
          disabled
          value={selectedInContextExamples}
        />
      </Container>
      <Container>
        <Spacing direction="horizontal" justify="space-between">
          <Spacing gap="3xs">
            <Paragraph small>Answering UL</Paragraph>
            <GrayText small>Show UL when answering questions</GrayText>
          </Spacing>
          <AnswerSwitch
            checked={showAnsweringUL}
            onChange={(checked) => {
              if (typeof checked === "boolean") setShowAnsweringUL(checked);
            }}
          />
        </Spacing>
      </Container>
      <Container>
        <Spacing direction="horizontal" justify="space-between">
          <Spacing gap="3xs">
            <Spacing direction="horizontal" gap="sm">
              <Paragraph small>Manual Flow: Node Resolution</Paragraph>
              <Tooltip
                title="Only applies when updating UL manually.
                When enabled, new nodes will go through the normal resolution flow.
                When disabled, new nodes will skip resolution and will be coined.
                Core nodes, e.g. MeansThat, And, Or etc., will always be resolved.
                "
              >
                {/*tried and failed to handle the forward ref here but couldn't get it working so
                  wrapped in a div instead*/}
                <div>
                  <Icon size="1.25rem" icon={QuestionMarkCircleIcon} />
                </div>
              </Tooltip>
            </Spacing>
            <GrayText small>
              Resolve new nodes when updating Ul manually
            </GrayText>
          </Spacing>
          <AnswerSwitch
            checked={resolveNewNodesInManualUpdate}
            onChange={(checked) => {
              setResolveNewNodesInManualUpdate(Boolean(checked));
            }}
          />
        </Spacing>
      </Container>
    </Sidebar>
  );
};
