import styled from "@emotion/styled";
import { RenderLeafProps } from "slate-react";
import { Decorations } from "./types/customTypes";

const Leaf = styled.span<Decorations>`
  background: ${({ selectedBracketMatch, unmatchedBracket }) =>
    selectedBracketMatch ? "blue" : unmatchedBracket && "red"};
  color: ${({ selectedBracketMatch, unmatchedBracket }) =>
    (selectedBracketMatch || unmatchedBracket) && "white"};
  text-decoration: ${({ parseError }) =>
    parseError ? "underline wavy red" : "none"};
`;

export const renderLeaf = ({ attributes, leaf, children }: RenderLeafProps) => (
  <Leaf {...attributes} {...leaf}>
    {children}
  </Leaf>
);
