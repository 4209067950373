import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import { Domain, DomainStatus } from "@dip/data-access/api-types";
import { useCreateRunTrainingScenariosMutation } from "@dip/data-access/dip-api-service";
import { SecondaryButton } from "@dip/ui/components/buttons";
import {
  Status,
  StatusBanner,
  defaultStatusConfig,
} from "@dip/ui/components/status-banner";
import styled from "@emotion/styled";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import {
  ScenarioStatus,
  getDomainStatusInfo,
  getScenarioStatusInfo,
} from "../constants";

const MenuContainer = styled(Spacing)`
  width: 100%;
`;

const Menu = styled(Spacing)`
  padding: 0 ${({ theme }) => theme.spacings.lg};
  padding-top: ${({ theme }) => theme.spacings.md};
  overflow: auto;
  // Header bar is 3rem and the status bar is 2.5rem
  height: calc(100vh - 5.5rem);
`;

const TopLevelMenuItem = styled(Paragraph)<{ selected?: boolean }>`
  padding: ${({ theme }) => theme.spacings.sm}
    ${({ theme }) => theme.spacings.md};
  border-radius: ${({ theme }) => theme.roundness.sm};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.component.background[2] : "transparent"};
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.text.primary.default
      : theme.colors.text.secondary.default};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  :hover {
    cursor: ${({ selected }) => (selected ? "default" : "pointer")};
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.component.background[2]
        : theme.colors.component.background[1]};
  }
`;

const ScenarioMenuItem = styled(Spacing)<{
  selected?: boolean;
  disabled?: boolean;
}>`
  padding: ${({ theme }) => theme.spacings.sm};
  border-radius: ${({ theme }) => theme.roundness.sm};
  padding-left: ${({ theme }) => theme.spacings.sm};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.component.background[2] : "transparent"};
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.text.primary.default
      : theme.colors.text.secondary.default};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  :hover {
    background-color: ${({ theme, selected, disabled }) =>
      disabled
        ? "transparent"
        : selected
          ? theme.colors.component.background[2]
          : theme.colors.component.background[1]};
  }
`;

const RunScenariosButton = styled(SecondaryButton)`
  background-color: transparent;
  font-weight: 400;
  height: 1.25rem;
`;

interface MenuItem {
  id: string;
  name: string;
  status: ScenarioStatus;
}

export interface DomainStatusMenuProps {
  domain: Domain;
  scenarios: MenuItem[];
  hasExclusions: boolean;
  errorMessage?: string; // TODO(UA-546): Remove when we "launch" DIP
}

export const DomainStatusMenu = ({
  domain,
  scenarios,
  hasExclusions,
  errorMessage, // TODO(UA-546): Remove when we "launch" DIP
}: DomainStatusMenuProps) => {
  const { scenarioId } = useParams();
  const navigate = useNavigate();
  const { numOfRefinements } = useSettingsSidebar();
  const [rerunTrainingScenarios] = useCreateRunTrainingScenariosMutation();

  const domainId = domain.id;
  const domainStatus = domain.status;
  const latestVersion = domain.understandings[0].versions.at(-1);

  const { pathname } = useLocation();
  const [lastPathName] = pathname.split("/").slice(-1);

  const scenariosDisabled =
    domainStatus === DomainStatus.TRAINING_SCENARIOS_NOT_RUN ||
    domainStatus === DomainStatus.FAILED_UNDERSTANDING ||
    domainStatus === DomainStatus.ERROR ||
    domainStatus === DomainStatus.GENERATING_UNDERSTANDING;

  const scenarioMenuItems = scenarios.map(
    ({ id, name, status: scenarioStatus }) => {
      const { icon, status } = getScenarioStatusInfo(scenarioStatus);
      return {
        id,
        name,
        icon,
        color: defaultStatusConfig[status].color,
      };
    }
  );

  const { status, text } = getDomainStatusInfo(domainStatus);

  const handleRerunTrainingScenarios = () =>
    latestVersion &&
    rerunTrainingScenarios({
      domainId,
      domainUnderstandingId: domain.understandings[0].id,
      versionId: latestVersion.id,
      refinements: numOfRefinements,
    });

  const isRunningTrainingScenariosDisabled =
    domainStatus !== DomainStatus.GENERATING_UNDERSTANDING &&
    domainStatus !== DomainStatus.RUNNING_TRAINING_SCENARIOS;

  return (
    <MenuContainer direction="vertical" gap="none">
      <StatusBanner
        status={status}
        text={status === Status.ERROR ? `${text}\n\n${errorMessage}` : text} // TODO(UA-546): This field should just be the text const
        rightItem={
          <RunScenariosButton
            disabled={isRunningTrainingScenariosDisabled}
            icon={<Icon icon={ArrowPathIcon} />}
            onClick={handleRerunTrainingScenarios}
          >
            Run
          </RunScenariosButton>
        }
      />
      <Menu gap="none">
        <TopLevelMenuItem
          small
          onClick={() => navigate(`/domains/${domainId}/understanding`)}
          selected={lastPathName === "understanding"}
        >
          Understanding
        </TopLevelMenuItem>
        {hasExclusions && (
          <TopLevelMenuItem
            small
            onClick={() =>
              navigate(`/domains/${domainId}/understanding/general-exclusions`)
            }
            selected={lastPathName === "general-exclusions"}
          >
            General exclusions
          </TopLevelMenuItem>
        )}
        {scenarioMenuItems.map(({ id, name, icon, color }) => (
          <ScenarioMenuItem
            key={id}
            direction="horizontal"
            gap="sm"
            onClick={() =>
              navigate(`/domains/${domainId}/understanding/scenarios/${id}`)
            }
            selected={scenarioId === id}
            disabled={scenariosDisabled}
          >
            <Icon icon={icon} color={color} />
            <Paragraph small>{name}</Paragraph>
          </ScenarioMenuItem>
        ))}
      </Menu>
    </MenuContainer>
  );
};
