import React, { useEffect, useState } from "react";
import {
  CardNodeCfg,
  DecompositionTreeGraph,
  DecompositionTreeGraphConfig,
  G6TreeGraphData,
  MarkerCfg,
  NodeCfg,
} from "@ant-design/graphs";
import { useNicknameDomain } from "../nicknames/NicknameDomainContext";
import { NicknamePair, getUsedNickname } from "../nicknames/nickname-pair";
import { useCognitoToken } from "../providers";
import { Explanation, extractExplanationDetails } from "../ul/explanation";
import {
  Passage,
  UlElement,
  isUlPassage,
  isUlString,
  isUlUuid,
} from "../ul/ul-element";

interface IOwnProps {
  explanation: Explanation;
  height: number;
  width: number;
  level: number;
  // vGap and hGap are used to control the vertical and horizontal distance of the node card
  vGap?: number;
  hGap?: number;
}

// TODO(SLFC-128): fix memory leak
export const ExplanationTree = ({
  explanation,
  height,
  width,
  level,
  vGap,
  hGap,
}: IOwnProps) => {
  const nicknameDomain = useNicknameDomain();
  const { token, setToken } = useCognitoToken();
  const [treeData, setTreeData] = useState<G6TreeGraphData>();
  const ac = new AbortController();

  async function serialisePassage(passage: Passage): Promise<string> {
    // Get the nicknames of all the nodes in the given passage
    const uuids = Array.from(gatherAllUuids(passage));
    const nicknameMap = new Map();
    const nicknames = await nicknameDomain.getNicknames(
      uuids,
      undefined,
      undefined,
      token,
      ac
    );
    nicknames.forEach((pair) => nicknameMap.set(pair.uuid, pair));

    // Replace each uuid with its nickname
    return serialiseUlElement(passage, nicknameMap, 0);
  }

  function serialiseUlElement(
    element: UlElement,
    nicknameMap: Map<string, NicknamePair | undefined>,
    depth: number
  ): string {
    const tabs = "\t".repeat(depth);
    let output;
    if (isUlPassage(element)) {
      const serialisedUlList = element.elements.map((elem) =>
        serialiseUlElement(elem, nicknameMap, depth + 1)
      );

      output =
        `\n${tabs}(` + serialisedUlList.map((elem) => elem).join(" ") + `)`;
    } else if (isUlString(element)) {
      output = element;
    } else {
      const nicknamePair = nicknameMap.get(element.uuid);
      output = nicknamePair
        ? getUsedNickname(nicknamePair).split(":")[0]
        : element.uuid.substring(0, 8);
    }
    return output;
  }

  function gatherAllUuids(element: UlElement): Set<string> {
    const uuids = new Set<string>();

    if (isUlPassage(element)) {
      element.elements.forEach((ulElement) =>
        gatherAllUuids(ulElement).forEach((uuid) => uuids.add(uuid))
      );
    }

    if (isUlUuid(element)) {
      uuids.add(element.uuid);
    }

    return uuids;
  }

  function getUniquePassageId(
    element: UlElement,
    parentId: string,
    depth: number,
    index: number
  ): string {
    // To make sure the ids are unique for each node in the explanation tree, we use the uuids of
    // the UL element, its parent's id, its depth and index (i.e. it is the ith child of the parent)
    const uuidsString = [...gatherAllUuids(element)]
      .map((uuid) => uuid.substring(0, 4))
      .join("");

    return btoa(`${uuidsString}${parentId}${index}${depth}`);
  }

  const getTreeDimensions = (
    { children, value }: G6TreeGraphData,
    maxWidth: number,
    maxHeight: number
  ): [number, number] => {
    // Iterate through the tree to find the max node width and max node height.
    let newMaxWidth = 0;
    let newMaxHeight = 0;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // ts-ignore here because the type of value is not defined in G6TreeGraphData
    const currentNodeUlString: string = value.items[0].text;
    if (!currentNodeUlString) {
      return [maxWidth, maxHeight];
    }

    const currentNodeMaxWidth = Math.max(
      ...currentNodeUlString.split("\n").map((subString) => subString.length)
    );
    const currentNodeMaxHeight = (currentNodeUlString.split("\n") || []).length;

    newMaxWidth = Math.max(currentNodeMaxWidth, maxWidth);
    newMaxHeight = Math.max(currentNodeMaxHeight, maxHeight);

    // Recursively traverse the tree and find the max width and height
    if (children && children.length > 0) {
      children.forEach((childNode) => {
        const [childMaxWidth, childMaxHeight] = getTreeDimensions(
          childNode,
          newMaxWidth,
          newMaxHeight
        );
        newMaxWidth = Math.max(childMaxWidth, newMaxWidth);
        newMaxHeight = Math.max(childMaxHeight, newMaxHeight);
      });
    }
    return [newMaxWidth, newMaxHeight];
  };

  async function serialiseExplanation(
    explanation: Explanation,
    parentId: string,
    depth: number,
    index: number
  ): Promise<G6TreeGraphData> {
    return extractExplanationDetails(
      explanation,
      async (passage, details, children) => {
        const currentPassageId = getUniquePassageId(
          explanation.passage,
          parentId,
          depth,
          index
        );
        // Not everything has detail passages, but anything with children does, so this will be
        // defined in the cases we need it.
        const detailsId =
          details.length === 0
            ? ""
            : getUniquePassageId(details[0], currentPassageId, depth + 1, 0);

        // We return a graph node for this explanation step (showing the passage), then, if there
        // are children, a child node containing the details of this explanation step (e.g. the
        // reasoning passage or the semantic equivalence passages, etc.), then, as children of the
        // detail node, we recurse for each child of this explanation step.
        return {
          id: currentPassageId,
          value: {
            items: [
              {
                text: await serialisePassage(explanation.passage),
              },
            ],
          },
          children:
            children.length === 0
              ? []
              : [
                  {
                    id: detailsId,
                    value: {
                      items: await Promise.all(
                        details.map(async (item, index1) => {
                          return {
                            text: await serialisePassage(item),
                          };
                        })
                      ),
                    },
                    children: await Promise.all(
                      children.map((child, index) =>
                        serialiseExplanation(child, detailsId, depth + 2, index)
                      )
                    ),
                  },
                ],
        };
      }
    );
  }

  useEffect(() => {
    const serialisedExplanation = async () => {
      return serialiseExplanation(explanation, "", 0, 0);
    };

    serialisedExplanation()
      .then((data) => {
        setTreeData(data);
      })
      .catch(() => console.log("Failed to serialise UL explanation"));
    return () => ac.abort();
  }, []);

  if (treeData === undefined) {
    // If the height and width are not set properly, we do not display the graph
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  } else {
    const [maxWidth, maxHeight] = getTreeDimensions(treeData, 1, 1);
    const config: DecompositionTreeGraphConfig = {
      data: treeData,
      behaviors: ["drag-canvas", "zoom-canvas", "drag-node"],
      layout: {
        direction: "TB",
        getWidth: () => {
          return vGap ? maxWidth * vGap : maxWidth;
        },
        getHeight: () => {
          return hGap ? maxHeight * hGap : maxHeight;
        },
      },
      height: height,
      width: width,
      level: level,
      markerCfg: (cfg: CardNodeCfg) => {
        const { children } = cfg;

        return {
          position: "bottom",
          show: (children as Array<any>).length >= 1,
        } as MarkerCfg;
      },
      nodeCfg: {
        autoWidth: true,
        autoHeight: true,
        anchorPoints: [
          [0.5, 0],
          [0.5, 1],
        ],
        items: {
          style: (cfg: string, group: string, type: string) => {
            const styles: { [key: string]: NodeCfg } = {
              value: {
                fill: "#52c41a",
              },
              text: {
                fill: "black",
                whiteSpace: "pre",
                fontFamily:
                  "NBInternationalMono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace",
              },
              icon: {
                width: 10,
                height: 10,
              },
            };
            return styles[type];
          },
        },
        nodeStateStyles: {
          hover: {
            stroke: "#1890ff",
            lineWidth: 2,
          },
        },
        style: {
          stroke: "#40a9ff",
          whiteSpace: "pre",
          backgroundColor: "#F7FAFF",
          fontFamily:
            "NBInternationalMono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace",
        },
      },
      edgeCfg: {
        endArrow: {
          fill: "#40a9ff",
        },
        type: "cubic-vertical",
        style: () => {
          return {
            stroke: "#40a9ff",
            lineWidth: 1,
            strokeOpacity: 0.5,
          };
        },
      },
    };
    return <DecompositionTreeGraph {...config} />;
  }
};
