import { ReactElement, ReactNode } from "react";
import { Context } from "../hooks";
import { ApplicationConfig } from "./applicationConfig";

type ConfigProviderProps = {
  children?: ReactNode | ReactNode[];
  config: ApplicationConfig;
};
export const ConfigProvider = ({
  config,
  children,
}: ConfigProviderProps): ReactElement => {
  return <Context.Provider value={config}>{children}</Context.Provider>;
};
