import { Outlet, useParams } from "react-router-dom";
import { DomainStatus } from "@dip/data-access/api-types";
import { useGetDomainQuery } from "@dip/data-access/dip-api-service";
import { PageSpinner } from "@dip/ui/components/spinners";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { DomainStatusMenu } from "../components";
import { ScenarioStatus } from "../constants";

const UnderstandingContainer = styled(Spacing)`
  height: 100%;
`;

const Sidebar = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.background.default};
  width: 20rem;
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.component.border[1]};

  @media (min-width: ${screenBreakpoints.sm}) {
    display: flex;
  }
`;

export const UnderstandingLayout = () => {
  const { domainId } = useParams();
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );

  if (!data) return <PageSpinner />;

  const currentUnderstanding = data.understandings[0];
  const latestVersion = currentUnderstanding.versions.at(-1);
  const domainStatus = data.status;

  const scenarios = data.trainingScenarios.map(({ id, solution }, index) => {
    const getStatus = () => {
      const latestScenarioAnswer =
        latestVersion?.trainingScenarioDecisions.find(
          (decision) => decision.trainingScenarioId === id
        )?.decision.result;
      switch (true) {
        case domainStatus === DomainStatus.GENERATING_UNDERSTANDING ||
          domainStatus === DomainStatus.FAILED_UNDERSTANDING ||
          domainStatus === DomainStatus.TRAINING_SCENARIOS_NOT_RUN:
          return ScenarioStatus.DISABLED;
        case domainStatus === DomainStatus.RUNNING_TRAINING_SCENARIOS:
          return ScenarioStatus.RUNNING;
        case latestScenarioAnswer === solution.answer:
          return ScenarioStatus.SUCCESS;
        default:
          return ScenarioStatus.WRONG_ANSWER;
      }
    };
    return {
      id: id ?? "",
      name: "Scenario " + (index + 1),
      status: getStatus(),
    };
  });

  return (
    <UnderstandingContainer direction="horizontal" gap="none">
      <Sidebar>
        <DomainStatusMenu
          domain={data}
          scenarios={scenarios}
          hasExclusions={Boolean(data.content.exclusions)}
          errorMessage={currentUnderstanding.error} // TODO(UA-546): Remove when we "launch" DIP
        />
      </Sidebar>
      <Outlet />
    </UnderstandingContainer>
  );
};
