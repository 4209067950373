import { Uuid } from "../ul/ul-element";
import { Tag, TagType } from "./tags.types";
import { User } from "./user.types";

enum ApprovalStatus {
  UNKNOWN_APPROVAL_STATUS = "Unknown Approval Status",
  NOT_STARTED = "Not Started",
  DRAFT = "Draft",
  REVIEW_REQUIRED = "Review Requested",
  CHANGES_REQUESTED = "Changes Requested",
  APPROVED = "Approved",
  WONT_DO = "Won't Do",
  BLOCKED = "Blocked",
}

interface Comment {
  id: Uuid["uuid"];
  value: string;
  timestamp: Date;
  author: User;
}

interface TicketTag extends Tag {
  type: TagType.TICKET_TAG_TYPE;
}

interface Ticket {
  id: Uuid["uuid"];
  author: User;
  assignee?: User;
  reviewer?: User;
  status: ApprovalStatus;
  comments: Comment[];
  createdAt: Date;
  modifiedAt: Date;
  tags: TicketTag[];
}

export { ApprovalStatus };

export type { Comment, Ticket, TicketTag };
