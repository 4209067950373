import React from "react";
import { Explanation } from "../../ul/explanation";
import { ExplanationTree } from "../ExplanationTree";
import { StatementCardError } from "./StatementCardError";
import { StatementDetailCard } from "./StatementDetailCard";

export const UlExplanation = ({
  explanation,
  explanationError,
  explanationTreeLevel,
  width,
  height,
  hGap,
  vGap,
}: {
  explanation?: Explanation;
  explanationError?: string;
  explanationTreeLevel?: number;
  width: number;
  height: number;
  hGap?: number;
  vGap?: number;
}) => {
  return (
    <StatementDetailCard
      title={"UL Explanation"}
      isLoading={!explanationError && !explanation}
      width={width}
      height={height}
    >
      {explanationError ? (
        <StatementCardError error={explanationError} />
      ) : (
        explanation && (
          <ExplanationTree
            width={width}
            height={height}
            level={explanationTreeLevel || 2}
            explanation={explanation}
            hGap={hGap}
            vGap={vGap}
          />
        )
      )}
    </StatementDetailCard>
  );
};
