import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

export type ModalProps = Omit<ComponentProps<"div">, "ref">;

const ModalRoot = styled(({ children, ...props }: ModalProps) => (
  <Dialog.Panel {...props}>{children}</Dialog.Panel>
))`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.roundness["2xl"]};
  display: flex;
  flex-direction: column;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;

  @media (min-width: ${screenBreakpoints.sm}) {
    width: 70%;
  }

  @media (min-width: ${screenBreakpoints.md}) {
    width: 50%;
  }

  @media (min-width: ${screenBreakpoints.lg}) {
    width: 40%;
  }

  @media (min-width: ${screenBreakpoints.xl}) {
    width: 30%;
  }

  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.xl};
`;

export const Modal = Object.assign(ModalRoot, {
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,
});
