import { NodeDto } from "@dip/data-access/api-types";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { Form, FormItem, FormProps } from "@dip/ui/components/forms";
import { TextArea } from "@dip/ui/components/inputs";
import { Modal } from "@dip/ui/components/modal";
import styled from "@emotion/styled";
import { v4 as uuid } from "uuid";
import { CopyButton } from "@unlikelyai-magic/core";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useModal } from "@unlikelyai-magic/ui/modals";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { ExpressedInInput } from "./ExpressedInInput";
import { NodeResolutionTag } from "./NodeResolutionTag";

/* It seems like styled components messes with generic typing of the Form component.
Using styled(Form) below leads to type errors on the onFinish props below.
These type errors are coming from the fact that, when used this way, Form does not recognize
the type of form data (which should be inferred from the "form" prop also
passed into the component). The type is unknown instead.

The suspicion is that, if the type of one prop depends on the type of another prop passed into
the same component, we will always have to use this hack, although this assumption has not been verified.
*/
const Container = styled(({ ...props }) => <Form {...props} />)`
  padding: 0;
`;

type FormValues = {
  description: string;
  expressedIns: string[];
};

type CoinNewNodeModalProps = {
  /*Determines whether the coined node is being edited or created*/
  isEdit: boolean;
  node: NodeDto;
  onSubmit: (node: NodeDto) => void;
};

export const CoinedNodeEditModal = ({
  isEdit,
  node,
  onSubmit,
}: CoinNewNodeModalProps) => {
  const { closeModal } = useModal();

  const { nickname, nodeId, principalClass, description, expressedIn } = node;

  const localNodeId = isEdit ? nodeId : uuid();

  const handleSubmit: FormProps<FormValues>["onFinish"] = (values) => {
    const {
      description: descriptionUpdated,
      expressedIns: expressedInsUpdated,
    } = values;

    onSubmit({
      nodeId: localNodeId,
      nickname,
      description: descriptionUpdated,
      resolved: false,
      expressedIn: expressedInsUpdated,
      principalClass: principalClass,
      specification: "",
    });
  };

  return (
    <Modal>
      <Container
        name="coin-node-form"
        initialValues={{
          description: isEdit ? description : "",
          // add a single empty string in the expressed ins list to have one empty input field by default
          expressedIns: isEdit ? expressedIn : [""],
        }}
        onFinish={handleSubmit}
      >
        <Modal.Header title={nickname} onClose={closeModal} />
        <Modal.Content>
          <Paragraph>
            Node Id: {localNodeId} <CopyButton strToCopy={localNodeId} />
          </Paragraph>
          <FormItem
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input a description",
              },
            ]}
            validateTrigger={["onChange", "onBlur"]}
          >
            <TextArea />
          </FormItem>
          <ExpressedInInput />
          <Paragraph>
            Principal class: {principalClass || "No principal class provided"}
          </Paragraph>
          <Paragraph>
            Type: <NodeResolutionTag resolved={false} />
          </Paragraph>
        </Modal.Content>
        <Modal.Footer>
          <Spacing justify="right" direction="horizontal">
            <FormItem label={null}>
              <PrimaryButton htmlType="submit">Submit</PrimaryButton>
            </FormItem>
            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          </Spacing>
        </Modal.Footer>
      </Container>
    </Modal>
  );
};
