import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { SerializedStyles } from "@emotion/react";
import styled, { CSSObject } from "@emotion/styled";
import { Button, Divider, Form, Input, Row, Select, SelectProps } from "antd";
import { eq } from "lodash";
import { BaseSelectRef } from "rc-select";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors, screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { InputActionButtons } from "../InputActionButtons/InputActionButtons";
import { LoadingOverlay } from "../LoadingOverlay";

interface ContainerProps {
  containerWidth?: string;
  style?: SerializedStyles;
}

interface LabelProps {
  labelWidth?: string;
  style?: SerializedStyles;
}

interface ValueContainerProps {
  labelVisible: boolean;
  valueWidth?: string;
}

interface ValueProps {
  editingEnabled?: boolean;
  style?: SerializedStyles;
}

interface InputPropsExtended extends SelectProps {
  inputStyle?: CSSObject;
}

const EditableSelectInputContainer = styled.div<ContainerProps>`
  width: ${({ containerWidth }) => `${containerWidth ?? "300px"}`};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media (min-width: ${screenBreakpoints.md}) {
    flex-direction: row;
    gap: 0.5rem;
  }

  ${({ style }) => style};
`;

const Label = styled(Paragraph)<LabelProps>`
  display: flex;
  font-size: 15px;
  align-items: center;
  flex: 1;
  width: 100%;
  ${({ style }) => style};
`;

const ValueContainer = styled.div<ValueContainerProps>`
  position: relative;
  transition: background-color 0.2s ease;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelVisible, valueWidth }) =>
      labelVisible ? (valueWidth ?? "50%") : "100%"};
  }
`;

const Value = styled(Paragraph)<ValueProps>`
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  flex: 1;
  font-weight: bolder;
  transition: background-color 0.2s ease;

  :hover {
    background: ${({ editingEnabled }) =>
      `${editingEnabled ? `${colors.neutral["100"]}` : ""}`};
  }

  ${({ style }) => style};
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: visible;
`;

const SelectInput = styled(Select)`
  ${({ inputStyle }: InputPropsExtended) => `${inputStyle ?? ""}`};

  border: 0;
  line-height: 1.5rem;
  flex: 1;
  font-weight: bolder;
  transition: background-color 0.2s ease;
  height: 100%;
  width: 100%;

  :focus {
    border: 0;
    outline: 0;
  }
`;

const AddOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  padding: 0 8px 4px;
`;

const AddOptionInput = styled(Input)`
  width: 75%;
`;

const AddOptionButton = styled(Button)`
  width: 25%;
`;

/**
 * @typedef EditableSelectInputProps<T>
 * @property {string} label The label to display for the input element. If an empty string is
 * passed. no label is displayed and the value container takes 100% of the available width
 * @property {T} value The value to display for the input element
 * @property {T[]} dataOptions The list of options to display in the dropdown menu
 * @property {keyof T} dataKey The key to use to extract the value from the data options. If not
 * provided, the data options are assumed to be strings
 * @property {boolean} editingEnabled Whether editing is enabled for the input element
 * @property {string} containerWidth The width of the container element. Defaults to 300px
 * @property {string} labelWidth The width of the label element. Defaults to 50%
 * @property {string} valueWidth The width of the value element. Defaults to 50%
 * @property {SerializedStyles} labelStyle Override styles for label container
 * @property {SerializedStyles} valueStyle Override styles for value container
 * @property {SerializedStyles} containerStyle Override styles for the parent container
 * @property {boolean} loading Whether the input element is in a loading state
 * @property {string} className The class name to apply to the container element
 * @property {(updatedValue: T | undefined) => void} onChange Callback function to
 * execute when the user clicks the OK button or presses enter on the keyboard
 */
type EditableSelectInputProps<T> = {
  label: string;
  value: T | undefined;
  dataOptions: T[];
  dataKey?: keyof T;
  editingEnabled: boolean;
  containerWidth?: string;
  labelWidth?: string;
  valueWidth?: string;
  labelStyle?: SerializedStyles;
  valueStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  loading?: boolean;
  className?: string;
  onChange?: (updatedValue: T | undefined) => void;
  allowNewOption?: boolean;
  onNewOptionAdded?: (newOptionValue: string) => Promise<void>;
};

/**
 * The component displays a static value when editing mode is off. When editing is enabled,
 * the component displays an input element that allows the user to select a value from a list of
 * values.
 * @param props {EditableSelectInputProps}
 */
export const EditableSelectInput = <T,>(props: EditableSelectInputProps<T>) => {
  // Define editing state for the component
  const [editing, setEditing] = useState(props.value === undefined);
  const ref = useRef(null);
  const [selectValue, setSelectValue] = useState<T | undefined>(
    props.value
      ? props.dataKey
        ? {
            ...props.value,
            value: props.value[props.dataKey] as string,
            label: props.value[props.dataKey] as string,
          }
        : props.value
      : undefined
  );
  // Tracking the dropdown menu state to allow keyboard shortcuts to work only when dropdown is
  // not open
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  // Define a ref for the input element to allow the element to be accessed across the component
  const inputRef = useRef<BaseSelectRef>(null);

  const [addOptionForm] = Form.useForm<{ newOption: string }>();

  // Set focus on the tag element when editing is enabled
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    } else {
      setDropdownOpen(false);
    }
  }, [editing]);

  const handleCompletion = () => {
    // On render, any editable value that is blank is set to editable by default. All the fields
    // which are editable try to grab focus, which causes onBlur to be triggered with blank values.
    // This condition ensures that only the fields which are actually edited are set to non-editable
    if (selectValue || (!selectValue && !eq(selectValue, props.value))) {
      setEditing(false);
    }
    if (!eq(props.value, selectValue)) {
      props.onChange?.(selectValue);
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const addOption = async (value: { newOption: string }) => {
    await props.onNewOptionAdded?.(value.newOption);
    addOptionForm.resetFields();
  };

  /**
   * Handles key press events for the tag element. If the enter key is pressed when the dropdown is
   * not visible, the input value is saved and editing is disabled. If the escape key is pressed
   * when the dropdown is not visible, editing is disabled without saving the input value.
   * @param event Key press event
   */
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!dropdownOpen) {
      if (event.key === "Enter") {
        handleCompletion();
      } else if (event.key === "Escape") {
        handleCancel();
      }
    }
  };

  // Define the static non-editable tag element. This element can be clicked to enable editing
  // mode when editing is enabled for the component
  const StaticValue = (
    <Value editingEnabled={props.editingEnabled} style={props.valueStyle}>
      {props.value
        ? props.dataKey
          ? (props.value[props.dataKey] as string)
          : (props.value as string)
        : ""}
    </Value>
  );

  const AddOptionForm = (
    <>
      <Divider style={{ margin: "8px 0", width: "100%" }} />
      <Form form={addOptionForm} onFinish={addOption}>
        <Form.Item name={"newOption"} style={{ margin: "0px" }}>
          <Row style={{ padding: "0 8px 4px", width: "100%", gap: "5px" }}>
            <AddOptionContainer>
              <AddOptionInput
                id={"AddOptionFormInput"}
                placeholder="Enter name..."
                onKeyDown={(e) => e.stopPropagation()}
              />
              <AddOptionButton
                id={"AddOptionFormButton"}
                onClick={addOptionForm.submit}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </AddOptionButton>
            </AddOptionContainer>
          </Row>
        </Form.Item>
      </Form>
    </>
  );

  // Define the editable tag element. This element is shown when editing is enabled and allows
  // the user to edit the tags
  const EditableValue = (
    <InputContainer
      onBlur={(mouseEvent) => {
        // If the blur is not caused by the add option input element or the add option button, the
        // input value is saved and editing is switched off
        if (
          mouseEvent.relatedTarget?.id !== "AddOptionFormInput" &&
          mouseEvent.relatedTarget?.id !== "AddOptionFormButton" &&
          mouseEvent.relatedTarget?.className !==
            "ant-select-selection-search-input"
        ) {
          handleCompletion();
        }
      }}
    >
      <SelectInput
        showSearch
        ref={inputRef}
        onChange={(value, option) =>
          setSelectValue((props.dataKey ? option : value) as T)
        }
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        value={selectValue}
        onFocus={(e) => e.preventDefault()}
        onKeyDown={handleKeyPress}
        dropdownRender={(menu) => (
          <>
            {menu}
            {props.allowNewOption && AddOptionForm}
          </>
        )}
        options={
          props.editingEnabled
            ? props.dataOptions.map((option) => ({
                ...(props.dataKey && { ...option }),
                value: props.dataKey
                  ? (option[props.dataKey] as string)
                  : (option as string),
                label: props.dataKey
                  ? (option[props.dataKey] as string)
                  : (option as string),
              }))
            : undefined
        }
      />
      {!props.loading && (
        <InputActionButtons
          onCancel={handleCancel}
          onSubmit={handleCompletion}
        />
      )}
    </InputContainer>
  );

  return (
    <EditableSelectInputContainer
      className={props.className}
      containerWidth={props.containerWidth}
      style={props.containerStyle}
    >
      {props.label && (
        <Label
          color={colors.neutral["400"]}
          labelWidth={props.labelWidth}
          style={props.labelStyle}
        >
          {props.label}
        </Label>
      )}
      <ValueContainer
        labelVisible={!!props.label}
        valueWidth={props.valueWidth}
        onClick={() => (props.editingEnabled ? setEditing(true) : undefined)}
      >
        {props.loading ? <LoadingOverlay /> : null}
        {editing ? EditableValue : StaticValue}
      </ValueContainer>
    </EditableSelectInputContainer>
  );
};
