import React, { ComponentProps } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const shimmer = keyframes`
    0% { background-position: -200px 0; }
    100% { background-position: 200px 0; }
`;

type SkeletonProps = ComponentProps<"div">;

const SkeletonWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: ${({ theme }) => theme.fontSizes.md};
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200px 100%;
  border-radius: ${({ theme }) => theme.roundness.xs};
  animation: ${shimmer} 1.5s infinite linear;
`;

export const Skeleton = ({ ...props }: SkeletonProps) => (
  <SkeletonWrapper {...props} />
);
