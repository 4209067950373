import styled from "@emotion/styled";
import { SecondaryButton } from "./SecondaryButton";

export const TransparentButton = styled(SecondaryButton)`
  background: none !important;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary.default};

  &:hover,
  &:active,
  &:disabled,
  &:disabled:hover,
  &.ant-btn-loading {
    color: ${({ theme }) => theme.colors.text.secondary.default};
  }
`;
