import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { TextBox } from "./TextBox";

type CodeTextBoxProps = ComponentProps<typeof TextBox>;

export const CodeTextBox = styled((props: CodeTextBoxProps) => (
  <TextBox {...props} />
))`
  color: ${({ theme }) => theme.colors.action.dark.hover};
  font-family: monospace;
`;
