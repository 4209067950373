import { NodeType, SolverDetails } from "../../reasoning";
import { Passage } from "../../ul/ul-element";

/**
 * Helper function to get a list of all knowledge passages from a given answer path
 * @param answerPath
 */
export const getKnowledgePassagesFromAnswerPath = (
  answerPath: SolverDetails[]
): Passage[] =>
  answerPath.reduce<Passage[]>((passages, solverDetails) => {
    if (solverDetails.solverType === NodeType.KNOWLEDGE) {
      passages.push(solverDetails.ulConclusion);
    }
    return passages;
  }, []);
