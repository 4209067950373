import { ComponentProps, useState } from "react";
import { DomainMode } from "@dip/data-access/api-types";
import { HeaderBar } from "@dip/ui/layouts";
import styled from "@emotion/styled";
import { Cog8ToothIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { TransparentButton } from "@unlikelyai-magic/ui/buttons";
import { Switch, Thumb } from "@unlikelyai-magic/ui/inputs";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { SettingsSidebar } from "./SettingsSidebar";

const Header = styled(HeaderBar)`
  background: ${({ theme }) => theme.colors.component.background[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.component.border[1]};
`;

const HeaderButton = styled(TransparentButton)`
  height: 3rem;
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ModeName = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
  margin: auto;
`;

const DomainName = styled(Paragraph)`
  white-space: nowrap;
  font-weight: medium;
  color: ${({ theme }) => theme.colors.text.primary.default};
  margin: auto;
`;

const ModeToggle = styled(Switch)<{ checked: boolean }>`
  height: 2.25rem;
  width: 3.5rem;

  ${Thumb} {
    width: 1.75rem;
    height: 1.75rem;
    transform: translateX(${({ checked }) => (checked ? 1.3375 : 0.25)}rem);
    ${({ theme, checked }) =>
      checked && {
        background: theme.colors.action.primary.default,
        color: theme.colors.text.dark.default,
      }};
  }
`;

type DomainHeaderProps = ComponentProps<"header"> & {
  domainName: string;
  mode: DomainMode;
  onModeChange: (mode: DomainMode) => void;
};

export const DomainHeader = ({
  domainName,
  mode,
  onModeChange,
}: DomainHeaderProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleMode = () => {
    onModeChange(
      mode === DomainMode.UNDERSTANDING
        ? DomainMode.ANSWERING
        : DomainMode.UNDERSTANDING
    );
  };

  const handleSettingsClick = () => setIsSettingsOpen(true);
  const handleSettingsClose = () => setIsSettingsOpen(false);

  return (
    <Header>
      <>
        <Spacing direction="horizontal" gap="xs">
          <ModeName textAlign="center">
            {mode === DomainMode.UNDERSTANDING ? "Understanding" : "Answering"}
          </ModeName>
          <DomainName>{domainName}</DomainName>
        </Spacing>
        <SettingsSidebar open={isSettingsOpen} onClose={handleSettingsClose} />
        <Spacing
          direction="horizontal"
          gap="sm"
          justify="flex-end"
          items="center"
        >
          <ModeToggle
            checked={mode === DomainMode.UNDERSTANDING}
            onChange={toggleMode}
            icon={DocumentIcon}
          />
          <HeaderButton
            icon={<Cog8ToothIcon />}
            onClick={handleSettingsClick}
          />
        </Spacing>
      </>
    </Header>
  );
};
