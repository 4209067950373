import { NodeDto } from "./nodeDto.dto";

export enum DecisionResult { // TODO (CTRL-14): This type is likely to change depending on what comes from the Acorn API
  Yes = "Yes",
  No = "No",
  DontKnow = "DontKnow",
}

export interface Decision {
  id: string;
  questionRepresentation: string[];
  result?: DecisionResult;
  explanation: string;
  decisionCommentary: string;
  debugInfo: string;
  nodes?: NodeDto[];
  dateCreated: string;
}
