import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { Form } from "@dip/ui/components/forms";
import { HeaderBar } from "@dip/ui/layouts";
import styled from "@emotion/styled";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { DomainNameInput } from "./components";

const Container = styled.div`
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  margin-inline: auto;
`;

const HeaderBarButton = styled(SecondaryButton)`
  box-shadow: none !important;
  padding: 0.5rem 1.5rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border: none;
`;

const StyledForm = styled(Form)`
  padding-top: 40px;
`;

export const CreateDomainFlowV1 = () => {
  const [domainFlowForm] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = () => {
    // TODO: Implement submit logic later
  };

  return (
    <Container>
      <HeaderBar>
        <Spacing direction="horizontal">
          <HeaderBarButton
            icon={<ChevronLeftIcon />}
            onClick={() => navigate("/")}
          >
            Back to domains
          </HeaderBarButton>
        </Spacing>
      </HeaderBar>
      <StyledForm
        layout="vertical"
        form={domainFlowForm}
        name="create-domain"
        onFinish={handleFinish}
      >
        <Spacing direction="vertical" gap="xl">
          <DomainNameInput />
        </Spacing>
      </StyledForm>
    </Container>
  );
};
