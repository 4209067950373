import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { Pagination, Skeleton } from "antd";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "../../backend";

export interface TablePaginationProps {
  page: number;
  pageSize: number;
  total: number;
  showTotal?: boolean;
  onPageChange: (page: number, pageSize: number) => void;
  isLoading?: boolean;
  showSizeChanger?: boolean;
}

const StyledPaginationContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledPaginationLoadingContainer = styled.div`
  width: 40%;
`;

export const TablePagination: FunctionComponent<TablePaginationProps> = (
  props
) => {
  const isLoading = props.isLoading || false;

  if (isLoading) {
    return (
      <StyledPaginationContainer>
        <StyledPaginationLoadingContainer>
          <Skeleton.Button block active />
        </StyledPaginationLoadingContainer>
      </StyledPaginationContainer>
    );
  }

  return (
    <StyledPaginationContainer>
      <Pagination
        defaultPageSize={DEFAULT_PAGINATION_PAGE_SIZE}
        current={props.page}
        pageSize={props.pageSize}
        showSizeChanger={
          props.showSizeChanger === undefined ? true : props.showSizeChanger
        }
        onChange={props.onPageChange}
        total={props.total}
        showTotal={(total) => (props.showTotal ? `${total} results` : null)}
      />
    </StyledPaginationContainer>
  );
};
