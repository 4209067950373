import { TagType } from "@unlikelyai-magic/proto/ai/unlikely/core/proto/tag_pb";
import { Uuid } from "../ul/ul-element";
import { User } from "./user.types";

interface Tag {
  id: Uuid["uuid"];
  value: string;
  createdAt: Date;
  modifiedAt: Date;
  author: User;
  type: TagType;
}

export type { Tag };
export { TagType };
