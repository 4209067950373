import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpertiseType, InContextExample } from "@dip/data-access/api-types";
import { useCreateDomainMutation } from "@dip/data-access/dip-api-service";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { HeaderBar } from "@dip/ui/layouts";
import styled from "@emotion/styled";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Form } from "antd";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { ExampleRow } from "./components";
import { ContextPage, DomainInputStep, ExamplesStep } from "./steps";

const Container = styled.div`
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
`;

const HeaderBarButton = styled(SecondaryButton)`
  box-shadow: ${({ theme }) => theme.shadows.sm} !important;
  padding: 0.5rem 1.5rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const HeaderBarTextButton = styled(HeaderBarButton)`
  box-shadow: none !important;
  border: none;
`;

export const CreateDomainFlow = () => {
  const navigate = useNavigate();
  const [createDomain] = useCreateDomainMutation();
  const [currentStep, setCurrentStep] = useState(0);

  const [understandingOnboardingForm] = Form.useForm();

  const steps = [
    () => <ContextPage onGoForward={() => setCurrentStep(1)} />,
    () => (
      <DomainInputStep
        onGoBack={() => setCurrentStep(0)}
        onGoForward={() => setCurrentStep(2)}
      />
    ),
    () => <ExamplesStep onGoBack={() => setCurrentStep(1)} />,
  ];

  const Step = steps[currentStep];

  const handleFinish = () => {
    const formValues = understandingOnboardingForm.getFieldsValue(true);
    const inContextExampleArray = [
      ...(formValues[`in-context-checkbox-${InContextExample.CITIZENSHIP}`]
        ? [InContextExample.CITIZENSHIP]
        : []),
      ...(formValues[
        `in-context-checkbox-${InContextExample.PERSONAL_JURISDICTION}`
      ]
        ? [InContextExample.PERSONAL_JURISDICTION]
        : []),
    ];

    createDomain({
      title: formValues.title,
      content: {
        rules: formValues.domainDescription,
        ...(formValues.generalExclusions && {
          exclusions: formValues.generalExclusions,
        }),
      },
      context: formValues.domainDefinition
        .toUpperCase()
        .replace("-", "_") as InContextExample,
      inContextExamples: !inContextExampleArray.length
        ? [InContextExample.NONE]
        : inContextExampleArray,
      trainingScenarios: formValues.examples.map(
        ({ problem, answer, reasoning }: ExampleRow) => ({
          question: problem,
          solution: { answer, explanation: reasoning },
        })
      ),
      expertiseType: ExpertiseType.UL,
      domainVersion: 0,
      refinements: formValues.refinements,
    });
  };

  return (
    <Container>
      <HeaderBar>
        <Spacing direction="horizontal">
          <HeaderBarButton>Refinements</HeaderBarButton>
          <HeaderBarButton>In-context examples</HeaderBarButton>
          <HeaderBarTextButton
            icon={<ChevronLeftIcon />}
            onClick={() => navigate("/")}
          >
            Back to domains
          </HeaderBarTextButton>
        </Spacing>
      </HeaderBar>
      <Form
        form={understandingOnboardingForm}
        name="understanding-onboarding"
        initialValues={{ refinements: 5 }}
        onFinish={handleFinish}
      >
        <Step />
      </Form>
    </Container>
  );
};
