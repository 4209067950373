import { ComponentProps, FocusEvent, useEffect, useState } from "react";
import { useParsePassageMutation } from "@dip/data-access/dip-api-service";
import { Popover } from "@dip/ui/components/popover";
import { ULTextBox } from "@dip/ui/components/text-boxes";

type ULRuleInputProps = Omit<
  ComponentProps<typeof ULTextBox>,
  "content" | "parseError" | "onBlur"
> & {
  content?: string;
};

export const ULRuleInput = ({
  content,
  onValueChange,
  ...props
}: ULRuleInputProps) => {
  const [error, setError] = useState<string>();
  const [parseUL, { data: parseErrorResult }] = useParsePassageMutation();

  useEffect(
    () => setError(parseErrorResult?.data?.description ?? undefined),
    [parseErrorResult]
  );

  const handleBlur = ({ target }: FocusEvent<HTMLDivElement, Element>) => {
    target.textContent && parseUL({ text: target.textContent });
  };

  return (
    <Popover
      content={error}
      showArrow={false}
      placement="bottom"
      overlayStyle={{ padding: "0 1rem" }}
      overlayInnerStyle={{ border: "1px solid red" }}
    >
      <div>
        <ULTextBox
          content={content ?? ""}
          parseError={parseErrorResult?.data}
          onBlur={handleBlur}
          onValueChange={(value) => {
            setError(undefined);
            onValueChange?.(value);
          }}
          {...props}
        />
      </div>
    </Popover>
  );
};
