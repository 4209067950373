import { NicknamePair } from "../nicknames/nickname-pair";
import {
  ALL_LEGACY_AND_NON_DEFAULT_STORE_URIS,
  LEGACY_STORE_URIS,
} from "../stores/stores";
import { serialiseUlElement } from "../ul/UlSerializer";
import { Passage, UlElement, Uuid } from "../ul/ul-element";

/*
Types matching the backend (Answer.kt, DebugMessage.kt, Solution.kt)
 */

// Request types

export const DEFAULT_SOLVE_TIMEOUT = 5;
export const DEFAULT_ALL_SOLUTIONS = false;

export const DEFAULT_DEBUG_LOG_FETCH_TIMEOUT = 6;

// After this many debug messages come in, stop processing.
export const DEFAULT_MAX_ALLOWED_DEBUG_MESSAGES = 100000;

export type RequestSolve = {
  requestId: string;
  question: Passage;
  options: RequestSolveOptions;
};

export type RequestSolveOptions = {
  storeUris: string[];
  contextPassages: Passage[];
  constrainedUnknowns: ConstrainedUnknown[];
  satisfaction: Satisfaction;
  allSolutions: boolean;
  disabledSolverCategories: string[];
  newlyCoinedNodes: Uuid[];
};

export type Satisfaction =
  | SatisfactionHaveReasonedFor
  | SatisfactionHaveSeenNAnswers
  | SatisfactionHaveReasonedForAfterInitial
  | SatisfactionHaveNotSeenAnswerForAfterInitial
  | SatisfactionEither;

export type SatisfactionHaveSeenNAnswers = {
  "@type": "HAVE_SEEN_N_ANSWERS";
  n: number;
};

export type SatisfactionHaveReasonedForAfterInitial = {
  "@type": "HAVE_REASONED_FOR_AFTER_INITIAL";
  secs: number;
};

export type SatisfactionHaveNotSeenAnswerForAfterInitial = {
  "@type": "HAVE_NOT_SEEN_ANSWER_FOR_AFTER_INITIAL";
  secs: number;
};

export type SatisfactionEither = {
  "@type": "EITHER";
  satisfactions: Satisfaction[];
};

export type SatisfactionHaveReasonedFor = {
  "@type": "HAVE_REASONED_FOR";
  secs: number;
};

export type DebugLogInfo = {
  questionId: Uuid;
  question: Passage | null;
  startTime: number | null;
  options: RequestSolveOptions | null;
  user: string;
  numFiles: number;
};

export type ConstrainedUnknown = {
  unknown: Uuid;
  constrainedValues: UlElement[];
};

export const DEFAULT_REQUEST_SOLVE_OPTIONS: RequestSolveOptions = {
  storeUris: LEGACY_STORE_URIS,
  contextPassages: [],
  constrainedUnknowns: [],
  satisfaction: {
    "@type": "HAVE_REASONED_FOR",
    secs: DEFAULT_SOLVE_TIMEOUT,
  },
  allSolutions: DEFAULT_ALL_SOLUTIONS,
  disabledSolverCategories: [],
  newlyCoinedNodes: [],
};

export const ALL_REQUEST_SOLVE_OPTIONS: RequestSolveOptions = {
  storeUris: ALL_LEGACY_AND_NON_DEFAULT_STORE_URIS,
  contextPassages: [],
  constrainedUnknowns: [],
  satisfaction: {
    "@type": "HAVE_REASONED_FOR",
    secs: DEFAULT_SOLVE_TIMEOUT,
  },
  allSolutions: true,
  disabledSolverCategories: [],
  newlyCoinedNodes: [],
};

// Response types

export type SolveResponse =
  | ResponseHeader
  | ResponseSolverInfo
  | ResponseTopLevelAnswer
  | ResponseDebugMessage
  | ResponseHeartbeat
  | InternalError
  | NetworkError; // Include extra network error option for easier processing

export type ResponseHeader = {
  "@type": "HEADER";
  questionId: string;
};

export type ResponseSolverInfo = {
  "@type": "SOLVER_INFO";
  solverInfo: SolverInfo;
};

export type SolverInfo = {
  solverId: number;
  name: string;
  description: string;
  sourcePassage?: Passage;
  sourcePassageUris: string[];
};

export type ResponseTopLevelAnswer = {
  "@type": "TOP_LEVEL_ANSWER";
  answer: AnswerVM;
  timeTakenMs: number | null;
};

export type ResponseDebugMessage = {
  "@type": "DEBUG_MESSAGE";
  message: DebugMessage;
};

export type InternalError = {
  "@type": "INTERNAL_ERROR";
  errorClass: string;
  message: string | null;
  stackTrace: string | null;
};

export type NetworkError = {
  "@type": "NETWORK_ERROR";
  message: string;
};

export type DebugMessage =
  | DebugMessageSolution
  | DebugMessageQueryStart
  | DebugMessageQueryEnd
  | DebugMessageSolverScheduled
  | DebugMessageSolverStart
  | DebugMessageSolverEnd
  | DebugMessageSolverLog
  | DebugMessageSolverAborted;

export type DebugMessageSolution = {
  "@type": "SOLUTION";
  solution: LlSolution;
};

export type ResponseHeartbeat = {
  "@type": "HEARTBEAT";
  message: string;
};

export type LlMapping = [LlUnknown, LlElement];

export type LlSolution = {
  solutionId: string;
  solverNodeId: string;
  executingSolverNodeId: string;
  mapping: LlMapping[];
  explanation: LlExplanation;
};

export type LlExplanation = {
  operation: any;
  inputSolutionIds: string[];
};

export type UlSolution = {
  mapping: SolutionMappingItem[];
  explanation: AnswerExplanation;
  solutionId: string;
  solverNodeId: string;
  executingSolverNodeId: string;
};

export type DebugMessageQueryStart = {
  "@type": "STATUS_QUERY_START";
  timestamp: number;
  queryId: string;
  parentSolverNodeId: string | null;
  query: LlPassage;
  forRulePassage: LlPassage | null;
  queryAsQuestion: LlPassage;
};

export type DebugMessageQueryEnd = {
  "@type": "STATUS_QUERY_END";
  timestamp: number;
  queryId: string;
  numSolutions: number;
};

export type DebugMessageSolverScheduled = {
  "@type": "STATUS_SOLVER_SCHEDULED";
  timestamp: number;
  solverNodeId: string;
  parentQueryId: string;
  solverId: number;
};

export type DebugMessageSolverStart = {
  "@type": "STATUS_SOLVER_START";
  timestamp: number;
  solverNodeId: string;
  executingSolverNodeId?: string;
  cached: boolean;
};

export type DebugMessageSolverEnd = {
  "@type": "STATUS_SOLVER_END";
  timestamp: number;
  solverNodeId: string;
  numSolutions: number;
};

export type DebugMessageSolverLog = {
  "@type": "STATUS_SOLVER_LOG";
  timestamp: number;
  solverNodeId: string;
  level: string;
  message: string | null;
  payload: { [key: string]: any } | null;
};

export type DebugMessageSolverAborted = {
  "@type": "STATUS_SOLVER_ABORTED";
  timestamp: number;
  solverNodeId: string;
  reason: string;
};

export type AnswerExplanation =
  | AnswerExplanationAnd
  | AnswerExplanationBackwardChaining
  | AnswerExplanationComputed
  | AnswerExplanationHypothetical
  | AnswerExplanationKnowledge
  | AnswerExplanationOr
  | AnswerExplanationExists
  | AnswerExplanationSemanticEquivalance
  | AnswerExplanationProofByContradiction
  | AnswerExplanationReducer
  | AnswerExplanationDontKnow;

export type AnswerExplanationAnd = {
  "@type": "AND";
  conclusion: Passage;
  clauseSolutions: UlSolution[];
};

export type AnswerExplanationBackwardChaining = {
  "@type": "BACKWARD_CHAINING";
  reasoningPassage: Passage;
  source: string;
  conclusion: Passage;
  bindings: SolutionMappingItem[];
  precedentSolvedAs: UlSolution;
};

export type AnswerExplanationComputed = {
  "@type": "COMPUTED";
  computationUnit: Uuid;
  conclusion: Passage;
};

export type AnswerExplanationHypothetical = {
  "@type": "HYPOTHETICAL";
  conclusion: Passage;
  context: Passage[];
  innerSolution: UlSolution;
};

export type AnswerExplanationKnowledge = {
  "@type": "KNOWLEDGE";
  conclusion: Passage;
  knowledgePassage: Passage;
  source: string;
};

export type AnswerExplanationOr = {
  "@type": "OR";
  conclusion: Passage;
  solvedClause: Passage;
  fromSolution: UlSolution;
};

export type AnswerExplanationExists = {
  "@type": "EXISTS";
  conclusion: Passage;
  subSolution: UlSolution;
};

export type AnswerExplanationSemanticEquivalance = {
  "@type": "SEMANTIC_EQUIVALENCE";
  conclusion: Passage;
  substitutions: Substitution[];
  equivalentSolvedAs: UlSolution;
  equivalencePassagesUsed: Passage[];
};

export type AnswerExplanationProofByContradiction = {
  "@type": "PROOF_BY_CONTRADICTION";
  conclusion: Passage;
  skolemization: Substitution[];
  contradiction: Passage;
  innerSolution: UlSolution;
};

export type AnswerExplanationReducer = {
  "@type": "REDUCER";
  conclusion: Passage;
  subquery: Passage;
  combiner: Passage;
  initialState: SolutionMappingItem;
  subquerySolutions: UlSolution[];
  combinerSolution?: UlSolution;
  reducerPassageUsed: Passage;
};

export type AnswerExplanationDontKnow = {
  "@type": "DONT_KNOW";
  conclusion: Passage;
  analyseFailureAsciiArt: string;
  exhaustive: boolean;
};

export type Substitution = {
  first: UlElement;
  second: UlElement;
};

export type Outcome = "Yes" | "No" | "DontKnow";

export const displayOutcome = (outcome: Outcome) => {
  switch (outcome) {
    case "Yes":
      return "Yes";
    case "No":
      return "No";
    case "DontKnow":
      return "I don't know";
  }
};

export type IsItTrueThatAnswer = {
  outcome: string;
  localNodeMappings: SolutionMappingItem[];
  solutionId: string;
  explanation: AnswerExplanation;
  solutionInvalidations: string[];
};

export type WhatAnswer = {
  mapping: SolutionMappingItem[];
  localNodeMappings: SolutionMappingItem[];
  solutionId: string;
  explanation: AnswerExplanation;
  solutionInvalidations: string[];
};

export type DontKnowAnswer = {
  solutionId: string;
  localNodeMappings: SolutionMappingItem[];
  explanation: AnswerExplanation;
  solutionInvalidations: string[];
};

export const isIsItTrueThatAnswer = (
  answer: AnswerVM
): answer is IsItTrueThatAnswer => {
  return "outcome" in answer;
};

export const isWhatAnswer = (answer: AnswerVM): answer is WhatAnswer => {
  return "mapping" in answer && !("outcome" in answer);
};

export const isDontKnowAnswer = (
  answer: AnswerVM
): answer is DontKnowAnswer => {
  return !("mapping" in answer) && !("outcome" in answer);
};

export type AnswerVM = IsItTrueThatAnswer | WhatAnswer | DontKnowAnswer;

export type SolutionMappingItem = {
  first: UlElement;
  second: UlElement;
};

export type LlElement = LlString | LlUuid | LlUnknown | LlPassage;
export type LlString = string;
export type LlUuid = Uuid;
export type LlUnknown = { unknown: number; nickname: string | undefined };
export type LlPassage = { elements: LlElement[] };

export const toUlElement = (element: LlElement): UlElement => {
  if (typeof element === "string") {
    return element;
  }
  if ("uuid" in element) {
    return element;
  }
  if ("unknown" in element) {
    if (element.nickname) {
      return `<${element.nickname}>`;
    } else {
      return `<x${element.unknown}>`; // TODO: Need a better way of handling unknowns
    }
  }
  if ("elements" in element) {
    return { elements: element.elements.map(toUlElement) } as Passage;
  }
  throw new Error(
    "Unsupported element type encountered when parsing LlElement"
  );
};

// Serialise a Passage to a string, taking into account the rendering of Ll nicknames, i.e. renders
//  "((What X)(IsA X Cheese))" instead of "((What "<X>")(IsA "<X>" Cheese))" for Ll unknowns which
// were resolved to strings
export function serialiseUlElementLlAware(
  element: UlElement,
  nicknameMap: Map<string, NicknamePair | undefined>,
  usePseudoNicknames: boolean,
  truncateUuids: boolean
): string {
  // Function to replace "<X>" with X for any X in a string
  function removeQuotesAndBrackets(input: string): string {
    return input.replace(/"(<[^>]+>)"/g, "$1");
  }

  return removeQuotesAndBrackets(
    serialiseUlElement(element, nicknameMap, usePseudoNicknames, truncateUuids)
  );
}

export const filterToValidAnswers = (
  answers: ResponseTopLevelAnswer[]
): ResponseTopLevelAnswer[] => {
  const invalidatedIds = answers.flatMap(
    (topLevelAnswer) => topLevelAnswer.answer.solutionInvalidations
  );
  return answers.filter(
    (topLevelAnswer) =>
      !invalidatedIds.includes(topLevelAnswer.answer.solutionId)
  );
};

export function contentEquals(a: Satisfaction, b: Satisfaction): boolean {
  if (a["@type"] !== b["@type"]) {
    return false;
  }
  if (a["@type"] === "EITHER") {
    if (
      a.satisfactions.length !== (b as SatisfactionEither).satisfactions.length
    ) {
      return false;
    }
    for (let i = 0; i < a.satisfactions.length; i++) {
      if (
        !contentEquals(
          a.satisfactions[i],
          (b as SatisfactionEither).satisfactions[i]
        )
      ) {
        return false;
      }
    }
    return true;
  }
  if (a["@type"] === "HAVE_SEEN_N_ANSWERS") {
    return a.n === (b as SatisfactionHaveSeenNAnswers).n;
  }
  if (a["@type"] === "HAVE_REASONED_FOR") {
    return a.secs === (b as SatisfactionHaveReasonedFor).secs;
  }
  if (a["@type"] === "HAVE_REASONED_FOR_AFTER_INITIAL") {
    return a.secs === (b as SatisfactionHaveReasonedForAfterInitial).secs;
  }
  if (a["@type"] === "HAVE_NOT_SEEN_ANSWER_FOR_AFTER_INITIAL") {
    return a.secs === (b as SatisfactionHaveNotSeenAnswerForAfterInitial).secs;
  }
  throw new Error("Unknown satisfaction type " + a["@type"]);
}
