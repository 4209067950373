import React, { ReactNode } from "react";
import { Spin } from "antd";

export const StatementDetailCard = ({
  width,
  height,
  title,
  extra,
  isLoading,
  children,
}: {
  width?: number;
  height?: number;
  title: string;
  extra?: ReactNode;
  isLoading: boolean;
  children: ReactNode | undefined;
}) => {
  const CardContent = () => (
    <div
      style={{
        width: width,
        height: height,
        border: "1px solid #d9d9d9",
        overflow: "hidden",
      }}
      className={"rd-modal-card"}
    >
      <div
        style={{
          display: "flex",
          margin: "4px",
        }}
        className={"rd-modal-card-title"}
      >
        <span style={{ margin: "auto" }}>{title}</span>
        {extra}
      </div>
      {children}
    </div>
  );

  if (isLoading) {
    return (
      <Spin tip="Loading...">
        <CardContent />
      </Spin>
    );
  }

  return <CardContent />;
};
