import { useEffect } from "react";
import { theme } from "@dip/theme";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { TextArea } from "@dip/ui/components/inputs";
import styled from "@emotion/styled";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";

/* It seems like styled components messes with generic typing of the Form component.
Using styled(Form) below leads to type errors on the onFinish and onValuesChange props below.
These type errors are coming from the fact that, when used this way, Form does not recognize
the RulesForm type as the type of form data (which should be inferred from the "form" prop also
passed into the component). The type is unknown instead.

The suspicion is that, if the type of one prop depends on the type of another prop passed into
the same component, we will always have to use this hack, although this assumption has not been verified.
*/
const Container = styled(({ ...props }) => <Form {...props} />)`
  padding: 1rem;
`;

const FieldContainer = styled(Spacing)`
  padding-bottom: 1.5rem;
`;

const RuleContainer = styled(Form.Item)`
  flex: 1;
  margin-bottom: 0;
`;

const RuleNumberText = styled.div`
  padding-top: ${({ theme }) => theme.spacings.sm};
  color: ${({ theme }) => theme.colors.action.dark.hover};
  font-family: monospace;
`;

const RuleInput = styled(TextArea)`
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.action.dark.hover};
  background-color: ${({ theme }) => theme.colors.component.background[1]};
  font-family: monospace;
`;

const AddButton = styled(PrimaryButton)`
  width: 25%;
`;

const MinusButton = styled(SecondaryButton)`
  border: none;
  background-color: transparent;
  margin: auto;
`;

type RulesForm = {
  rules: string[];
};

export type ULFieldName = "exclusions" | "representation";

type ULTabContentProps = {
  rules: string[];
  setHasUnsavedTabContent: (tabHasUnsavedChanges: boolean) => void;
  onSave?: (representation: string[]) => void;
  isDisabled?: boolean;
};

export const ULTabContent = ({
  rules,
  setHasUnsavedTabContent,
  onSave,
  isDisabled = false,
}: ULTabContentProps) => {
  const [form] = useForm<RulesForm>();
  const formValues = Form.useWatch([], form);
  const initialFormValues = rules.map((rule) => rule.trim());

  useEffect(
    () =>
      setHasUnsavedTabContent(!_.isEqual(initialFormValues, formValues?.rules)),
    [formValues?.rules, initialFormValues, setHasUnsavedTabContent]
  );

  // allow the user to save their edits with cmd+s (or ctrl+s on windows)
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        form.submit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [form]);

  const onFinish = (formValues: RulesForm) => onSave?.(formValues.rules);

  return (
    <Container
      form={form}
      initialValues={{ rules: initialFormValues }}
      onFinish={onFinish}
    >
      <Form.List name="rules">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <FieldContainer key={index} direction="horizontal">
                <RuleNumberText>{`Rule ${index + 1}`}: </RuleNumberText>
                <RuleContainer {...field}>
                  <RuleInput autoSize disabled={isDisabled} />
                </RuleContainer>
                <Spacing>
                  <MinusButton
                    icon={
                      <Icon
                        icon={MinusCircleIcon}
                        color={theme.colors.text.secondary.default}
                      />
                    }
                    onClick={() => remove(field.name)}
                  />
                </Spacing>
              </FieldContainer>
            ))}
            <Form.Item>
              <Spacing items="center">
                <AddButton
                  icon={<Icon icon={PlusCircleIcon} />}
                  onClick={() => add()}
                />
              </Spacing>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Container>
  );
};
