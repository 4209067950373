import { Dispatch, SetStateAction } from "react";
import { SecondaryButton, TransparentButton } from "@dip/ui/components/buttons";
import { Popover } from "@dip/ui/components/popover";
import styled from "@emotion/styled";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { Row } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Select } from "@unlikelyai-magic/ui/inputs";
import { NodeFilters } from "./types";

const StyledSelect = styled(Select)`
  width: 9.375rem;
  padding-left: ${({ theme }) => theme.spacings.sm};

  .react-select__option--is-selected {
    background: ${({ theme }) => theme.colors.action.primary.default};
    color: ${({ theme }) => theme.colors.text.dark.default};
  }
`;

const FiltersButton = styled(SecondaryButton)`
  height: 100%;

  &:disabled,
  &:disabled:hover {
    background: ${({ theme }) => theme.colors.component.background[2]};
  }
`;

const DEFAULT_FILTERS: NodeFilters = {
  type: "all",
};

const TYPE_OPTIONS: { value: NodeFilters["type"]; label: string }[] = [
  { value: "all", label: "All" },
  { value: "resolved", label: "Resolved" },
  { value: "coined", label: "Coined" },
];

/**
 * Gets the option object from the value
 * Ideally not required but the antd select is pretty annoying
 * @param value The value to get the option for
 */
const typeOptionFromValue = (value: NodeFilters["type"]) =>
  TYPE_OPTIONS.find((option) => option.value === value);

type DomainNodesFiltersProps = {
  filters: NodeFilters;
  setFilters: Dispatch<SetStateAction<NodeFilters>>;
  isDisabled?: boolean;
};

export const DomainNodesFilters = ({
  filters,
  setFilters,
  isDisabled,
}: DomainNodesFiltersProps) => {
  const clearFilters = () => setFilters(DEFAULT_FILTERS);

  const FiltersContent = (
    <Row align="middle">
      Type:
      <StyledSelect
        value={typeOptionFromValue(filters.type)}
        onChange={(value) => setFilters({ ...filters, type: value.value })}
        options={TYPE_OPTIONS}
      />
    </Row>
  );

  const hasFilters = filters.type !== DEFAULT_FILTERS.type;

  return (
    <Row>
      <Popover placement="bottom" trigger="click" content={FiltersContent}>
        <div>
          <FiltersButton
            icon={<Icon icon={FunnelIcon} />}
            disabled={isDisabled}
          >
            Filters
          </FiltersButton>
        </div>
      </Popover>
      {hasFilters && (
        <TransparentButton onClick={clearFilters} disabled={isDisabled}>
          Clear
        </TransparentButton>
      )}
    </Row>
  );
};
