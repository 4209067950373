import { ComponentProps, forwardRef, useEffect } from "react";
import { theme } from "@dip/theme";
import { ActionsMenu } from "@dip/ui/components/actions-menu";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import { Form } from "@dip/ui/components/forms";
import styled from "@emotion/styled";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "antd";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { CustomElement } from "@unlikelyai-magic/utils";
import { ULRuleInput } from "./ULRuleInput";

/* It seems like styled components messes with generic typing of the Form component.
Using styled(Form) below leads to type errors on the onFinish and onValuesChange props below.
These type errors are coming from the fact that, when used this way, Form does not recognize
the RulesForm type as the type of form data (which should be inferred from the "form" prop also
passed into the component). The type is unknown instead.

The suspicion is that, if the type of one prop depends on the type of another prop passed into
the same component, we will always have to use this hack, although this assumption has not been verified.
*/
const Container = styled.div`
  padding: 1rem;
`;

const FieldContainer = styled(Spacing)`
  padding-bottom: 1.5rem;
`;

const RuleContainer = styled(Form.Item)`
  flex: 1;
  margin-bottom: 0;
`;

const RuleNumberText = styled.div`
  padding-top: ${({ theme }) => theme.spacings.sm};
  color: ${({ theme }) => theme.colors.action.dark.hover};
  font-family: monospace;
`;

const RuleNumberButton = styled(
  forwardRef<HTMLButtonElement, ComponentProps<typeof SecondaryButton>>(
    (props, ref) => <SecondaryButton {...props} />
  )
)`
  height: 1.25rem;
  margin-top: 0.6rem;
  color: ${({ theme }) => theme.colors.action.dark.hover};
  font-family: monospace;
`;

const AddButton = styled(PrimaryButton)`
  width: 25%;
`;

const InputActionsButton = styled(
  forwardRef<HTMLButtonElement, ComponentProps<typeof SecondaryButton>>(
    (props, ref) => <SecondaryButton {...props} />
  )
)`
  border: none;
  background-color: transparent;
  margin: auto;
`;

export type ULFieldName = "exclusions" | "representation";

type ULTabContentProps = {
  fieldName: ULFieldName;
  onSave?: () => void;
  isDisabled?: boolean;
};

export const ULTabContent = ({
  fieldName,
  onSave,
  isDisabled = false,
}: ULTabContentProps) => {
  // allow the user to save their edits with cmd+s (or ctrl+s on windows)
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        onSave?.();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onSave]);

  return (
    <Container>
      <Form.List name={fieldName}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <FieldContainer key={index} direction="horizontal">
                <RuleNumberText>Rule</RuleNumberText>
                <ActionsMenu
                  actions={[...Array(fields.length).keys()].map(
                    (desiredIndex) => ({
                      text: (desiredIndex + 1).toString(),
                      onClick: () => move(index, desiredIndex),
                    })
                  )}
                >
                  <RuleNumberButton disabled={isDisabled}>
                    {index + 1}
                  </RuleNumberButton>
                </ActionsMenu>
                <RuleNumberText>:</RuleNumberText>
                <RuleContainer
                  {...field}
                  trigger="onValueChange"
                  valuePropName="content"
                  getValueFromEvent={(value: CustomElement[]) =>
                    value?.[0]?.children?.[0]?.text
                  }
                >
                  <ULRuleInput readOnly={isDisabled} disabled={isDisabled} />
                </RuleContainer>
                <Spacing direction="horizontal">
                  <Tooltip title="Add rule below">
                    <InputActionsButton
                      icon={<Icon icon={PlusCircleIcon} />}
                      onClick={() => add(`Rule ${index + 2}`, index + 1)}
                      disabled={isDisabled}
                    />
                  </Tooltip>
                  <InputActionsButton
                    icon={
                      <Icon
                        icon={MinusCircleIcon}
                        color={theme.colors.text.secondary.default}
                      />
                    }
                    onClick={() => remove(field.name)}
                    disabled={isDisabled}
                  />
                </Spacing>
              </FieldContainer>
            ))}
            <Form.Item>
              <Spacing items="center">
                <AddButton
                  icon={<Icon icon={PlusCircleIcon} />}
                  onClick={() => add()}
                  disabled={isDisabled}
                />
              </Spacing>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Container>
  );
};
