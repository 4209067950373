import React from "react";
import { Tag } from "antd";

export const StatementCardError = ({ error }: { error: string }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Tag color={"red"}>{error}</Tag>
    </div>
  );
};
