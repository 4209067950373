import React from "react";
import styled from "@emotion/styled";
import { CardBody } from "./CardBody";
import { CardContainer, CardContainerProps } from "./CardContainer";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";

export type CardProps = CardContainerProps & {
  onClick?: () => void;
};

const ClickableCardContainer = styled(CardContainer)`
  cursor:  pointer;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.lg}
`;

export const Card = ({ onClick, ...props }: CardProps) => {
  const clickable = Boolean(onClick);
  const Container = clickable ? ClickableCardContainer : CardContainer;

  return <Container {...props} onClick={onClick} />;
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
