import { UlElement, isUlPassage, isUlUuid } from "../ul/ul-element";

const WHAT = "a57cf61b-4ad7-40d0-8185-de886cc9bec6";
export const IS_IT_TRUE_THAT = "884e61d4-7278-4fe2-8858-74b662bf6076";

/**
 * Returns true if the given ul element looks like a question, false otherwise.
 */
export function looksLikeAQuestion(ul: UlElement) {
  if (!isUlPassage(ul) || ul.elements.length !== 2) {
    return false;
  }
  const header = ul.elements[0];

  // (What X Y Z ...)
  if (
    isUlPassage(header) &&
    header.elements.length > 1 &&
    isUlUuid(header.elements[0]) &&
    header.elements[0].uuid === WHAT
  ) {
    return true;
  }

  // IsItTrueThat
  return isUlUuid(header) && header.uuid === IS_IT_TRUE_THAT;
}
