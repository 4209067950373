import {
  UpdateDomainContentRequest,
  UpdateDomainContentResponse,
} from "@dip/data-access/api-types";
import { Form } from "@dip/ui/components/forms";
import { TextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from "@reduxjs/toolkit/query";
import { CustomElement } from "@unlikelyai-magic/utils";
import { RulesForm } from "../layouts";

const DescriptionBox = styled(TextBox)`
  // Header bar is 3rem and description title is 2.5rem
  height: calc(100vh - 5.5rem);
  overflow: auto;
  border-radius: 0;
`;

type DescriptionProps = {
  domainId?: string;
  versionId?: number;
  currentDescription: string;
  descriptionFieldName: string;
  updateDomainContent: MutationTrigger<
    MutationDefinition<
      UpdateDomainContentRequest,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      "Domains",
      UpdateDomainContentResponse,
      "dipApi"
    >
  >;
  isDisabled?: boolean;
};

export const Description = ({
  domainId,
  versionId,
  currentDescription,
  descriptionFieldName,
  updateDomainContent,
  isDisabled = false,
}: DescriptionProps) => {
  const form = Form.useFormInstance<RulesForm>();
  /* TODO (ALL-287): We really shouldn't allow the user to edit the content of the domain directly. A better UX is needed!
  When addressed, this component should just go back to being a read-only TextBox with the description content.
  */

  const handleUpdateContent = () => {
    if (!domainId || !versionId) return;

    const { content } = form.getFieldsValue(true);

    // if the field value has not changed, do not send an API request
    if (content[descriptionFieldName] === currentDescription) return;

    updateDomainContent({ domainId, domainVersion: versionId, content });
  };

  return (
    <Form.Item
      name={["content", descriptionFieldName]}
      trigger="onValueChange"
      valuePropName="content"
      getValueFromEvent={(value: CustomElement[]) =>
        value?.[0]?.children?.[0]?.text
      }
    >
      <DescriptionBox
        content=""
        disabled={isDisabled}
        readOnly={isDisabled}
        header="Description"
        onBlur={handleUpdateContent}
      />
    </Form.Item>
  );
};
